import { useState, useEffect, useCallback } from "react";
import Button from "../../General/Button";
import Pagination from "../../General/Pagination";
import Spinner from 'react-bootstrap/Spinner';
import { getMyReports, deleteMyReports, getMyReportsSaas } from "../../../services/reportsService";
import i18n from "../../../i18n";
import { toast } from "react-hot-toast";

const MyReports = (props) => {
  const [data, setData] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [reportsToDelete, setReportsToDelete] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);
  const [enableDeleteButton, setEnableDeleteButton] = useState(false);  

  const handlePageChange = useCallback(({ startIndex, endIndex }) => {
    setDisplayedData(data.slice(startIndex, endIndex));
  }, [data]);

  const getStoredReports = () => {
    setShowSpinner(true);
    props.isSaas ? (
    getMyReportsSaas().then((res) => {
      setData(res);
      setShowSpinner(false);
    }))
    :
    (
      getMyReports().then((res) => {
        setData(res);
        setShowSpinner(false);
      })
    )
  }

  useEffect(() => {
    getStoredReports();
  }, [props.isSaas]);

  const handleViewReport = (item) => {
    props.handleStoredReport(item);
  }

  useEffect(() => {
    if (reportsToDelete.length > 0) {
      setEnableDeleteButton(true);
    } else {
      setEnableDeleteButton(false);
    }
  }, [reportsToDelete]);

  const handleCheckboxChange = (item) => {
    if (reportsToDelete.includes(item.id)) {
      setReportsToDelete(reportsToDelete.filter(id => id !== item.id));
    } else {
      setReportsToDelete([...reportsToDelete, item.id]);
    }
  };

  const handleDelete = async () => {
    try {
      setEnableDeleteButton(false);
      setShowSpinner(true);      
      const result = await deleteMyReports(reportsToDelete);
      if (result) {
        toast.success("Reports deleted successfully");
        setReportsToDelete([]);
        getStoredReports();
      } else {
        toast.error("Failed deleting reports");
      }

    } catch (error) {
      toast.error("An error occurred while saving the report");
      console.error("Error in handleSave:", error);
    }
  }
 
  return (
    <div className="divMyReports">
      <div className="divNewReportMyReports">
        <button onClick={enableDeleteButton ? handleDelete : undefined} className={`mediumSizeButton ${enableDeleteButton ? 'btnDeleteMyReports' : 'btnDeleteMyReportsDisable'}`}>
          Delete
        </button>
        <Button
          text="New Report"
          title="New Report"
          className="mediumSizeButton"
          onClick={props.handlePerformanceReport}
        />
      </div>        
      <table className="tableMyReports">
        <thead>
          <tr className="trMyReportsHead">
            <th className="thMyReports thSmallMyReports thMyReportsRadiusTopLeft" />
            <th className="thMyReports">{i18n.advanceReportsReportName}</th>
            <th className="thMyReports">{i18n.advanceReportsLastModified}</th>
            <th className="thMyReports">{i18n.advanceReportsReportId}</th>
            <th className="thMyReports thMyReportsRadiusTopRight" />
          </tr>
        </thead>
        <tbody>
          {showSpinner ? (
            <tr className="trMyReports">
              <td className="backgroundWhite" />
              <td className="backgroundWhite" />
              <td className="divSpinnerMyReports backgroundWhite">
                <Spinner className="loaderSpinner" animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              </td>
              <td className="backgroundWhite" />
              <td className="backgroundWhite" />
            </tr>
          ) : (
            data == [] || data.length == 0 ? (
              <tr className="trMyReports">
              <td />
              <td />
              <td className="divSpinnerMyReports">
                <h3>No reports stored</h3>
              </td>
              <td />
              <td />
            </tr>
            ) :(
              displayedData.map((item, index) => (
                <tr key={index} className="trMyReports">
                  <td className="tdMyReports tdSmallMyReports">
                    <input
                      key={item.id}
                      type="checkbox"
                      value={item.id}
                      checked={reportsToDelete.includes(item.id)}
                      onChange={() => handleCheckboxChange(item)}
                    />
                  </td>
                  <td className="tdMyReports">{item.name}</td>
                  <td className="tdMyReports">
                    {item.modifiedAt != null 
                      ? new Date(item.modifiedAt).toLocaleString('es-ES', { timeZoneName: 'short' }) 
                      : new Date(item.createdAt).toLocaleString('es-ES', { timeZoneName: 'short' })
                    }
                  </td>
                  <td className="tdMyReports">{item.id}</td>
                  <td className="tdMyReports"><a className="aViewMyReports" onClick={() => handleViewReport(item)}>View</a></td>
                </tr>
              ))
            )            
          )}
        </tbody>
      </table>
      <Pagination
        totalItems={data.length}
        itemsPerPage={10}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

export default MyReports;