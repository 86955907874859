import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const get_all_selected_deal_client = createAsyncThunk("get_all_selected_deal_client", async ({dealId},thunkAPI) => {
try {
    const myHeaders = new Headers();
myHeaders.append("Authorization", "Bearer "+localStorage.getItem('token'));

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow"
};

const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/getdealclient?dealId=${dealId}`, requestOptions)
if (!response.ok) {
    const errorMessage = await response.json();
    if (errorMessage) {
        throw new Error(errorMessage.message);
    }
}
const result = await response.json();
return result;
} catch (error) {
    return thunkAPI.rejectWithValue({
        message: error.message,
    });
}
})

export const getAllSelectedDealClient = createSlice({
    name: "getAllSelectedDealClient",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_get_all_selected_deal_clients: (state) => {
            state.data = []
            state.isSuccess = false
            state.isError = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_all_selected_deal_client.pending, (state) => {
                state.loading = true
            })
            .addCase(get_all_selected_deal_client.fulfilled,(state,action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(get_all_selected_deal_client.rejected,(state,action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const {clear_get_all_selected_deal_clients} = getAllSelectedDealClient.actions
export default getAllSelectedDealClient.reducer