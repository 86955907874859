import React, { useState, useRef, useEffect } from "react";

import "./stepstyle.css";
import NewCompaign from "../Campaign/newCompaign";

import Subtitle from "../Subtitle/subtitle";
const StepForm = () => {




    return (
        <div className="content_outer">
            <Subtitle subtitle="Campaign Manager" />
            <div className="content">
                <div className="step_form_wrapper pt-4">
                    <ul className="multistep_header">
                        <li className="active_form">
                            <p className="number">1</p>
                            <p className="step_heading">New Campaign</p>
                        </li>
                        <li>
                            <p className="number">2</p>
                            <p className="step_heading">New Line Item</p>
                        </li>
                        <li>
                            <p className="number">3</p>
                            <p className="step_heading">New Creatives</p>
                        </li>
                    </ul>
                </div>
                <div>
                <NewCompaign/>
                </div>

            </div>
        </div>
    );
};
export default StepForm;
