export const incompatibleFormatEngagements = [
  'Pre Roll 30¨',
  'Branded Video',
  'Animated Banner',
  'Bumper Ad',
  'In-stream Skippable',
  'In-Stream Non Skippable'  
]


export const incompatibleFormatViews = [
  'Game Ad',
  'Animated Banner',
  'Interactive Banner',
  'Carousel',
  'Carousel Premium',
  'Interactive Video',
  'Filmstrip'
]