import React, { useState, useEffect } from 'react'
import { Table } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { lineitem_log_history } from '../../app/features/lineitemHistory/lineitemHistorySlice';
import Moment from "react-moment";
import Loader from '../Loader';
import { clear_log_history } from '../../app/features/lineitemHistory/lineitemHistorySlice';

const CampaignHistory = () => {
	const location = useLocation()
	const navigate = useNavigate()
	const dispatch = useDispatch()
	const [show, setShow] = useState(false)

	useEffect(() => {
		return () => {
			dispatch(clear_log_history())
		};
	}, [])

	const { id } = location?.state ? location?.state : location;

	useEffect(() => {
		dispatch(lineitem_log_history({ id, entityId: 0 }))
	}, [id])
	const historyData = useSelector((state) => { return state.log_history })

	useEffect(() => {
		if (historyData?.loading) {
			setShow(true)
		}
		else {
			setShow(false)
		}
	}, [historyData])

	const dates = [];

	historyData?.data?.data?.properties?.forEach((record) => {
		const modifiedTime = record?.propertyChanges[0]?.modifiedAt;
		const changedValues = record?.propertyChanges.map((data) => ({
			oldValue: data?.oldValue,
			newValue: data?.newValue,
			user: data?.modifiedBy,
			modifiedAt: data?.modifiedAt,
			propertyName: data?.propertyName
		}));
		dates.push({ modifiedTime, changedValues });
	});

	return (
		<div className='content_outer'>
			<Loader showLoader={show} />
			<div className="cmn_header_top campaign__header mb-3">
				<div className="row w-100 m-0">
					<div className="col-lg-11 m-auto">
						<h3><img src="/assets/images/chevron_left.svg" onClick={() => navigate(-1)} /> History</h3>

					</div>
				</div>
			</div>

			<div className="row m-0">
				<div className="col-lg-11 m-auto">
					<div className="title__active mt-3">
						<h3>Campaign states</h3>
						<div className="status_badge">
							<p>
								<img src="/assets/images/check_circle.svg" /><span className="text-secondary">
									Active</span>
							</p>
						</div>
					</div>
					<div className='advert_tabel history_list'>

						<div className='media_plan_info_list mb-5'>
							<ul>
								<li>
									<strong>ID</strong>
									<p>{historyData?.data?.data?.campaignValue?.campaign?.id}</p>
								</li>
								<li>
									<strong>Campaign Name</strong>
									<p>{historyData?.data?.data?.campaignValue?.campaign?.name}</p>
								</li>
								<li>
									<strong>Status</strong>
									<p>{historyData?.data?.data?.campaignValue?.campaign?.statusNavigation?.description}</p>
								</li>
								<li>
									<strong>Budget</strong>
									<p>{historyData?.data?.data?.campaignValue?.campaign?.budge}</p>
								</li>
								<li>
									<strong>Budget USD</strong>
									<p>{historyData?.data?.data?.campaignValue?.campaign?.budgetUsd}</p>
								</li>
								<li className='flex-grow-1'>
									<ul className="header__media p-0 justify-content-end">
										<li className='flex-grow-0'>
											<svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" height="20" width="20" xmlns="http://www.w3.org/2000/svg">
												<path d="M7 11h2v2H7zm0 4h2v2H7zm4-4h2v2h-2zm0 4h2v2h-2zm4-4h2v2h-2zm0 4h2v2h-2z"></path>
												<path d="M5 22h14c1.103 0 2-.897 2-2V6c0-1.103-.897-2-2-2h-2V2h-2v2H9V2H7v2H5c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2zM19 8l.001 12H5V8h14z"></path>
											</svg>
											<strong>Start Date:</strong>
											<p><time dateTime="1721759400000"><Moment format='  Do MMMM YYYY'>{historyData?.data?.data?.campaignValue?.campaign?.dateFrom}</Moment></time></p>
										</li>
										<li className="block_divider"><span></span></li>
										<li className='grow-1'>
											<strong>End Date:</strong>
											<p><time dateTime="1721759400000"><Moment format='  Do MMMM YYYY'>{historyData?.data?.data?.campaignValue?.campaign?.dateTo}</Moment></time></p>
										</li>
									</ul>
								</li>

							</ul>
						</div>
					</div>

					{historyData?.data?.data?.properties.length != 0 ?
						<div className='list_wrapper'>
							{dates.map((val, i) => {
								if (val?.changedValues?.length !== 0) {
									return (
										<div className='list_items' key={i}>
											<div className='history_grid mb-5'>
												<h4>
													<Moment format='MMMM Do'>
														{val?.modifiedTime}
													</Moment>
													{" "}at{" "}
													<Moment format='HH:mm'>
														{val?.modifiedTime}
													</Moment>
													&nbsp;(Time zone UTC +0)
												</h4>
												<div className="advert_tabel campign_index_table">
													<Table responsive>
														<thead>
															<tr>
																<th className='text-start w-50'>Description</th>
																<th className='text-start'>User</th>
															</tr>
														</thead>
														<tbody>
															{val?.changedValues?.map((data, index) => {
																if (data?.propertyName !== "WhiteListId" || data?.propertyName !== "BudgetKickbackDeal") {
																	return (
																		<tr key={index}>
																			<td className='align-top'>
																				<div>
																					{data?.propertyName === "DateFrom" || data?.propertyName === "DateTo" ? <Accordion>
																						<Accordion.Item eventKey="0">
																							<Accordion.Header>
																								{data?.propertyName === "DateFrom" ? "Date From" : "Date To"}
																							</Accordion.Header>
																							<Accordion.Body>
																								<b>
																									From {<Moment format='l'>{data?.oldValue}</Moment>} to {<Moment format='l'>{data?.newValue}</Moment>}
																								</b>
																							</Accordion.Body>
																						</Accordion.Item>
																					</Accordion> :
																						<Accordion>
																							<Accordion.Item eventKey="0">
																								<Accordion.Header>
																									{data?.propertyName === "Is_billable" ? "Billing Status" :
																										data?.propertyName === "Budge" ? "Budget" :
																											data?.propertyName === "BudgetUsd" ? "Budget USD" :
																												data?.propertyName}
																								</Accordion.Header>
																								<Accordion.Body>
																									<b>
																										From {typeof (data?.oldValue) === "number" ? Number(data?.oldValue)?.toLocaleString() : data?.oldValue} to {typeof (data?.newValue) === "number" ? Number(data?.newValue)?.toLocaleString() : data?.newValue}
																									</b>
																								</Accordion.Body>
																							</Accordion.Item>
																						</Accordion>
																					}
																				</div>
																			</td>
																			<td className='align-middle'>
																				{data?.user}
																			</td>
																		</tr>
																	)
																}
															})}
														</tbody>
													</Table>
												</div>
											</div>
										</div>
									);
								}
							})}
						</div>
						: <div className="no_data_cmn mt-3 bg-white"><img className="nodata" src="/assets/images/notfound.svg" /></div>
					}
				</div>
			</div>

		</div>
	)
}

export default CampaignHistory;