import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const changeNewUserPassword = createAsyncThunk("changeNewUserPassword", async ({ new_password, token }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "token": token,
        "new_password": new_password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };

    try {
        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}Auth/passwordreset/resetpassword`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const change_new_user_password = createSlice({
    name: 'change_new_user_password',
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_password_reset_state: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(changeNewUserPassword.pending, (state) => {
            state.loading = true
        })
            .addCase(changeNewUserPassword.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(changeNewUserPassword.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_password_reset_state } = change_new_user_password.actions;
export default change_new_user_password.reducer;