import "./style.css";

const Button = (props) => {
  return (
    <button
      className={`regularButton ${props.className}`}
      onClick={props.onClick}
      title={props.title}
    >
      {props.text}
    </button>
  )
}

export default Button;