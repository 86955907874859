
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-hot-toast";
import WhiteList from "./whiteList";

const LineItemList = (props) => {
  const {list, defaultList, excludeItems, getData, listMode, isBlackList} = props;
  const params = useParams();
  const [disableUpdateExclude, setDisableUpdateExclude] = useState(true);
  const [checkedExclude, setCheckedExclude] = useState(excludeItems);
  const [loadershown, setloadershown] = useState(false);

  const enableExcludeList = () => {
		setDisableUpdateExclude(false);
	}

  useEffect(() => {
    setCheckedExclude(excludeItems);
  }, [excludeItems]);

  const handleChange = (e, id, title) => {
		if(e.target.checked) {
      setCheckedExclude([...checkedExclude, {appid: id, title: title}]);
		} else {
			setCheckedExclude(checkedExclude.filter(excludedId => excludedId.appid !== id));
		}
	};

  const listHasDifferences = (listToUpdate) => {
    let hasDifferences = false;
    for(let i=0; i<list.length; i++) {
      if(list[i].isFromDefault) {
        const matchingItemInList = listToUpdate.find(item => item.appid === list[i].appid)
        if(list[i].exclude === true) {
          // If the item is in the defaultList as exclude and it is not in the listToUpdate
          if(!matchingItemInList) {
            hasDifferences = true;
            break;
          }
        } else {
          // If the item is not in the defaultList as exclude and it is in the listToUpdate
          if(matchingItemInList) {
            hasDifferences = true;
            break;
          }
        }
      }
    }
    return hasDifferences;
  }

  const updateExcludeList = async (listToUpdate) => {
    if(listHasDifferences(listToUpdate)) {
      const listKey = listToUpdate.map((str) => ({ 'appid': str.appid, 'title': str.title }));
		  setloadershown(true)
		  var raw = JSON.stringify({
		  	"exclude": listKey,
		    });
		  const response = await fetch(
		  	`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/excludecampaignitems/${params.id}`,
		  	{
		  		method: "POST",
		  		headers: {
		  			Authorization: "Bearer " + localStorage.getItem("token"),
		  			'Content-Type':'application/json',
		  		},
		  		body: raw,
		  	}
		  );
		  setDisableUpdateExclude(true);
      getData();
		  toast.success("Updated list!");
		  setloadershown(false);
      } else {
        toast.success("No changes on the list to update");
        setDisableUpdateExclude(true);
      }    
	};

  const handleChangeListMode = async (e) => {
		setloadershown(true)
    const listMode = e.target.checked ? 1 : 0
		await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/changestatuslistmode/${params.id}/${listMode}`,
			{
				method: "PUT",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
					'Content-Type':'application/json',
				},
			}
		);
    getData();    
		toast.success("List mode status changed!");
		setloadershown(false);
	};

  const downloadList = async (e) => {
    let listType = 'downloadwhitelist'
		setloadershown(true)
    if(isBlackList) {
      listType='downloadblacklist'
    }
		await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/${listType}/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
					'Content-Type':'application/json',
				},
			}
		).then(response => response.blob()
    .then(blob => {
      let fileName='whitelist' + Date.now() + '.xlsx';
      if(isBlackList){
        fileName='blacklist' + Date.now() + '.xlsx';
      }
      const element = document.createElement("a");
      element.href = URL.createObjectURL(blob);
      element.download = fileName;
      element.hidden = true;
      document.body.append(element);
      element.click();
      element.remove();
      toast.success("Downloaded list!");
    }));
		setloadershown(false);
	};

  return (
    <div className="new_compaign">
      {defaultList ? (
        <div className="campaign_up_list">
          <div className="form-group sass_checkbox access_login">   
            <label className="enableList">Enable/disable list</label>
            <input
              type="checkbox"
              checked={listMode}
              className="mb-0"
              onChange={(e) => handleChangeListMode(e)}            
            />
          </div>      
          <button className="cmn_search_btn btnDownloadFileList"
            onClick={() => disableUpdateExclude ? enableExcludeList() : updateExcludeList(checkedExclude)}
          >
            {disableUpdateExclude ? 'Edit List' : 'Save List'}
          </button>
        </div>
        ) : (
          <div className="divBtnDownloadFile">
            <button className="cmn_search_btn btnDownloadFileList" onClick={() => downloadList()}>Download file</button>
          </div>
        )
      }
      <div className="lineItemListPadding">
        <div className="campiagn_outer border-0 p-0">
          <div className="advert_tabel">
            <div className="table-responsive">
              <table className="table table-striped mt-0">
                <thead>
                  <tr>
                    <th className="text-center">Title</th>
                    <th className="text-center">Id</th>
                    {defaultList && <th className="text-center">Exclude</th>}
                  </tr>
                </thead>
                <tbody>
                  {!list ? (
                    <div>
                      {" "}
                      <img
                        className="nodata"
                        src="/assets/images/notfound.svg"
                      />{" "}
                    </div>
                  ) : (
                    list?.map((data, i) => {
                    if (!defaultList || data.isFromDefault) {
                      return (
                        <>
                          <tr key={i}>
                            <td className="text-center">
                              {data?.title}
                            </td>
                            <td className="text-center">
                              {data?.appid}
                            </td>
                            {defaultList &&
                              <td className="text-center">
                                <input
                                  defaultChecked={data.exclude}
                                  disabled={disableUpdateExclude ? true : false}
                                  onChange={(e) => handleChange(e, data?.appid, data?.title)}
                                  type="checkbox"
                                  className="mb-0"
                                />
                              </td>
                            }
                          </tr>
                        </>
                      );
                    }
                    })
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default LineItemList;
