import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const lineitem_log_history = createAsyncThunk("lineitem_log_history", async ({id ,entityId}, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            redirect: 'follow'
        };

        // const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getchangehistory?lineitemid=${id}`, requestOptions)
        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getchangehistory?entityId=${id}&entityType=${entityId}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const lineitemLogHistory = createSlice({
    name: "lineitemLogHistory",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers : {
      clear_log_history : (state) => {
        state.data = []
        state.isSuccess = false
        state.isError = false
        state.loading = false
        state.error = null
      }
    },
    extraReducers: (builder) => {
        builder
            .addCase(lineitem_log_history.pending, (state) => {
                state.loading = true
            })
            .addCase(lineitem_log_history.fulfilled, (state, action) => {
                state.loading = false
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
            })
            .addCase(lineitem_log_history.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_log_history } = lineitemLogHistory.actions;
export default lineitemLogHistory.reducer