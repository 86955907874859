import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_user_advertise_clients = createAsyncThunk("get_user_advertise_clients", async ({advertiser_id},thunkAPI) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/getclients?advertiserId=${advertiser_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const getUserAdvertiseClients = createSlice({
    name: "getUserAdvertiseClients",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_user_client_list_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_user_advertise_clients.pending, (state) => {
                state.loading = true
            })
            .addCase(get_user_advertise_clients.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(get_user_advertise_clients.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export const { clear_user_client_list_state } = getUserAdvertiseClients.actions
export default getUserAdvertiseClients.reducer