import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const update_optimize_tab_saas = createAsyncThunk("update_optimize_tab_saas", async ({ updated_items }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var raw = JSON.stringify({
            "KiteLineitemupdateDto": updated_items
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/updatelineitemoptimization`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const updateOptimizeTabSaas = createSlice({
    name: "updateOptimizeTabSaas",
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_update_optimize_tab_saas_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(update_optimize_tab_saas.pending, (state) => {
                state.loading = true
            })
            .addCase(update_optimize_tab_saas.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(update_optimize_tab_saas.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export const { clear_update_optimize_tab_saas_state } = updateOptimizeTabSaas.actions
export default updateOptimizeTabSaas.reducer