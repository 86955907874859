import i18n from "../../../i18n";

const incompatibleData = [
  {selectedRow: i18n.advanceReportsAppName, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedRow: i18n.advanceReportsCreative, incompatibleRow: i18n.advanceReportsCountryYoutube},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleRow: i18n.advanceReportsCountryApp},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleRow: i18n.advanceReportsAppName},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsClicks},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsClicks},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsCTR},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsCTR},
  {selectedColumn: i18n.advanceReportsQuartiles, incompatibleRow: i18n.advanceReportsCountryYoutube},
  {selectedColumn: i18n.advanceReportsQuartiles, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedRow: i18n.advanceReportsDevices, incompatibleRow: i18n.advanceReportsCountryYoutube},
  {selectedRow: i18n.advanceReportsDevices, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedRow: i18n.advanceReportsDate, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedRow: i18n.advanceReportsDate, incompatibleRow: i18n.advanceReportsAppName},
  {selectedRow: i18n.advanceReportsDevices, incompatibleRow: i18n.advanceReportsCreative},
  {selectedRow: i18n.advanceReportsDevices, incompatibleRow: i18n.advanceReportsCreativeId},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedRow: i18n.advanceReportsDevices, incompatibleRow: i18n.advanceReportsAppName},
  
  {selectedRow: i18n.advanceReportsDevices, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsAppName, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsCountryApp, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsCreative, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsCreativeId, incompatibleColumn: i18n.advanceReportsGoal},
  {selectedRow: i18n.advanceReportsDate, incompatibleColumn: i18n.advanceReportsGoal},

  {selectedRow: i18n.advanceReportsDevices, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsAppName, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCountryApp, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCreative, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCreativeId, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsDate, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},

  {selectedRow: i18n.advanceReportsDevices, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsAppName, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCountryApp, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCreative, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCreativeId, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsDate, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},

  {selectedRow: i18n.advanceReportsDevices, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsAppName, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCountryApp, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCreative, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsCreativeId, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},
  {selectedRow: i18n.advanceReportsDate, incompatibleColumn: i18n.advanceReportsInversionBudgetCurrency},

  {selectedRow: i18n.advanceReportsDevices, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsAppName, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsYoutubeChannel, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCountryApp, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCountryYoutube, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCreative, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsCreativeId, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  {selectedRow: i18n.advanceReportsDate, incompatibleColumn: i18n.advanceReportsInversionBudgetUSD},
  
  {selectedInventoryType: i18n.app, incompatibleRow: i18n.advanceReportsYoutubeChannel},
  {selectedInventoryType: i18n.app, incompatibleRow: i18n.advanceReportsCountryYoutube},
  {selectedInventoryType: i18n.youtube, incompatibleRow: i18n.advanceReportsCountryApp},
  {selectedInventoryType: i18n.youtube, incompatibleRow: i18n.advanceReportsAppName},

  {column: i18n.advanceReportsGoal, onlyCompatibleRow: i18n.advanceReportsLineItem},
  {column: i18n.advanceReportsGoal, onlyCompatibleRow: i18n.advanceReportsLineItemId},
  {column: i18n.advanceReportsInversionBudgetCurrency, onlyCompatibleRow: i18n.advanceReportsLineItem},
  {column: i18n.advanceReportsInversionBudgetCurrency, onlyCompatibleRow: i18n.advanceReportsLineItemId},
  {column: i18n.advanceReportsInversionBudgetUSD, onlyCompatibleRow: i18n.advanceReportsLineItem},
  {column: i18n.advanceReportsInversionBudgetUSD, onlyCompatibleRow: i18n.advanceReportsLineItemId},
]

export default incompatibleData;