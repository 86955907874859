import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const update_subgroup = createAsyncThunk("update_subgroup", async ({ selected_group_id, selected_group_name, updated_permissions }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var raw = JSON.stringify({
            "subgroupname": selected_group_name,
            "groupId": selected_group_id,
            "permissionsid": updated_permissions
        });

        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/updatepermissiongroup`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const updateSubgroup = createSlice({
    name: "updateSubgroup",
    initialState: {
        message: {},
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    }, reducers: {
        clear_update_subgroup_state: (state) => {
            state.message = {}
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(update_subgroup.pending, (state) => {
                state.loading = true
            })
            .addCase(update_subgroup.fulfilled, (state, action) => {
                state.message = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(update_subgroup.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export default updateSubgroup.reducer
export const { clear_update_subgroup_state } = updateSubgroup.actions