import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";


export const campaignfilter = createAsyncThunk(
  'filter/campaignfilter',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaign-filter`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const pipelinefilter = createAsyncThunk(
  'filter/pipelinefilter',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/pipeline-filter`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const statusfilter = createAsyncThunk(
  'filter/statusfilter',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/status-filter`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const advertizerfilter = createAsyncThunk(
  'filter/advertizerfilter',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/advertisers/get`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getowners = createAsyncThunk(
  'filter/getowners',
  async ({ token, ownergroup }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}deal/owners/get?groupId=${ownergroup}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const applyfilter= createAsyncThunk(
  'filter/applyfilter',
  async({token, filter, page, pageSize}, thunkAPI)=>{
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deals?page=${page}&pageSize=${pageSize}`,filter, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)


export const dealdetail=createAsyncThunk(
  'filter/dealdetail',
  async({token, dealId}, thunkAPI)=>{
    try{
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/deal/${dealId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)


export const kite_products=createAsyncThunk(
  'filter/kite_products',
  async({token, clientId}, thunkAPI)=>{
    try{
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/kite-products?clientId=${clientId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)


export const deal_currency=createAsyncThunk(
  'filter/deal_currency',
  async({token, dealId}, thunkAPI)=>{
    try{
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/mp-currency/${dealId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const storeCampaign=createAsyncThunk(
  'filter/storeCampaign',
  async (values , thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-campaign`, values,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + values?.token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const updateCampaign=createAsyncThunk(
  'filter/updateCampaign',
  async ({values, id} , thunkAPI) => {
    try {
      return await axios.put(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-campaign/${id}`, values,
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + values?.token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const storeProduct=createAsyncThunk(
  'filter/storeProduct',
  async ({token, clientId, productName} , thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-product`, 
      {
        client_id: clientId,
        name: productName
       },
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)


export const CampaignManagerSlice = createSlice({
  name: 'campaignManager',
  initialState: {
    advertizer_filters: [],
    campaign_filters: [],
    pipeline_filter: [],
    status_filter: [],
    owner_groups: [],
    filtered_result: [],
    kiteProducts:[],
    deal_detail:{},
    dealCurrency:'',
    isCampaignSaved:false,
    isCampaignupdated:false,
    isFetching: false,
    isSuccess: false,
    isError: false,
    error: '',
    isFetchingstoreProduct:false,
    isSuccessstoreProduct: false,
    isErrorstoreProduct: false
  },
  reducers: {
    clearState: (state) => {
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      state.isFetchingstoreProduct = false;
      state.isSuccessstoreProduct = false;
      state.isErrorstoreProduct = false;
      state.isCampaignSaved=false;
      state.isCampaignupdated=false;
      state.error="";
      return state;
    },
    clearcampaignState:(state)=>{
      state.isFetching = false;
      state.isSuccess = false;
      state.isError = false;
      return state;
    }

  },
  extraReducers: {
    [campaignfilter.fulfilled]: (state, { payload }) => {
      state.campaign_filters = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [campaignfilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [campaignfilter.pending]: (state) => {
      state.isFetching = true;
    },
    [pipelinefilter.fulfilled]: (state, { payload }) => {
      state.pipeline_filter = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [pipelinefilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [pipelinefilter.pending]: (state) => {
      state.isFetching = true;
    },
    [statusfilter.fulfilled]: (state, { payload }) => {
      state.status_filter = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [statusfilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [statusfilter.pending]: (state) => {
      state.isFetching = true;
    },
    [advertizerfilter.fulfilled]: (state, { payload }) => {
      state.advertizer_filters = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [advertizerfilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [advertizerfilter.pending]: (state) => {
      state.isFetching = true;
    },
    [getowners.fulfilled]: (state, { payload }) => {
      state.owner_groups = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [getowners.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [getowners.pending]: (state) => {
      state.isFetching = true;
    },
    [applyfilter.fulfilled]: (state, { payload }) => {
      state.filtered_result = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [applyfilter.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [applyfilter.pending]: (state) => {
      state.isFetching = true;
    },
    [dealdetail.fulfilled]: (state, { payload }) => {
      state.deal_detail = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [dealdetail.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [dealdetail.pending]: (state) => {
      state.isFetching = true;
    },
    [kite_products.fulfilled]: (state, { payload }) => {
      state.kiteProducts = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [kite_products.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [kite_products.pending]: (state) => {
      state.isFetching = true;
    },

    [deal_currency.fulfilled]: (state, { payload }) => {
      state.dealCurrency = payload.data;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [deal_currency.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [deal_currency.pending]: (state) => {
      state.isFetching = true;
    },

    [storeCampaign.fulfilled]: (state, { payload }) => {
      state.isCampaignSaved = true;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [storeCampaign.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [storeCampaign.pending]: (state) => {
      state.isFetching = true;
    },
    [updateCampaign.fulfilled]: (state, { payload }) => {
      state.isCampaignupdated = true;
      state.isFetching = false;
      state.isSuccess = true;
      state.isError = false;
    },
    [updateCampaign.rejected]: (state, { payload }) => {
      state.isFetching = false;
      state.isError = true;
      state.error = payload;
    },
    [updateCampaign.pending]: (state) => {
      state.isFetching = true;
    },
    [storeProduct.fulfilled]: (state, { payload }) => {
      state.isFetchingstoreProduct = false;
      state.isSuccessstoreProduct = true;
      state.isErrorstoreProduct = false;
    },
    [storeProduct.rejected]: (state, { payload }) => {
      state.isFetchingstoreProduct = false;
      state.isErrorstoreProduct = true;
      state.error = payload;
    },
    [storeProduct.pending]: (state) => {
      state.isFetchingstoreProduct = true;
    },
  }
})
export const { clearState, clearcampaignState } = CampaignManagerSlice.actions;
export const campaignManagerSelector = (state) => state.campaignManager;
