import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { toast } from "react-hot-toast";
import { useDispatch,useSelector } from 'react-redux';
import { resetPassword } from '../../app/features/Auth/resetPasswordSlice';
import { change_user_password } from '../../app/features/Auth/changePasswordSlice';
import { useNavigate } from 'react-router-dom';
export const Resetpassword = () => {
    const navigate = useNavigate();
    const params = useParams()
    const token = params.token
    const data = useSelector((state) => {return state.reset_user_password})
    const userPass = useSelector((state) => {return state.change_user_password})
      const dispatch = useDispatch()
    const [new_password, setNew_password] = useState("")
    const [confirm_password, setConfirm_password] = useState("")
    const [expired_token,setToken_expired] = useState(false)

    useEffect(() => {
        dispatch(resetPassword({token}))
  
    }, [token])
    useEffect(() => {
        if(userPass?.data?.data) { 
            toast.success("Password changed successfully")
             navigate('/')
            }
  
    }, [userPass])
    useEffect(() => {
        if(!data?.data?.data){
            setToken_expired(true)
        }
        else{
            setToken_expired(false)
        }
    }, [data])

    const handle_chnge_password = (e) => {
        e.preventDefault();
        if (new_password !== "" && confirm_password !== "") {
            if (new_password !== confirm_password) {
                toast.error("Password doesn't matched !!")
            }
            else {
               dispatch(change_user_password({token,new_password}))
            }
        }else{
            toast.error("All Fields are mandatory !!")
        }
    }
    return (
        <>
            <div className="sufee-login d-flex align-content-center flex-wrap">
                <div className="container">
                    <div className="login-content">
                        <div className="log_in_form">
                            <div className="login-logo"><a href="index.html"><img src="/assets/images/mariano.png" className="" /></a></div>
                            <div className="login-form">
                                {expired_token ? <h1>InvalidToken</h1> : <form className="">
                                    <div className="mb-3"><input name="password" placeholder="New password" type="password" id="exampleForm.ControlInput1" className="form-control" value={new_password} onChange={(e) => setNew_password(e.target.value)} /></div>
                                    <div className="mb-3"><input name="password" placeholder="Password" type="password" id="exampleForm.ControlTextarea1" className="form-control" value={confirm_password} onChange={(e) => setConfirm_password(e.target.value)} /></div>
                                    <button type="submit" onClick={(e) => handle_chnge_password(e)} className="mt-5 btn btn-primary">Update Password</button>
                                </form>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Resetpassword