import React from "react";
import { ButtonSC } from "./button.styled";

 const Button = ({ text, bg, color, onClick, disabled }) => {
	return (
		<ButtonSC bg={bg} color={color} onClick={onClick} disabled={disabled}>
			{text}
		</ButtonSC>
	);
};

export default Button;
