import React from 'react'
import Modal from 'react-bootstrap/Modal';
const CommonModal = ({ show, handleClose, handle_save, children, heading }) => {

    return (
        <Modal show={show} onHide={handleClose} centered>
            <Modal.Header closeButton>
                <Modal.Title>{heading}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="content pt-0">
                    {children}
                    <div className='d-flex justify-content-end mt-4'>
                        <button className='ms-auto me-4 cancel_cm_btn' onClick={handleClose}>
                            Cancel
                        </button>
                        <button className='cmn_btn ms-2' onClick={() => { handle_save() }}>
                            Update
                        </button>
                    </div>
                </div>
            </Modal.Body>

        </Modal>
    )
}

export default CommonModal