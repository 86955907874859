import React, { useState, useEffect } from 'react';
import Loader from '../Loader';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Col } from 'react-bootstrap';
import { toast } from "react-hot-toast";
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { userSelector, GetLanguages, GetRoles, GetGroups, GetAllCategories, GetSubGroups, clearSaveUser } from '../../app/features/Users/UserSlice';
import Multiselect from "multiselect-react-dropdown";
import { get_user_advertise_clients } from '../../app/features/Users/get_advertise_clients_slice';
const Adduser = () => {
	const initialValues = {
		last_name: "",
		first_name: "",
		email: "",
		group_id: "",
		role_id: "",
		language_key: "",
		subGroupId: "",
		token: localStorage.getItem('token')
	}
	const formikRef = React.useRef();
	const location = useLocation();
	const dispatch = useDispatch();
	const navigation = useNavigate();

	const [loadershown, setLoadershown] = useState(false);
	const [selectedClient, setSelectedClients] = useState(false)
	const [GroupId, setGroupId] = useState(false)
	const [reportUser, setReportUser] = useState(false)
	const [all_deals, setAll_deals] = useState([])
	const [dealsId, setDealsId] = useState([])
	const [all_client_list, setAll_client_list] = useState([])
	const [subgroup_id, setSubgroup_id] = useState()
	const [selected_clients, setSelected_clients] = useState([])
	const permission = JSON.parse(localStorage.getItem('userdata'));

	const client_list = useSelector((state) => { return state.all_user_clients })

	useEffect(() => {
		if (client_list?.isSuccess) {
			setAll_client_list(client_list?.data?.data[0])
		}
	}, [client_list])

	const {
		languages, roles,
		groups,
		subgroups, error, successmessage,
		isErrorSaveUser, isSuccessSaveUser
	} = useSelector(userSelector);

	const {
		advertiser_id,
		deal_Name,
		Agency_name,
		user,
		admin_value
	} = location.state ? location.state : location;

	useEffect(() => {
		if (advertiser_id) {
			dispatch(get_user_advertise_clients({ advertiser_id }))
		}
	}, [advertiser_id])

	useEffect(() => {
		dispatch(GetLanguages({ token: localStorage.getItem('token') })).then((language) => {
		})
		dispatch(GetRoles({ token: localStorage.getItem('token') })).then((roles) => {
		})
		dispatch(GetGroups({ token: localStorage.getItem('token') })).then((groups) => {
		})
		dispatch(GetAllCategories({ token: localStorage.getItem('token') })).then((categories) => {
		})
		get_all_deals()
	}, [])
	useEffect(() => {
		if (isSuccessSaveUser) {
			toast.success(successmessage)
			navigation("/userlist")
		}
		if (isErrorSaveUser) {
			toast.error(error)
		}
		dispatch(clearSaveUser())
	}, [isSuccessSaveUser, isErrorSaveUser])

	const changeGroup = (groupId) => {
		groupId == 5 ? setGroupId(true) : setGroupId(false)
		formikRef.current?.setFieldValue("subGroupId", "", false)
		dispatch(GetSubGroups({ token: localStorage.getItem('tokem'), groupId: groupId, advertiserId: advertiser_id }))
	}

	//this function is used to save the new user nitin 04/09/2023
	const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
		setLoadershown(true);
		const myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		const requestBody = {
			"last_name": values.last_name,
			"first_name": values.first_name,
			"email": values.email,
			"group_id": values?.group_id,
			"language_key": values?.language_key,
			"role_id": values?.role_id,
			"subGroupId": values?.subGroupId,
			"dealId": dealsId ? dealsId : [],
			"advertiser_id": advertiser_id ? advertiser_id : null,
			"advertiser_clients_id": null,
			"advertiserclientsid": selected_clients?.length !== 0 ? selected_clients : []
		};

		const requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: JSON.stringify(requestBody),
			redirect: 'follow'
		};

		try {
			const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/save`, requestOptions);
			if (!response.ok) {
				throw new Error("You cannot create a user with an existing email");

			}

			const result = await response.text();
			setLoadershown(false);
			navigation('/userlist', {
				state: {
					refresh: true,
					show_new_user: true,
					show_permission: true
				}
			});
		} catch (error) {
			toast.error(error.message);
			setLoadershown(false)

		}
	};

	const handleSetClients = (e) => {
		//this function is used to set the client id for selection of deals nitin 23/08/2023
		if (e.target.value == 3) {
			setSelectedClients(true)
		}
		else {
			setSelectedClients(false)
		}
	}

	const handleSelectSubGroup = (e) => {
		setSubgroup_id(e.target.value)
		//this function is used to set the subgroup true to display the kite deals to user nitin 23/08/2023
		e.target.value == 6 ? setReportUser(true) : setReportUser(false)
	}

	//this below function is used to get the list of all deals nitin 04/09/2023
	const get_all_deals = () => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/deals/get`, requestOptions)
			.then(response => response.json())
			.then(result => { setAll_deals(result?.data) })
			.catch(error => console.log('error', error));
	}

	//this function is coming from the multiselect input here i am saving the id of the deal
	const handle_multiselect_deals = (e) => {
		var allSelectedAdvertiserId = e.map((val) => val.id).filter(Boolean);
		setDealsId(allSelectedAdvertiserId);
	};

	const handle_multiselect_clients = (e) => {
		const all_selected_clients = e.map((val) => val.id).filter(Boolean);
		setSelected_clients(all_selected_clients);
	}

	const handle_multiremove_clients = (e) => {
		const all_selected_clients = e.map((val) => val.id).filter(Boolean);
		setSelected_clients(all_selected_clients);
	}

	return (
		<div className="content_outer">
			<Loader showLoader={loadershown} />
			<div className="user_header">
				<h2>
					<img
						src="/assets/images/chevron_left.svg"
						onClick={() => navigation('/userlist', {
							state: {
								refresh: true,
								show_new_user: true,
								show_permission: true
							}
						})}
					/>

					Add User
				</h2>
			</div>
			<div className="content">
				<div className="App">

					<div className=''>

						<div className='form_inner add_user_form'>
							<Formik
								initialValues={initialValues}
								validationSchema={Yup.object({
									first_name: Yup.string().required('First Name is required'),
									last_name: Yup.string().required('Last Name is required'),
									email: Yup.string().required('Email is required').email(),
									group_id: Yup.string().required('Please Select Group'),
									role_id: Yup.string().required('Please Select Role'),
									language_key: Yup.string().required('Please Select Language'),
									subGroupId: Yup.string().required('Please Select sub group'),
								})}
								onSubmit={handleFormSubmit}
								innerRef={formikRef}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									setFieldValue,
									validateField
									/* and other goodies */
								}) => (
									<Form>
										<div className='row'>

											<div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">First Name</label>
													<input type="text" className='form-control' placeholder='First Name' name="first_name" onChange={handleChange}
														value={values.first_name} />
												</div>
												{errors.first_name && touched.first_name ? (
													<span className='text-danger'>{errors.first_name}</span>
												) : null}
											</div>
											<div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Last Name</label>
													<input type="text" className='form-control' placeholder='Last Name' name="last_name" onChange={handleChange}
														value={values.last_name} />
												</div>
												{errors.last_name && touched.last_name ? (
													<span className='text-danger'>{errors.last_name}</span>
												) : null}
											</div>
											<div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Email</label>
													<input type="text" className='form-control' placeholder='Email' name="email" onChange={handleChange}
														value={values.email} />
												</div>
												{errors.email && touched.email ? (
													<span className='text-danger'>{errors.email}</span>
												) : null}
											</div>
											<div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Language</label>

													<select name="language_key" className='form-select' value={values?.language_key} onChange={(e) => { handleChange(e); }} >
														<option value="">--Select--</option>
														{languages?.map((lang, index) => {
															return (
																<option value={lang?.key} key={index}>{lang?.name}</option>
															)
														})}
													</select>
												</div>
												{errors.language_key && touched.language_key ? (
													<span className='text-danger'>{errors.language_key}</span>
												) : null}
											</div>
											{advertiser_id === null && !user && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Role</label>

													<select name="role_id" className='form-select' value={values?.role_id} onChange={(e) => { handleChange(e); handleSetClients(e) }} >
														<option value="">--Select--</option>
														{roles?.map((role, index) => {
															if (role.id == "2") return null
															return (
																<option value={role?.id} key={index}>{role?.name}</option>
															)

														})}
													</select>
												</div>
												{errors.role_id && touched.role_id ? (
													<span className='text-danger'>{errors.role_id}</span>
												) : null}
											</div>}
											{advertiser_id !== null && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Role</label>

													<select name="role_id" className='form-select' value={values?.role_id} onChange={(e) => { handleChange(e); handleSetClients(e) }} >
														<option value="">--Select--</option>
														{roles?.map((role, index) => {
															if (role.id == "2") {
																return (
																	<option value={role?.id} key={index}>{role?.name}</option>
																)
															}
														})}
													</select>
												</div>
												{errors.role_id && touched.role_id ? (
													<span className='text-danger'>{errors.role_id}</span>
												) : null}
											</div>}
											{advertiser_id === null && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Group</label>

													<select name="group_id" className='form-select' value={values?.group_id} onChange={(e) => { handleChange(e); changeGroup(e.target.value) }} >
														<option value="">--Select--</option>
														{groups?.map((group, index) => {
															if (group.id == "9") return null
															return (
																<option value={group?.id} key={index}>{group?.name}</option>
															)

														})}
													</select>
												</div>
												{errors.group_id && touched.group_id ? (
													<span className='text-danger'>{errors.group_id}</span>
												) : null}
											</div>}
											{advertiser_id !== null && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Group</label>

													<select name="group_id" className='form-select' value={values?.group_id} onChange={(e) => { handleChange(e); changeGroup(e.target.value) }} >
														<option value="">--Select--</option>
														{groups?.map((group, index) => {
															if (group.id == "9") {
																return (
																	<option value={"9"} key={index}>{group?.name}</option>
																)
															}
														})}
													</select>
												</div>
												{errors.group_id && touched.group_id ? (
													<span className='text-danger'>{errors.group_id}</span>
												) : null}
											</div>}
											<div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Sub-Group</label>

													<select
														name="subGroupId"
														className='form-select'
														value={values?.subGroupId}
														onChange={(e) => {
															handleChange(e);
															handleSelectSubGroup(e)
														}}
													>
														<option value="">--Select--</option>
														{subgroups?.map((subgroup, index) => {
															if (user && subgroup.id == 13) return null
															return (
																<option value={subgroup?.id} key={index}>{subgroup?.description}</option>
															)
														})}
													</select>
												</div>
												{errors.subGroupId && touched.subGroupId ? (
													<span className='text-danger'>{errors.subGroupId}</span>
												) : null}
											</div>
											{/* only when these 3 fiels are true than this below will execute nitin 23/08/2023 */}
											{selectedClient && GroupId && reportUser && <div className="col-md-4">
												<div className="form-group select_country_container mb-4 mt-1">
													<label className="lbl_position multisel_lbl" htmlFor="country">
														Kite Deals
													</label>
													<Multiselect
														options={all_deals.map((i) => {
															const name = i?.campaign ?? "Unknown";
															return { ...i, name };
														})}
														// selectedValues={deals}
														onSelect={(e) => { handle_multiselect_deals(e) }}
														// onRemove={(e) => handle_multiremove_advertiser(e)}
														displayValue="name"
													/>
												</div>

											</div>}
											<div className="col-md-4 d-none">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Sub-Group</label>

													<select name="subGroupId" className='form-select' >
														<option value="">--Select--</option>

													</select>
												</div>

											</div>
											{advertiser_id !== null && admin_value && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Deal Name</label>
													<input type="text" className='form-control' placeholder='Email' name="email" onChange={handleChange}
														value={deal_Name} disabled={true} />
												</div>
											</div>}
											{advertiser_id !== null && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Advertiser Id</label>
													<input type="text" className='form-control' placeholder='Advertiser Id' name="email" onChange={handleChange}
														value={user ? permission?.AdvertiserId : advertiser_id} disabled={true} />
												</div>
											</div>}
											{(advertiser_id !== null && (subgroup_id == 12 || subgroup_id == 14)) && <div className="col-md-4">
												<div className="form-group select_country_container mb-4 mt-1">
													<label className="lbl_position multisel_lbl" htmlFor="country">
														Clients
													</label>
													<Multiselect
														options={all_client_list.map((i) => {
															const name = i?.companyName ?? "Unknown";
															return { ...i, name };
														})}
														singleSelect={subgroup_id == 14}
														onSelect={(e) => { handle_multiselect_clients(e) }}
														onRemove={(e) => handle_multiremove_clients(e)}
														displayValue="name"
													/>
												</div>

											</div>}
											{advertiser_id !== null && admin_value && <div className="col-md-4">
												<div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
													<label className="lbl_position ">Agency Name</label>
													<input type="text" className='form-control' placeholder='Email' name="email" onChange={handleChange}
														value={Agency_name} disabled={true} />
												</div>
											</div>}
											<Col lg={12} className="text-end mt-3 p-0 bg-transparent saas_modal modal-body ">

												<div className="model_btn">
													<button className="cancel_cm_btn" onClick={() => navigation(-1)}>Cancel</button>
													<button className="cmn_btn ms-2">Save</button>
												</div>
											</Col>
										</div>
									</Form>
								)}
							</Formik>
						</div>
					</div>
				</div>
			</div>
		</div>

	)
}

export default Adduser