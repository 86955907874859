import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import VideoPlayer from "./VideoPlayer.js";
import axios from "axios";

const ViewTag = () => {
	const location = useLocation();
	const { tags } = location.state ? location.state : location;
	const [tag, setTag] = useState();

	const [videoUrl, setVideoUrl] = useState(null);

	return (
		<>
			<div className="content_outer">
				<div className="content">
					<VideoPlayer
						controls
						autoplay
						sources={[
							{
								src: "https://storage.googleapis.com/interactive-media-ads/media/android.mp4",
								type: "video/mp4",
							},
						]}
						ima={{
							adTagUrl: tags,
						}}
					/>
				</div>
			</div>
		</>
	);
};

export default ViewTag;
