import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

export const save_service_fee = createAsyncThunk("save_service_fee", async ({advertiser_id,service_fee},thunkAPI) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        const raw = JSON.stringify({
            "AdvertiserclientId": advertiser_id,
            "ServiceFees": service_fee
        });

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: raw,
            redirect: "follow"
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}UserSaas/saveservicefee`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const saveServiceFee = createSlice({
    name: "saveServiceFee",
    initialState: {
        data: "",
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_save_service_fee_state: (state) => {
            state.data = ""
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(save_service_fee.pending, (state) => {
                state.loading = true
            })
            .addCase(save_service_fee.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(save_service_fee.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export const {clear_save_service_fee_state} = saveServiceFee.actions
export default saveServiceFee.reducer