import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import Moment from "react-moment";
import Loader from '../Loader';
import WhiteList from "./Lists/whiteList";
import BlackList from "./Lists/blackList";
import LineItemList from "./Lists/lineItemList";

const CampaignList = () => {
	const params = useParams();
	const navigation = useNavigate();
	const [list, setList] = useState(null);
	const [list_item, setList_item] = useState([]);
	const [loadershown, setloadershown] = useState(false);
	const [checkedExclude, setCheckedExclude] = useState([]);
	const [defaultList, setDefaultList] = useState(true);
	const [whiteList, setWhiteList] = useState(false);
	const [blackList, setBlackList] = useState(false);
	const [listMode, setListMode] = useState(1);

	useEffect(() => {
		getdata();
	}, []);
	const getdata = async () => {
		setloadershown(true)
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			}
		);
		const result = await response.json();
		setList_item(result);
		setListMode(result?.data?.listMode);
		let item = result?.data?.listOriginalLineItemDto?.result;
		setList(item);
		setCheckedExclude([]);
		if (typeof item !== 'undefined') {
			item.forEach(listItem => {
				if(listItem.exclude == true) {
					setCheckedExclude(prevState => [...prevState, {appid: listItem.appid, title: listItem.title}]);
				}
			});
		}
		setloadershown(false)
	};

	const handleDefaultList = () => {
		setDefaultList(true);
		setWhiteList(false);
		setBlackList(false);
	}

	const handleWhiteList = () => {
		setWhiteList(true);
		setDefaultList(false);
		setBlackList(false);
	}

	const handleBlackList = () => {
		setBlackList(true);
		setWhiteList(false);
		setDefaultList(false);
	}

	return (
		<>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="cmn_header_top">
					<h3>
						<img src="/assets/images/chevron_left.svg" onClick={() => navigation(-1)} />
						Line Item Details
					</h3>
          		</div>
				<div className="content">
					<div className="App">
						<div className="mediaplan_details media_plan_info_list kite_space_cmn">
							<div className="advert_tabel">
								<ul>
									<li>
										<strong>Inventory Type:</strong>:
										<p>{list_item?.data?.inventoryNavigation?.description}</p>
									</li>
									<li>
										<strong>Offer type</strong>:
										<p>{list_item?.data?.offerTypeNavigation?.descripcion}</p>
									</li>
									<li>
										<strong>Name</strong>:<p>{list_item?.data?.name} </p>
									</li>
									<li>
										<strong>Start Date</strong>:
										<p>
											{" "}
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_from}
											</Moment>
										</p>
									</li>
									<li>
										<strong>End Date</strong>:
										<p>
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_to}
											</Moment>
										</p>
									</li>
									<li>
										<strong>Format</strong>:
										<p> {list_item?.data?.formatNavigation?.name}</p>
									</li>
									<li>
										<strong>Goal</strong>:
										<p> {list_item?.data?.inversion_goal?.toLocaleString(
											undefined,
											{ minimumFractionDigits: 0 })}</p>
									</li>
									<li>
										<strong>Status</strong>:
										<p>
											{" "}
											{list_item?.data?.lineItemStatusNavigation?.description}
										</p>
									</li>
									<li>
										<strong>Country</strong>:
										<p>
											{" "}
											{list_item?.data?.countryList.map((list,index) => {
												return(
													<span>{list.country?.ab?.toUpperCase()}{index != list_item.data?.countryList.length -1 ? ',' : ''}&nbsp;</span>
												)
											})}
										</p>
									</li>
									<li>
										<strong>Ages</strong>:
										<p>
											{" "}
											{list_item?.data?.audienceList?.map((list,index) => {
												return(
													<span>{list?.audience?.age}{index != list_item.data?.audienceList.length -1 ? ',' : ''}&nbsp;</span>
												)
											})}
										</p>
									</li>
									<li>
										<strong>Gender</strong>:
										<p>
											{" "}
											{list_item?.data?.targetgender_name}
										</p>
									</li>
								</ul>
							</div>
						</div>						
						<Row className="listsTitles">
							<Col lg={4} className={`colLineItemTitleList ${defaultList ? 'colLineItemTitleListBorderBottom' : ''}`}>
								<button onClick={handleDefaultList} className="buttonListCampaign">
									Line items list
								</button>
							</Col>
							<Col lg={4} className={`colLineItemTitleList ${whiteList ? 'colLineItemTitleListBorderBottom' : ''}`}>
								<button onClick={handleWhiteList} className="buttonListCampaign">
									White list
								</button>
							</Col>
							<Col lg={4} className={`colLineItemTitleList ${blackList ? 'colLineItemTitleListBorderBottom' : ''}`}>
								<button onClick={handleBlackList} className="buttonListCampaign">
									Black list
								</button>
							</Col>
						</Row>						
            {defaultList && (
              <LineItemList list={list} defaultList={true} excludeItems={checkedExclude} getData={getdata} listMode={listMode}/>
            )}
						{whiteList && (
							<WhiteList inventoryTypeId={list_item?.data?.inventoryNavigation?.id}/>
						)}
						{blackList && (
							<BlackList getData={getdata} inventoryTypeId={list_item?.data?.inventoryNavigation?.id}/>
						)}
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignList;
