import React, { useEffect, useState } from "react";
import './profile.css'
import { MdOutlineFileUpload } from "react-icons/md";
import { useSelector, useDispatch } from "react-redux";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import Modal from 'react-bootstrap/Modal';
import { getUserProfileInfo } from "../../app/features/profile/getProfileInfoSlice";
import { clear_profile_info_state } from "../../app/features/profile/getProfileInfoSlice";
import { updateUserProfile } from "../../app/features/profile/updateUserProfileSlice";
import { clear_update_user_profile_state } from "../../app/features/profile/updateUserProfileSlice";
import { useNavigate } from "react-router-dom"
const Profile = () => {
  const permission = JSON.parse(localStorage.getItem('userdata'));
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [userEmail, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [position, setPosition] = useState("")
  const [loadershown, setloadershown] = useState(false);
  const [buttonBlue, setButtonBlue] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [image, setImage] = useState("")
  const [imageName, setImageName] = useState("")
  const [imageURL, setImageURL] = useState("")
  const [mediaLink, setMediaLink] = useState("")
  const dispatch = useDispatch()
  const navigate = useNavigate()

  useEffect(() => {
    dispatch(getUserProfileInfo({ userId: permission?.id }))
  }, [])

  const user_data = useSelector((state) => { return state.get_user_profile })
  const user_updated_data = useSelector((state) => { return state.update_profile })

  useEffect(() => {
    if (user_data?.loading) {
      setloadershown(true)
    }
    if (user_data?.isSuccess) {
      setTimeout(() => {
        setFirstName(user_data?.data?.firstName)
        setLastName(user_data?.data?.lastName)
        setEmail(user_data?.data?.email)
        setCompany(user_data?.data?.companyName)
        setPosition(user_data?.data?.position)
        setloadershown(false)
      }, 1000)
    }
    if (user_data?.isError) {
      toast.error("Internal Server Error Occured !!")
      handleClose()
      setloadershown(false)
    }
  }, [user_data])

  const handle_update_user_profile = (e) => {
    e.preventDefault()
    handleClose()
    setloadershown(true)
    dispatch(updateUserProfile({ userId: permission?.id, position }))

  }

  useEffect(() => {
    if (user_updated_data?.isSuccess) {
      setTimeout(() => {
        dispatch(clear_profile_info_state())
        dispatch(clear_update_user_profile_state())
        toast.success("User Profile Updated SuccessFully !!")
        dispatch(getUserProfileInfo({ userId: permission?.id }))
        handleClose()
        setImageName("")
        setImage("")
      }, 1000)
    }

    if (user_updated_data?.isError) {
      toast.error(user_updated_data?.error?.message)
      setloadershown(false)
      setImageName("")
      setImage("")
    }

  }, [user_updated_data])

  // Here i am checking the position value is changed by the user or not
  useEffect(() => {
    if (position !== user_data?.data?.position) {
      setButtonBlue(true)
    }
    else {
      setButtonBlue(false)
    }

  }, [position])

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    if (file.type === 'image/jpeg' || file.type === 'image/png') {
      setImageName(file);
      const imageURL = URL.createObjectURL(file);
      setImage(imageURL);
    } else {
      toast.error("Only JPG or PNG files are allowed");
    }
  };


  const handleSaveIMage = () => {
    dispatch(updateUserProfile({ userId: permission?.id, position, image: imageName ? imageName : user_data?.data?.image }))
  }

  const handlePreviewImage = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  }

  useEffect(() => {
    let timer
    if (user_data?.isSuccess) {
      timer = setTimeout(() => {
        setImageURL(user_data?.data?.profileurl)
        setMediaLink(user_data?.data?.mediaLink)
      }, 3000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [user_data])
  return (
    <div className="content_outer">
      <Loader showLoader={loadershown} />
      <Modal show={show} onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Change profile image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="content pt-0">
            <div className="add_profile" onDrop={(e) => handleDrop(e)} onDragOver={(e) => e.preventDefault()} >
              <div className="drop_image" style={{ backgroundColor: '#FFA500' }}  >
                {image ? (
                  <img src={image} alt="profile_image" className="user_image" />
                ) : (imageURL && mediaLink) ? (
                  <img key={imageURL} src={`${mediaLink}${imageURL}`} alt="profile_image" className="user_image" />
                ) : (
                  <p>{first_name[0] + last_name[0]}</p>
                )}
              </div>

              <h2 className="text-center">Drag a photo here or:</h2>
            </div>
            {!imageName && <div className="upload_profile_image">
              <input type="file" onDrop={(e) => handleDrop(e)} onChange={(e) => { setImageName(e.target.files[0]); handlePreviewImage(e) }} />
              <button type="submit" className="bordered_btn" onClick={handleClose}><MdOutlineFileUpload size={24} className="me-2" />Upload </button>
            </div>}
            {imageName && <div className="upload_profile_image">
              <button className="bordered_btn" onClick={() => handleSaveIMage()}>save </button>
            </div>}
          </div>
        </Modal.Body>

      </Modal>
      <div className="user_header"><h2>
        <img src="/assets/images/chevron_left.svg" alt="back" onClick={() => navigate(-1)} />
        Profile
      </h2></div>
      <div className="content">
        <div className="profile_hader">
          <div className="profile_image_wrapper" style={{ backgroundColor: '#FFA500' }}>
            {(imageURL && mediaLink) ? <img key={imageURL} src={`${mediaLink}${imageURL}`} alt="profile_image" className="user_image" /> : <p>{first_name[0] + last_name[0]}</p>}
            <img src="/assets/images/edit_profile.png" alt="profile_image" className="edit_profile_img " onClick={handleShow} />
          </div>
          <h2>
            Hi, {first_name}!
          </h2>
        </div>
        <div className="form_inner add_user_form">
          <Form onSubmit={handle_update_user_profile}>
            <Row className="m-0">
              <Col sm={4} className="mb-4" >
                <Form.Group className="form-group select_country_container cmn_modal_field mt-0 border-red" controlId="formBasicEmail" style={{ backgroundColor: "#C0C0C0" }}>
                  <Form.Label className="center_label lbl_position form-label form-label" style={{ color: "white" }}>First Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="first_name"
                    value={first_name}
                    placeholder="Enter First Name"
                    disabled={true}
                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                  />
                </Form.Group>

              </Col>
              <Col sm={4} className="mb-4">
                <Form.Group className="form-group select_country_container cmn_modal_field mt-0 border-red" controlId="formBasicEmail" style={{ backgroundColor: "#C0C0C0" }}>
                  <Form.Label className="center_label lbl_position form-label form-label" style={{ color: "white" }}>Last Name</Form.Label>
                  <Form.Control
                    type="text"
                    value={last_name}
                    placeholder="Enter Last Name"
                    disabled={true}
                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={4} className="mb-4">
                <Form.Group className="form-group select_country_container cmn_modal_field mt-0 border-red" controlId="formBasicEmail" style={{ backgroundColor: "#C0C0C0" }}>
                  <Form.Label className="center_label lbl_position form-label form-label" style={{ color: "white" }}>Email </Form.Label>
                  <Form.Control
                    type="email"
                    value={userEmail}
                    placeholder="Enter email"
                    disabled={true}
                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={4} className="mb-4">
                <Form.Group className="form-group select_country_container cmn_modal_field mt-0 border-red" controlId="formBasicEmail" style={{ backgroundColor: "#C0C0C0" }}>
                  <Form.Label className="center_label lbl_position form-label form-label" style={{ color: "white" }}>Company</Form.Label>
                  <Form.Control
                    type="text"
                    value={company}
                    placeholder="Company"
                    disabled={true}
                    style={{ backgroundColor: "#C0C0C0", color: "white" }}
                  />
                </Form.Group>
              </Col>
              <Col sm={4} className="mb-4">
                <Form.Group className="form-group select_country_container cmn_modal_field mt-0" controlId="formBasicEmail">
                  <Form.Label className="center_label lbl_position form-label form-label">Position</Form.Label>
                  <Form.Control
                    type="text"
                    onChange={(e) => setPosition(e.target.value)}
                    value={position}
                    placeholder="Position"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Col lg={12} className="text-end mt-3 p-0 bg-transparent saas_modal modal-body">
              <div className="model_btn">

                <button className="transparent btn btn-primary" onClick={() => { navigate(-1) }}>
                  Cancel
                </button>

                <button className="cmn_btn cancel_cmn ms-2" style={{ backgroundColor: buttonBlue && "blue" }}>
                  Update
                </button>
              </div>
            </Col>
          </Form>
        </div>

      </div>
      <Loader showLoader={loadershown} />
    </div>
  );
};

export default Profile;
