import React, { useState, useEffect } from "react";
import "./style.css";
import Dropdown from "react-bootstrap/Dropdown";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { FaHome } from "react-icons/fa";
import { AiOutlineRight } from "react-icons/ai";
import { FiChevronDown } from "react-icons/fi";
import { BiBell } from "react-icons/bi";
import { CommonSelector } from "../../app/features/Common/CommonSlice";
import { change_background_color } from "../../app/features/get_background_color/backhround-color-slice";
import { getUserProfileInfo } from "../../app/features/profile/getProfileInfoSlice";
const Header = () => {
	const dispatch = useDispatch();
	const navigation = useNavigate();
	const [imageURL, setImageURL] = useState("")
	const [show_agency, setShow_agency] = useState(true)
	const [mediaLink, setMediaLink] = useState("")
	const background_color_data = useSelector((state) => { return state.background_color })

	const { header_breadcrum } = useSelector(CommonSelector);
	const handleClick = () => {
		localStorage.clear();
		navigation("/");
	};


	const permission = JSON.parse(localStorage.getItem('userdata'));
	const user_data = useSelector((state) => { return state.get_user_profile })

	useEffect(() => {
		dispatch(change_background_color())
		dispatch(getUserProfileInfo({ userId: permission?.id }))
	}, [])

	useEffect(() => {
		let timer
		if (user_data?.isSuccess) {
			timer = setTimeout(() => {
				setImageURL(user_data?.data?.profileurl)
				setMediaLink(user_data?.data?.mediaLink)
			}, 4000)
		}

		return () => {
			clearTimeout(timer)
		}
	}, [user_data])

	return (
		<header className="header_outer">
			<div className="left_content d-flex">
				{/* <Link to="/home"><FaHome></FaHome> </Link> */}
				<div className="bredcrum">
					<ul>
					</ul>
				</div>
			</div>

			<div className="right_content">
				<Dropdown className="user_dropdown ms-2">
					<Dropdown.Toggle variant="success" id="dropdown-basic">
						<div className="header_image" style={{ backgroundColor: '#FFA500' }}>
							{(imageURL && mediaLink) ? (
								<img
									key={imageURL} // Add key prop here
									src={`${mediaLink}${imageURL}`}
									alt="profile_image"
									className="user_image"
								/>
							) : (
								<p>
									{`${JSON.parse(localStorage.getItem("userdata")).first_name[0]}${JSON.parse(
										localStorage.getItem("userdata")).last_name[0]
										} `}
								</p>
							)}
						</div>


						<div className="ms-2 ms-3">
							<span>{`${JSON.parse(localStorage.getItem("userdata")).first_name +
								" " +
								JSON.parse(localStorage.getItem("userdata")).last_name
								} `}</span>
							<p className="designation">{localStorage.getItem("userGroup")}</p>

						</div>
						<div className="arrow__icon">	<FiChevronDown /></div>
					</Dropdown.Toggle>

					<Dropdown.Menu>
						<Dropdown.Item>
							<Link to="/profile"> My Profile</Link>{" "}
						</Dropdown.Item>
						{Object.values(permission.Permissions).includes('useradmin') && (
							<Dropdown.Item onClick={() => {
								navigation('/userlist', {
									state: {
										refresh: true,
										show_new_user: true,
										show_permission : true
									}
								}); setShow_agency(true)
							}}>
								Users
							</Dropdown.Item>
						)}
						{Object.values(permission.Permissions).includes('addAgencyUser') && <Dropdown.Item onClick={() => navigation('/userlist', {
							state: {
								user: true,
								hideFilter: true
								// show_new_user: false
							}
						})}>
							Agency Users
						</Dropdown.Item>}
						{Object.values(permission.Permissions).includes('viewAdminSaas') && <Dropdown.Item onClick={() => {
							navigation('/userlist', {
								state: {
									user: true,
									validate: true,
									show_new_user: true,
									show_permission : false
								}
							}); setShow_agency(true)
						}}>
							{/* <Link to="/userlist" params={{ testvalue: "hello" }}>Create Idea</Link> */}
							Admin Agency Users
						</Dropdown.Item>}
						{/* this is commented nitin 1/09/2023 */}
						{/* <Dropdown className="select_language">
							<Dropdown.Toggle variant="success" id="dropdown-basic12">
								<span>
									Language <FiChevronDown />
								</span>
							</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									<Link to="">English</Link>{" "}
								</Dropdown.Item>
								<Dropdown.Item>
									<Link to="">Spanish</Link>{" "}
								</Dropdown.Item>
								<Dropdown.Item>
									<Link to="">Portuguese</Link>{" "}
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown> */}
						<Dropdown.Item onClick={handleClick}>Logout</Dropdown.Item>
					</Dropdown.Menu>
				</Dropdown>
			</div>
		</header>
	);
};

export default Header;
