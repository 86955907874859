import { useState } from "react";
import { DateRange } from 'react-date-range';
import { Modal } from "react-bootstrap";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Button from "../../General/Button";
import i18n from "../../../i18n";

const ModalCustomDate = (props) => {
  const [dateRanges, setDateRanges] = useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);

  const handleApplyDates = () => {
    const dates = formatDateRanges(dateRanges);
    props.setSelectedStartEndDate(dates);
    props.setSelectedDateRange("customDate");
    props.setShowModalCustomDate(false);
    props.handleClose();
  }

  const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formatDateRanges = (dateRanges) => {
    const startDate = dateRanges[0].startDate;
    const endDate = dateRanges[0].endDate;
    const ranges = {
      startDate: formatDate(startDate),
      endDate: formatDate(endDate)
    }
    return ranges;
  }

  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle modalCustomDate" id="contained-modal-title-vcenter">
          {i18n.customDate}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="divDateRange">
          <DateRange
            ranges={dateRanges}
            onChange={item => {
              setDateRanges([item.selection]);
            }}
            editableDateInputs={true}
            moveRangeOnFirstSelection={false}
            months={2}
            direction="horizontal"
            minDate={new Date(new Date().setFullYear(new Date().getFullYear() - 1))}
          />
        </div>
        <div className="divDateRangeButton">
          <h3 onClick={props.handleClose} className="h3Cancel">Cancel</h3>
          <Button text="Apply" className="largeSizeButton" onClick={handleApplyDates} />
        </div>
      </Modal.Body>
    </Modal>
  )
}

export default ModalCustomDate;
