import React, { useState, useEffect, useCallback, useMemo } from "react";
import ReactDOM from "react-dom";
import axios from "axios";
import DataTable from "react-data-table-component";
import ReactPaginate from "react-paginate";
import Button from "react-bootstrap/Button";
import { Table } from "react-bootstrap";
import { FaEdit } from "react-icons/fa";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";
import { Link, useNavigate, useLocation } from "react-router-dom";
import "./style.css";
import Updateuser from "./updateuser";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-hot-toast";
import Loader from "../Loader";
import { AiOutlineSearch, AiOutlinePlus } from "react-icons/ai";
import { MdOutlineModeEdit } from "react-icons/md";
import { useDispatch,useSelector } from "react-redux";
import { change_user_password_previous } from "../../app/features/Users/reser_userpassword_slice";
import { clear_update_password_state } from "../../app/features/Users/reser_userpassword_slice";
import Dropdown from "react-bootstrap/Dropdown";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
const removeItem = (array, item) => {
  const newArray = array.slice();
  newArray.splice(
    newArray.findIndex((a) => a === item),
    1
  );

  return newArray;
};

const Userlist = () => {
  const dispatch = useDispatch()
  const navigation = useNavigate();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  // const [deleted, setDeleted] = useState([]);
  const [filteredData, setFlitered] = useState([]);
  const [search, setSearch] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [userId, setUserId] = useState();
  const [loadershown, setloadershown] = useState(false);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total_pages, setTotal_Pages] = useState(null);
  const [subGroup, setSubGroup] = useState("");
  const location = useLocation();
  const [all_sub_group, set_all_sub_groups] = useState([]);
  const [user_name, set_user_name] = useState("");
  const [user_id, set_user_id] = useState(null);
  const [searched_value, setSearched_value] = useState("");
  const permission = JSON.parse(localStorage.getItem('userdata'));
  const change_passwod_data = useSelector((state) => { return state.change_password })

  //this function is used to get the advertiser_id from the admin saas component we design the api accordingly to get users
  const {
    //here in this path we get the client id from the prvious page and here we can hit a endpoint to get all the information of selected advertiser nitin 04/09/2023
    advertiser_id,
    DealName,
    Agency_Name,
    user,
    admin_value,
    refresh,
    validate,
    show_new_user,
    hideFilter,
    show_permission
  } = location.state ? location.state : location;

  useEffect(() => {
    // this will get data when we get into the page nitin 31/08/2023
    getUserList();
  }, []);

  //this useEffect will hit when we change page size nitin 31/08/2023
  useEffect(() => {
    if (page !== 1 || pageSize !== 10) {
      getUserList();
    }
  }, [page, pageSize, subGroup, user_id, user_name]);

  useEffect(() => {
    getUserList();
  }, [subGroup, user_id, user_name, refresh, validate]);

  // this below function is used to get all the list of users nitin 31/08/2023

  const getUserList = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      SubGroup: subGroup ? subGroup : null,
      advertiser_id: user ? permission?.AdvertiserId : advertiser_id ? advertiser_id : null,
      id: user_id ? user_id : null,
      first_name: user_name ? user_name : null,
      role_id: validate ? 2 : null,
      // group_id:user ? 8 : null
    });


    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}User/getall?page=${page}&pageSize=${pageSize}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setData(result?.data?.results);
        setloadershown(false);
        setTotal_Pages(result?.data?.page_count);
      })
      .catch((error) => {
        toast.error("Some internal error occurred");
        setloadershown(false);
      });
  };

  const handleDelete = useCallback(
    (row) => async () => {
      await axios
        .post(
          `${process.env.REACT_APP_DOT_NET_BASE_URL}user/delete/${row.id}`,
          {},
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + localStorage.getItem("token"),
            },
          }
        )
        .then((response) => {
          setData(removeItem(data, row));
        });
    },
    []
  );

  const handlePageChange = (page) => {
    // fetchUsers(page);
    setCurrentPage(page);
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    // fetchUsers(page, newPerPage);
    setPerPage(newPerPage);
  };

  const handleModal = (id) => {
    setModalOpen(true);
    setUserId(id);
  };

  const handleSubmitPassword = () => {
    if (newPassword !== confirmPassword) {
      toast.error("Password doesn't matched");
      setNewPassword("");
      setConfirmPassword("");
    } else if (newPassword?.length < 6 || confirmPassword?.length < 6) {
      toast.error("Password must be 6 chracter long");
      setNewPassword("");
      setConfirmPassword("");
    } else {
      dispatch(change_user_password_previous({ userId, newPassword }))
    }
  };

  //this function is used to handle the access login we can enable and disable login from here nitin 31/08/2023
  const handleAccessLogin = (id, statement) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      id: id,
      Value: !statement,
    });

    var requestOptions = {
      method: "PUT",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}User/update_access`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Access updated successfully");
        getUserList();
      })
      .catch((error) => toast.error(error));
  };

  //this function is used to go to save user component 04/09/2023
  const go_to_next = () => {
    navigation("/addUser", {
      state: {
        advertiser_id: user ? permission?.AdvertiserId : advertiser_id ? advertiser_id : null,
        deal_Name: DealName ? DealName : null,
        Agency_name: Agency_Name ? Agency_Name : null,
        user: user,
        admin_value: admin_value
      },
    });
  };

  //this below function is used to clear the search result nitin 04/09/2023
  const handleSubGroupClear = () => {
    setSubGroup("");

    // Use a Promise to ensure the state is cleared before continuing
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
        getUserList(); // Resolve the promise with true after 300 milliseconds
      }, 300);
    });
  };

  // this below function is used to get all the subgroups for searching nitin 26/09/2023
  useEffect(() => {
    get_all_sub_groups();
  }, []);

  const get_all_sub_groups = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}User/getsubgroup`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => set_all_sub_groups(result?.data))
      .catch((error) => toast.error(error));
  };

  const handle_search_with_id = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
      set_user_name(e.target.value);
    } else {
      set_user_id(e.target.value);
    }
  };

  useEffect(() => {
    if (change_passwod_data?.isSuccess) {
      dispatch(clear_update_password_state())
      toast.success("Password Changed Successfully!!!")
      setModalOpen(false);
      setNewPassword("");
      setConfirmPassword("");
    }
    else if (change_passwod_data?.isError) {
      dispatch(clear_update_password_state())
      toast.error(change_passwod_data?.error?.message)
      setModalOpen(false);
    }

  }, [change_passwod_data])

  return (
    <>
      <div className="content_outer">
        <Loader showLoader={loadershown} />
        <div className="cmn_header_top mb-3">
          {advertiser_id && (
            <img
              src="/assets/images/chevron_left.svg"
              onClick={() => {
                navigation("/adminSaas");
              }}
            />
          )}
          <h3>
            User List {advertiser_id ? "- " : null}
            {advertiser_id ? DealName : null}
          </h3>
          <div className="flex-grow-1 text-center">
            <div className="cmn_header__search">
              <AiOutlineSearch size={24} />
              <input
                type="text"
                value={searched_value}
                onBlur={(e) => handle_search_with_id(e)}
                onChange={(e) => setSearched_value(e.target.value)}
                placeholder="Search by Id or First Name"
              />
            </div>
          </div>
          <div className="d-flex gap-3">
            {show_permission && Object.values(permission.Permissions).includes('editAdminSaas') && <button onClick={() =>  navigation("/editSubgroup")} className="cmn_btn ms-0">
              {" "}
             <MdOutlineModeEdit size={20} className="me-2"/>
              Edit Sub-Groups
            </button>}
            {(Object.values(permission.Permissions).includes('useradmin') || Object.values(permission.Permissions).includes('addAgencyUser')) && <div className="">
              <button onClick={() => go_to_next()} className="cmn_btn ms-0">
                {" "}
                <AiOutlinePlus className="me-2" size={20} />
                New Users
              </button>
            </div>}
          </div>
        </div>
        <div className="content pt-0">
          <div className="App">
            <div className="cmn__filters campiagn_filter filter_menu w-100">
              <ul className="w-100">
                <li>
                  <img src="/assets/images/filter_list.svg" />
                </li>
                {!hideFilter && <li>
                  <Dropdown className="owner_filter ">
                    {/* first */}
                    <Dropdown.Toggle id="dropdown-basic" className="w-100">
                      {subGroup ? subGroup : "Sub-Group"}
                      <svg
                        className="ms-auto"
                        width="16"
                        height="12"
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 16 16"
                      >
                        <path
                          fill="none"
                          stroke="#343a40"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m2 5 6 6 6-6"
                        />
                      </svg>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {all_sub_group?.map((data, index) => {
                        return (
                          <Dropdown.Item
                            key={index}
                            onClick={() => setSubGroup(data?.SubGroup)}
                          >
                            {data?.SubGroup}
                          </Dropdown.Item>
                        );
                      })}
                    </Dropdown.Menu>
                  </Dropdown>
                </li>}

                <button
                  className="clear__btn"
                  onClick={() => {
                    handleSubGroupClear();
                    setSubGroup("");
                    set_user_id(null)
                    set_user_name("")
                    setSearched_value("")
                  }}
                >
                  Clear
                </button>
              </ul>
            </div>
            <div className="advert_tabel campign_index_table">
              <Table responsive>
                <thead>
                  <tr>
                    <th className="text-start">ID</th>
                    <th className="text-start">Name</th>
                    <th className="text-start">E-mail</th>
                    {permission?.role === 1 && permission?.group === 8 && <th className="text-start">Agency</th>}
                    <th className="text-start">Sub-Group</th>
                    <th className="text-start">Last Login</th>
                    <th className="text-start">Access Login</th>
                    <th className="text-start">Details</th>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((value, index) => {
                    return (
                      <tr key={index}>
                        <td>{value?.id}</td>
                        <td>{`${value?.first_name + " " + value?.last_name
                          }`}</td>
                        <td>{value?.email}</td>
                        {permission?.role === 1 && permission?.group === 8 && <td>{value?.agency_name ? value?.agency_name : "Not Available"}</td>}
                        <td>{value?.SubGroup || "NA"}</td>
                        <td>
                          {new Date(
                            value?.latestLogTimestamp
                          )?.toLocaleDateString("en-IN")}
                        </td>

                        <td>
                          <div className="form-group sass_checkbox access_login">
                            <input
                              type="checkbox"
                              checked={value?.allow_access}
                              className="mb-0"
                              onChange={(e) =>
                                handleAccessLogin(
                                  value?.id,
                                  value?.allow_access
                                )
                              }
                            />
                          </div>
                        </td>
                        <td>
                          <div className="icons_downloads">
                            <div
                              className="pdf_icon"
                              onClick={() =>
                                navigation(`/updateUser/${value?.id}`)
                              }
                            >
                              <img
                                src="/assets/images/edit_fill.svg"
                                title="Edit the user"
                              />
                            </div>

                            <div className="pdf_icon">
                              <img
                                src="/assets/images/lock_reset.svg"
                                title="Reset the passowrd"
                                onClick={() => {
                                  handleModal(value?.id);
                                }}
                              />
                            </div>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
              <div className="cmn__pagination">
                <ul>
                  <span>Rows per page</span>
                  <li className="no__rows">
                    <select
                      name=""
                      id=""
                      onChange={(e) => setPageSize(e.target.value)}
                    >
                      <option>Select</option>
                      <option value={5}>5</option>
                      <option value={10}>10</option>
                      <option value={15}>15</option>
                      <option value={20}>20</option>
                    </select>
                  </li>
                  <li className="total_pages me-3">
                    <span className="active_pages">{page}</span> of{" "}
                    <span>{total_pages}</span>
                  </li>
                  {/* here we implement validations to handle the page and next page functionality nitin 31/08/2023 */}
                  {total_pages !== 1 && page !== 1 && (
                    <li>
                      <BsChevronBarLeft onClick={() => setPage(1)} />
                    </li>
                  )}
                  {total_pages !== 1 && page !== 1 && (
                    <li>
                      <BsChevronLeft onClick={() => setPage(page - 1)} />
                    </li>
                  )}
                  {total_pages !== 1 && page !== total_pages && (
                    <li>
                      <BsChevronRight onClick={() => setPage(page + 1)} />
                    </li>
                  )}
                  {total_pages !== 1 && page !== total_pages && (
                    <li>
                      <BsChevronBarRight onClick={() => setPage(total_pages)} />
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        show={modalOpen}
        onHide={() => {
          setModalOpen(false);
        }}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>Reset Password</Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <div className="form-group select_country_container cmn_modal_field mb-3">
            <label className="lbl_position ">New Password</label>
            <input
              type="password"
              placeholder="New Password"
              className="form-control"
              autocomplete="new-password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <div className="form-group select_country_container cmn_modal_field mb-3">
            <label className="lbl_position ">Confirm Password</label>
            <input
              type="password"
              placeholder="Confirm Password"
              className="form-control"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </div>
          <div className="d-flex justify-content-end pt-3">
            <button type="button" className="ms-auto me-4 cancel_cm_btn">
              Cancel
            </button>
            <button
              type="submit "
              className="cmn_btn ms-2"
              onClick={handleSubmitPassword}
            >
              Submit
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Userlist;

// const rootElement = document.getElementById("root");
// ReactDOM.render(<App />, rootElement);
