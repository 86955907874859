import React, { useState, useEffect } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { Col } from 'react-bootstrap';
import { toast } from "react-hot-toast";
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { FaRegEdit, FaPlusCircle, FaQuestionCircle } from 'react-icons/fa';
import Accordion from 'react-bootstrap/Accordion';
import Overlay from 'react-bootstrap/Overlay';
import Tooltip from 'react-bootstrap/Tooltip';
import Loader from '../Loader';
import { useParams } from "react-router-dom";
import { get_user_advertise_clients } from '../../app/features/Users/get_advertise_clients_slice';
import Multiselect from "multiselect-react-dropdown";
import { get_selected_advertiser_clients } from '../../app/features/Users/get_selected_advertiser_clients_slice';
import { save_advertiser_clients } from '../../app/features/Users/save_advertiser_clients_slice';
import {
  userSelector, GetLanguages,
  GetRoles, GetGroups,
  GetAllCategories, GetSubGroups,
  GetUser, GetPermissionsBySubgroup,
  SaveSubGroup, clearSaveSubGroup,
  SaveUser, clearSaveUser
} from '../../app/features/Users/UserSlice';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const Updateuser = () => {
  const [show, setShow] = useState(false);
  const [edit, setEdit] = useState(false);
  const [subgroupname, setSubgroupname] = useState("");
  const [newsubgroupname, setNewSubGroupName] = useState("");
  const [all_categories, setAllcategories] = useState([]);
  const [new_sub_categories, setNewSubcategories] = useState([]);
  const [permissionWithCategory, setPermissionWithCategory] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [advertiserId, setAdvertiserId] = useState(null);
  const permission = JSON.parse(localStorage.getItem('userdata'));
  const [all_client_list, setAll_client_list] = useState([])
  const [selected_clients, setSelected_clients] = useState([])
  const [subgroup_id, setSubgroup_id] = useState(null)
  const [selected_advertiser_client_list, setSelected_advertiser_clients_list] = useState([])

  const initialValues = {
    id: "",
    last_name: "",
    first_name: "",
    email: "",
    group_id: "",
    role_id: "",
    allRead: "",
    allWrite: "",
    language_key: "",
    subGroupId: "",
    token: localStorage.getItem('token')
  }

  const params = useParams();
  const formikRef = React.useRef();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const {
    languages, roles,
    groups, allcategories,
    subgroups, error,
    userData, permissions_by_subgroup,
    isSuccessSaveSubGroup, isErrorSaveSubGroup,
    isSuccessSaveUser, isErrorSaveUser
  } = useSelector(userSelector);

  useEffect(() => {
    setSubgroup_id(userData?.SubGroupId)
  }, [userData])

  useEffect(() => {
    dispatch(GetUser({ token: localStorage.getItem('token'), userId: params?.id }))
  }, [params?.id]);

  useEffect(() => {
    dispatch(get_selected_advertiser_clients({ user_id: params?.id }))
  }, [params?.id])

  const client_list = useSelector((state) => { return state.all_user_clients })
  const selected_clients_list = useSelector((state) => { return state.all_selected_advertiser_clients })

  useEffect(() => {
    let newArray = []
    let selected_array = []
    if (selected_clients_list?.data?.data?.length > 0) {
      selected_clients_list?.data?.data[0].map((data) => {
        newArray?.push({
          name: data?.companyName,
          id: data?.id
        })
        selected_array?.push(data?.id)

      })
    }
    setSelected_advertiser_clients_list(newArray)
    setSelected_clients(selected_array)
  }, [selected_clients_list])

  useEffect(() => {
    if (client_list?.isSuccess) {
      setAll_client_list(client_list?.data?.data[0])
    }

  }, [client_list])

  useEffect(() => {
    var havePermission = false;
    let all_category = [];
    for (var i = 0; i < allcategories.length; i++) {
      for (var j = 0; j < allcategories[i].permissions.length; j++) {
        for (var k = 0; k < permissions_by_subgroup.length; k++) {
          if (allcategories[i].permissions[j].id == permissions_by_subgroup[k].id) {
            havePermission = true;
          }
        }
      }
      if (havePermission) {
        all_category.push(allcategories[i])
      }
      havePermission = false;
    }
    setPermissionWithCategory(all_category);
  }, [permissions_by_subgroup])

  useEffect(() => {
    let newArray = [...allcategories]
    if (permissions_by_subgroup.length > 0 && allcategories.length > 0) {
      newArray.forEach((value, index) => {
        if (value.permissions.length > 0) {
          newArray[index] = {
            ...newArray[index], permissions: [...newArray[index].permissions]
          }
          newArray[index].permissions.forEach((pvalue, pindex) => {
            let permission_index = permissions_by_subgroup.findIndex((x) => x.id == pvalue.id);
            if (permission_index != -1) {
              newArray[index].permissions[pindex] = { ...newArray[index].permissions[pindex], checked: true }

            }

          })
        }
      })
    }
    setAllcategories(newArray)
    setNewSubcategories(allcategories)
  }, [allcategories, permissions_by_subgroup])

  useEffect(() => {
    if (isSuccessSaveUser) {
      toast.success("User updated Successfully")
      setShowLoader(false);
      navigation(-1)
    }
    else {
      setShowLoader(false);

    }
    if (isErrorSaveUser) {
      toast.error(error)
    }
    dispatch(clearSaveUser());
  }, [isSuccessSaveUser, isErrorSaveUser])

  useEffect(() => {
    setShowLoader(true);
    if (Object.keys(userData).length > 0) {
      setShowLoader(false);
      userData?.advertiser_id && dispatch(get_user_advertise_clients({ advertiser_id: userData?.advertiser_id }))
      formikRef.current?.setFieldValue("id", userData?.id, false)
      formikRef.current?.setFieldValue("last_name", userData?.last_name, false)
      formikRef.current?.setFieldValue("first_name", userData?.first_name, false)
      formikRef.current?.setFieldValue("email", userData?.email, false)
      formikRef.current?.setFieldValue("group_id", userData?.group_id, false)
      formikRef.current?.setFieldValue("role_id", userData?.role_id, false)
      formikRef.current?.setFieldValue("language_key", userData?.language_key, false)
      formikRef.current?.setFieldValue("allRead", userData?.AllRead, false)
      formikRef.current?.setFieldValue("allWrite", userData?.AllWrite, false)
      setAdvertiserId(userData?.advertiser_id)
      if (userData?.group_id != null) {
        dispatch(GetSubGroups({ token: localStorage.getItem('tokem'), groupId: userData?.group_id })).then((result) => {
          setTimeout(() => {
            let selected_subgroup_index = result.payload.data.findIndex((x) => x.id == userData?.SubGroupId);
            if (selected_subgroup_index !== -1) {
              setSubgroupname(result.payload.data[selected_subgroup_index].description);
            }
            formikRef.current?.setFieldValue("subGroupId", userData?.SubGroupId, false)
            getpermissionsubgroup(userData?.SubGroupId);
          }, 500);

        })
      }
    }
  }, [userData])

  useEffect(() => {
    if (isSuccessSaveSubGroup) {
      getpermissionsubgroup(formikRef.current?.values.subGroupId)
      setEdit(false)
      setShow(false);
    }
    if (isErrorSaveSubGroup) {
      toast.error(error);
    }
    dispatch(clearSaveSubGroup())
  }, [isSuccessSaveSubGroup, isErrorSaveSubGroup])

  useEffect(() => {
    dispatch(GetLanguages({ token: localStorage.getItem('token') })).then((language) => {
    })
    dispatch(GetRoles({ token: localStorage.getItem('token') })).then((roles) => {
    })
    dispatch(GetGroups({ token: localStorage.getItem('token') })).then((groups) => {
    })
    dispatch(GetAllCategories({ token: localStorage.getItem('token') })).then((categories) => {
    })
  }, [])

  const changeGroup = (groupId) => {
    formikRef.current?.setFieldValue("subGroupId", "", false)
    dispatch(GetSubGroups({ token: localStorage.getItem('tokem'), groupId: groupId }))
  }

  const handleFormSubmit = (values, { setSubmitting, resetForm }) => {
    if (subgroup_id == 12) {
      values.advertiser_id = advertiserId
      values.advertiserclientsid = []
      setShowLoader(true)
      dispatch(SaveUser(values))
      dispatch(save_advertiser_clients({ user_id: userData?.id, selected_clients }))
      // }
    } else {
      values.advertiser_id = advertiserId
      values.advertiserclientsid = []
      values.advertiser_clients_id = null
      setShowLoader(true)
      dispatch(SaveUser(values))
      dispatch(save_advertiser_clients({ user_id: userData?.id, selected_clients }))
    }
  };

  const getpermissionsubgroup = (subgroupId) => {
    dispatch(GetPermissionsBySubgroup({ token: localStorage.getItem('token'), subgroupId: subgroupId }))
  }

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);
  const handleCloseEdit = () => setEdit(false);
  const handleSaveSubgroup = async () => {
    if (newsubgroupname == "") {
      toast.error("Sub Group Name is Required")
      return;
    }
    let all_permissions = [];
    await new_sub_categories.forEach((permission, index) => {
      permission.permissions.forEach((per_val, per_index) => {
        if (per_val.checked && per_val.checked == true) {
          all_permissions.push(per_val)
        }
      })
    })

    let subGroup = {
      id: 0,
      description: newsubgroupname,
      userGroupId: formikRef.current?.values.group_id,
      permissions: await all_permissions,
      token: localStorage.getItem('token')
    };
    dispatch(SaveSubGroup(subGroup))
  }

  const handleEditSubGroup = async () => {
    let all_permissions = [];
    await all_categories.forEach((permission, index) => {
      permission.permissions.forEach((per_val, per_index) => {
        if (per_val.checked && per_val.checked == true) {
          all_permissions.push(per_val)
        }
      })
    })

    let subGroup = {
      id: formikRef.current?.values.subGroupId,
      description: subgroupname,
      userGroupId: formikRef.current?.values.group_id,
      permissions: await all_permissions,
      token: localStorage.getItem('token')
    };
    dispatch(SaveSubGroup(subGroup))
  }

  const handleEdit = () => {
    setEdit(true)
  }

  const toggleSelectCategories = (e, index) => {
    let newArray = [...all_categories]
    if (e.target.checked) {
      newArray[index] = {
        ...newArray[index], permissions: newArray[index].permissions.map((item) => ({
          ...item,
          checked: true
        }))
      }
    } else {
      newArray[index] = {
        ...newArray[index], permissions: newArray[index].permissions.map((item) => ({
          ...item,
          checked: false
        }))
      }
    }
    setAllcategories(newArray);
  }

  const toggleSelectnewCategories = (e, index) => {
    let newArray = [...new_sub_categories]
    if (e.target.checked) {
      newArray[index] = {
        ...newArray[index], permissions: newArray[index].permissions.map((item) => ({
          ...item,
          checked: true
        }))
      }
    } else {
      newArray[index] = {
        ...newArray[index], permissions: newArray[index].permissions.map((item) => ({
          ...item,
          checked: false
        }))
      }
    }
    setNewSubcategories(newArray);
  }

  const toggleSelectPermision = (e, cat_index, permission_index) => {
    let newArray = [...all_categories]
    newArray[cat_index] = {
      ...newArray[cat_index], permissions: [...newArray[cat_index].permissions]
    }
    if (e.target.checked) {
      newArray[cat_index].permissions[permission_index] = { ...newArray[cat_index].permissions[permission_index], checked: true }
    } else {
      newArray[cat_index].permissions[permission_index] = { ...newArray[cat_index].permissions[permission_index], checked: false }
    }
    setAllcategories(newArray);
  }
  const toggleNewSelectPermision = (e, cat_index, permission_index) => {
    let newArray = [...new_sub_categories]
    newArray[cat_index] = {
      ...newArray[cat_index], permissions: [...newArray[cat_index].permissions]
    }
    if (e.target.checked) {
      newArray[cat_index].permissions[permission_index] = { ...newArray[cat_index].permissions[permission_index], checked: true }
    } else {
      newArray[cat_index].permissions[permission_index] = { ...newArray[cat_index].permissions[permission_index], checked: false }
    }
    setNewSubcategories(newArray);
  }

  const handle_multiselect_clients = (e) => {
    const all_selected_clients = e.map((val) => val.id).filter(Boolean);
    setSelected_clients(all_selected_clients);
  }

  const handle_multiremove_clients = (e) => {
    const all_selected_clients = e.map((val) => val.id).filter(Boolean);
    setSelected_clients(all_selected_clients);
  }

  return (
    <div className="content_outer">
      <Loader showLoader={showLoader} />
      <div className="user_header"><h2>Update User</h2></div>
      <div className="content">
        <div className="App">

          <div className='form_inner add_user_form'>

            <div className='form_inner'>
              <Formik
                initialValues={initialValues}
                validationSchema={Yup.object({
                  first_name: Yup.string().required('First Name is required'),
                  last_name: Yup.string().required('Last Name is required'),
                  email: Yup.string().required('Email is required').email(),
                  group_id: Yup.string().required('Please Select Group'),
                  role_id: Yup.string().required('Please Select Role'),
                  language_key: Yup.string().required('Please Select Language'),
                  subGroupId: Yup.string().required('Please Select sub group'),
                })}
                onSubmit={handleFormSubmit}
                innerRef={formikRef}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange
                }) => (
                  <Form>
                    <div className='row'>

                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="firsttname">First Name</label>
                          <input type="text" className='form-control' placeholder='First Name' name="first_name" onChange={handleChange}
                            value={values.first_name} />
                          {errors.first_name && touched.first_name ? (
                            <span className='text-danger'>{errors.first_name}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="lastname">Last Name</label>
                          <input type="text" className='form-control' placeholder='Last Name' name="last_name" onChange={handleChange}
                            value={values.last_name} />
                          {errors.last_name && touched.last_name ? (
                            <span className='text-danger'>{errors.last_name}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="email">Email</label>
                          <input type="text" className='form-control' placeholder='Email' name="email" onChange={handleChange}
                            value={values.email} />
                          {errors.email && touched.email ? (
                            <span className='text-danger'>{errors.email}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="language">Language</label>

                          <select name="language_key" className='form-control' value={values?.language_key} onChange={(e) => { handleChange(e); }} >
                            <option value="">--Select--</option>
                            {languages?.map((lang, index) => {
                              return (
                                <option value={lang?.key} key={index}>{lang?.name}</option>
                              )
                            })}
                          </select>
                          {errors.language_key && touched.language_key ? (
                            <span className='text-danger'>{errors.language_key}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="role">Role</label>

                          <select name="role_id" className='form-control' value={values?.role_id} onChange={(e) => { handleChange(e); }} >
                            <option value="">--Select--</option>
                            {userData?.group_id === 9 ? roles?.map((role, index) => {
                              if (role?.id === 2) {
                                return (
                                  <option value={role?.id} key={index}>{role?.name}</option>
                                )
                              }
                            }) : roles?.map((role, index) => {
                              return (
                                <option value={role?.id} key={index}>{role?.name}</option>
                              )
                            })}
                          </select>
                          {errors.role_id && touched.role_id ? (
                            <span className='text-danger'>{errors.role_id}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1"><label className="lbl_position" htmlFor="role">Group</label>

                          <select name="group_id" className='form-control' value={values?.group_id} onChange={(e) => { handleChange(e); changeGroup(e.target.value) }} >
                            <option value="">--Select--</option>
                            {(userData?.SubGroupId == 12 && userData?.SubGroup == "Agency User") ? groups?.map((group, index) => {
                              if (group?.id == 9) {
                                return (
                                  <option value={group?.id} key={index}>{group?.name}</option>
                                )
                              }
                            }) : groups?.map((group, index) => {
                              return (
                                <option value={group?.id} key={index}>{group?.name}</option>
                              )
                            })}
                          </select>
                          {errors.group_id && touched.group_id ? (
                            <span className='text-danger'>{errors.group_id}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {(permission?.role === 1 && Object.values(permission.Permissions).includes('updateSubGroup')) && <div className="form-group">
                          <div className='subgroup d-flex mb-2'>
                            <label htmlFor="role">Sub-Group</label>
                            <div className='ms-2'>
                              <FaPlusCircle className='me-2 ' onClick={handleShow} /> <FaRegEdit onClick={handleEdit} />
                            </div>
                          </div>
                        </div>}
                        <div className="form-group select_country_container cmn_modal_field mb-4 mt-1">
                          <label className="lbl_position" htmlFor="firsttname">Sub-Group</label>
                          <select name="subGroupId" className='form-control' value={values?.subGroupId} onChange={(e) => { handleChange(e); getpermissionsubgroup(e.target.value); setSubgroup_id(e.target.value)}} >
                            <option value="">--Select--</option>
                            {subgroups?.map((subgroup, index) => {
                              return (
                                <option value={subgroup?.id} key={index}>{subgroup?.description}</option>
                              )
                            })}
                          </select>
                          {errors.subGroupId && touched.subGroupId ? (
                            <span className='text-danger'>{errors.subGroupId}</span>
                          ) : null}
                        </div>
                      </div>
                      {(subgroup_id == 12 || subgroup_id == 14) ? <div className="col-md-4">
                        <div className="form-group select_country_container mb-4 mt-1">
                          <label className="lbl_position multisel_lbl" htmlFor="country">
                            Clients
                          </label>
                          <Multiselect
                            options={all_client_list.map((i) => {
                              const name = i?.companyName ?? "Unknown";
                              return { ...i, name };
                            })}
                            selectedValues={selected_advertiser_client_list}
                            singleSelect={subgroup_id == 14}
                            onSelect={(e) => { handle_multiselect_clients(e) }}
                            onRemove={(e) => handle_multiremove_clients(e)}
                            displayValue="name"
                          />
                        </div>
                      </div> : null}
                      <div className="col-md-6">
                        <div className="form-group">
                          {(permission?.role === 1 && Object.values(permission.Permissions).includes('updateSubGroup')) && <div role="group" aria-labelledby="checkbox-group">

                            <label className="form-label mx-2" >
                              <Field type="checkbox" name="allRead" onChange={handleChange} checked={values.allRead} />
                              <span className='ms-1'> Allow all read</span>
                            </label>
                            <label className="form-label mx-2" >
                              <Field type="checkbox" name="allWrite" onChange={handleChange} checked={values.allWrite} />
                              <span className='ms-1'> Allow all write</span>
                            </label>
                          </div>}
                        </div>
                      </div>
                      <div className="col-md-6">
                        {(permission?.role === 1 && Object.values(permission.Permissions).includes('updateSubGroup')) && <div className="form-group">
                          <Accordion defaultActiveKey="0">
                            {permissionWithCategory?.map((category, index) => {
                              return (
                                <Accordion.Item eventKey={index} key={index}>
                                  <Accordion.Header><b>Categoria:</b> {category.description}</Accordion.Header>
                                  <Accordion.Body>
                                    {category.permissions.map((permission) => {
                                      return (
                                        <div className="ml-30 display-flex-aling-center" key={permission.id}>
                                          <p className="ml-30 mb-0"> <b>Permiso:</b> {permission.description}</p>
                                          <a className="tooltip-alert-trigger" id={'messagePermission-' + permission.id}>
                                            <Overlay placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{permission.internalCode}</Tooltip>}>
                                              <div className="mediaplan-permission ml-1 cursor-pointer">
                                                <h6>
                                                  <FaQuestionCircle />
                                                </h6>
                                              </div>
                                            </Overlay>
                                          </a>
                                        </div>
                                      )
                                    })}
                                  </Accordion.Body>
                                </Accordion.Item>
                              )
                            })}
                          </Accordion>
                        </div>}
                      </div>
                      <Col lg={12} className="text-end mt-3 modal-body bg_transparent">
                        <div className='d-flex border-top mt-4 pt-4'>
                          <button type='button' className='ms-auto me-4 cancel_cm_btn' onClick={() => navigation(-1)}>
                            Cancel
                          </button>
                          <button type='submit ' className='cmn_btn ms-2'>
                            Submit
                          </button>
                        </div>
                      </Col>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Sub group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="text" className='form-control' placeholder="Insert Sub Group Name" value={newsubgroupname} onChange={(e) => { setNewSubGroupName(e.target.value) }} />
              <Accordion defaultActiveKey="0">
                {
                  new_sub_categories.map((data, data_index) => {
                    return (
                      <Accordion.Item eventKey={data_index} key={data_index}>
                        <Accordion.Header >
                          <input className="form-check-input ml-4" type="checkbox" onChange={(e) => { toggleSelectnewCategories(e, data_index) }} />{data.description}
                        </Accordion.Header>

                        <Accordion.Body>
                          {data?.permissions?.map((cat, index) => {
                            return (
                              <div className="ml-30 display-flex-aling-center" key={cat.id}>
                                <p className="ml-30 mb-0">
                                  <input className="form-check-input ml-4" name="handle" type="checkbox" value={cat.id} checked={cat?.checked || false} onChange={(e) => { toggleNewSelectPermision(e, data_index, index) }} /> {cat.description}
                                </p>
                              </div>
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleClose}>
                Close
              </Button>
              <Button variant="primary" onClick={handleSaveSubgroup}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal className='modal-lg' show={edit} onHide={handleCloseEdit}>
            <Modal.Header closeButton>
              <Modal.Title> Edit sub group</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <input type="text" className='form-control' placeholder="Insert Sub Group Name" value={subgroupname} onChange={(e) => { setSubgroupname(e.target.value) }} required />
              <Accordion defaultActiveKey="0">
                {
                  all_categories.map((data, data_index) => {
                    return (
                      <Accordion.Item eventKey={data_index} key={data_index}>
                        <Accordion.Header >
                          <input className="form-check-input ml-4" type="checkbox" onChange={(e) => { toggleSelectCategories(e, data_index) }} />{data.description}
                        </Accordion.Header>

                        <Accordion.Body>
                          {data?.permissions?.map((cat, index) => {
                            return (
                              <div className="ml-30 display-flex-aling-center" key={cat.id}>
                                <p className="ml-30 mb-0">
                                  <input className="form-check-input ml-4" name="handle" type="checkbox" checked={cat?.checked || false} onChange={(e) => { toggleSelectPermision(e, data_index, index) }} /> {cat.description}
                                </p>
                                <a className="tooltip-alert-trigger" id={'messagePermission-' + cat.id}>
                                  <Overlay placement="top" overlay={<Tooltip bsPrefix="tooltip-alert">{cat.internalCode}</Tooltip>}>
                                    <div className="mediaplan-permission ml-1 cursor-pointer">
                                      <h6>
                                        <FaQuestionCircle />
                                      </h6>
                                    </div>
                                  </Overlay>
                                </a>
                              </div>
                            )
                          })}
                        </Accordion.Body>
                      </Accordion.Item>
                    )
                  })}
              </Accordion>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleCloseEdit}>
                Close
              </Button>
              <Button variant="primary" onClick={handleEditSubGroup}>
                Save
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  )
}

export default Updateuser;