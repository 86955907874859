import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const campaignfilterStatus = createAsyncThunk(
    'mediaPlanMaanger/campaignfilterStatus',
    async ({ token }, thunkAPI) => {
      try {
        return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaign-status`, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)
  
        });
  
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  )
export const filtercampaign = createAsyncThunk(
    'mediaPlanMaanger/filtercampaign',
    async ({ token, deal_id, filter, page, pageSize }, thunkAPI) => {
      try {
        return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaigns/${deal_id}/${page}/${pageSize}`,filter, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)
  
        });
  
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  )

export const changeCampaignStatus = createAsyncThunk(
    'mediaPlanMaanger/changeCampaignStatus',
    async ({ token, campaign_id, status_id }, thunkAPI) => {
      try {
        return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-campaign-status`,{
          id: campaign_id,
          status_id: status_id
        }, {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)
  
        });
  
      } catch (error) {
        return thunkAPI.rejectWithValue(error.message)
      }
    }
  )



  export const MediaPlanManagerSlice = createSlice({
    name: 'MediaPlanManager',
    initialState: {
      campaign_status_filter: [],
      filtered_campaign: [],
      isFetchingCampaignStatus: false,
      isSuccessCampaignStatus: false,
      isErrorCampaignStatus: false,
      isFetchingFilterCampaign: false,
      isSuccessFilterCampaign: false,
      isErrorFilterCampaign: false,
      isFetchingchangeCampaignStatus: false,
      isSuccesschangeCampaignStatus: false,
      isErrorchangeCampaignStatus: false,
      MediaPlanManagerError: '',

    },
    reducers: {
      clearmediaState: (state) => {
        state.isFetchingCampaignStatus = false;
        state.isSuccessCampaignStatus = false;
        state.isErrorCampaignStatus = false;
        state.isFetchingFilterCampaign = false;
        state.isSuccessFilterCampaign = false;
        state.isErrorFilterCampaign = false;
        state.MediaPlanManagerError="";
        return state;
      },
      updateFilteredCampaign: (state, action)=>{
        state.filtered_campaign= action.payload.campaigns;
        return state;
      }
  
    },
    extraReducers: { 
      [campaignfilterStatus.fulfilled]: (state, { payload }) => {
        state.campaign_status_filter = payload.data;
        state.isFetchingCampaignStatus = false;
        state.isSuccessCampaignStatus = true;
        state.isErrorCampaignStatus = false;
      },
      [campaignfilterStatus.rejected]: (state, { payload }) => {
        state.isFetchingCampaignStatus = false;
        state.isErrorCampaignStatus = true;
        state.MediaPlanManagerError = payload;
      },
      [campaignfilterStatus.pending]: (state) => {
        state.isFetchingCampaignStatus = true;
      },
      [filtercampaign.fulfilled]: (state, { payload }) => {
        state.filtered_campaign = payload.data;
        state.isFetchingFilterCampaign = false;
        state.isSuccessFilterCampaign = true;
        state.isErrorFilterCampaign = false;
      },
      [filtercampaign.rejected]: (state, { payload }) => {
        state.isFetchingFilterCampaign = false;
        state.isErrorFilterCampaign = true;
        state.MediaPlanManagerError = payload;
      },
      [filtercampaign.pending]: (state) => {
        state.isFetchingFilterCampaign = true;
      },
      [changeCampaignStatus.fulfilled]: (state, { payload }) => {
        state.isFetchingchangeCampaignStatus = false;
        state.isSuccesschangeCampaignStatus = true;
        state.isErrorchangeCampaignStatus = false;
      },
      [changeCampaignStatus.rejected]: (state, { payload }) => {
        state.isFetchingchangeCampaignStatus = false;
        state.isErrorchangeCampaignStatus = true;
        state.MediaPlanManagerError = payload;
      },
      [changeCampaignStatus.pending]: (state) => {
        state.isFetchingchangeCampaignStatus = true;
      },
    }
  })
  export const { clearmediaState, updateFilteredCampaign } = MediaPlanManagerSlice.actions;
  export const mediaPlanManagerSelector = (state) => state.MediaPlanManager;