import React from "react";
import { ContainerSubtitleSC, TextSC } from "./subtitle.styled";

export const Subtitle = ({ subtitle = "Reach calculator" }) => {
	return (
		<ContainerSubtitleSC>
			<TextSC> {subtitle}</TextSC>
		</ContainerSubtitleSC>
	);
};

export default Subtitle;
