import React, { useMemo } from 'react';
import { MdOutlineCancel } from "react-icons/md";

const SelectedItems = React.memo((props) => {
  const selectedItemsButtons = useMemo(() => {
    return props.selectedItems.map((item, index) => (
      <div key={item} className="lettersPerformanceReport divIndividualSelectedAttributesPerformanceReport">
        <p className="divIndividualParagraphSelectedAttributesPerformanceReport">{item}</p>
        <MdOutlineCancel
          className="cursorPointer"
          size={16}
          onClick={() => {
            props.setSelectedItems(prevSelectedItems => prevSelectedItems.filter(selectedItem => selectedItem !== item));
            props.setItemsReports(prevItemsReports => [...prevItemsReports, item]);
            props.handleDisableColumns(item);
          }}
        />
      </div>
    ));
  }, [props.selectedItems, props.setSelectedItems, props.setItemsReports]);

  return (
    <>
      <div className="fixedTitleSelectedAttributes">
        <h5 className="lettersPerformanceReport fontBold">{props.title}</h5>
      </div>
      <div className="scrollSelectedAttributes">
        {selectedItemsButtons}
      </div>
    </>
  ) 
});

export default SelectedItems;