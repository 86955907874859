import React, { useState, useEffect, useRef } from "react";
import "./campaign.css";
import { useLocation, useNavigate } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import { Table } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Nav from "react-bootstrap/Nav";
import ProgressBar from "react-bootstrap/ProgressBar";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useSelector, useDispatch } from "react-redux";
import { AiOutlineSearch } from "react-icons/ai";
import { MdOutlineHandshake } from "react-icons/md";
import { BiCalendar } from "react-icons/bi";
import { FaChevronRight } from "react-icons/fa";
import {
  BsChevronLeft,
  BsChevronRight,
  BsChevronBarLeft,
  BsChevronBarRight,
} from "react-icons/bs";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-hot-toast";
import Modal from "react-bootstrap/Modal";
import Loader from "../Loader";
import Moment from "react-moment";
import moment from "moment";
import { Row, Col } from "react-bootstrap";
import { kite_states } from '../../app/features/kiteStates/kite_states_slice';
import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
  getLineitemFormats,
  getCountries,
  getGender,
  getAgeRange,
  getDevices,
  getPassionPoint,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import NewCreativesSaas from "./newCreativesSaas";
import { FiAlertCircle } from "react-icons/fi";
import Spinner from "react-bootstrap/Spinner";
import Tab from "react-bootstrap/Tab";
import EditCreativesSaas from "./editCreativesSaas";
// import Moment from 'react-moment';
const CreativesSaas = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [show_lineitem, setShow_lineitem] = useState(false)
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleCloseLineitem = () => setShow_lineitem(false);
  const handleShowLineitem = () => setShow_lineitem(true)
  const location = useLocation();
  const [creative_id, setCreative_id] = useState(null);
  const [lineitem_details, setLineitem_details] = useState([]);
  const [loadershown, setloadershown] = useState(false);
  const [lineitem_name, setLineitem_name] = useState("");
  const [lineitem_inventory, setLineitem_inventory] = useState(null);
  const [lineitem_format, setLineitem_format] = useState(null);
  const [lineitem_budget, setLineitem_budget] = useState("");
  const [lineitem_offer, setLineitem_offer] = useState(null);
  const [lineitem_bid, setLineitem_bid] = useState(null);
  const [lineitem_budget_usd, setLineitem_budget_usd] = useState(null);
  const [lineitem_bid_usd, setLineitem_bid_usd] = useState(null);
  const [lineitem_impression, setLineitem_impression] = useState(null);
  const [lineitem_countries, setLineitem_countries] = useState([]);
  const [lineitem_countries_id, setLineitem_countries_id] = useState([]);
  const [lineitem_gender, setLineitem_gender] = useState(null);
  const [lineitem_age, setLineitem_age] = useState([]);
  const [lineitem_age_id, setLineitem_age_id] = useState([]);
  const [lineitem_device, setLineitem_device] = useState([]);
  const [lineitem_device_id, setLineitem_device_id] = useState([]);
  const [lineitem_passion_points, setLineitem_passion_points] = useState([]);
  const [lineitem_passion_points_id, setLineitem_passion_points_id] = useState([]);
  const [start_date, setStart_date] = useState("");
  const [end_date, setEnd_date] = useState("");
  const [current_start_date, setCurrent_start_date] = useState("");
  const [current_end_date, setCurrent_end_date] = useState("");
  const [all_creative_list, setAll_creative_list] = useState([]);
  const [creativeModalShow, setCreativeModalShow] = useState(false);
  const [age_group, setAge_group] = useState([]);
  const [youtube_inventory, setYoutube_inventory] = useState(false);
  const [enable_save_button, setEnable_save_button] = useState(false);
  const [creativeStatus, setCreativeStatus] = useState(false);
  const [creative_name_list, setCreative_name_list] = useState([]);
  const [spinner, setSpinner] = useState(false);
  const [show_div, setShow_div] = useState(false);
  const [searchValue, setSearchValue] = useState('')
  const [enabled, setEnabled] = useState(null);
  const [sort, setSort] = useState(false)
  const [lineitem_pacing, setLineitem_pacing] = useState([])
  const [lineitem_pacing_rate, setLineitem_pacing_rate] = useState([])
  const [selected_pacing, setSelected_pacing] = useState(null)
  const [selected_pacing_rate, setSelected_pacing_rate] = useState(null)
  const [edit_creatives, setedit_creatives] = useState(false)
  const [selected_creative_id, setSelected_creative_id] = useState(null)
  const handleCloseCampaignStatus = () => setCreativeStatus(false);
  const handleCreativeStatus = () => setCreativeStatus(true);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [total_pages, setTotal_Pages] = useState(null);
  const [states, setStates] = useState([])
  const [lineitem_selected_states, setLineitem_selected_states] = useState([])
  const [lineitem_selected_state_id, setLineitem_selected_state_id] = useState([])
  const [selectedStates, setSelectedStates] = useState([]);
  const {
    //here in this path we get the lineitem id from the prvious page and here we can hit a endpoint to get all the information of selected lineitem nitin 06/09/2023
    lineitem_id,
    dealID,
    campaign_id,
    client_id,
    deal_currency,
    campaign_budget,
    remaining_budget,
    deal_status_id,
    advertiser_id,
    currency,
  } = location.state ? location.state : location;

  //this below variable is used to get the advertiser_id from the localstorage and we used this in get all clients endpoint and for all permissions nitin 20/09/2023
  const permission = JSON.parse(localStorage.getItem("userdata"));

  // this below function is used to get all the inventories and offers,country,gender,passion points,devices nitin
  const {
    inventories,
    line_item_formats,
    offer_types,
    countries,
    genders,
    age_range,
    devices,
    passion_points,
  } = useSelector(LineItemsSelector);

  useEffect(() => {
    get_all_creatives();
  }, [page, pageSize])

  //this below function is used to get the all the details of inventory format and offer types nitin 07/09/2023
  useEffect(() => {
    if (inventories.length == 0) {
      dispatch(getInventories({ token: localStorage.getItem("token") }));
    }
    if (line_item_formats.length == 0) {
      dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
    }
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }

    if (countries.length == 0) {
      dispatch(getCountries({ token: localStorage.getItem("token") }));
    }
    if (genders.length == 0) {
      dispatch(getGender({ token: localStorage.getItem("token") }));
    }
    dispatch(getDevices({ token: localStorage.getItem("token") }));
    dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
    dispatch(getAgeRange({ token: localStorage.getItem("token") }));
  }, []);

  //this below function is used to get the details of the selected lineitem details nitin 06/09/2023
  useEffect(() => {
    if (lineitem_id) {
      get_selected_lineitem_details();
    }
  }, [lineitem_id]);
  const get_selected_lineitem_details = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getlineitemid/${lineitem_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLineitem_details(result?.data);
        setloadershown(false);
      })
      .catch((error) => toast.error(error));
  };

  //this below useEffect is used to store the values in state of edit lineitem we create diffrent modal for edit and new lineitem nitin 06/09/2023
  useEffect(() => {
    if (lineitem_details.length != 0) {
      setLineitem_name(lineitem_details?.name);
      setLineitem_inventory(lineitem_details?.inventoryNavigation?.id);
      setLineitem_format(lineitem_details?.formatNavigation?.id);
      setStart_date(new Date(lineitem_details?.date_from));
      setEnd_date(new Date(lineitem_details?.date_to));
      setCurrent_start_date(new Date(lineitem_details?.date_from));
      setCurrent_end_date(new Date(lineitem_details?.date_to));
      setLineitem_budget(lineitem_details?.inversion_budget);
      setLineitem_offer(lineitem_details?.offerTypeNavigation?.id);
      setLineitem_bid(lineitem_details?.bid_kite);
      setLineitem_budget_usd(lineitem_details?.inversion_budget_usd);
      setLineitem_bid_usd(lineitem_details?.bid_kite_usd);
      setLineitem_impression();
      setLineitem_gender(lineitem_details?.targetgender_id);
      setSelected_pacing(lineitem_details?.pacing_id);
      setSelected_pacing_rate(lineitem_details?.pacing_rate);
      if (lineitem_details?.countryList) {
        let all_countries_id = [];
        lineitem_details?.countryList?.map((data) => {
          all_countries_id.push({
            id: data?.country?.id,
            name: data?.country?.labelKey,
          });
        });
        setLineitem_countries(all_countries_id);
      }
      if (lineitem_details?.countryList) {
        let all_countries_id = [];
        lineitem_details?.countryList?.map((data) => {
          all_countries_id.push(data?.country?.id);
        });
        setLineitem_countries_id(all_countries_id);
      }
      if (lineitem_details?.audienceList) {
        let all_age = [];
        lineitem_details?.audienceList?.map((data) => {
          all_age.push({
            id: data?.audience?.id,
            name: data?.audience?.age,
          });
        });
        setLineitem_age(all_age);
      }
      if (lineitem_details?.audienceList) {
        let all_age_id = [];
        lineitem_details?.audienceList?.map((data) => {
          all_age_id.push(data?.audience?.id);
        });
        setLineitem_age_id(all_age_id);
      }
      if (lineitem_details?.stateList) {
        let all_states = [];
        lineitem_details.stateList.forEach((data) => {
          let countryId = data.countryId;
          const keys = Object.keys(data.state);
          const values = keys.map(key => data.state[key]);
          all_states.push({
            id: values[0],
            name: values[2],
            countryId: countryId
          });
        });
        setSelectedStates(all_states);
      }
      if (lineitem_details?.deviceList) {
        let devicesval = [];
        lineitem_details?.deviceList.map((value) => {
          let Deviceindex = devices.findIndex(
            (item) => item.id === value?.deviceId
          );
          if (Deviceindex != -1) {
            devicesval.push(devices[Deviceindex]);
          }
        });
        setLineitem_device(devicesval);
      }
      if (lineitem_details?.deviceList) {
        let devicesval = [];
        lineitem_details?.deviceList.map((value) => {
          devicesval.push(value?.deviceId);
        });
        setLineitem_device_id(devicesval);
      }
      if (lineitem_details?.passionPointList) {
        let passionpnt = [];
        lineitem_details?.passionPointList.map((value) => {
          let passionPointIndex = passion_points.findIndex(
            (item) => item.id === value?.passionpointId
          );
          if (passionPointIndex != -1) {
            passionpnt.push(passion_points[passionPointIndex]);
          }
        });
        setLineitem_passion_points(passionpnt);
      }
      if (lineitem_details?.passionPointList) {
        let passionpnt = [];
        lineitem_details?.passionPointList.map((value) => {
          passionpnt.push(value?.passionpointId);
        });
        setLineitem_passion_points_id(passionpnt);
      }
    }
  }, [lineitem_details]);

  const all_states = useSelector((state) => { return state.kitestates })

  useEffect(() => {
    if (all_states?.isSuccess) {
      setStates(all_states?.data?.data[0])
    }
  }, [all_states])

  useEffect(() => {
    if (lineitem_countries_id?.length > 0) {
      dispatch(kite_states({ CountryId: lineitem_countries_id }))
    }

  }, [lineitem_countries_id])

  //this below function is used to set the selected dates nitin 07/09/2023
  const handleCallback = (start, end) => {
    setStart_date(start.format("YYYY-MM-DD"));
    setEnd_date(end.format("YYYY-MM-DD"));
  };

  //this function is used to set the countries nitin 07/09/2023
  const handle_multiselect_countries = (e) => {
    var allSelectedCountries_id = e.map((val) => val.id).filter(Boolean);
    setLineitem_countries_id(allSelectedCountries_id);
  };

  //this below function is used to set the multiple ages this is trigger from the multiselect age range field nitin 07/09/2023
  const handle_multiselect_age_range = (e) => {
    var allSelectedAges = e.map((val) => val.id).filter(Boolean);
    setLineitem_age_id(allSelectedAges);
  };

  //this below function is used to set the multiple devices this is trigger from the devices field nitin 07/09/2023
  const handle_multiselect_devices = (e) => {
    var allSelectedDevices = e.map((val) => val.id).filter(Boolean);
    setLineitem_device_id(allSelectedDevices);
  };

  //this function is used to set the multiple passion points this is trigger from the passion points fields nitin 07/09/2023
  const handle_multiselect_passion_points = (e) => {
    var allSelectedPassionPoints = e.map((val) => val.id).filter(Boolean);
    setLineitem_passion_points_id(allSelectedPassionPoints);
  };

  //    this below function is uded to remove selected countries from thw main array nitin 18/09/2023
  const handle_multiremove_country = (e) => {
    var allSelectedCountries = e.map((val) => val.id);
    setLineitem_countries_id(allSelectedCountries);
    if (selectedStates.length > 0){
			var allSelectedStates = selectedStates.filter((val) => allSelectedCountries.includes(val.countryId));
			setSelectedStates(allSelectedStates);
		}
  };

  //    this below function is uded to remove selected devices from thw main array nitin 18/09/2023
  const handle_multiremove_device = (e) => {
    var allSelectedDevices = e.map((val) => val.id);
    setLineitem_device_id(allSelectedDevices);
  };

  //    this below function is uded to remove selected ages from thw main array nitin 18/09/2023
  const handle_multiremove_age = (e) => {
    var allSelectedAges = e.map((val) => val.id);
    setLineitem_age_id(allSelectedAges);
  };

  //    this below function is uded to remove selected passion points from thw main array nitin 18/09/2023
  const handle_multiremove_passion_points = (e) => {
    var allSelectedPassionPoints = e.map((val) => val.id);
    setLineitem_passion_points_id(allSelectedPassionPoints);
  };

  const handle_multiselect_states = (e) => {
    var allSelectedStatesComplete = e.map((val) => ({ id: val.id, name: val.name, countryId: val.countryId })).filter(Boolean);
    setSelectedStates(allSelectedStatesComplete);
  }

  const handle_multiremove_states = (e) => {
    var allSelectedStatesComplete = e.map((val) => ({
      id: val.id,
      name: val.name,
      countryId: val.countryId
    })).filter(Boolean);
		setSelectedStates(allSelectedStatesComplete);
  }

  //this function is used to update the selected line item nitin 07/09/2023
  const update_lineitem = async () => {
    if (lineitem_name == "") {
      toast.error("Lineitem Name can't be empty");
    } else if (lineitem_inventory == null) {
      toast.error("Please Select Inventory Type");
    } else if (lineitem_format == null) {
      toast.error("Please Select Format");
    } else if (start_date == "") {
      toast.error("Please Select Start Date");
    } else if (end_date == "") {
      toast.error("Please Select End Date");
    } else if (lineitem_budget == null) {
      toast.error("Budget Field Can't be empty");
    } else if (
      lineitem_budget >
      remaining_budget + lineitem_details?.inversion_budget
    ) {
      toast.error(
        `Budget can't be greater than ${remaining_budget + lineitem_details?.inversion_budget
        }`
      );
    } else if (lineitem_offer == null) {
      toast.error("Please Select Offer Type");
    } else if (lineitem_bid == null) {
      toast.error("Bid can't be empty");
    } else if (lineitem_countries_id.length == 0) {
      toast.error("Please select country");
    } else if (lineitem_gender == null || lineitem_gender == "" || lineitem_gender == 'Select') {
      toast.error("Please select gender");
    } else if (lineitem_device_id.length == 0) {
      toast.error("please select device");
    } else if (lineitem_age_id.length == 0) {
      toast.error("Please select Age");
    } else {
      setloadershown(true);
      
      if(selectedStates.length > 0 &&
        !(lineitem_countries_id.includes(4) || 
        lineitem_countries_id.includes(12) ||
        lineitem_countries_id.includes(21))) {
          setSelectedStates([]);
      } 
      
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      let dateFrom = new Date(start_date);
			dateFrom.setHours(0,0,0,0);

			let dateTo = new Date(end_date);
			dateTo.setHours(0,0,0,0);

      var raw = JSON.stringify({
        audiences: lineitem_age_id,
        campaign_id: campaign_id,
        date_from: dateFrom,
        date_to: dateTo,
        deal_id: dealID,
        format_id: lineitem_format,
        inventory_type_id: lineitem_inventory,
        inversion_budget: lineitem_budget,
        inversion_goal: lineitem_impression,
        bid_kite_usd: lineitem_bid_usd,
        inversion_goal_usd: lineitem_budget_usd,
        inversion_offer_type_id: lineitem_offer,
        bid_kite: lineitem_bid,
        bid_kite_usd: lineitem_bid_usd,
        lineitem_devices: lineitem_device_id,
        lineitem_passion_points: lineitem_passion_points_id,
        name: lineitem_name,
        target_country_id: lineitem_countries_id,
        target_state_id: (selectedStates.length > 0 && !(lineitem_countries_id.includes(4) || lineitem_countries_id.includes(12) || lineitem_countries_id.includes(21))) ? [] : selectedStates.map(state => state.id),
        target_gender_id: lineitem_gender,
        pacing_id: selected_pacing ? selected_pacing : null,
        pacing_rate: selected_pacing_rate ? selected_pacing_rate : null,
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      try {
        const response = await fetch(
          `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/update-lineitem/${lineitem_id}`,
          requestOptions
        )
        const result = await response.json();
        if (!result.success) {
          const errorMessage = result.message || "An error occurred";
          throw new Error(errorMessage);
        }
        return toast.success("Lineitem Updated successfully!")
      } catch (error) {
        toast.error(error.message);
      } finally {
        handleClose();
        get_selected_lineitem_details();
      }
    }
  };

  const get_all_creatives = (searchedName, searchedCreativeId) => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    let url = `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManagerSaas/creatives/${lineitem_id}?pageSize=${pageSize}&page=${page}`;

    if (searchedCreativeId) {
      url += `&id=${searchedCreativeId}`;
    }
    if (searchedName) {
      url += `&name=${searchedName}`;
    }

    fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setTotal_Pages(result?.data?.page_count);
        setAll_creative_list(result?.data?.results);
        setloadershown(false);
      })
      .catch((error) => toast.error("Some Internal Error occurred !!"));
  };

  //this below function is used to remove id 1 from the age group nitin 14/09/2023
  useEffect(() => {
    if (age_range) {
      let ages = [];
      age_range?.map((data) => {
        if (data?.id !== 1) {
          ages.push({
            id: data?.id,
            age: data?.age,
          });
        }
        setAge_group(ages);
      });
    }
  }, [age_range]);

  // this below function is used to set the estimated impressions in form nitin 14/09/2023
  useEffect(() => {
    if (lineitem_budget && lineitem_bid) {
      let value = (lineitem_budget / lineitem_bid) * 1000;
      setLineitem_impression(value);
    }
  }, [lineitem_budget, lineitem_bid]);

  // this below function is used to update the status of the creatives nitin 15/09/2023
  const handle_update_creative_status = () => {
    handleCloseCampaignStatus();
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      id: creative_id,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManagerSaas/creative-onOff`,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        toast.success("Creative updated successfully");
        get_all_creatives();
        enabled === 1 && handleShowLineitem()
      })
      .catch((error) => {
        toast.error("Internal error occurred while Updating status");
        setloadershown(false);
      });
  };

  //this below function is used to get the bid usd and budget usd on the basis of kite bid and budget nitin 15/09/2023
  const handle_get_lineitem_bid_usd_budget_usd = () => {
    if (lineitem_bid && lineitem_budget) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        deal_id: dealID,
        id: lineitem_id,
        bid_kite: lineitem_bid,
        inversion_budget: lineitem_budget,
        inversion_offer_type_id: lineitem_offer,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/calculatebudgetusd`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setLineitem_bid_usd(result?.data?.bid_kite_usd);
          setLineitem_budget_usd(result?.data?.inversion_goal_usd);
          setLineitem_impression(result?.data?.estimated_impressions);
        })
        .catch((error) => toast.error(error));
    }
  };

  // this below function is used to set the youtube inventory on which basis we are selecting only single country nitin 19/09/2023
  const handle_set_inventory_type_id = (e) => {
    if (e.target.value == 4) {
      setYoutube_inventory(true);
    } else {
      setYoutube_inventory(false);
    }
  };

  // this below function is used to set the youtube inventory when the page loads on which basis we are selecting only single country nitin 19/09/2023
  useEffect(() => {
    if (lineitem_inventory == 4) {
      setYoutube_inventory(true);
    }
  }, [lineitem_inventory]);

  // this function is triggered when we edit the only end date means when the lineitem is active nitin 12/09/2023
  const handleCallbackEdit = (start, end) => {
    setEnd_date(end.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    if (
      lineitem_name !== "" &&
      lineitem_inventory != null &&
      lineitem_format != null &&
      start_date != "" &&
      end_date != "" &&
      lineitem_budget != null &&
      lineitem_offer != null &&
      lineitem_bid != null &&
      lineitem_countries.length != 0 &&
      lineitem_gender != null &&
      lineitem_device.length != 0 &&
      lineitem_age.length != 0
    ) {
      setEnable_save_button(true);
    } else if (
      lineitem_name === "" ||
      lineitem_inventory == null ||
      lineitem_format == null ||
      start_date == "" ||
      end_date == "" ||
      lineitem_budget == null ||
      lineitem_offer == null ||
      lineitem_bid == null ||
      lineitem_countries.length == 0 ||
      lineitem_gender == null ||
      lineitem_device.length == 0 ||
      lineitem_age.length == 0
    ) {
      setEnable_save_button(false);
    }
  }, [
    lineitem_name,
    lineitem_inventory,
    lineitem_format,
    start_date,
    end_date,
    lineitem_budget,
    lineitem_offer,
    lineitem_bid,
    lineitem_countries,
    lineitem_gender,
    lineitem_device,
    lineitem_age,
  ]);

  const handle_show_status_popup = (id, status_id) => {
    handleCreativeStatus();
    setCreative_id(id);
    setEnabled(status_id);
  };

  const handleFilterCreative = (e) => {
    setSpinner(true);
    setShow_div(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      lineitem_id: lineitem_id,
      name: e.target.value,
      "id": !isNaN(e.target.value) && e.target.value !== '' ? e.target.value : 0
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/filter-creative`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setCreative_name_list(result?.data);
        setSpinner(false);
      })
  };

  const handle_search_with_name = (e) => {
    if (/[a-zA-Z]/.test(e.target.value)) {
      setSpinner(true);
      setShow_div(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        lineitem_id: lineitem_id,
        name: e.target.value,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/filter-creative`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setSpinner(false);
          setCreative_name_list(result?.data);
        })
        .catch((error) => toast.error(error));
    }
  };

  // this below function is used to update the status of lineitem and campaign nitin 10/10/2023
  const handle_update_lineitem_campaign_status = () => {
    handleCloseLineitem()
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "id": lineitem_details?.id
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaignstatus`, requestOptions)
      .then(response => response.json())
      .then(result => { handleCloseLineitem(); get_selected_lineitem_details(); setloadershown(false) })
      .catch(error => toast.error(error.message));
  }

  // this below function is used to sort the data nitin 11/10/2023
  const handle_sort = (field_name) => {
    setSort(!sort)
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManagerSaas/creatives/${lineitem_id}?pageSize=${lineitem_id}&page=${page}&Order=${sort ? "desc" : "asc"}&FieldName=${field_name}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setAll_creative_list(result?.data?.results);
        setloadershown(false);
      })
      .catch((error) => toast.error("Some Internal Error occurred !!"));
  }

  // const this function is used to delete the selected lineitem nitin 20/11/2023
  const handleDelete = () => {
    setloadershown(true)
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'DELETE',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/delete-lineItem/${lineitem_id}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        toast.success("Lineitem deleted successfully"); handleClose(); navigate("/campaignsaas/LineItemsSaas", {
          state: {
            campaign_id: campaign_id,
            client_id: client_id,
            deal_status_id: deal_status_id,
            advertiser_id: advertiser_id,
            currency: currency,
            deal_currency: deal_currency,
          },
        })
      })
      .catch(error => toast.error(error));
  }

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacing`, requestOptions)
      .then(response => response.json())
      .then(result => { setLineitem_pacing(result?.data) })
      .catch(error => toast.error(error));
  }, [])

  useEffect(() => {
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacingrate`, requestOptions)
      .then(response => response.json())
      .then(result => { setLineitem_pacing_rate(result?.data) })
      .catch(error => toast.error(error));
  }, [])

  return (
    <div className="content_outer">
      <Loader showLoader={loadershown} />

      <div className="cmn_header_top">
        <h3>
          <img
            src="/assets/images/chevron_left.svg"
            onClick={() =>
              navigate("/campaignsaas/LineItemsSaas", {
                state: {
                  campaign_id: campaign_id,
                  client_id: client_id,
                  deal_status_id: deal_status_id,
                  advertiser_id: advertiser_id,
                  currency: currency,
                  deal_currency: deal_currency,
                },
              })
            }
          />
          Detail / Creatives
        </h3>
        <div className="cmn_header__search search_bar position-relative">
          <AiOutlineSearch size={24} className={searchValue.length > 0 ? "changeIcon" : ""} />
          <input
            type="text"
            value={searchValue}
            placeholder="Search by Id or Creative Name"
            onChange={(e) => {
              handleFilterCreative(e)
              setSearchValue(e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                if(isNaN(searchValue)){
                  get_all_creatives(searchValue, null);
                } else {
                  get_all_creatives(null, searchValue);
                }
                setSearchValue(searchValue);
                setShow_div(false);
              }
            }}
          />
          {show_div && (
            <div className="search_dropown cursorPointer">
              {spinner && (
                <div className="p-3 text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              )}
              <ul>
                {creative_name_list?.map((data, index) => {
                  return (
                    <li
                      key={index}
                      onClick={() => {
                        get_all_creatives(data?.name, null);
                        setShow_div(false);
                        setSearchValue(data?.name);
                      }}
                    >
                      {`${data?.name} (${data.id})`}
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
        {(deal_status_id == 1 || deal_status_id == 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && (
          <div className="">
            <button
              className="cmn_btn ms-0"
              onClick={() => setCreativeModalShow(true)}
            >
              <FontAwesomeIcon className="ms-2" icon={faPlus} />
              New creative
            </button>
          </div>
        )}
      </div>
      <div className="content pt-0">
        <div className="App">
          <div className="mediaplan_details kite_space_cmn">
            <div className="title__active">
              <h3>{lineitem_details?.name}</h3>
              <div className="status_badge">
                <p>
                  {lineitem_details?.status_id == 1 && (
                    <img src="/assets/images/draft_FILL.svg" />
                  )}
                  {lineitem_details?.status_id == 1 && <span> Draft</span>}
                  {lineitem_details?.status_id == 2 && (
                    <img src="/assets/images/check_circle.svg" />
                  )}
                  {lineitem_details?.status_id == 2 && <span> Active</span>}
                  {lineitem_details?.status_id == 3 && (
                    <img src="/assets/images/pause_circle.svg" />
                  )}
                  {lineitem_details?.status_id == 3 && <span> Pause</span>}
                  {lineitem_details?.status_id == 4 && (
                    <img src="/assets/images/cancel_FILL.svg" />
                  )}
                  {lineitem_details?.status_id == 4 && <span> Inactive</span>}
                </p>
              </div>
            </div>
            <Row>
              <Col lg={12}>
                <div className="advert_tabel">
                  <div className="media_plan_info_list">
                    <ul className="header__media">
                      <li>
                        <MdOutlineHandshake size={24} />
                        <strong>Lineitem Id</strong>
                        <p>{lineitem_details?.id}</p>
                      </li>
                      <li>
                        <BiCalendar size={20} />
                        <strong>Start Date</strong>
                        <p>
                          {
                            <Moment format="DD/MM/YYYY">
                              {lineitem_details?.date_from}
                            </Moment>
                          }
                        </p>
                      </li>
                      <li className="block_divider">
                        <span></span>
                      </li>
                      <li>
                        <strong>End Date</strong>
                        <p>
                          {
                            <Moment format="DD/MM/YYYY">
                              {lineitem_details?.date_to}
                            </Moment>
                          }
                        </p>
                      </li>
                      <li className="block_divider">
                        <span></span>
                      </li>
                      <li>
                        <strong>Flight days</strong>
                        <p>
                          {
                            <p>
                              {moment(lineitem_details?.date_to).diff(
                                moment(lineitem_details?.date_from),
                                "days"
                              )}
                            </p>
                          }
                        </p>
                      </li>
                      <li className="block_divider">
                        <span></span>
                      </li>
                      <li>
                        <strong>Remaining days</strong>
                        <p>
                          {Date.now() > moment(lineitem_details?.date_from)
                            ? moment(lineitem_details?.date_to).diff(
                              moment(),
                              "days"
                            )
                            : moment(lineitem_details?.date_to).diff(
                              moment(lineitem_details?.date_from),
                              "days"
                            )}
                        </p>
                      </li>
                    </ul>
                    <ul className="">
                      <li>
                        <strong>Inventory type</strong>
                        <p>
                          {lineitem_details?.inventoryNavigation?.description}
                        </p>
                      </li>
                      <li>
                        <strong>Offer type</strong>
                        <p>
                          {lineitem_details?.offerTypeNavigation?.descripcion}
                        </p>
                      </li>
                      <li>
                        <strong>Country</strong>
                        <p>
                          {lineitem_details?.countryList?.map(
                            (countries, index) => {
                              return (
                                <span key={index}>
                                  {countries?.country?.ab?.toUpperCase()}
                                  {index !=
                                    lineitem_details?.countryList.length - 1
                                    ? ","
                                    : ""}
                                  &nbsp;
                                </span>
                              );
                            }
                          )}
                        </p>
                      </li>
                      <li>
                        <strong>Format</strong>
                        <p>{lineitem_details?.formatNavigation?.name}</p>
                      </li>
                      <li>
                        <strong>Estimated Impressions</strong>
                        <p>
                          {lineitem_details?.estimated_impressions?.toLocaleString(
                            undefined,
                            { minimumFractionDigits: 2 }
                          )}
                        </p>
                      </li>
                      <li>
                        <strong>Audience</strong>
                        <p>
                          {lineitem_details?.audienceList?.map(
                            (all_audience, index) => {
                              return (
                                <span key={index}>
                                  {all_audience?.audience?.audience}
                                  {index !=
                                    lineitem_details?.audienceList.length - 1
                                    ? ","
                                    : ""}
                                  &nbsp;
                                </span>
                              );
                            }
                          )}
                        </p>
                      </li>
                      <li>
                        <strong>Gender</strong>
                        <p>{lineitem_details?.targetgender_name}</p>
                      </li>
                      {/*{(deal_status_id == 1 || deal_status_id == 2) && (Object.values(permission.Permissions).includes('editcampaignsaas')) && (
                        <li className="" onClick={handleShow}>
                          <div className="pdf_icon">
                            <img src="/assets/images/edit_fill.svg" />
                          </div>
                        </li>
                      )}*/}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col lg={4} className="d-none">
                <div>
                  <ul className="budget single_budget creative_buget">
                    <li className="d-flex w-100 align-items-center">
                      Budget
                      <p className="ms-auto mb-0">
                        Total:{" "}
                        <strong>{lineitem_details?.inversion_budget}</strong>
                      </p>
                    </li>
                    <li className="progress_result">
                      <div className="w-100">
                        <ProgressBar className="bar_color w-100" now={50} />
                      </div>
                    </li>
                    <li className="total_used">
                      <div className="totalbuget">
                        <div className="dot_circle"></div>
                        <p>
                          Used : <strong>564</strong>
                        </p>
                      </div>
                      <div className="totalbuget">
                        <div className="dot_circle"></div>
                        <p>
                          Remaining : <strong>546</strong>
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </div>
        <div className="">
          <div className="new_compaign">
            <div className="mediaplan__tabs">
              <div className=" border-bottom-0 mb-0">
                <div className="">
                  <div className="campiagn_outer border-0 p-0">
                    <div className="toggle_navgations text-end"></div>
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="first"
                    >
                      <Nav variant="pills" className="cmn_tabs">

                        <Nav.Item>
                          <Nav.Link eventKey="second">
                            {" "}
                            <svg
                              stroke="currentColor"
                              fill="none"
                              strokeWidth="2"
                              viewBox="0 0 24 24"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              height="20"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect x="3" y="3" width="7" height="7"></rect>
                              <rect x="14" y="3" width="7" height="7"></rect>
                              <rect x="14" y="14" width="7" height="7"></rect>
                              <rect x="3" y="14" width="7" height="7"></rect>
                            </svg>
                          </Nav.Link>
                        </Nav.Item>
                        <Nav.Item>
                          <Nav.Link eventKey="first">
                            <svg
                              stroke="currentColor"
                              fill="currentColor"
                              strokeWidth="0"
                              viewBox="0 0 1024 1024"
                              height="20"
                              width="20"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path d="M912 192H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 284H328c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h584c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM104 228a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0zm0 284a56 56 0 1 0 112 0 56 56 0 1 0-112 0z"></path>
                            </svg>
                          </Nav.Link>
                        </Nav.Item>
                      </Nav>
                      <Tab.Content>
                        <Tab.Pane eventKey="first">
                          <div className="advert_tabel campign_index_table saas_mediaplan_table">
                            <Table responsive>
                              <thead>
                                <tr style={{ cursor: "pointer" }}>
                                  <th className="text-start" onClick={() => handle_sort("id")}>ID<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                                  <th className="text-start" onClick={() => handle_sort("format_id")}>Format<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                                  <th className="text-start" onClick={() => handle_sort("name")}>Creative name<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                                  <th className="text-start" onClick={() => handle_sort("enabled")}>Status<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>
                                  <th className="text-start">
                                    Status Approval
                                  </th>
                                  <th className="text-start">Landing page</th>
                                  <th style={{ opacity: "0" }}>pdf</th>
                                </tr>
                              </thead>
                              <tbody>
                                {all_creative_list?.length == 0 && (
                                  <div className="no_data">
                                    <img
                                      className="nodata"
                                      src="/assets/images/notfound.svg"
                                    />
                                  </div>
                                )}
                                {all_creative_list?.map(
                                  (creative_list, index) => {
                                    return (
                                      <tr key={index}>
                                        <td className="text-start">
                                          {creative_list?.id}
                                        </td>
                                        <td className="text-start">
                                          {
                                            creative_list?.formatNavigation
                                              ?.name
                                          }
                                        </td>
                                        <td className="text-start">
                                          {creative_list?.name}
                                        </td>

                                        <td>
                                          <Dropdown className="status_dropdown media_plan_dropdown status_design">
                                            {creative_list?.enabled == 1 ? (
                                              <Dropdown.Toggle
                                                className={`filter_btn`}
                                              >
                                                <img src="/assets/images/check_circle.svg" />
                                                <span> On</span>
                                                <FaChevronRight className="ms-auto" />
                                              </Dropdown.Toggle>
                                            ) : (
                                              <Dropdown.Toggle
                                                className={`filter_btn`}
                                              >
                                                <img src="/assets/images/draft_FILL.svg" />
                                                <span> Off</span>
                                                <FaChevronRight className="ms-auto" />
                                              </Dropdown.Toggle>
                                            )}
                                            {Object.values(permission.Permissions).includes('editcampaignsaas') && (
                                              <Dropdown.Menu>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handle_show_status_popup(
                                                      creative_list?.id,
                                                      1
                                                    )
                                                  }
                                                >
                                                  {/* <Dropdown.Item onClick={() => handle_update_creative_status(creative_list?.id)}> */}
                                                  <img src="/assets/images/check_circle.svg" />
                                                  On
                                                </Dropdown.Item>
                                                <Dropdown.Item
                                                  onClick={() =>
                                                    handle_show_status_popup(
                                                      creative_list?.id,
                                                      0
                                                    )
                                                  }
                                                >
                                                  <img src="/assets/images/draft_FILL.svg" />
                                                  Off
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            )}
                                          </Dropdown>
                                        </td>
                                        <td>{creative_list?.approvalStatusText}</td>
                                        <td>{creative_list?.urlLandingPage}</td>
                                        {Object.values(permission.Permissions).includes('editcampaignsaas') && <td className="text-start pe-3">
                                          <div className="pdf_icon ms-auto">
                                            {creative_list?.enabled == 1 ? <img onClick={() => toast.error("You cannot edit a Creative that has been ON")} src="/assets/images/edit_fill.svg" /> : <img onClick={() => { setedit_creatives(true); setSelected_creative_id(creative_list?.id) }} src="/assets/images/edit_fill.svg" />}
                                          </div>
                                        </td>}
                                      </tr>
                                    );
                                  }
                                )}
                              </tbody>
                            </Table>

                            <div className="cmn__pagination">
                              <ul>
                                <span>Rows per page</span>
                                <li className="no__rows">
                                  <select
                                    value={pageSize}
                                    onChange={(e) => {
                                      setPageSize(e.target.value);
                                    }}
                                  >
                                    <option value="5">5</option>
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                  </select>
                                </li>
                                <li className="total_pages me-3">
                                  <span className="active_pages">
                                    {page} of {' '}
                                  </span>
                                  <span>
                                    {total_pages}
                                  </span>
                                </li>
                                {total_pages !== 1 && page !== 1 &&
                                  <li>
                                    <BsChevronBarLeft
                                      onClick={() => setPage(1)}
                                    />
                                  </li>
                                }
                                {total_pages !== 1 && page !== 1 &&
                                  <li>
                                    <BsChevronLeft
                                      onClick={() => setPage(page - 1)}
                                    />
                                  </li>
                                }
                                {page !== total_pages &&
                                  <li>
                                    <BsChevronRight
                                      onClick={() => setPage(page + 1)}
                                    />
                                  </li>
                                }
                                {page !== total_pages &&
                                  <li>
                                    <BsChevronBarRight
                                      onClick={() => setPage(total_pages)}
                                    />
                                  </li>
                                }
                              </ul>
                            </div>
                          </div>
                        </Tab.Pane>
                        <Tab.Pane eventKey="second">
                          <div className="mt-4">
                            <div index="0" className="">
                              {all_creative_list?.length == 0 && (
                                <div className="no_data">
                                  <img
                                    className="nodata"
                                    src="/assets/images/notfound.svg"
                                  />
                                </div>
                              )}
                              <div className="video_view_card row m-0">
                                {all_creative_list?.map((creatives, index) => {
                                  if (creatives?.creativeTypeId === 1) {
                                    return (
                                      <Col lg={2} index={index}>
                                        <div className="video_view_card">
                                          <div className="creativesimg">
                                            <video className="w-100" controls>
                                              <source
                                                height={100}
                                                src={`http://creatives.kitemedia.digital/${creatives.object}`}
                                                type="video/mp4"
                                              />
                                            </video>
                                          </div>
                                          <div className="creative_text pb-0">
                                            <p>Creative ID : <strong>{creatives?.id}</strong></p>
                                            <p>Format : <strong>{creatives?.formatNavigation?.name}</strong></p>
                                            <p>Name : <strong>{creatives.name}</strong></p>
                                            <p>Status : <strong>{creatives?.enabled == 0 ? "Off" : "On"}</strong></p>
                                            <p>Landing Page : <strong><a href={creatives?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                            <p className="mb-0">Status in platform : <strong>{creatives.plateformStatus ? creatives.plateformStatus : 'Pending'}</strong></p>
                                          </div>
                                        </div>
                                      </Col>

                                    )
                                  }

                                })
                                }
                                {

                                  all_creative_list?.map((creative, index) => {
                                    if (creative?.creativeTypeId === 4) {
                                      return (
                                        <Col lg={2} index={index}>
                                          <div className="video_view_card">
                                            <div className="creativesimg">
                                              <img
                                                className="img-fluid"
                                                src={`http://creatives.kitemedia.digital/${creative.object}`}
                                                alt=""
                                              />
                                            </div>
                                            <div className="creative_text pb-0">
                                              <p>Creative ID : <strong>{creative?.id}</strong></p>
                                              <p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
                                              <p>Name : <strong>{creative.name}</strong></p>
                                              <p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
                                              <p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                              <p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
                                            </div>
                                          </div>
                                        </Col>

                                      )
                                    }

                                  })
                                }
                                {

                                  all_creative_list?.map((creative, index) => {
                                    if (creative?.creativeTypeId === 5) {
                                      return (
                                        <Col lg={2} index={index}>
                                          <div className="video_view_card">
                                            <div className="creativesimg">
                                              <img
                                                className="img-fluid"
                                                src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
                                                alt=""
                                              />
                                            </div>
                                            <div className="creative_text pb-0">
                                              <p>Creative ID : <strong>{creative?.id}</strong></p>
                                              <p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
                                              <p>Name : <strong>{creative.name}</strong></p>
                                              <p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
                                              <p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                              <p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
                                            </div>
                                          </div>
                                        </Col>

                                      )
                                    }

                                  })
                                }

                                {
                                  all_creative_list?.map((creative, index) => {
                                    if (creative?.creativeTypeId === 3) {
                                      return (
                                        <Col lg={2} index={index}>
                                          <div className="video_view_card">
                                            {creative?.object !== null && (
                                              <div className="creativesimg">
                                                <iframe
                                                  src={
                                                    creative?.object.includes("youtu.be") // Check if the URL is in the short format
                                                      ? `https://www.youtube.com/embed/${creative?.object.split('/').pop()}`
                                                      : creative?.object.includes("watch") // Check if the URL is in the long format
                                                        ? `https://www.youtube.com/embed/${creative?.object.split('v=')[1]}`
                                                        : creative?.object // Use the original URL if it doesn't match the above conditions
                                                  }
                                                  className="w-100"
                                                  frameBorder="0"
                                                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                                  allowFullScreen
                                                ></iframe>
                                              </div>
                                            )}
                                            <div className="creative_text pb-0">
                                              <p>Creative ID : <strong>{creative?.id}</strong></p>
                                              <p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
                                              <p>Name : <strong>{creative.name}</strong></p>
                                              <p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
                                              <p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                              <p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    }
                                  })
                                }
                                {
                                  all_creative_list?.map((creative, index) => {
                                    if (creative?.creativeTypeId === 2) {
                                      return (
                                        <Col lg={2} index={index}>
                                          <div className="video_view_card">
                                            <div className="creativesimg">
                                              <img
                                                className="img-fluid"
                                                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0crO16mI5mGJwYUh1Al9Faxf765RbmQZahg&usqp=CAU"
                                                alt=""
                                              />
                                            </div>
                                            <div className="creative_text pb-0">
                                              <p>Creative ID : <strong>{creative?.id}</strong></p>
                                              <p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
                                              <p>Name : <strong>{creative.name}</strong></p>
                                              <p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
                                              <p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                              <p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
                                            </div>
                                          </div>
                                        </Col>
                                      )
                                    }
                                  })
                                }
                                {
                                  all_creative_list?.map((creative, index) => {
                                    if (creative?.creativeTypeId === 6) {
                                      return (
                                        <Col lg={2} index={index}>
                                          <div className="video_view_card">
                                            <div className="creativesimg">
                                              <img
                                                className="img-fluid"
                                                src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
                                                alt=""
                                              />
                                            </div>
                                            <div className="creative_text pb-0">
                                              <p>Creative ID : <strong>{creative?.id}</strong></p>
                                              <p>Format : <strong>{creative?.formatNavigation?.name}</strong></p>
                                              <p>Name : <strong>{creative.name}</strong></p>
                                              <p>Status : <strong>{creative?.enabled == 0 ? "Off" : "On"}</strong></p>
                                              <p>Landing Page : <strong><a href={creative?.urlLandingPage} target="blank">Visit Link</a></strong></p>
                                              <p className="mb-0">Status in platform : <strong>{creative.plateformStatus ? creative.plateformStatus : 'Pending'}</strong></p>
                                            </div>
                                          </div>
                                        </Col>

                                      )
                                    }
                                  })
                                }
                              </div>
                            </div>
                          </div>
                        </Tab.Pane>
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* tabs ends here */}
        </div>
      </div>
      {/* add new line item */}
      <Modal
        show={creativeStatus}
        onHide={handleCloseCampaignStatus}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body>
          <div className="model_content">
            <div className="text-center mb-4">
              <FiAlertCircle className="alert_icon" size={64} />
            </div>
            <h4 className="text-center border-bottom pb-5">
              {" "}
              {enabled == 0
                ? "Do you want to turn off these creative?"
                : "Do you want to turn on these creative?"}
            </h4>
            <div className="d-flex justify-content-end mt-5">
              <button
                className="me-4 cancel_cm_btn"
                onClick={handleCloseCampaignStatus}
              >
                Cancel
              </button>
              <button
                className="cmn_btn ms-2 "
                onClick={() => {
                  handle_update_creative_status();
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={show}
        onHide={handleClose}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="p-5">
          <Loader showLoader={loadershown} />
          <Modal.Title id="contained-modal-title-vcenter">
            Edit LineItem
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-5 pt-0 saas_modal">
          <div className="">
            <label className="lable_title">Details</label>
            <Row className="mb-3">
              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position " htmlFor="lignitemname">
                    Line Item Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    name="name"
                    placeholder="lorem"
                    value={lineitem_name}
                    onChange={(e) => setLineitem_name(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
                  <label className="lbl_position text-white" htmlFor="inventorytype">
                    Inventory Type
                  </label>
                  <select
                    name="inventory_type_id"
                    className="form-select bg-secondary text-white"
                    onChange={(e) => setLineitem_inventory(e.target.value)}
                    value={lineitem_inventory}
                    disabled={true}
                  >
                    <option>select</option>
                    {inventories?.map((all_inventory, index) => {
                      if (all_inventory?.id == 1 || all_inventory?.id == 4) {
                        return (
                          <option value={all_inventory?.id} key={index}>
                            {all_inventory?.description}
                          </option>
                        );
                      }
                    })}
                  </select>
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
                  <label className="lbl_position text-white" htmlFor="format">
                    Format
                  </label>
                  <select
                    name="format_id"
                    className="form-select bg-secondary text-white"
                    value={lineitem_format}
                    onChange={(e) => setLineitem_format(e.target.value)}
                    disabled={true}
                  >
                    <option>select</option>
                    {line_item_formats
                      .filter((format) => {
                        // Filter formats based on the selected inventory type (lineitem_inventory)
                        return (
                          (lineitem_inventory == "1" && format.id <= 9) || // Show certain formats for inventory type 1
                          (lineitem_inventory == "4" &&
                            (format.id === 12 ||
                              format.id === 13 ||
                              format.id === 18)) // Show certain formats for inventory type 4
                        );
                      })
                      .map((selected_format) => {
                        return (
                          <option
                            value={selected_format.id}
                            key={selected_format.id}
                          >
                            {selected_format.name}
                          </option>
                        );
                      })}
                  </select>
                </div>
              </Col>

              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3 bg-secondary">
                  <label className="lbl_position text-white" htmlFor="ofertype">
                    Offer Type
                  </label>
                  <select
                    name="inversion_offer_type_id"
                    className="form-select bg-secondary text-white"
                    onChange={(e) => setLineitem_offer(e.target.value)}
                    value={lineitem_offer}
                    disabled={true}
                  >
                    <option>select</option>
                    {lineitem_format == 1 ||
                      lineitem_format == 2 ||
                      lineitem_format == 3 ||
                      lineitem_format == 4 ||
                      lineitem_format == 5 ||
                      lineitem_format == 6 ||
                      lineitem_format == 7 ||
                      lineitem_format == 8 ||
                      lineitem_format == 9 ||
                      lineitem_format == 12 ||
                      lineitem_format == 18
                      ? offer_types?.map((all_offers, index) => {
                        if (all_offers?.id == 5) {
                          return (
                            <option value={all_offers?.id} key={index}>
                              {all_offers?.descripcion}
                            </option>
                          );
                        }
                      })
                      : null}

                    {lineitem_format == 13
                      ? offer_types?.map((all_offers, index) => {
                        if (all_offers?.id == 6) {
                          return (
                            <option value={all_offers?.id} key={index}>
                              {all_offers?.descripcion}
                            </option>
                          );
                        }
                      })
                      : null}
                  </select>
                </div>
              </Col>
            </Row>
            <label className="lable_title">Pricing and budget</label>
            <Row className="">
              <Col lg={3}>
                <div className="form-group mb-3 select_country_container cmn_modal_field">
                  <label className="lbl_position" htmlFor="startdate">
                    Start Date
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    minDate={current_start_date}
                    maxDate={current_end_date}
                    value={start_date}
                    onChange={date =>
                      date < end_date ?
                        setStart_date(date) :
                        (toast.error("Start date must be before end date"))
                    }
                    selected={start_date}
                  />
                </div>
                <p>
                  Actual Start date :{" "}
                  {
                    <Moment format="DD/MM/YYYY">
                      {lineitem_details?.date_from}
                    </Moment>
                  }{" "}
                </p>
              </Col>
              <Col lg={3}>
                <div className="form-group mb-3 select_country_container cmn_modal_field">
                  <label className="lbl_position" htmlFor="startdate">
                    End Date
                  </label>
                  <DatePicker
                    className="form-control"
                    dateFormat="dd/MM/yyyy"
                    minDate={current_start_date}
                    maxDate={current_end_date}
                    value={end_date}
                    onChange={date =>
                      date > start_date ?
                        setEnd_date(date) :
                        (toast.error("End date must be after start date"))
                    }
                    selected={end_date}
                  />
                </div>
                <p>
                  Actual end date :{" "}
                  {
                    <Moment format="DD/MM/YYYY">
                      {lineitem_details?.date_to}
                    </Moment>
                  }{" "}
                </p>
              </Col>
              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position" htmlFor="inventorytype">
                    Pacing
                  </label>
                  <select name="format_id" className="form-select"
                    value={selected_pacing}
                    onChange={(e) => setSelected_pacing(e.target.value)}
                  >
                    <option value="">--Select--</option>
                    {lineitem_inventory == 4 ? lineitem_pacing?.map((data, index) => {
                      if (data?.id == 1) {
                        return (
                          <option key={index} value={data?.id}>{data?.description}</option>
                        )
                      }
                    }) :
                      lineitem_pacing?.map((data, index) => {
                        return (
                          <option key={index} value={data?.id}>{data?.description}</option>
                        )

                      })}
                  </select>
                </div>
              </Col>
              <Col lg={3}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position" htmlFor="inventorytype">
                    Pacing Rate
                  </label>
                  <select name="format_id" className="form-select"
                    value={selected_pacing_rate}
                    onChange={(e) => setSelected_pacing_rate(e.target.value)}
                  >
                    <option value="">--Select--</option>
                    {lineitem_pacing_rate?.map((data, index) => {
                      return (
                        <option key={index} value={data?.id}>{data?.description}</option>
                      )
                    })}
                  </select>
                </div>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position " htmlFor="enddate">
                    Budget {currency ? currency : deal_currency}(
                    <span style={{ fontSize: "10px" }}>
                      Budget must be between 1 and{" "}
                      {remaining_budget +
                        lineitem_details?.inversion_budget}
                    </span>
                    )
                  </label>
                  <input
                    type="number"
                    name="inversion_budget"
                    className="form-control"
                    placeholder="Budget"
                    value={lineitem_budget}
                    onChange={(e) => setLineitem_budget(e.target.value)}
                  />
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position " htmlFor="sellrate">
                    Bid {currency ? currency : deal_currency}
                  </label>
                  <input
                    type="number"
                    name="inversion_sell_rate"
                    className="form-control"
                    placeholder="bid Rate"
                    onChange={(e) => setLineitem_bid(e.target.value)}
                    value={lineitem_bid}
                    onBlur={() => handle_get_lineitem_bid_usd_budget_usd()}
                  />
                </div>
              </Col>
            </Row>

            <Row className="mb-3">
              <Col lg={4}>
                <div className="form-group mb-2">
                  <label className=" " htmlFor="netrate">
                    Budget USD:&nbsp;
                  </label>
                  <span>
                    {lineitem_budget_usd?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2
                    })}
                  </span>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group mb-2">
                  <label className=" " htmlFor="netrate">
                    Bid USD:&nbsp;
                  </label>
                  <span>
                    {lineitem_bid_usd?.toLocaleString(undefined, {
                      minimumFractionDigits: 4,
                      maximumFractionDigits: 4
                    })}
                  </span>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group  mb-2">
                  <label className=" " htmlFor="netrate">
                    Estimated impressions&nbsp;
                  </label>
                  <span>
                    {lineitem_impression?.toLocaleString()}
                  </span>
                </div>
              </Col>
            </Row>
          </div>

          <div className="">
            <label className="lable_title">Targeting</label>
            <Row className="mb-3">
              {youtube_inventory ? (
                <Col lg={4}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="country">
                      Country
                    </label>
                    <Multiselect
                      options={countries.map((i) => {
                        const name = i?.labelKey ?? "Unknown";
                        return { ...i, name };
                      })}
                      selectedValues={lineitem_countries}
                      onSelect={(e) => {
                        handle_multiselect_countries(e);
                      }}
                      singleSelect={true}
                      displayValue="name"
                    />
                  </div>
                </Col>
              ) : (
                <Col lg={4}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="country">
                      Country
                    </label>
                    <Multiselect
                      options={countries.map((i) => {
                        const name = i?.labelKey ?? "Unknown";
                        return { ...i, name };
                      })}
                      selectedValues={lineitem_countries}
                      onSelect={(e) => {
                        handle_multiselect_countries(e);
                      }}
                      onRemove={(e) => handle_multiremove_country(e)}
                      displayValue="name"
                    />
                  </div>
                </Col>
              )}
              {lineitem_countries_id?.length > 0 && (lineitem_countries_id?.includes(4) || lineitem_countries_id?.includes(12) || lineitem_countries_id?.includes(21)) &&
                <Col lg={4}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="country">
                      States
                    </label>
                    <Multiselect
                      options={states?.map((i) => {
                        const name = i?.value ?? "Unknown";
                        return { id: i.id, name, countryId: i.countryId };
                      })}
                      selectedValues={selectedStates}
                      onSelect={(e) => { handle_multiselect_states(e) }}
                      onRemove={(e) => handle_multiremove_states(e)}
                      className="react-datepicker-popper"
                      displayValue="name"
                    />
                  </div>
                </Col>}
              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position " htmlFor="devices">
                    Devices
                  </label>
                  <Multiselect
                    options={devices.map((i) => {
                      const name = i?.name ?? "Unknown";
                      return { id: i.id, name, countryId: i.countryId };
                    })}
                    selectedValues={lineitem_device}
                    onSelect={(e) => handle_multiselect_devices(e)}
                    onRemove={(e) => handle_multiremove_device(e)}
                    displayValue="name"
                  />
                </div>
              </Col>
            </Row>
            <label className="lable_title">Audience</label>
            <Row>
              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3">
                  <label className="lbl_position" htmlFor="country">
                    Gender
                  </label>
                  <select
                    name="gender"
                    className="form-select"
                    onChange={(e) => setLineitem_gender(e.target.value)}
                    value={lineitem_gender}
                  >
                    <option>Select</option>
                    {genders?.map((all_gender, index) => {
                      return (
                        <option value={all_gender?.id} key={index}>
                          {all_gender?.description}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </Col>
              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
                  <label className="lbl_position" htmlFor="country">
                    Age
                  </label>
                  <Multiselect
                    options={age_group.map((i) => {
                      const name = i?.age ?? "Unknown";
                      return { ...i, name };
                    })}
                    selectedValues={lineitem_age}
                    onSelect={(e) => {
                      handle_multiselect_age_range(e);
                    }}
                    onRemove={(e) => handle_multiremove_age(e)}
                    displayValue="name"
                  />
                </div>
              </Col>

              <Col lg={4}>
                <div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
                  <label className="lbl_position " htmlFor="Passion Points">
                    Passion Points
                  </label>
                  <Multiselect
                    options={passion_points.map((i) => {
                      const name = i?.name ?? "Unknown";
                      return { ...i, name };
                    })}
                    selectedValues={lineitem_passion_points}
                    onSelect={(e) => handle_multiselect_passion_points(e)}
                    onRemove={(e) => handle_multiremove_passion_points(e)}
                    displayValue="name"
                  />
                </div>
              </Col>
            </Row>
          </div>
          <div className="model_btn model_btn_new_style">
            <div>{lineitem_details?.status_id == 1 && <button className="cancel_cm_btn  delete_cmn_btn delete_pink_btn" onClick={() => handleDelete()} >Delete</button>}</div>
            <div> <button className="cancel_cm_btn" onClick={handleClose}>
              Cancel
            </button>
              <button
                className={
                  enable_save_button ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"
                }
                //this below onclick function is hit the function to update the lineitem
                onClick={() => update_lineitem()}
              >
                Save
              </button></div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        show={creativeModalShow}
        onHide={() => setCreativeModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="contained-modal-title-vcenter">
            New creative
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <NewCreativesSaas
            lineitem_id={lineitem_id}
            format_id={lineitem_details?.formatNavigation?.id}
            setCreativeModalShow={setCreativeModalShow}
            get_all_creatives={get_all_creatives}
            inventoryNavigationId={lineitem_details?.inventoryNavigation?.id}
          />
        </Modal.Body>
      </Modal>
      <Modal
        show={show_lineitem}
        onHide={() => setShow_lineitem(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Body className=" pt-0 saas_modal">
          <div className="model_content">
            <div className="text-center mb-4">
              <svg stroke="currentColor" fill="none" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" className="alert_icon" height="64" width="64" xmlns="http://www.w3.org/2000/svg"><circle cx="12" cy="12" r="10"></circle><line x1="12" y1="8" x2="12" y2="12"></line><line x1="12" y1="16" x2="12.01" y2="16"></line></svg></div>
            <h4 className="text-center border-bottom pb-5">Do you want to active lineitem and campaign for this creative ?</h4>
            <div className="d-flex justify-content-end mt-5"><button className="me-4 cancel_cm_btn" onClick={() => setShow_lineitem(false)}>Cancel</button><button className="cmn_btn ms-2" onClick={() => handle_update_lineitem_campaign_status()}>Confirm</button></div>
          </div>
        </Modal.Body>
      </Modal>

      {/* this below modal is used to edit the creatives nitin 24/10/2023 */}
      <Modal
        show={edit_creatives}
        onHide={() => setedit_creatives(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="campaign_modal"
      >
        <Modal.Header closeButton className="">
          <Modal.Title id="contained-modal-title-vcenter">
            Edit creative
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className=" pt-0 saas_modal">
          <EditCreativesSaas
            selected_creative_id={selected_creative_id}
            format_id={lineitem_details?.formatNavigation?.id}
            setedit_creatives={setedit_creatives}
            lineitem_id={lineitem_id}
            get_all_creatives={get_all_creatives}
          />
        </Modal.Body>
      </Modal>
      {/* add new line item */}
    </div>
  );
};

export default CreativesSaas;
