import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import Loader from "../Loader";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-hot-toast";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import {
    ExchangeRates,
    deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";
const Edit_campaign = (props) => {
    const dispatch = useDispatch();
    const { dealCurrencies } = useSelector(deligateSelector);
    const [campaign_name, setCampaign_Name] = useState("")
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [currentStartDate, setCurrentStartDate] = useState('')
    const [currentEndDate, setCurrentEndDate] = useState('')
    const [campaign_budget, setCampaign_budget] = useState(null)
    const [campaign_details, setCampaign_details] = useState([])
    const [loadershown, setloadershown] = useState(false);
    const [campaign_budget_usd, setCampaign_budget_usd] = useState(null)
    const [active_button, setActive_button] = useState(false)

    // this below function is used to get all the deals values nitin 19/09/2023
    useEffect(() => {
        if (props?.deal_currency != "") {
            dispatch(ExchangeRates({ dealCurrency: props?.deal_currency }));
        }
    }, [props?.deal_currency]);

    //this below function is used to set the dates while saving new campaigns nitin 15/09/2023
    const handleCallback = (start, end) => {
        setStartDate(start.format("YYYY-MM-DD"))
        setEndDate(end.format("YYYY-MM-DD"))
    };

    //this below function is used to get the details of selected campaign based on client_id and campaign_id nitin 15/09/2023
    useEffect(() => {
        if (props?.client_id && props?.selected_campaign_id) {
            get_selected_campaign_detail()
        }
    }, [props?.client_id, props?.selected_campaign_id])

    const get_selected_campaign_detail = () => {
        setloadershown(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/getCampaignAdvertiserClient/${props?.client_id}/${props?.selected_campaign_id}`, requestOptions)
            .then(response => response.json())
            .then(result => {
                setCampaign_details(result?.data);
                setCampaign_Name(result?.data?.name);
                setCampaign_budget(result?.data?.budge);
                setCampaign_budget_usd(result?.data?.budget_usd);
                setStartDate(new Date(result?.data?.date_from));
                setEndDate(new Date(result?.data?.date_to));
                setCurrentStartDate(new Date(result?.data?.date_from));
                setCurrentEndDate(new Date(result?.data?.date_to));
                setloadershown(false)
            })
            .catch(error => toast.error(error));

    }

    //this below function is used to update the selected campaign nitin 15/09/2023
    const handle_update_campaigns = () => {
        if (campaign_name === "") {
            toast.error("Campaign name can't be empty")
        }
        else if (startDate === "" || endDate === "") {
            toast.error("Please select dates !!")
        }
        else if (campaign_budget === null) {
            toast.error("Please add budget !!")
        }
        else if (campaign_budget < campaign_details?.lineItemTotalBudget) {
            toast.error(`Budget cannot be lower than ${campaign_details?.lineItemTotalBudget}`)
        }
        else {
            setloadershown(true)
            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");
            myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

            var raw = JSON.stringify({
                "id": props?.selected_campaign_id,
                "name": campaign_name,
                "kite_deal_id": props?.client_deal_id ? props?.client_deal_id : campaign_details?.kite_deal_id,
                "date_from": startDate,
                "date_to": endDate,
                "budge": campaign_budget,
                "kite_advertiser_client_id": props?.client_id,
                "status_id": campaign_details?.status_id,
                "budget_usd": campaign_budget_usd,
            });

            var requestOptions = {
                method: 'PUT',
                headers: myHeaders,
                body: raw,
                redirect: 'follow'
            };

            fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/updateCampaignAdvertiserClient`, requestOptions)
                .then(response => response.text())
                .then(result => { props?.get_all_campaigns(); toast.success("Campaign updated succesfully"); props?.handleCloseEditCampaign(); props?.get_Advertiser_detail() })
                .catch(error => { toast.error("Internal Error Occurred While Updating the Campaign"); setloadershown(false) });
        }

    }

    // this below function is used to calculate the budget usd nitin 19/09/2023
    const calculate_budget_usd = (e) => {
        let value = dealCurrencies['USD'] * e.target.value;
        if (value) {
            setCampaign_budget_usd(value)
        }
    }

    // this below function is used to enable the save button nitin 19/09/2023
    useEffect(() => {
        if (campaign_name !== "" && startDate !== "" && endDate !== "" && campaign_budget !== "") {
            setActive_button(true)
        } else if (campaign_name === "" || startDate === "" || endDate === "" || campaign_budget === "") {
            setActive_button(false)
        }
    }, [campaign_name, startDate, endDate, campaign_budget])

    // this function is used to delete the selected campaign nitin 20/11/2023
    const handleDelete = () => {
        setloadershown(true)
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'DELETE',
            headers: myHeaders,
            redirect: 'follow'
        };

        fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/campaign/deleteCampaignAdvertiserClient/${props?.selected_campaign_id}`, requestOptions)
            .then(response => response.text())
            .then(result => {  props?.get_all_campaigns(); toast.success("Campaign Deleted successfully!"); props?.handleCloseEditCampaign(); })
            .catch(error => toast.error(error));
    }

    return (
        <div>
            <Loader showLoader={loadershown} />
            <Row>
                <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="campaignName">Campaign Name</label>
                        <input className="form-control" type="text" placeholder="Campaign Name*" value={campaign_name} onChange={(e) => setCampaign_Name(e.target.value)} />
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="campaignName">Start date</label>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            value={startDate}
                            disabled={startDate <= new Date()}
                            onChange={date => {
															date >= endDate ?
															toast.error('Start date must be before than End date') :
															setStartDate(date)
                            }}
                            selected={startDate}
                        />
                    </div>
                    <p>
                        Actual Start date: {<Moment format="DD/MM/YYYY">
                            {campaign_details?.date_from}
                        </Moment>} </p>
                </Col>
                <Col lg={3}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="campaignName">End date</label>
                        <DatePicker
                            className="form-control"
                            dateFormat="dd/MM/yyyy"
                            minDate={new Date()}
                            value={endDate}
                            onChange={date => {
															date <= startDate ?
															toast.error('End date must be after Start date') :
															setEndDate(date)
														}}
                            selected={endDate}
                        />
                    </div>
                    <p>
                        Actual end date: {
                            <Moment format="DD/MM/YYYY">
                                {campaign_details?.date_to}
                            </Moment>
                        }
                    </p>
                </Col>
                <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position " for="campaignName">Budget({props?.deal_currency}) {(campaign_details?.lineItemTotalBudget && `Budget not lower than ${campaign_details?.lineItemTotalBudget}`)}</label>
                        <input className="form-control" type="text" placeholder="Budget" value={campaign_budget} onChange={(e) => { setCampaign_budget(e.target.value); calculate_budget_usd(e) }} />
                    </div>
                </Col>
                <Col lg={6} className="model_content_padding">
                    <div>
                        Budget USD : {"$" + campaign_budget_usd?.toLocaleString()}
                    </div>
                </Col>
                <Col lg={12}>
                    <div className="model_btn model_btn_new_style">
                        <div>
                            {props?.campaign_status == 1 && <button className="cancel_cm_btn  delete_cmn_btn delete_pink_btn" onClick={() => handleDelete()}>Delete</button>}
                        </div>
                        <div>
                            <button className="cancel_cm_btn paddingCancelButton" onClick={() => props?.handleCloseEditCampaign()}>Cancel</button>
                            <button className={active_button ? "cmn_btn" : "cmn_btn cancel_cmn ms-2"} onClick={() => handle_update_campaigns()}>Save</button>
                        </div>

                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Edit_campaign