import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const change_user_password_previous = createAsyncThunk("change_user_password", async ({ userId,newPassword },thunkAPI) => {
try {
    var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );
      
      var raw = JSON.stringify({
        password: newPassword,
      });
      
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      
      const response = await fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}Auth/passwordchange/${userId}`,
        requestOptions
      )
      if (!response.ok) {
        const errorMessage = await response.json();
        if (errorMessage) {
            throw new Error(errorMessage.message);
        }
    }
    const result = await response.json();
    return result;
} catch (error) {
    return thunkAPI.rejectWithValue({
        message: error.message,
    });
}
})

export const changeUserPasswordPrevious = createSlice({
    name: "changeUserPassword",
    initialState: {
        data: {},
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_update_password_state: (state) => {
            state.data = {}
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(change_user_password_previous.pending, (state) => {
                state.loading = true
            })
            .addCase(change_user_password_previous.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(change_user_password_previous.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const {clear_update_password_state} = changeUserPasswordPrevious.actions
export default changeUserPasswordPrevious.reducer