import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const fetchabstract = createAsyncThunk(
    'abstract/fetchabstract',
    async ({ gender, age, country, token }, thunkAPI) => {
        try {
            return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/abstract`, { gender, age, country }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token,
                }
            }).then((data) => {
                return { ...data.data }
            }).catch((e) => { 
                return thunkAPI.rejectWithValue(e.response.data);
            })

        } catch (e) {
            let error = "";
            if (e.response) {
                error = e.response.data;
            } else if (e.request) {
                error = e.request;
            } else {
                error = e.message;
            }
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const reachPlanner = createAsyncThunk(
    'abstract/reachPlanner',
    async ({ country,gender,age,budget, product,number,offerType, token }, thunkAPI) => {
        try {
            return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/reach`, { country,gender,age,budget, product,number,offerType }, {
                headers: {
                    'Content-Type': 'application/json',
                    "Authorization": "Bearer " + token,
                }
            }).then((data) => {
                return { ...data.data }
            }).catch((e) => {
                return thunkAPI.rejectWithValue(e.response.data);
            })

        } catch (e) {
            let error = "";
            if (e.response) {
                error = e.response.data;
            } else if (e.request) {
                error = e.request;
            } else {
                error = e.message;
            }
            return thunkAPI.rejectWithValue(error)
        }
    }
)


export const AbstractSlice = createSlice({
    name: "abstract",
    initialState: {
        incidencia: {},
        poblacion_proyectada: {},
        population_projection_by_age: {},
        use_as_per_age: {},
        rachpalnnerdata:{},
        connected_Population: 0,
        dataloaded: false,
        reachplannerdataLoaded: false,
        status: '',
        isFetching: false,
        isSuccess: false,
        isError: false,
        isFetchingreachPlanner: false,
        isSuccessreachPlanner: false,
        isErrorreachPlanner: false,
        errorMessage: ''
    },
    reducers: {
        clearState: (state) => {
            state.isError = false;
            state.isSuccess = false;
            state.isFetching = false;
            return state;
        },
        cleareachPlanner: (state)=>{
            state.isErrorreachPlanner = false;
            state.isSuccessreachPlanner = false;
            state.isFetchingreachPlanner = false;
            state.reachplannerdataLoaded = false;
            return state;
        }
    },
    extraReducers: {
        [fetchabstract.fulfilled]: (state, { payload }) => ({
            ...state,
             isFetching: false,
             dataloaded:true,
            isSuccess:true,
            status:payload.message,
            incidencia:payload.data.incidencia,
            poblacion_proyectada:payload.data.poblacion_proyectada,
            population_projection_by_age:payload.data.population_projection_by_age,
            use_as_per_age:payload.data.use_as_per_age,
            connected_Population: payload.data.connected_population

        }),
        [fetchabstract.pending]: (state) => ({
            ...state,
            isFetching: true
        }),
        [fetchabstract.rejected]: (state, { payload }) => ({
            ...state,
            isFetching:false,
            isError:true,
            errorMessage:payload.message
        }),
        [reachPlanner.fulfilled]: (state, { payload }) => {
            state.isFetchingreachPlanner = false;
            state.isSuccessreachPlanner = true;
            state.isErrorreachPlanner = false;
            state.reachplannerdataLoaded= true;
            state.rachpalnnerdata = payload.data;
          },
          [reachPlanner.rejected]: (state, { payload }) => {
            state.isFetchingreachPlanner = false;
            state.isErrorreachPlanner = true;
            state.errorChangeDeligate = payload;
          },
          [reachPlanner.pending]: (state) => {
            state.isFetchingreachPlanner = true;
          },
    }
})
export const { clearState, cleareachPlanner } = AbstractSlice.actions;

export const abstractSelector = (state) => state.abstract;  