import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import LineItemList from "./lineItemList";
import { toast } from "react-hot-toast";

const BlackList = (props) => {
	const {getData, inventoryTypeId} = props;
	const params = useParams();
	const [blackListFile, setBlackListFile] = useState(null);
	const [blackList, setBlackList] = useState(null);


	useEffect(() => {
		getClientBlackList();
	}, [])
	const getClientBlackList = async () => {
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getclientblacklist/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				}
			}
		);
		const result = await response.json();
		setBlackList(result.data.exclude);
	}

	const uploadList = async () => {
		const formData = new FormData();
		formData.append("file", blackListFile);
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/upload_black_list/${params.id}`,
			{
				method: "POST",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
				body: formData,
			}
		);
		if(response.status === 200) {
			getClientBlackList();
			getData();
			toast.success("List uploaded successfully!");
		} else {
			toast.error("File extension must be xls or xlsx");
		}

	}

	const downloadExampleFile = () => {		
		var archiveType = 'blackorwhite-apps-example.xlsx';
		if (inventoryTypeId == 4) {
			archiveType = 'blackorwhite-yt-example.xlsx';		
		}
		window.open(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}assets/docs/${archiveType}`
		);		
	}

  return (
    <>
    	<div className="whiteListUpload">
    	  <div className="fileFormatList">
				File format: here you can get a file example to understand how to create the structure of your lists.
    	    <button className="btnExampleFile" onClick={downloadExampleFile}>
    	      Download example file
    	    </button>
    	  </div>
			</div>
			<div className="fileUploadWhiteListContainer">
    		<div className="fileUploadWhiteList">
					<label className="labelFile labelFileWhiteButton" htmlFor="fileWhiteList">Choose File</label>
					<input
							id="fileWhiteList"
							type="file"
							name="file"
							style={{ display: 'none' }}
							onChange={(e) => {
								setBlackListFile(e.currentTarget.files[0]);
							}}
					/>
					<label className="lable_title labelFileWhite">
						{blackListFile ? blackListFile.name : 'Select file'}
					</label>
    		</div>
				<button className="uploadListButton" onClick={uploadList}>
					Upload
				</button>
			</div>
				
			{blackList !== null &&			
        <LineItemList list={blackList} defaultList={false} isBlackList={true}/>
			}
    </>
  );

}

export default BlackList;