import styled from "styled-components";

export const ButtonSC = styled.button`
	background: ${({ bg }) => bg};
	border-radius: 120px;
	border: none;
	font-weight: bold;
	color: ${({ color }) => color};
	width: 100%;
	height: 40px;
	font-size: 12px;
	margin: 0 0em;
	padding: 0.25em 0em;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.42;
	letter-spacing: normal;
`;
