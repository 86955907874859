import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    message: "",
    isError: false,
    isSuccess: false,
    loading: false,
    error: null
}

export const updateUserProfile = createAsyncThunk("updateUserProfile", async ({userId ,position,image}, thunkAPI) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        const formdata = new FormData();
        formdata.append("position", position);
        image && formdata.append("image" , image)

        const requestOptions = {
            method: "POST",
            headers: myHeaders,
            body: formdata,
            redirect: "follow"
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/Updateuserprofile/${userId}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const update_user_profile = createSlice({
    name: "update_user_profile",
    initialState,
    reducers: {
        clear_update_user_profile_state: (state) => {
            Object.assign(state, initialState)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(updateUserProfile.pending, (state) => {
                state.loading = true
            })
            .addCase(updateUserProfile.fulfilled, (state, action) => {
                state.message = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(updateUserProfile.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_update_user_profile_state } = update_user_profile.actions
export default update_user_profile.reducer