import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_user_group_permissions = createAsyncThunk("get_user_group_permissions", async ({ selected_group_id }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var raw = JSON.stringify({
            "SubGroupsId": selected_group_id
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/getpermissiongroup`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const getUserSubGroupPermissions = createSlice({
    name: "getUserSubGroupNames",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_permission_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
        },
    extraReducers: (builder) => {
        builder
            .addCase(get_user_group_permissions.pending, (state) => {
                state.loading = true
            })
            .addCase(get_user_group_permissions.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(get_user_group_permissions.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }

})
export const {clear_permission_state} = getUserSubGroupPermissions.actions
export default getUserSubGroupPermissions.reducer