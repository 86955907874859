import ClipLoader from "react-spinners/ClipLoader";

const Loader = (props) => {
	const { showLoader } = props;
	return (
		<>
			{showLoader && (
				<div className="loader_outer">
					<img src="/assets/images/loader.gif" />
				</div>
			)}
		</>
	);
};

export default Loader;
