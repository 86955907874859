import i18n from "../../../i18n";

const columnsReportsList = [
  {reportType: "both", value: i18n.advanceReportsImpressions},
  {reportType: "both", value: i18n.advanceReportsViews},
  {reportType: "both", value: i18n.advanceReportsClicks},
  {reportType: "both", value: i18n.advanceReportsEngagements},
  {reportType: "both", value: i18n.advanceReportsVTR},
  {reportType: "both", value: i18n.advanceReportsCTR},
  {reportType: "both", value: i18n.advanceReportsER},
  {reportType: "both", value: i18n.advanceReportsInversionBudgetCurrency},
  {reportType: "both", value: i18n.advanceReportsInversionBudgetUSD},
  {reportType: "both", value: i18n.advanceReportsSpentUSD},
  {reportType: "both", value: i18n.advanceReportsCurrency},
  {reportType: "both", value: i18n.advanceReportsSpentCurrencyDeal},
  {reportType: "both", value: i18n.advanceReportsStartDate},
  {reportType: "both", value: i18n.advanceReportsEndDate},
  {reportType: "both", value: i18n.advanceReportsQuartiles},
  {reportType: "both", value: i18n.advanceReportsCpm},
  {reportType: "both", value: i18n.advanceReportsCpv},
  {reportType: "both", value: i18n.advanceReportsCpmCurrency},
  {reportType: "both", value: i18n.advanceReportsCpvCurrency},
  {reportType: "fixed", value: i18n.advanceReportsGoal},
]

export default columnsReportsList;