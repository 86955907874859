import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { MdOutlineChevronLeft } from "react-icons/md";
import CommonModal from '../Modal/commonModal';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { get_all_selected_deal_client } from '../../app/features/Admin_saas/get_all_selected_deal_client_slice';
import Loader from "../Loader";
import { save_service_fee } from '../../app/features/Admin_saas/save_service_fee_slice';
import { toast } from "react-hot-toast";
import { clear_save_service_fee_state } from '../../app/features/Admin_saas/save_service_fee_slice';
import { clear_get_all_selected_deal_clients } from '../../app/features/Admin_saas/get_all_selected_deal_client_slice';

const ClientServiceFee = () => {
    const navigate = useNavigate()
    const location = useLocation()
    const dispatch = useDispatch()
    const [show, setShow] = useState(false);
    const [loadershown, setloadershown] = useState(true);
    const [servicefee, setServiceFee] = useState("")
    const [advertiserId, setAdvertiserId] = useState("")
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const { dealId, dealName } = location?.state ? location?.state : location;
    const all_client_data = useSelector((state) => { return state.get_selected_deal_clients_admin_saas });
    const saved_service_fee_data = useSelector((state) => { return state.save_all_service_fee })

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_get_all_selected_deal_clients())
        };

    }, [])

    useEffect(() => {
        dispatch(get_all_selected_deal_client({ dealId }))
    }, [dealId])

    useEffect(() => {
        if (all_client_data?.isSuccess) {
            setloadershown(false)
        }
        if (all_client_data?.isError) {
            toast.error(all_client_data?.error?.message)
            setloadershown(false)
        }
    }, [all_client_data])

    const handle_save_service_fee = () => {
        if (!servicefee || /[^\d.]/.test(servicefee)) {
            toast.error("Please fill Service Fee with valid numeric value");
        }
        else if (servicefee == 0 || servicefee < 0.01) {
            toast.error("Service Fee Cannot be Zero")
        }
        else {
            dispatch(save_service_fee({ advertiser_id: advertiserId, service_fee: servicefee }))
        }
    }

    useEffect(() => {
        if (saved_service_fee_data?.isSuccess) {
            toast.success("Service Fee Updated SuccessFully !!!")
            dispatch(get_all_selected_deal_client({ dealId }))
            dispatch(clear_save_service_fee_state())
            handleClose()
        }
    }, [saved_service_fee_data])

    return (
        <div className="content_outer adminSaas">
            <Loader showLoader={loadershown} />
            <div className="cmn_header_top campaign__header mb-3">
                <Row className="m-0 w-100">
                    <Col lg={10} className="m-auto">
                        <h3><MdOutlineChevronLeft size={22} onClick={() => navigate("/adminSaas")} />{dealName}</h3>
                    </Col>
                </Row>
            </div>
            <Row className="m-0 w-100">
                <Col lg={10} className="m-auto">
                    <h3 className="filter_title mt-4">Clients - Service Fee</h3>
                    <div className='advert_tabel campign_index_table'>
                        <Table responsive className={(all_client_data?.data?.data?.length === 0 || all_client_data?.error != null) && "no_data"}>
                            <thead>
                                <tr>
                                    <th className='text-start'>ID</th>
                                    <th className='text-start'>Name</th>
                                    <th className='text-start'>Country</th>
                                    <th className='text-start'>Email</th>
                                    <th className='text-start' colSpan={2}>Service Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(all_client_data?.data?.data?.length === 0 || all_client_data?.error != null) && <img className="nodata" src="/assets/images/notfound.svg" />}
                                {all_client_data?.data?.data?.length != 0 ?
                                    all_client_data?.data?.data?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data?.advertiserclientId}</td>
                                                <td>{data?.name}</td>
                                                <td>{data?.country}</td>
                                                <td>{data?.email}</td>
                                                <td>{data?.serviceFees}</td>
                                                <td className='text-end'>
                                                    <div className="icons_downloads justify-content-end">
                                                        <div className="pdf_icon" onClick={() => { handleShow(); setServiceFee(data?.serviceFees); setAdvertiserId(data?.advertiserclientId) }} title="Edit Service Fee">
                                                            <img src="/assets/images/edit_fill.svg" />
                                                        </div>
                                                        <div className="pdf_icon" onClick={() => { navigate("/adminSaas/clientServiceHistory", { state: { adveriser_id: data?.advertiserclientId, clientName: data?.name, dealName } }) }} title="Client History Service Fee">
                                                            <img src="/assets/images/description_file.svg" />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    : ""}


                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
            <CommonModal show={show} handleClose={handleClose} handle_save={handle_save_service_fee} heading={"Update Service Fee"}>
                <div className='form-group select_country_container cmn_modal_field mb-3'>
                    <label className='lbl_position '>
                        Service Fee
                    </label>
                    <input value={servicefee} type='text' className='form-control' placeholder='Service Fee' onChange={(e) => setServiceFee(e.target.value)} />
                </div>
            </CommonModal>
        </div>
    )
}

export default ClientServiceFee