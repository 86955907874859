import React, { useState, useEffect } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {
  AiOutlineClose,
  AiOutlineArrowRight,
  AiOutlineLeft,
} from "react-icons/ai";
import { MultiSelect } from "react-multi-select-component";
import { Row, Col, Form, Table } from "react-bootstrap";
import {
  index,
  reportSelector,
  getmedia_Planner,
} from "../../app/features/Report/reportSlice";
import { toast } from "react-hot-toast";
import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
  getPassionPoint,
  getGender,
  getAgeRange,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  CommonSelector,
  updateBreadCrumb,
} from "../../app/features/Common/CommonSlice";
import {
  campaignManagerSelector,
  dealdetail,
  deal_currency,
  getowners,
  pipelinefilter,
  clearState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import Loader from "../Loader";
const MediaPlanner = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();

  const [country, setCountry] = useState("pe");
  const [countries, setCountries] = useState([]);
  const [passionPoint, setpassionPoint] = useState([]);
  const [loadershown, setloadershown] = useState(false);
  const [mediaplangender, setCampaignGender] = useState(" ");
  const [mediaplanneragerange, setCampaignAgeRange] = useState([]);
  const [product, setProduct] = useState(1);
  const [selected, setSelected] = useState([]);
  const [mediaplans, setMediaPlans] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [gender, setGender] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState([]);
  const [audienceRange, setAudienceRange] = useState([]);
  const { products, ageRanges, mediaPlanner } = useSelector(reportSelector);
  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);

  const { offer_types, passion_points, genders, age_range } =
    useSelector(LineItemsSelector);

  useEffect(() => {
    dispatch(index({}));
    dispatch(getInventories({ token: localStorage.getItem("token") }));
    // dispatch(fetchabstract({country: filteredCountries, gender: filteredGender, age: ageRange, token: localStorage.getItem('token')}))
  }, []);

  useEffect(() => {
    if (Object.keys(deal_detail).length > 0) {
      dispatch(updateBreadCrumb([]));
    }
  }, []);

  useEffect(() => {
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }
    if (passion_points.length == 0) {
      dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
    }
    if (genders.length == 0) {
      dispatch(getGender({ token: localStorage.getItem("token") }));
    }
    if (age_range.length == 0) {
      dispatch(getAgeRange({ token: localStorage.getItem("token") }));
    }
  }, [offer_types, passion_points, genders, age_range]);

  useEffect(() => {

    let mediaArray = [];
    mediaPlanner.forEach((value, index) => {
      JSON.parse(value)?.result.forEach((mediaval, index) => {
        mediaArray.push(mediaval);
      });
    });
    setMediaPlans(mediaArray);
  }, [mediaPlanner]);

  const handleCampaignAgeRangeChange = (data) => {
    let genderArray = [...mediaplanneragerange];
    if (data.target.checked == true) {
      if (genderArray.indexOf(data.target.value) === -1) {
        genderArray.push(data.target.value);
      }
    }
    if (data.target.checked == false) {
      let index = genderArray.indexOf(data.target.value);
      if (index != -1) {
        genderArray.splice(index, 1);
      }
    }
    setCampaignAgeRange(genderArray);
  };

  const handlePassionPointchange = (data) => {
    let selectedpasionpoint = data.map((i) => {
      return i.value.toUpperCase();
    });
    setSelected(data);
    setpassionPoint(selectedpasionpoint);
  };

  const getmediaPlanner = () => {
    let postdata = {
      gender: mediaplangender,
      inventory_type_id: product,
      passion_points: passionPoint,
      audiences: audienceRange,
      country: country.toUpperCase(),
    };
    if (audienceRange.length == 0) {
      toast.error("Please select Age range");
      return false;
    }
    if (mediaplangender.length == 0) {
      toast.error("Please select gender");
      return false;
    }
    setloadershown(true);
    dispatch(getmedia_Planner(postdata))
      .then((response) => {
        setloadershown(false);
        response?.payload?.data?.length != 0
          ? toast.success("records fetched successfully")
          : toast.error("Sorry no record found !!");
      })
      .catch((error) => toast.error(error));
  };

  const resetMediaplanner = () => {
    setCountry("pe");
    setCampaignGender("4");
    setProduct(1);
    setSelected([]);
    setMediaPlans([]);
    setAudienceRange([]);
    setSelectedAudience([]);
  };


  const handleAudience = (data) => {
    let selectAudience = data.map((i) => {
      return i.id.toString();
    });
    setSelectedAudience(data);
    setAudienceRange(selectAudience);
  };

  useEffect(() => {
    getdata();
  }, []);

  const exportData = () => {
    let date = new Date();
    var html = document.querySelector("table").outerHTML;
    export_table_to_csv(
      html,
      "report-" +
      date.getDate() +
      "." +
      (date.getMonth() + 1) +
      "." +
      date.getFullYear() +
      ".csv"
    );
  };
  const export_table_to_csv = (html, filename) => {
    var csv = [];
    var rows = document.querySelectorAll("table tr");
    for (var i = 0; i < rows.length; i++) {
      var row = [],
        cols = rows[i].querySelectorAll("td, th");
      for (var j = 0; j < cols.length; j++) row.push(cols[j].innerText);
      csv.push(row.join(","));
    }
    download_csv(csv.join("\n"), filename);
  };

  const download_csv = (csv, filename) => {
    var csvFile;
    var downloadLink;
    csvFile = new Blob([csv], {
      type: "text/csv",
    });
    downloadLink = document.createElement("a");
    downloadLink.download = filename;
    downloadLink.href = window.URL.createObjectURL(csvFile);
    downloadLink.style.display = "none";
    document.body.appendChild(downloadLink);
    downloadLink.click();
  };

  const getdata = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}countries/getCountries`,
      {
        method: "GET",
      }
    );
    const result = await response.json();
    setCountries(result.data);
  };

  return (
    <div className="content_outer">
      <div className="content">
        <div className="App">
          <div className="header_nav">
            <button onClick={() => navigation(-1)}>
              <AiOutlineLeft />
            </button>
            <div className="bredcrum">
              <p>Media Planner</p>
            </div>
          </div>

          <div className=" mb-3">
            <div className="negative_spacing">
              <Row>
                <Col lg={4}>
                  <Form.Group className=" form-group mb-3">
                    <label htmlFor="" className="center_label">
                      Country:{" "}
                    </label>
                    <Form.Select
                      onChange={(e) => {
                        setCountry(e.target.value);
                      }}
                      value={country}
                    >
                      {countries?.map((data, index) => (
                        <option value={data.ab} key={index}>
                          {" "}
                          {data.labelKey}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <Form.Group className=" form-group mb-3">
                    <label htmlFor="" className="center_label">
                      Gender:{" "}
                    </label>
                    <Form.Select
                      onChange={(e) => {
                        setCampaignGender(e.target.value);
                      }}
                      value={mediaplangender}
                    >
                      {genders?.map((data, index) => (
                        <option value={data.id} key={index}>
                          {" "}
                          {data.description}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4} className={"ms-auto"}>
                  <div className="form-group mb-3 justify-content-around">
                    <label htmlFor="">Age Range: </label>

                    <MultiSelect
                      className="mutiselect_cmn"
                      options={age_range.map((i) => ({
                        ...i,
                        label: i.age,
                        value: i.id.toString(),
                      }))}
                      value={selectedAudience}
                      onChange={handleAudience}
                      disableSearch={true}
                      hasSelectAll={false}
                      labelledBy="Select Passion Point"
                    />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col lg={4}>
                  <Form.Group className=" form-group mb-3">
                    <label htmlFor="" className="center_label">
                      Inventory Type:{" "}
                    </label>
                    <Form.Select
                      onChange={(e) => {
                        setProduct(e.target.value);
                      }}
                      value={product}
                    >
                      {products?.map((data, index) => (
                        <option value={data.productCode} key={index}>
                          {" "}
                          {data.description}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col lg={4}>
                  <div className="form-group mb-3">
                    <label htmlFor="" className="center_label">
                      {" "}
                      Passion Point:{" "}
                    </label>
                    <MultiSelect
                      className="mutiselect_cmn"
                      options={passion_points.map((i) => ({
                        ...i,
                        label: i.name,
                        value: i.name,
                      }))}
                      value={selected}
                      onChange={handlePassionPointchange}
                      disableSearch={true}
                      hasSelectAll={false}
                      labelledBy="Select Passion Point"
                    />
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="d-flex settings_btns">
                    <div>
                      <label style={{ opacity: "0", display: "block" }}>
                        btns
                      </label>
                      <button
                        className="cmn_btn cancel_cm_btn me-3"
                        onClick={resetMediaplanner}
                      >
                        Reset
                      </button>
                    </div>
                    <div>
                      <label style={{ opacity: "0", display: "block" }}>
                        btns
                      </label>

                      <button
                        className="cmn_btn"
                        onClick={() => {
                          getmediaPlanner();
                        }}
                      >
                        Go!
                      </button>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            {mediaplans.length > 0 && (
              <div className="mt-3">
                <h3 className="filter_title mb-0">Result:</h3>
                <div className="advert_tabel mt-0 export_table">
                  <button className="button expo_button" onClick={exportData}>
                    Export
                  </button>
                  <Table
                    responsive
                    striped
                    bordered
                    className={mediaplans?.length === 0 ? "no_data" : null}
                  >
                    <thead>
                      <tr>
                        <th>Title</th>
                        <th>App Id</th>
                      </tr>
                    </thead>
                    <tbody>
                      {mediaplans?.length === 0 ? (
                        <img
                          className="nodata"
                          src="/assets/images/notfound.svg"
                        />
                      ) : (
                        mediaplans?.map((media_p, index) => {
                          return (
                            <tr key={index} style={{ cursor: "pointer" }}>
                              <td className="text-start">{media_p?.title}</td>
                              <td className="text-start">{media_p?.appid}</td>
                            </tr>
                          );
                        })
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <Loader showLoader={loadershown} />
    </div>
  );
};
export default MediaPlanner;
