const billableOptions = [
  {
    id: 0,
    name: 'Not to bill',
  },
  {
    id: 1,
    name: 'Ready to bill',
  },
  {
    id: 2,
    name: 'Billed',
  }
]

export default billableOptions;