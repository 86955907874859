import React, { useMemo, useCallback } from 'react';

const NoneSelectedItems = React.memo((props) => {
  const noneSelectedButtons = useMemo(() => {
    return props.filteredReports(props.itemReports, props.searchTerm).map((item) => {
      const { isDisabled, message } = props.disabledItemHandle(item);
      return (
        <button
          className={`rowButton ${isDisabled ? 'disabled' : ''}`}
          key={item}
          onClick={() => props.handleItemClick(item)}
          disabled={isDisabled}
          title={isDisabled ? message : ''}
        >
          {item}
        </button>
      );
    });
  }, [props.itemReports, props.disabledItemHandle, props.handleItemClick]);

  return <>{noneSelectedButtons}</>;
});

export default NoneSelectedItems;