import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";
import { toast } from "react-hot-toast";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Multiselect from "multiselect-react-dropdown";
import Dropdown from "react-bootstrap/Dropdown";
// import { MultiSelect } from "react-multi-select-component";
import MultiRangeSlider from "multi-range-slider-react";
import Loader from "../Loader";
import Modal from "react-bootstrap/Modal";
import {
  clearState,
  CreativeSelector,
  getLineItem,
  listCreative,
  CreativeOnOff,
} from "../../app/features/Creative/CreativeSlice";
import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
  getLineitemFormats,
  getCountries,
  getGender,
  getAgeRange,
  getDevices,
  getPassionPoint,
  saveLineItem,
  clearsaveLineItem,
  updateLineItem,
  clearupdateLineItem,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  campaignManagerSelector,
  deal_currency,
  clearcampaignState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import {
  ExchangeRates,
  getHsDeal,
  deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";

const initialValues = {
  name: "",
  deal_id: "",
  campaign_id: "",
  inventory_type_id: "",
  format_id: "",
  date_from: "",
  date_to: "",
  inversion_budget: 0,
  inversion_sell_rate: 0,
  inversion_offer_type_id: "",
  inversion_goal: 0,
  target_country_id: [],
  target_gender_id: "",
  target_frequency: "",
  audiences: [],
  inversion_netMargin: 0,
  inversion_netRate: 0,
  inversion_netRate_usd: 0,
  inversion_sellRate_usd: 0,
  white_list: "",
  black_list: "",
  inversion_budget_usd: 0,
  lineitem_devices: [],
  lineitem_passion_points: [],
  token: localStorage.getItem("token"),
};

const NewLineItem = (props) => {
  const formikRef = useRef();
  const location = useLocation();
  const dispatch = useDispatch();
  const navigation = useNavigate();
  const [formats, SetFormats] = useState([]);
  const [preselectedDevices, setPreSelectedDevices] = useState([]);
  const [SelectedCountries, setSelectedCountries] = useState([]);
  const [preselectedpoints, setPreSelectedpoints] = useState([]);
  const [budget, setBudget] = useState(false);
  const [reload, setReload] = useState(false);
  const [reload_check, setReload_check] = useState(false);
  const [sum, setSum] = useState();
  const [equal, setEqual] = useState(false);
  const [inventoryTypeId, setInventoryTypeId] = useState();
  const [selectedFormatID, setSelectedFormatId] = useState();
  const [valueinventory, setValueinventory] = useState(false);
  const [sellRate, setSellRate] = useState(0);
  const [budgetRate, setBudgetRate] = useState(0);
  const [formulaData, setFormulaData] = useState([]);
  const [loadershown, setloadershown] = useState(false);
  const [modaleditShow, setModalEditShow] = useState(false)
  const [bonificated, setBonificated] = useState(false)
  const [file, setFile] = useState()
  const [dateWarning, setDateWarning] = useState(false)
  const [startDate, setStartDate] = useState()
  const [pendingBudget, setPendingBudget] = useState(0)
  const [confirm_delete, setConfirm_delete] = useState(false)
  const [deleted_lineitem_id, setDeleted_lineitem_id] = useState(null)
  const [formula_inversion_budget_usd, setFormula_inversion_budget_usd] =
    useState();
  const [formula_inversion_netrate, setFormula_inversion_netrate] = useState();
  const [formula_inversion_sellrate_usd, setFormula_inversion_sellrate_usd] =
    useState();
  const [formula_inversion_netrate_usd, setFormula_inversion_netrate_usd] =
    useState();
  const [formula_inversion_goal, setFormula_inversion_goal] = useState();
  const [offerTypeId, setOfferTypeId] = useState();
  const [minmaxslider, SetMinMaxSlider] = useState({
    minval: 0,
    maxval: 5,
  });
  const {
    kiteProducts,
    isCampaignSaved,
    isCampaignupdated,
    isFetching,
    isSuccess,
    isError,
  } = useSelector(campaignManagerSelector);
  const {
    list_creative,
    line_Item,
    isSuccesslistCreative,
    isErrorlistCreative,
  } = useSelector(CreativeSelector);
  // const [preselected, setSelected] = useState([]);
  // const [filteredCountries, setcountries] = useState([]);

  const {
    inventories,
    line_item_formats,
    offer_types,
    countries,
    genders,
    age_range,
    devices,
    passion_points,
    error,
    isSuccesssaveLineItem,
    isErrorsaveLineItem,
    inserted_lineitem_id,
    isSuccessupdateLineItem,
    isErrorupdateLineItem,
  } = useSelector(LineItemsSelector);
  const { dealCurrencies, hs_deal } = useSelector(deligateSelector);

  useEffect(() => {
    dispatch(
      getHsDeal({ token: localStorage.getItem("token"), dealId: props.dealid })
    );
  }, []);
  useEffect(() => {
    if (inventories.length == 0) {
      dispatch(getInventories({ token: localStorage.getItem("token") }));
    }
    if (line_item_formats.length == 0) {
      dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
    }
    if (line_item_formats.length > 0 && inventories.length > 0) {
      let filteredformats = [];
      if (line_Item) {
        filteredformats = line_item_formats.filter((element, index) => {
          return (
            element?.inventoryTypeId ==
            line_Item?.inventory_type_id
          );
        });
      } else {
        filteredformats = line_item_formats.filter((element, index) => {
          return element?.inventoryTypeId == inventories[0]?.id;
        });
      }
      SetFormats(filteredformats);
      if (line_Item) {
        setTimeout(() => {
          formikRef.current?.setFieldValue(
            "inventory_type_id",
            line_Item?.inventory_type_id,
            true
          );
          setSelectedFormatId(line_Item?.format_id);
          if (line_Item?.inventory_type_id == 4) {
            setValueinventory(true);
          }
        }, 500);
      }
    }
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }

    if (countries.length == 0) {
      dispatch(getCountries({ token: localStorage.getItem("token") }));
    }
    if (genders.length == 0) {
      dispatch(getGender({ token: localStorage.getItem("token") }));
    }
    // if (age_range.length == 0) {
    //   dispatch(getAgeRange({ token: localStorage.getItem("token") }));
    // } else {
    //   handleAudienceList();
    // }
    // if (devices.length == 0) {
    //   dispatch(getDevices({ token: localStorage.getItem("token") }));
    // } else {
    //   handleLineitemDevices();
    // }
    // if (passion_points.length == 0) {
    //   dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
    // } else {
    //   handlePassionPoints();
    // }
  }, []);

  useEffect(() => {
    dispatch(getAgeRange({ token: localStorage.getItem("token") }));
    dispatch(getDevices({ token: localStorage.getItem("token") }));
    dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
    dispatch(getOfferTypes({ token: localStorage.getItem("token") }))
    dispatch(getInventories({ token: localStorage.getItem("token") }))
    dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
  }, [])



  useEffect(() => {
    if (reload) {
      toast.success("Line Item Saved Successfully");
      setTimeout(() => {
        setReload(false);
        props.searchFilter();
        props.getcampain();
      }, 1000);
      // props.setModalShow(false)
    }
    if (isErrorsaveLineItem) {
      toast.error(error);
    }
    dispatch(clearcampaignState());
  }, [reload]);

  useEffect(() => {
    if (reload_check) {
      setTimeout(() => {
        toast.success("Line Item Updated Successfully");
        props.searchFilter();
        props.setCreativeModalShow(false);
      }, 1000);
    }
    if (isErrorupdateLineItem) {
      toast.error(error);
    }
    dispatch(clearupdateLineItem());
  }, [reload_check]);

  useEffect(() => {
    if (props.dealCurrency != "") {
      dispatch(ExchangeRates({ dealCurrency: props.dealCurrency }));
    }
  }, [props.dealCurrency]);

  const handleInventorychange = (e) => {
    if (e.target.value == 4) {
      setValueinventory(true);
    } else {
      setValueinventory(false);
    }
    setInventoryTypeId(e.target.value);
    formikRef.current?.setFieldValue("format_id", "", false);
    let value = e.target.value;
    let filteredformats = line_item_formats.filter((element, index) => {
      return element?.inventoryTypeId == value;
    });
    SetFormats(filteredformats);
    //  dispatch(countrystate({ country: value }))
  };




  useEffect(() => {
    if (line_Item && props?.lineItemId !== null && props?.getLineItemData) {
      setTimeout(() => {
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "name",
            line_Item?.name,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "deal_id",
            line_Item?.deal_id,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "campaign_id",
            line_Item?.campaign_id,
            true
          )
        }

        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "format_id",
            line_Item.format_id,
            true
          )
        }
        { props?.getLineItemData && setSelectedFormatId(line_Item?.format_id) }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "date_from",
            line_Item?.date_from,
            true
          )
        }
        { props?.getLineItemData && setStartDate(line_Item?.date_from) }
        // {
        //   props?.getLineItemData && formikRef.current?.setFieldValue(
        //     "date_to",
        //     line_Item?.date_to,
        //     true
        //   )
        // }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "inversion_budget",
            line_Item?.inversion_budget,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "inversion_sell_rate",
            line_Item?.inversion_sell_rate,
            true
          )
        }
        // formikRef.current?.setFieldValue("inversion_offer_type_id", line_Item?.inversion_offer_type_id, true);
        // formikRef.current?.setFieldValue("inversion_goal", line_Item?.inversion_goal, true);
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "target_country_id",
            line_Item?.target_country_id,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "target_gender_id",
            line_Item?.target_gender_id,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "target_frequency",
            line_Item?.target_frequency,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "inventory_type_id",
            line_Item?.inventory_type_id,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "inversion_netMargin",
            line_Item?.inversion_netMargin,
            true
          )
        }

        handleLineitemDevices();
        handleAudienceList();
        handlePassionPoints();
        // formikRef.current?.setFieldValue("target_age_to", line_Item?.target_age_to, true);
        // formikRef.current?.setFieldValue("inversion_netRate", line_Item?.inversion_netRate, true);
        // formikRef.current?.setFieldValue("inversion_netRate_usd", line_Item?.inversion_netRate_usd, true);
        // formikRef.current?.setFieldValue("inversion_sellRate_usd", line_Item?.inversion_sellRate_usd, true);
        // formikRef.current?.setFieldValue("inversion_budget_usd", line_Item?.inversion_budget_usd, true);
        // formikRef.current?.setFieldValue(
        //   "white_list",
        //   line_Item?.white_list,
        //   true
        // );
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "black_list",
            line_Item?.black_list,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "inversion_offer_type_id",
            line_Item?.inversion_offer_type_id,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "lineitem_devices",
            line_Item?.deviceList?.deviceId,
            true
          )
        }
        {
          props?.getLineItemData && formikRef.current?.setFieldValue(
            "lineitem_passion_points",
            line_Item?.passionPointList?.passionpointId,
            true
          )
        }
        {
          props?.getLineItemData && setFormula_inversion_budget_usd(
            line_Item?.inversion_budget_usd
          )
        }
        { props?.getLineItemData && setFormula_inversion_netrate(line_Item?.inversion_netRate) }
        {
          props?.getLineItemData && setFormula_inversion_netrate_usd(
            line_Item?.inversion_netRate_usd
          )
        }
        {
          props?.getLineItemData && setFormula_inversion_sellrate_usd(
            line_Item?.inversion_sellRate_usd
          )
        }
        { props?.getLineItemData && setFormula_inversion_goal(line_Item?.inversion_goal) }
        setOfferTypeId(line_Item?.inversion_offer_type_id)
        { props?.getLineItemData && setBudgetRate(line_Item?.inversion_budget) }
        { props?.getLineItemData && setSellRate(line_Item?.inversion_sell_rate) }
        { props?.getLineItemData && setBonificated(line_Item?.is_bonificated) }

        if (line_Item?.countryList) {
          let country_selected = [];
          line_Item?.countryList.map((value) => {
            let country_list = countries.findIndex(
              (item) => item.id === value?.countryId
            );
            if (country_list != -1) {
              country_selected.push({
                id: countries[country_list].id,
                name: countries[country_list].labelKey,
              });
            }
          });
          setSelectedCountries(country_selected);
          handle_multiselect_country(country_selected, "target_country_id");
        }
      }, 1000);
    }
  }, [line_Item]);


  useEffect(() => {
    if (!line_Item && props?.getLineItemData) {
      formikRef.current?.setFieldValue("deal_id", props?.dealid, true);
      formikRef.current?.setFieldValue("campaign_id", props?.campaignId, true);
    }
  }, [props.dealId]);

  useEffect(() => {
    if (line_Item) {
      setSum(props.total_Budget - props.sum_Budget);
    } else {
      let sum = props?.totalBudget - props.sumBudget;
      setSum(sum);
    }

    if (sum >= 0) {
      setBudget(true);
    } else {
      setBudget(false);
    }
  }, []);
  const handleLineitemDevices = () => {
    if (line_Item?.deviceList) {
      let devicesval = [];
      line_Item?.deviceList.map((value) => {
        let Deviceindex = devices.findIndex(
          (item) => item.id === value?.deviceId
        );
        if (Deviceindex != -1) {
          devicesval.push(devices[Deviceindex]);
        }
      });
      setPreSelectedDevices(devicesval);
      handle_multiselect_devices(devicesval, "lineitem_devices");
    }
  };

  const handlePassionPoints = () => {
    if (line_Item?.passionPointList) {
      let passionpnt = [];
      line_Item?.passionPointList.map((value) => {
        let passionPointIndex = passion_points.findIndex(
          (item) => item.id === value?.passionpointId
        );
        if (passionPointIndex != -1) {
          passionpnt.push(passion_points[passionPointIndex]);
        }
      });
      setPreSelectedpoints(passionpnt);
      handle_multiselect_passionpoints(passionpnt);
    }
  };

  // const handleCountryList = () => {

  // }

  const handleAudienceList = () => {
    if (line_Item?.audienceList) {
      let targetAuiance = [];
      line_Item.audienceList.forEach((value, index) => {
        targetAuiance.push(value?.audienceId.toString());
      });
      formikRef.current?.setFieldValue("audiences", targetAuiance, true);
    }
  };

  const handle_check_sum = (e) => {
    if (sum + line_Item?.inversion_budget < e.target.value) {
      if (!bonificated) {
        setEqual(true);
      }
    } else {
      setEqual(false);
    }
  };

  const calculatebudget = (e, field) => {
    let budget = e.target.value;
    if (budget != "") {
      compute("USD", budget, field);
    }
    let sellrate = formikRef.current?.values.inversion_sell_rate;
    calculateGoal(budget, sellrate);
  };

  const compute = (output_currency1, budget, field) => {
    if (Object.keys(dealCurrencies).length > 0) {
      const new_rate = dealCurrencies[output_currency1];
      // rate.innerText = `1 ${input_currency1} = ${new_rate} ${output_currency1}`
      let output_amount = (budget * new_rate).toFixed(2);
      formikRef.current?.setFieldValue(field, output_amount, true);
      return output_amount;
    }
  };

  const netRateCalculation = async (sellrate, budget, goal) => {
    return new Promise(async (resolve, reject) => {
      if (
        sellrate != 0 &&
        hs_deal?.kickBack != "" &&
        budget != 0 &&
        goal != 0
      ) {
        let kick_back = parseInt(hs_deal?.kickBack.replace(/\%/g, ""));
        let net_rate =
          sellrate * (1 - kick_back / 100) - sellrate * (budget / goal);
        compute("USD", net_rate, "inversion_netRate_usd");
        formikRef.current?.setFieldValue(
          "inversion_netRate",
          net_rate?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
          false
        );
        return resolve(true);
      } else {
        return reject("Insufficent parameters");
      }
    });
  };
  const calculatenetrate = (e) => {
    let selRate = e.target.value;
    let budget = formikRef.current?.values.inversion_budget;
    let goal = formikRef.current?.values.inversion_goal?.toLocaleString(
      undefined,
      { minimumFractionDigits: 0 }
    );
    if (selRate != 0 && hs_deal?.kickBack != "") {
      netRateCalculation(selRate, budget, goal).then((status) => {
        calculateGoal(budget, selRate);
      });
    }
  };

  const calculateGoal = (budget, sellrate, offer = null) => {
    let selected_offer;
    if (!offer) {
      selected_offer = formikRef.current?.values.inversion_offer_type_id;
    } else {
      selected_offer = offer;
    }
    if (selected_offer != "" && budget != "" && sellrate != "") {
      let offer_value = offer_types?.find(
        (o) => o.id === parseInt(selected_offer)
      );
      if (offer_value) {
        let offer_name = offer_value?.descripcion;
        let goal_value = 0;
        switch (offer_name) {
          case "CPV":
            formikRef.current?.setFieldValue(
              "inversion_netRate_usd",
              parseInt(
                formikRef.current?.values.inversion_netRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            formikRef.current?.setFieldValue(
              "inversion_sellRate_usd",
              parseInt(
                formikRef.current?.values.inversion_sellRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            goal_value = parseInt(budget) / parseInt(sellrate);
            break;
          case "CPM":
            formikRef.current?.setFieldValue(
              "inversion_netRate_usd",
              parseInt(
                formikRef.current?.values.inversion_netRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            formikRef.current?.setFieldValue(
              "inversion_sellRate_usd",
              parseInt(
                formikRef.current?.values.inversion_sellRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            goal_value = (parseInt(budget) / parseInt(sellrate)) * 1000;
            break;
          case "CPCV":
            formikRef.current?.setFieldValue(
              "inversion_netRate_usd",
              parseInt(
                formikRef.current?.values.inversion_netRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            formikRef.current?.setFieldValue(
              "inversion_sellRate_usd",
              parseInt(
                formikRef.current?.values.inversion_sellRate_usd
              )?.toLocaleString(undefined, { minimumFractionDigits: 0 }),
              true
            );
            goal_value = parseInt(budget) / parseInt(sellrate);
            break;
        }
        formikRef.current?.setFieldValue("inversion_goal", goal_value, false);
        netRateCalculation(sellrate, budget, goal_value)?.toLocaleString();
      }
    }
  };
  const handleCallback = (start, end) => {
    formikRef.current?.setFieldValue(
      "date_from",
      start.format("YYYY-MM-DD"),
      true
    );
    formikRef.current?.setFieldValue("date_to", end.format("YYYY-MM-DD"), true);
  };

  const handleCallback1 = (start, end) => {
    formikRef.current?.setFieldValue(
      "date_from",
      startDate,
      true
    );
    formikRef.current?.setFieldValue("date_to", end.format("YYYY-MM-DD"), true);
  };

  const handleFormSubmit = async (values) => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      name: values.name,
      deal_id: props?.dealid,
      campaign_id: props?.campaignId,
      inventory_type_id: values?.inventory_type_id * 1,
      format_id: values?.format_id * 1,
      date_from: values?.date_from,
      date_to: values?.date_to,
      inversion_budget: values?.inversion_budget,
      inversion_sell_rate: values?.inversion_sell_rate,
      inversion_offer_type_id: values?.inversion_offer_type_id * 1,
      inversion_goal: values?.inversion_goal * 1,
      target_country_id: values?.target_country_id,
      inversion_netMargin: Object.values(permission.Permissions).includes('demoData') ? 0.50 : values?.inversion_netMargin,
      target_gender_id: values?.target_gender_id * 1,
      // target_frequency: values?.target_frequency,
      black_list: values?.black_list,
      inversion_budget_usd: formula_inversion_budget_usd,
      inversion_netRate_usd: formula_inversion_netrate_usd,
      inversion_sellRate_usd: formula_inversion_sellrate_usd,
      inversion_netRate: formula_inversion_netrate,
      lineitem_devices: values?.lineitem_devices
        ? values?.lineitem_devices
        : [],
      lineitem_passion_points: values?.lineitem_passion_points
        ? values?.lineitem_passion_points
        : [],
      audiences: values?.audiences ? values?.audiences : [],
      is_bonificated: bonificated
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-lineitem`,
      requestOptions
    );
    const result = await response.json()
    if (result) {
      props.setEditLineItemModal(false);
      props.onRefresh();
      props.getcampain();
      props?.getData()
      setloadershown(false);
      // saveCsv(result?.data, values?.white_list,)
    } else {
      toast.error("Some internal error occured");
      setloadershown(false);
    }
  };

  const handleUpdate = async (values) => {
    if (values?.date_from === "" || values?.date_to === "") {
      setDateWarning(true)
    } else {
      setloadershown(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      let dateFrom = new Date(values?.date_from);
			dateFrom.setHours(0,0,0,0);

		  let dateTo = new Date(values?.date_to);
		  dateTo.setHours(0,0,0,0);

      var raw = JSON.stringify({
        name: values.name,
        deal_id: values?.deal_id,
        campaign_id: props?.campaignId,
        inventory_type_id: values?.inventory_type_id * 1,
        format_id: values?.format_id * 1,
        date_from: dateFrom,
        date_to: dateTo,
        inversion_budget: budgetRate,
        inversion_sell_rate: sellRate,
        inversion_offer_type_id: offerTypeId,
        inversion_goal: formula_inversion_goal,
        target_country_id: values?.target_country_id
          ? values?.target_country_id
          : [],
        inversion_netMargin: Object.values(permission.Permissions).includes('demoData') ? 0.50 : values?.inversion_netMargin,
        target_gender_id: values?.target_gender_id * 1,
        // target_frequency: values?.target_frequency,
        black_list: values?.black_list,
        inversion_budget_usd: formula_inversion_budget_usd,
        inversion_netRate_usd: formula_inversion_netrate_usd,
        inversion_sellRate_usd: formula_inversion_sellrate_usd,
        inversion_netRate: formula_inversion_netrate,
        lineitem_devices: values?.lineitem_devices
          ? values?.lineitem_devices
          : [],
        lineitem_passion_points: values?.lineitem_passion_points
          ? values?.lineitem_passion_points
          : [],
        audiences: values?.audiences ? values?.audiences : [],
        is_bonificated: bonificated
      });

      var requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const response = await fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-lineitem/${props?.lineItemId}`,
        requestOptions
      );
      const result = await response.json();
      if (result) {
        setloadershown(false);
        toast.success("Lineitem updated successfully");
        props.setEditLineItemModal(false);
        props.searchFilter();
        props.onRefresh();
        props.getcampain();
        props?.getData()
        setloadershown(false);
        // saveCsv(result?.data, values?.white_list);
      }
      else {
        toast.error("Some Internal Error Occurred !!");
        setloadershown(false);
      }
    }


  };

  const handle_multiselect_passionpoints = (e) => {
    let selectedids = e.map((val) => {
      return val.id;
    });
    formikRef.current?.setFieldValue(
      "lineitem_passion_points",
      selectedids,
      true
    );
  };

  const handle_multiselect_country = (e) => {
    let selectedids = e.map((val) => {
      return val.id;
    });
    formikRef.current?.setFieldValue("target_country_id", selectedids, true);
  };



  const handle_multiselect_devices = (e) => {
    let selectedids = e.map((val) => {
      return val.id;
    });
    formikRef.current?.setFieldValue("lineitem_devices", selectedids, true);
  };

  const handle_multiremove = (e, type) => {
    let selectedids = e.map((val) => {
      return val.id;
    });
    formikRef.current?.setFieldValue(type, selectedids, false);
  };

  const createCrative = () => {
    props.setModalShow(false);
    props.setCreativeModalShow(true);
  };
  const handleInput = (e) => {
    SetMinMaxSlider({
      ...minmaxslider,
      minval: e.minValue,
      maxval: e.maxValue,
    });
  };

  const handleDelete = async (id) => {
    if (props?.lineItemStat === "ACTIVE") {
      setModalEditShow(true)
    } else {
      setConfirm_delete(false)
      setloadershown(true)
      const response = await fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/delete-lineItem/${id}`,
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + localStorage.getItem("token"),
          },
        }
      );
      const result = await response.json();
      if (result) {
        props.setEditLineItemModal(false);
        props.getData();
        props.getcampain();
        props.setModalShow(false);
        props.searchFilter();
        setloadershown(false)
        toast.success("Deleted successfully");
      } else {
        toast.error("something went wrong");
      }
    }
  };

  function validate_Field(value) {
    let error;
    if (!value) {
      error = "this field is Required";
    }
    return error;
  }

  const handleSetOffer = (e) => {
    setOfferTypeId(e.target.value);
  };

  const getFormaulaData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      deal_id: props?.dealid,
      inversion_sell_rate: sellRate ? sellRate * 1 : "",
      inversion_budget: budgetRate ? budgetRate * 1 : "",
      inversion_offer_type_id: offerTypeId ? offerTypeId * 1 : "",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/get_Lineitem_formulas`,
      requestOptions
    );
    const result = await response.json();
    setFormulaData(result);
    setFormula_inversion_budget_usd(result?.data?.inversion_budget_usd);
    setFormula_inversion_netrate(result?.data?.inversion_netrate);
    setFormula_inversion_netrate_usd(result?.data?.inversion_netRate_usd);
    setFormula_inversion_sellrate_usd(result?.data?.inversion_sellRate_usd);
    setFormula_inversion_goal(result?.data?.inversion_goal);
  };

  // const saveCsv = (id, values) => {
  //   if (file !== undefined) {
  //     var myHeaders = new Headers();
  //     myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

  //     var formdata = new FormData();
  //     formdata.append("id", id);
  //     formdata.append("file", file ? file : line_Item?.white_list);

  //     var requestOptions = {
  //       method: 'POST',
  //       headers: myHeaders,
  //       body: formdata,
  //       redirect: 'follow'
  //     };

  //     fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/Whitelist_csv`, requestOptions)
  //       .then(response => response.json())
  //       // .then(result => toast.su)
  //       .catch(error => toast.error("Internal Error Occurred !!"));
  //   }

  // }

  const permission = JSON.parse(localStorage.getItem('userdata'));

  useEffect(() => {
    if(props?.lineItemId){
      getLine_Item();
    }
  }, [props?.lineItemId, props?.getLineItemData]);


  const getLine_Item = () => {
    setloadershown(true)
    if (props?.lineItemId !== null && props?.getLineItemData) {
      dispatch(
        getLineItem({
          lineitem_id: props?.lineItemId,
          token: localStorage.getItem("token"),
        })
      ).then((res) => {setloadershown(false)})
    }
  };

  useEffect(() => {
    setPendingBudget(props?.total__budget - props?.used__budget)
  }, [props?.used__budget])


  //this function is used to delete the lineitem it is a pop up that confirm do you want to delete or not nitin 1/09/2023
  const handlePopUpDelete = (id) => {
    //creating a modal to confirmation
    setConfirm_delete(true)
    //when this state becomes true it open a confirmation modal
    //setting up the id that is going to delete
    setDeleted_lineitem_id(id)
  }

  const clear_country_fields = () => {
   const val =  document.getElementsByClassName("singleChip")
  }

  return (
    // <div className="content_outer">
    <div className="content pt-0">
      <Loader showLoader={loadershown} />

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          inventory_type_id: Yup.string().required("Select Inventory Type"),
          format_id: Yup.string().required("Select Format"),
          date_from: Yup.string().required("Select Date Range"),
          date_to: Yup.string().required("Select Date Range"),
          inversion_budget: Yup.number()
            .min(0, "Too Short!")
            .required("Enter Budget"),
          inversion_sell_rate: Yup.number()
            .min(0, "Too Short!")
            .required("Enter Sell Rate"),
          target_country_id: Yup.array()
            .required("Select Country")
            .min(1, "Select atleast one country"),
          target_gender_id: Yup.string().required("Select Gender"),
          lineitem_devices: Yup.array()
            .required("Select Device")
            .min(1, "Select atleast one device"),
        })}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form>
            <div className="">
              <label className="lable_title">
                Details
              </label>
              <Row className="mb-3">
                <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="lignitemname">
                      Line item Name*
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      placeholder="Item name"
                    />
                    {errors.name && touched.name ? (
                      <span className="text-danger">{errors.name}</span>
                    ) : null}
                  </div>
                </Col>
                {/* <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="inventorytype">
                      Inventory Type
                    </label>
                    <select
                      name="inventory_type_id"
                      className="form-select"
                      value={values?.inventory_type_id}
                      onChange={(e) => {
                        handleChange(e);
                        handleInventorychange(e);
                        clear_country_fields()
                      }}
                    >
                      <option value="">--Select--</option>
                      {inventories?.map((inv, index) => {
                        return (
                          <option value={inv?.id} key={index}>
                            {inv?.description}
                          </option>
                        );
                      })}
                    </select>
                    {errors.inventory_type_id && touched.inventory_type_id ? (
                      <span className="text-danger">
                        {errors.inventory_type_id}
                      </span>
                    ) : null}
                  </div>
                </Col> */}
                {/* <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="format">
                      Format
                    </label>
                    <select
                      name="format_id"
                      className="form-select"
                      value={selectedFormatID}
                      onChange={(e) => {
                        handleChange(e);
                        setSelectedFormatId(e.target.value);
                      }}
                    >
                      <option value="">--Select--</option>
                      {formats?.map((format, for_index) => {
                        return (
                          <option value={format?.id} key={for_index}>
                            {format?.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.format_id && touched.format_id ? (
                      <span className="text-danger">{errors.format_id}</span>
                    ) : null}
                  </div>
                </Col> */}
                <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="ofertype">
                      Offer Type
                    </label>
                    <select
                      name="inversion_offer_type_id"
                      className="form-control"
                      value={offerTypeId}
                      // disabled={props?.offerHide}
                      onChange={(e) => {
                        handleChange(e);
                        calculateGoal(
                          values?.inversion_budget,
                          values.inversion_sell_rate,
                          e.target.value
                        );
                        handleSetOffer(e);
                      }}
                    >
                      <option value="">--Select--</option>
                      {selectedFormatID == 12
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 5) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 13
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 6 || offr.id == 5) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 3 ||
                        selectedFormatID == 4 ||
                        selectedFormatID == 5 ||
                        selectedFormatID == 6 ||
                        selectedFormatID == 7 ||
                        selectedFormatID == 8 ||
                        selectedFormatID == 9 ||
                        selectedFormatID == 13
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 5 || offr.id == 4) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 2 ||
                        selectedFormatID == 1 ||
                        selectedFormatID == 10 ||
                        selectedFormatID == 11 ||
                        selectedFormatID == 13
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 5 || offr.id == 7) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 14 ||
                        selectedFormatID == 15 ||
                        selectedFormatID == 13
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 5) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 16
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 8) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}
                      {selectedFormatID == 18 || selectedFormatID == 13
                        ? offer_types?.map((offr, offr_index) => {
                          if (offr.id == 5) {
                            return (
                              <option value={offr?.id} key={offr_index}>
                                {offr?.descripcion}
                              </option>
                            );
                          }
                        })
                        : null}

                    </select>
                    {errors.inversion_offer_type_id &&
                      touched.inversion_offer_type_id ? (
                      <span className="text-danger">
                        {errors.inversion_offer_type_id}
                      </span>
                    ) : null}
                  </div>
                </Col>
              </Row>
              {/* row 2 */}
              <label className="lable_title">
                Pricing and budget
              </label>
              <Row className="mb-3">
                {props?.campaign_detail && (
                  <Col lg={3}>
                    <div className="form-group mb-3 select_country_container cmn_modal_field">
                      <label className="lbl_position" htmlFor="startdate">
                        Start Date - End Date
                      </label>
                      <DateRangePicker
                        initialSettings={{
                          startDate:
                            new Date(props?.campaign_detail?.date_from) <
                              new Date()
                              ? new Date()
                              : new Date(props?.campaign_detail?.date_from),
                          endDate: new Date(props?.campaign_detail?.date_to),
                          minDate:
                            new Date(props?.campaign_detail?.date_from) <
                              new Date()
                              ? new Date()
                              : new Date(props?.campaign_detail?.date_from),
                          maxDate: new Date(props?.campaign_detail?.date_to),
                          locale: {
                            format: "DD/MM/YYYY", // Specify the desired date format here
                          },
                        }}
                        onCallback={handleCallback}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker>
                      {errors.date_to && touched.date_to ? (
                        <span className="text-danger">{errors.date_to}</span>
                      ) : null}
                    </div>
                  </Col>
                )}
                {/* //this is for active date */}
                {props?.editStatus === "ACTIVE" && <Col lg={3}>
                  <div className="form-group mb-3 select_country_container cmn_modal_field">
                    <label className="lbl_position" htmlFor="startdate">
                      End Date
                    </label>
                    <DateRangePicker
                      initialSettings={{
                        endDate: new Date(props?.endDate),
                        minDate:
                          new Date(props?.startDate) < new Date()
                            ? new Date()
                            : new Date(props?.startDate),
                        maxDate: new Date(props?.endDate),
                        singleDatePicker: true,
                        locale: {
                          format: "DD/MM/YYYY", // Specify the desired date format here
                        },
                      }}
                      onCallback={handleCallback1}
                    >
                      <input type="text" className="form-control" />
                    </DateRangePicker>
                    {errors.date_to && touched.date_to ? (
                      <span className="text-danger">{errors.date_to}</span>
                    ) : null}
                  </div>


                </Col>}

                {/* this is for editing */}
                {line_Item && props?.lineItemId && props?.status_id !== "ACTIVE" &&
                  <Col lg={3}>
                    <div className="form-group mb-3 select_country_container cmn_modal_field">
                      <label className="lbl_position" htmlFor="startdate">
                        Start Date - End Date
                      </label>

                      <DateRangePicker
                        initialSettings={{
                          startDate:
                            new Date(props?.startDate) < new Date()
                              ? new Date()
                              : new Date(props?.startDate),
                          endDate: new Date(props?.endDate),
                          minDate:
                            new Date(props?.startDate) < new Date()
                              ? new Date()
                              : new Date(props?.startDate),
                          maxDate: new Date(props?.endDate),

                          locale: {
                            format: "DD/MM/YYYY", // Specify the desired date format here
                          },
                        }}
                        onCallback={handleCallback}
                      >
                        <input type="text" className="form-control" />
                      </DateRangePicker>

                      {dateWarning ? (
                        <span className="text-danger">please select Date Range</span>
                      ) : null}
                    </div>


                    <div className="d-flex">
                      <p className="me-4">
                        Actual start Date:
                        <Moment format="DD/MM/YYYY">
                          {props?.startDate}
                        </Moment>
                      </p>
                      <p>
                        Actual End Date:
                        <Moment format="DD/MM/YYYY">
                          {props?.endDate}
                        </Moment>
                      </p>
                    </div>
                  </Col>}

                <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="enddate">
                      Budget ({props.dealCurrency}) ({bonificated ? null : !equal ? (
                        <p className="inline">
                          Must be between{" "}
                          {bonificated ? null : !props?.lineItemId ? 0 : null}{" "}
                          {bonificated ? null : !props?.lineItemId ? "and" : null}{" "}
                          {bonificated ? null : !props?.lineItemId ? pendingBudget?.toFixed(2) : null}
                          {bonificated ? null : props?.lineItemId ? 0 : null}{" "}
                          {bonificated ? null : props?.lineItemId ? "and" : null}{" "}
                          {bonificated ? null : props?.lineItemId ? (line_Item?.inversion_budget + sum)?.toFixed(2) : null}
                          {/* Budget must be between{" "}
                          {bonificated ? null : !line_Item ? 0 : null}{" "}
                          {bonificated ? null : !line_Item ? "and" : null}{" "}
                          {bonificated ? null : !line_Item ? (line_Item?.inversion_budget + sum)?.toFixed(2) : 0}{" "}
                          {bonificated ? null : line_Item ? "and" : null}{" "}
                          {bonificated ? null : line_Item
                            ? (pendingBudget)?.toFixed(2)
                            : null} */}
                        </p>


                      ) : (
                        <p style={{ color: "red" }} className="inline">
                          You can not add more then{" "}
                          {line_Item
                            ? sum + line_Item?.inversion_budget
                            : sum}
                        </p>
                      )})
                    </label>
                    <input
                      type="number"
                      min={bonificated ? null : line_Item?.inversion_budget ? 0 : 1}
                      max={bonificated ? null :
                        !props?.lineItemId ? pendingBudget : line_Item?.inversion_budget + sum
                      }
                      style={equal ? { border: "1px solid red", borderRadius: "6px" } : null}
                      name="inversion_budget"
                      value={
                        values?.inversion_budget == 0
                          ? null
                          : values?.inversion_budget
                      }
                      onBlur={getFormaulaData}
                      onChange={(e) => {
                        handleChange(e);
                        calculatebudget(e, "inversion_budget_usd");
                        handle_check_sum(e);
                        setBudgetRate(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Budget"
                    />
                    {/* {bonificated ? null : !equal ? (
                      <p>
                        Budget must be between{" "}
                        {bonificated ? null : !line_Item ? 0 : null}{" "}
                        {bonificated ? null : !line_Item ? "and" : null}{" "}
                        {bonificated ? null : !line_Item ? sum : 0}{" "}
                        {bonificated ? null : line_Item ? "and" : null}{" "}
                        {bonificated ? null : line_Item
                          ? line_Item?.inversion_budget + sum
                          : null}
                      </p>
                    ) : (
                      <p style={{ color: "red" }}>
                        You can not add more then{" "}
                        {line_Item
                          ? sum + line_Item?.inversion_budget
                          : sum}
                      </p>
                    )} */}
                  </div>
                  {errors.inversion_budget && touched.inversion_budget ? (
                    <span className="text-danger">
                      {errors.inversion_budget}
                    </span>
                  ) : null}
                </Col>

                <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="sellrate">
                      Sell Rate ({props.dealCurrency})
                    </label>
                    <input
                      type="number"
                      name="inversion_sell_rate"
                      value={
                        values.inversion_sell_rate
                          ? values.inversion_sell_rate
                          : null
                      }
                      onBlur={getFormaulaData}
                      onChange={(e) => {
                        handleChange(e);
                        calculatebudget(e, "inversion_sellRate_usd");
                        calculatenetrate(e);
                        setSellRate(e.target.value);
                      }}
                      className="form-control"
                      placeholder="Sell Rate"
                    />
                    {errors.inversion_sell_rate &&
                      touched.inversion_sell_rate ? (
                      <span className="text-danger">
                        {errors.inversion_sell_rate}
                      </span>
                    ) : null}
                  </div>
                </Col>
                {Object.values(permission.Permissions).includes('demoData') && <Col lg={3}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="netmargin">
                      Net Margin{" "}
                    </label>
                    <select
                      name="inversion_netMargin"
                      className="form-control"
                      value={Object.values(permission.Permissions).includes('demoData') ? "50" : values?.inversion_netMargin}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      disabled={Object.values(permission.Permissions).includes('demoData')}
                    >
                      <option value="">--Select--</option>
                      <option value="40">40%</option>
                      <option value="45">45%</option>
                      <option value="50">50%</option>
                      <option value="55">55%</option>
                      <option value="60">60%</option>
                      <option value="65">65%</option>
                      <option value="70">70%</option>
                      <option value="75">75%</option>
                      <option value="80">80%</option>
                      <option value="85">85%</option>
                      <option value="90">90%</option>
                    </select>
                  </div>
                </Col>}
              </Row>
              <label className="lable_title">
                Targeting
              </label>
              <Row className="mb-3">
                {!valueinventory ? <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="country">
                      Country
                    </label>
                    <Multiselect
                      options={countries.map((i) => ({
                        ...i,
                        name: i.labelKey,
                      }))}
                      selectedValues={SelectedCountries}
                      onSelect={(e) =>
                        handle_multiselect_country(e, "target_country_id")
                      }
                      onRemove={(e) =>
                        handle_multiremove(e, "target_country_id")
                      }
                      displayValue="name"
                    />
                    {errors.target_country_id && touched.target_country_id ? (
                      <span className="text-danger">
                        {errors.target_country_id}
                      </span>
                    ) : null}
                  </div>
                </Col>
                  :
                  <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                      <label className="lbl_position" htmlFor="country">
                        Country
                      </label>
                      <Multiselect
                        options={countries.map((i) => ({
                          ...i,
                          name: i.labelKey,
                        }))}
                        selectedValues={SelectedCountries}
                        onSelect={(e) =>
                          handle_multiselect_country(e, "target_country_id")
                        }
                        singleSelect={true}
                        onRemove={(e) =>
                          handle_multiremove(e, "target_country_id")
                        }
                        displayValue="name"
                      />
                      {errors.target_country_id && touched.target_country_id ? (
                        <span className="text-danger">
                          {errors.target_country_id}
                        </span>
                      ) : null}
                    </div>
                  </Col>
                }
                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="devices">
                      Devices
                    </label>
                    <Multiselect
                      options={devices}
                      selectedValues={props?.getLineItemData ? preselectedDevices : []}
                      onSelect={(e) =>
                        handle_multiselect_devices(e, "lineitem_devices")
                      }
                      onRemove={(e) =>
                        handle_multiremove(e, "lineitem_devices")
                      }
                      displayValue="name"
                    />
                    {errors.lineitem_devices && touched.lineitem_devices ? (
                      <span className="text-danger">
                        {errors.lineitem_devices}
                      </span>
                    ) : null}
                  </div>
                </Col>
              </Row>
              <label className="lable_title">
                Audience
              </label>
              <Row>
                <Col lg={4}>
                  <div className="form-group select_gender mb-3">
                    <label className="mb-2" htmlFor="country">
                      Gender
                    </label>
                    <div role="group" aria-labelledby="my-radio-group">
                      {genders?.map((gend) => {
                        return (
                          <label>
                            <span> {gend?.description} </span>
                            <Field
                              type="radio"
                              name="target_gender_id"
                              value={gend?.id.toString()}
                              onChange={handleChange}
                              checked={
                                values.target_gender_id == gend?.id
                                  ? true
                                  : false
                              }
                            />
                          </label>
                        );
                      })}
                    </div>
                    {errors.target_gender_id && touched.target_gender_id ? (
                      <span className="text-danger">
                        {errors.target_gender_id}
                      </span>
                    ) : null}
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="form-group select_gender  mb-3">
                    <label htmlFor="customRange2" className="form-label">
                      {" "}
                      Age
                    </label>

                    <div role="group" aria-labelledby="checkbox-group">
                      {age_range?.map((age, age_index) => {
                        if (age?.id === 1) return null; // Skip rendering this element

                        return (
                          <label className="form-label mx-2" key={age_index}>
                            <Field
                              type="checkbox"
                              name="audiences"
                              value={age?.id}
                              onChange={handleChange}
                              checked={values.audiences.includes(age?.id.toString())}
                            />
                            <span className="ms-1"> {age?.age}</span>
                          </label>
                        );
                      })}
                    </div>

                  </div>
                </Col>

                {/* <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="frequency">
                      Frequency
                    </label>
                    <input
                      type="number"
                      name="target_frequency"
                      onChange={handleChange}
                      value={values.target_frequency}
                      className="form-control"
                      placeholder="Frequency"
                    />
                    {errors.target_frequency && touched.target_frequency ? (
                      <span className="text-danger">
                        {errors.target_frequency}
                      </span>
                    ) : null}
                  </div>
                </Col> */}
                <Col lg={4}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="Passion Points">
                      Passion Points
                    </label>
                    <Multiselect
                      selectedValues={props?.getLineItemData ? preselectedpoints : []}
                      options={passion_points}
                      onSelect={(e) =>
                        handle_multiselect_passionpoints(
                          e,
                          "lineitem_passion_points"
                        )
                      }
                      onRemove={(e) =>
                        handle_multiremove(e, "lineitem_passion_points")
                      }
                      displayValue="name"
                    />
                  </div>
                </Col>
              </Row>
            </div>

            <div className="">
              <Row>

                <Col lg={12}>
                  <div className="rates_list mt-3 mb-1">
                    {!Object.values(permission.Permissions).includes('demoData') && <div className="form-group mb-2">
                      <div style={{ display: "flex" }}>
                        <label className="mb-2" htmlFor="netrate">
                          Net Rate ({props.dealCurrency}):{" "}
                        </label>
                        <span>
                          {" "}
                          {offerTypeId == 6 || offerTypeId == 7
                            ? formula_inversion_netrate
                              ? formula_inversion_netrate?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                              : 0
                            : formula_inversion_netrate
                              ? formula_inversion_netrate?.toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                              })
                              : 0}
                        </span>
                      </div>
                    </div>}

                    <div className="form-group mb-2">
                      <label className=" " htmlFor="netrate">
                        Budget USD:{" "}
                      </label>
                      <span>
                        {" "}
                        {formula_inversion_budget_usd
                          ? formula_inversion_budget_usd?.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                          })
                          : 0}
                      </span>
                    </div>

                    <div className="form-group mb-2">
                      <label className=" " htmlFor="netrate">
                        Sell Rate USD:{" "}
                      </label>
                      <span>
                        {" "}
                        {offerTypeId == 6 || offerTypeId == 7
                          ? formula_inversion_sellrate_usd
                            ? formula_inversion_sellrate_usd?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                            })
                            : 0
                          : formula_inversion_sellrate_usd
                            ? formula_inversion_sellrate_usd?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                            : 0}
                      </span>
                    </div>

                    {!Object.values(permission.Permissions).includes('demoData') && <div className="form-group  mb-2">
                      <label className=" " htmlFor="netrate">
                        Net Rate USD:{" "}
                      </label>
                      <span>
                        {" "}
                        {offerTypeId == 6 || offerTypeId == 7
                          ? formula_inversion_netrate_usd
                            ? formula_inversion_netrate_usd?.toLocaleString(undefined, {
                              minimumFractionDigits: 4,
                            })
                            : 0
                          : formula_inversion_netrate_usd
                            ? formula_inversion_netrate_usd?.toLocaleString(undefined, {
                              minimumFractionDigits: 2,
                            })
                            : 0}
                      </span>
                    </div>}

                    <div className="form-group  mb-2">
                      <label className=" " htmlFor="goal">
                        Goal:{" "}
                      </label>
                      <span>
                        {" "}
                        {formula_inversion_goal
                          ? formula_inversion_goal?.toLocaleString()
                          : 0}
                      </span>
                    </div>
                  </div>
                </Col>

                {!Object.values(permission.Permissions).includes('demoData') && <Col lg={6}>
                  <div className="form-check mt-2">
                    <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" onClick={() => setBonificated(!bonificated)} checked={bonificated} />
                    <label className="form-check-label" htmlFor="flexCheckDefault">
                      Is bonified
                    </label>
                  </div>
                </Col>}
                {/* {valueinventory && (
                  <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                      <label className="lbl_position " htmlFor="Whitelist">
                        White List
                      </label>
                      <input
                        type="file"
                        name="white_list"
                        onChange={(e) => setFile(e.target.files[0])}
                        className="form-control"
                        placeholder="Creative"
                        validate={validate_Field}
                      />
                      {errors.white_list && touched.white_list ? (
                        <span className="text-danger">{errors.white_list}</span>
                      ) : null}
                    </div>
                    {line_Item ? <p className="File_input">{line_Item?.white_list}</p> : null}
                  </Col>
                )} */}
                {/* <Col lg={6}>
                                    <div className='form-group mb-3'>
                                        <label className='mb-2' htmlFor="blacklist">Black List</label>
                                        <input type="text" name="black_list" onChange={handleChange} className='form-control' />
                                    </div>
                                </Col> */}
              </Row>
            </div>
            <div className="d-flex justify-content-end mt-4">
              {props?.lineItemId && Object.values(permission.Permissions).includes('editcampaign') ||
                props?.lineItemId && props?.editStatus !== "ACTIVE" ? (
                <button
                  type="button"
                  className={props?.editStatus === "ACTIVE" ? "d-none" : "cmn_btn delete_cmn_btn"}
                  onClick={() => {
                    handlePopUpDelete(line_Item.id) // handleDelete(line_Item.id);
                  }}
                >
                  Delete
                </button>
              ) : null}
              <button
                type="button"
                className="me-4 ms-auto cancel_cm_btn"
                onClick={() => {props.setEditLineItemModal(false);formikRef.current.resetForm();}}
              >
                Cancel
              </button>
              {/* {line_Item &&
                                <button type='button' onClick={createCrative} className='cmn_btn me-2 ms-3 cancel_cm_btn'>
                                    Creative
                                </button>
                            } */}

              {props?.lineItemId ? (
                <button
                  className="cmn_btn ms-2 "
                  disabled={equal}
                  type="submit"
                  onClick={() => {
                    handleUpdate(values);
                  }}
                >
                  Update
                </button>
              ) : (
                <button className="cmn_btn ms-2 " type="submit">
                  Save
                </button>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Modal
        show={modaleditShow}
        onHide={() => setModalEditShow(false)}
        size="sm"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-center pt-3">You cannot delete an active Line Item</h5>
          <div className="text-center p-3">
            <button className="cmn_btn delete_cmn_btn" onClick={() => setModalEditShow(false)}>Ok</button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={confirm_delete}
        onHide={() => setConfirm_delete(false)}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Are you sure !!
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-4 pt-0">
          <div className="d-flex justify-content-end mt-4">
            <button className="me-4 cancel_cm_btn" onClick={() => setConfirm_delete(false)}>cancel</button>
            <button className="cmn_btn ms-2 " onClick={() => handleDelete(deleted_lineitem_id)}>Confirm</button>
          </div>
        </Modal.Body>
      </Modal>
    </div>
    // {/* </div> */}
  );
};

export default NewLineItem;