import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const optimize_tab_saas_report_data = createAsyncThunk("optimize_tab_saas_report_data", async ({ lineitemIDS }, thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "application/json");
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var raw = JSON.stringify({
            "lineitemId": lineitemIDS
        });

        var requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: raw,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}reportsaas/get_optimizetab`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const optimizeTabSaasReportData = createSlice({
    name: "optimizeTabSaasReportData",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_optimize_tab_saas_report_data_state: (state) => {
            state.data = []
            state.isSuccess = false
            state.isError = false
            state.loading = false
            state.error = false
            return state
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(optimize_tab_saas_report_data.pending, (state) => {
                state.loading = true
            })
            .addCase(optimize_tab_saas_report_data.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(optimize_tab_saas_report_data.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export const { clear_optimize_tab_saas_report_data_state } = optimizeTabSaasReportData.actions
export default optimizeTabSaasReportData.reducer