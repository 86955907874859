import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Moment from "react-moment";
import Loader from '../Loader';


const CampaignListSaas = () => {
	const params = useParams();
	const navigation = useNavigate();
	const [list, setList] = useState({});
	const [list_item, setList_item] = useState([]);
	const [loadershown, setloadershown] = useState(false);


	useEffect(() => {
		getdata();
	}, []);

	const getdata = async () => {
		setloadershown(true)
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			}
		);
		const result = await response.json();
		setList_item(result);
		let item = JSON.parse(result.data.lineItemList.kiteList.items);
		setList(item);
		setloadershown(false)
	};

	return (
		<>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="content">
					<div className="App">
						<div className="header_nav">
							<button onClick={() => navigation(-1)}>
								<AiOutlineLeft />{" "}
							</button>
							<div className="bredcrum">
								<p>Line Item Details</p>
							</div>
						</div>
						<div className="mediaplan_details mb-3">
							<div className="advert_tabel">
								<ul>
									<li>
										<strong>Inventory Type:</strong>:
										<p>{list_item?.data?.inventoryNavigation?.description}</p>
									</li>
									<li>
										<strong>Offer type</strong>:
										<p>{list_item?.data?.offerTypeNavigation?.descripcion}</p>
									</li>
									<li>
										<strong>Name</strong>:<p>{list_item?.data?.name} </p>
									</li>
									<li>
										<strong>Start Date</strong>:
										<p>
											{" "}
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_from}
											</Moment>
										</p>
									</li>
									<li>
										<strong>End Date</strong>:
										<p>
											<Moment format="DD/MM/YYYY">
												{list_item?.data?.date_to}
											</Moment>
										</p>
									</li>
									<li>
										<strong>Format</strong>:
										<p> {list_item?.data?.formatNavigation?.name}</p>
									</li>
									<li>
										<strong>Goal</strong>:
										<p> {list_item?.data?.inversion_goal?.toLocaleString(
											undefined,
											{ minimumFractionDigits: 0 })}</p>
									</li>
									<li>
										<strong>Status</strong>:
										<p>
											{" "}
											{list_item?.data?.lineItemStatusNavigation?.description}
										</p>
									</li>
									{/* <li className="edit_campaign_details">
                                        <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 20 20" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"></path>
                                        </svg>
                                    </li> */}
								</ul>
							</div>
						</div>
						<div className="new_compaign">
							<h2 className="mt-3 cmn_title "> Line Items List</h2>
							<div className="">
								<div className="campiagn_outer border-0 p-0">
									<div className="advert_tabel">
										<div className="table-responsive">
											<table
												className={
													!list?.result
														? "table table-striped mt-0 no_data"
														: "table table-striped mt-0 "
												}
											>
												<thead>
													<tr>
														<th className="text-center">Title</th>
														<th className="text-center">App Id</th>
													</tr>
												</thead>
												<tbody>
													{!list?.result ? (
														<div>
															{" "}
															<img
																className="nodata"
																src="/assets/images/notfound.svg"
															/>{" "}
														</div>
													) : (
														list?.result?.map((data) => {
															return (
																<>
																	<tr>
																		<td className="text-start">
																			{data?.title}
																		</td>
																		<td className="text-start">
																			{data?.appid}
																		</td>
																	</tr>
																</>
															);
														})
													)}
												</tbody>
											</table>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CampaignListSaas;
