import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const resetPassword = createAsyncThunk("resetPassword", async ({ token }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "token": token
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}auth/passwordrecovery/validatetoken`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const reset_password = createSlice({
    name: "reset_password",
    initialState: {
        data: [],
        isError: false,
        isSuccess: false,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder.addCase(resetPassword.pending, (state) => {
            state.loading = true
        })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.isSuccess = true
                state.isError = false
                state.data = action.payload
                state.loading = false
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.isError = true
                state.loading = false
                state.error = action.payload
                state.isSuccess = false
            })
    }
})

export default reset_password.reducer