import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const changeDelegate = createAsyncThunk(
  'delegate/changeDelegate',
  async ({ token, owner, email, dealId }, thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/delegate/${owner}/${dealId}`, { email: email }, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getHsDeal = createAsyncThunk(
  'delegate/getHsDeal',
  async ({ token, dealId }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getbydealid/${dealId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const ExchangeRates = createAsyncThunk(
  'delegate/ExchangeRates',
  async ({ dealCurrency }, thunkAPI) => {
    try {
      return await axios.get(`https://api.exchangerate-api.com/v4/latest/${dealCurrency}`, {
        headers: {
          'Content-Type': 'application/json'
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)


export const DeligateSlice = createSlice({
  name: 'deligate',
  initialState: {
    isFetchingChangeDeligate: false,
    isSuccessChangeDeligate: false,
    isErrorChangeDeligate: false,
    isFetchingExchangeRates: false,
    isSuccessExchangeRates: false,
    isErrorExchangeRates: false,
    isFetchingGetHSdeal: false,
    isSuccessGetHSdeal: false,
    isErrorGetHSdeal: false,
    errorChangeDeligate: '',
    dealCurrencies: {},
    hs_deal: {}
  },
  reducers: {
    clearDeligateState: (state) => {
      state.isFetchingChangeDeligate = false;
      state.isSuccessChangeDeligate = false;
      state.isErrorChangeDeligate = false;
      state.isFetchingExchangeRates = false;
      state.isSuccessExchangeRates = false;
      state.isErrorExchangeRates = false;
      state.errorChangeDeligate = "";
      return state;
    }
  },
  extraReducers: {
    [changeDelegate.fulfilled]: (state, { payload }) => {
      state.isFetchingChangeDeligate = false;
      state.isSuccessChangeDeligate = true;
      state.isErrorChangeDeligate = false;
    },
    [changeDelegate.rejected]: (state, { payload }) => {
      state.isFetchingChangeDeligate = false;
      state.isErrorChangeDeligate = true;
      state.errorChangeDeligate = payload;
    },
    [changeDelegate.pending]: (state) => {
      state.isFetchingChangeDeligate = true;
    },
    [ExchangeRates.fulfilled]: (state, { payload }) => {
      state.isFetchingExchangeRates = false;
      state.isSuccessExchangeRates = true;
      state.isErrorExchangeRates = false;
      state.dealCurrencies = payload.rates;
    },
    [ExchangeRates.rejected]: (state, { payload }) => {
      state.isFetchingExchangeRates = false;
      state.isErrorExchangeRates = true;
      state.errorChangeDeligate = payload;
    },
    [ExchangeRates.pending]: (state) => {
      state.isFetchingExchangeRates = true;
    },
    [getHsDeal.fulfilled]: (state, { payload }) => {
      state.isFetchingGetHSdeal = false;
      state.isSuccessGetHSdeal = true;
      state.isErrorGetHSdeal = false;
      state.hs_deal = payload.data;
    },
    [getHsDeal.rejected]: (state, { payload }) => {
      state.isFetchingGetHSdeal = false;
      state.isErrorGetHSdeal = true;
      state.errorChangeDeligate = payload;
    },
    [getHsDeal.pending]: (state) => {
      state.isFetchingGetHSdeal = true;
    },
  }
})

export const { clearDeligateState } = DeligateSlice.actions;
export const deligateSelector = (state) => state.deligate;
