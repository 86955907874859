import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_selected_advertiser_clients = createAsyncThunk("get_selected_advertiser_clients", async ({ user_id }, thunkAPI) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/getsaveClients?userId=${user_id}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const getSelectedAdvertiserClients = createSlice({
    name: "getSelectedAdvertiserClients",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_selected_advertiser_state: (state) => {
            state.data = []
            state.isError = false
            state.isSuccess = false
            state.loading = false
            state.error = null
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_selected_advertiser_clients.pending, (state) => {
                state.loading = true
            })
            .addCase(get_selected_advertiser_clients.fulfilled, (state, action) => {
                state.data = action.payload
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(get_selected_advertiser_clients.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})

export const { clear_selected_advertiser_state } = getSelectedAdvertiserClients.actions
export default getSelectedAdvertiserClients.reducer