import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import { AiOutlineLeft } from "react-icons/ai";
import { MdCalendarMonth } from "react-icons/md";
import { useLocation } from "react-router-dom";
import Moment from "react-moment";

const CampaignCreatives = () => {
	const params = useParams();
	const navigate = useNavigate();
	const location = useLocation();
	const [creativeList, setCreativeList] = useState([]);
	const [loadershown, setloadershown] = useState(false);
	const [link, setLink] = useState("");

	const {
		campaignId,
		budget,
		start_date,
		end_date,
		product,
		currency,
		budget_used,
		status,
		remaining_budget,
		advertiser,
	} = location.state ? location.state : location;

	useEffect(() => {
		getdata();
	}, []);

	const getdata = async () => {
		setloadershown(true);
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaign/${campaignId}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
					"Content-Type": "application/json",
				},
			}
		);
		const result = await response.json();
		if (result?.data?.creatives !== null) {
			setCreativeList(result?.data?.creatives);
			setloadershown(false);
		} else {
			setCreativeList(null);
			setloadershown(false);
		}

		setLink(result?.data?.mediaLink);
	};

	const handleZip = (zipfile) => {
		navigate("/viewzip", { state: { zipfile } });
	};

	const handleTags = (tags) => {
		navigate("/viewtag", { state: { tags } });
	};

	return (
		<>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="cmn_header_top">
					<img src="/assets/images/chevron_left.svg" onClick={() => navigate(-1)} />
					<h3>Campaign All Creatives</h3>
				</div>
				<div className="content">
					<div className="App">
					</div>
					<div className="advert_tabel mb-4">
						<div className="media_plan_info_list">
							<ul className="header__media">
								<li>
									<strong>ID</strong> <p>123123123</p>
								</li>

								<li>
									<MdCalendarMonth size={20} />
									<strong>Start Date</strong>:
									<p>
										{" "}
										<Moment format="DD/MM/YYYY">{start_date}</Moment>
									</p>
								</li>
								<li>
									<strong>End Date</strong>:
									<p>
										{" "}
										<Moment format="DD/MM/YYYY">{end_date}</Moment>
									</p>
								</li>

							</ul>
							<ul>
								<li>
									<strong>Advertiser</strong>:<p> {advertiser}</p>
								</li>
								<li>
									<strong>Currency</strong>:<p> {currency}</p>
								</li>
								<li>
									<strong>Status</strong>:<p>{status}</p>
								</li>
								<li>
									<strong>Product</strong>:<p> {product}</p>
								</li>
							</ul>
						</div>
					</div>
					{creativeList != null ? (
						<div className="row">
							{/* this loop is for video */}
							{creativeList?.map((data) => {
								if (data?.creativeType?.id === 1) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													{/* <video className="w-100" controls autoplay>
														<source
															src={link + "/" + data?.object}
															type="video/mp4"
														/>
													</video> */}
{/* before we are using video tag here but i changed it do iframe because video tag is not working as we want but this is only work for a valid url if the url is not valid so it is not present the video and same thing happen on video tag
nitin - 24-08-2023
*/}
													<iframe
														src={
															link + "/" + data?.object
														}
														className="w-100"
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
														allowfullscreen
													></iframe>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p>
														<strong>Click Url:</strong>
														<a href={link + "/" + data?.object} target="blank">
															Link
														</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for youtube link  */}
							{creativeList?.map((data) => {
								if (data?.creativeType?.id === 3) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<iframe
														src={
															data?.object.includes("watch?v=")
																? data?.object?.replace("watch?v=", "embed/")
																: data?.object
														}
														className="w-100"
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
														allowfullscreen
													></iframe>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p>
														<strong>Click Url:</strong>
														<a href={data?.object} target="blank">
															Link
														</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for wallpaper */}

							{creativeList?.map((data) => {
								if (data?.creativeType?.id === 4) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src={link + "/" + data?.object}
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p>
														<strong>Click Url:</strong>
														<a href={link + "/" + data?.object} target="blank">
															Link
														</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for tags */}

							{creativeList.map((data) => {
								if (data?.creativeType?.id === 5) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p
														onClick={() => {
															handleTags(data?.object);
														}}
													>
														<strong>Click Url:</strong>View
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for zip file */}

							{creativeList.map((data) => {
								if (data?.creativeType?.id === 2) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0crO16mI5mGJwYUh1Al9Faxf765RbmQZahg&usqp=CAU"
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p
														onClick={() => {
															handleZip(link + "/" + data?.object);
														}}
													>
														<strong>Click Url:</strong>
														<a>Link</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}
						</div>
					) : (
						<b className="text-center">No Data Found</b>
					)}

					<div></div>
				</div>
			</div>

			{/* </div> */}
		</>
	);
};

export default CampaignCreatives;
