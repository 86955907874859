import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const get_user_subgroup = createAsyncThunk("get_user_group", async (thunkAPI) => {
    try {
        var myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        var requestOptions = {
            method: 'GET',
            headers: myHeaders,
            redirect: 'follow'
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/getsubgroupname`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const getUserSubGroup = createSlice({
    name: "getUserSubGroup",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    extraReducers: (builder) => {
        builder
            .addCase(get_user_subgroup.pending, (state) => {
                state.loading = true
            })
            .addCase(get_user_subgroup.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(get_user_subgroup.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }

})

export default getUserSubGroup.reducer