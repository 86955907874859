import React, {
	useContext,
	useState,
	useRef,
	useLayoutEffect,
	useEffect,
} from "react";
import JSZip from "jszip";
import { join, parse } from "path-browserify";
import { useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";

const ViewZip = ({ val, design, loadCanvas, deleteCard }) => {
	const myref = useRef();
	const location = useLocation();
	const [indexHtmlUrl, setIndexHtmlUrl] = useState("");

	const { zipfile } = location.state ? location.state : location;
	// let newfile = zipfile.replace('http://creatives.kitemedia.digital/', '')
	// let newfile_zip = newfile.replace('.zip', '');
	// let newzip = newfile_zip.slice(23)
	// myref.current="";
	useEffect(() => {
		fetch(`https://cors-anywhere.herokuapp.com/${zipfile}`)
		  .then((response) => response.arrayBuffer())
		  .then((buffer) => {
			// Use JSZip to extract the files
			const zip = new JSZip();
			return zip.loadAsync(buffer);
		  })
		  .then((zip) => {
			// Access the HTML file in the ZIP archive
			const htmlFile = zip.file("index.html");
			if (htmlFile) {
			  htmlFile.async("string").then((html) => {
				const iframe = document.createElement("iframe");
				iframe.srcdoc = html;
				document.body.appendChild(iframe);
				setIndexHtmlUrl(htmlFile)

			  });
			} else {
			  console.error("index.html not found in the ZIP archive.");
			}
		  })
		  .catch((error) => {
			console.error("Error loading ZIP archive:", error);
		  });
	  }, [zipfile]);
	  

	return (
		<iframe
			id="my-iframe"
			src={indexHtmlUrl}
			width="100%"
			height="500"
			title="Index HTML"
		/>
	);
};

export { ViewZip };
