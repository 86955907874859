import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "./style.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { updateBreadCrumb } from "../../app/features/Common/CommonSlice";
import { campaignManagerSelector } from "../../app/features/CampaignManager/CampaignManagerSlice";
import { useNavigate } from "react-router-dom";
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-hot-toast";
import Moment from "react-moment";
import Loader from "../Loader";
import { downloadCsv } from '../../utils/index';


const ReportRCFinance = () => {
  const dispatch = useDispatch();
  const [reports, setReports] = useState([]);
  const [dateDisable, setDateDisable] = useState(true);
  const [loadershown, setloadershown] = useState(false);
  const [selectedCampaigns, setSelectedCampaigns] = useState([]);
  const [selectedCampaignsId, setSelectedCampaignsId] = useState([]);
  const [formatData, setFormatData] = useState([]);
  const [selectedFormats, setSelectedFormats] = useState([]);
  const [daterange, setrange] = useState("");
  const [campaignData, setCampaignData] = useState([]);
  const [advertiseData, setAdvertiseData] = useState([]);
  const [startend, setStartend] = useState({ start: "", end: "" });
  const [campaign, setCampaign] = useState("");
  const [format, setFormat] = useState("");
  const [advertiser, setAdvertiser] = useState([]);
  const [advertiserId, setAdvertiserId] = useState([]);
  const [period, setPeriod] = useState("");
  const [dimension, setDimension] = useState([]);
  const [filterDate, setFilterDate] = useState("");
  const [impressions, setImpressions] = useState(true);
  const [views, setViews] = useState(false);
  const [clicks, setClicks] = useState(false);
  const [engagements, setEngagements] = useState(false);
  const [customDate, setCustomDate] = useState(false);
  const [cpcv, setcpcv] = useState(false);
  const [ctr, setctr] = useState(false);
  const [egRate, setegRate] = useState(false);
  const [showResults, setShowResults] = useState(true);
  const [overviewData, setOverviewData] = useState([]);
  const [resultData, setResultData] = useState([]);
  const [impressionView, setImpressionView] = useState(false);
  const [viewsView, setViewsView] = useState(false);
  const [clicksView, setClicksView] = useState(false);
  const [EngagmentView, setEngagmentView] = useState(false);
  const [VtrView, setVtrView] = useState(false);
  const [CtrView, setCtrView] = useState(false);
  const [ErView, setErView] = useState(false);
  const [periodError, setPeriodError] = useState(false);
  const [modifyingCampaign, setModifyingCampaign] = useState([]);
  const [modifyingFormat, setModifyingFormat] = useState([])
  const [dataPeriod, setDataPeriod] = useState(" ")
  const [allAdvertiserCampaigns, setAllAdvertiserCampaigns] = useState(false)
  const [spent, setSpent] = useState(false)
  const [spentCurrency, setSpentCurrency] = useState(false)
  const [goals, setGolas] = useState(false)
  const [showGoal, setShowGoal] = useState(true)
  const [billable_data, setBillable_data] = useState(null)
  const [asc, setAsc] = useState(false)
  const [hsDeal, setHsDeal] = useState(false)
  const [overSpentUsd, setOverSpentUsd] = useState(false)
  const [overCostUsd, setOverCostUsd] = useState(false)
  const [totalSpentUsd, setTotalSpentUsd] = useState(false)
  const [inversionBudgetUsd, setInversionBudgetUsd] = useState(false)
  const [over_under, setOver_Under] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [startEndDate, setStartEndDate] = useState(false)
  const [dimension_Options, setDimension_Options] = useState()

  const [adv, setAdv] = useState([]);

  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);
  const navigation = useNavigate();
  const removeEmptyParams = (url) => {
    return encodeURI(url.replace(/[^=&]+=(&|$)/g, "").replace(/&$/, ""));
  };

  const impressionViewRef = useRef(impressionView)
  const viewsViewRef = useRef(viewsView)
  const clicksViewRef = useRef(clicksView)
  const EngagmentViewRef = useRef(EngagmentView)
  const VtrViewRef = useRef(VtrView)
  const CtrViewRef = useRef(CtrView)
  const ErViewRef = useRef(ErView)
  const spentRef = useRef(spent)
  const spentCurrencyRef = useRef(spentCurrency)
  const hsDealRef = useRef(hsDeal)
  const overSpentUsdRef = useRef(overSpentUsd)
  const overCostUsdRef = useRef(overCostUsd)
  const totalSpentUsdRef = useRef(totalSpentUsd)
  const inversionBudgetUsdRef = useRef(inversionBudgetUsd)
  const over_underRef = useRef(over_under)
  const dateRef = useRef(startEndDate)
  const goalsRef = useRef(goals)

  const handleSelectAll = () => {
    if (!selectAll) {
      impressionViewRef.current.checked = true
      setImpressionView(true)

      viewsViewRef.current.checked = true
      setViewsView(true)

      clicksViewRef.current.checked = true
      setClicksView(true)

      EngagmentViewRef.current.checked = true
      setEngagmentView(true)

      VtrViewRef.current.checked = true
      setVtrView(true)

      CtrViewRef.current.checked = true
      setCtrView(true)

      ErViewRef.current.checked = true
      setErView(true)

      spentRef.current.checked = true
      setSpent(true)

      spentCurrencyRef.current.checked = true
      setSpentCurrency(true)

      hsDealRef.current.checked = true
      setHsDeal(true)

      overSpentUsdRef.current.checked = true
      setOverSpentUsd(true)

      overCostUsdRef.current.checked = true
      setOverCostUsd(true)

      totalSpentUsdRef.current.checked = true
      setTotalSpentUsd(true)

      inversionBudgetUsdRef.current.checked = true
      setInversionBudgetUsd(true)

      over_underRef.current.checked = true
      setOver_Under(true)

      dateRef.current.checked = true
      setStartEndDate(true)

      goalsRef.current.checked = true
      dimension?.includes("LineItem") && setGolas(true)
    }
    else {
      impressionViewRef.current.checked = false
      setImpressionView(false)

      viewsViewRef.current.checked = false
      setViewsView(false)

      clicksViewRef.current.checked = false
      setClicksView(false)

      EngagmentViewRef.current.checked = false
      setEngagmentView(false)

      VtrViewRef.current.checked = false
      setVtrView(false)

      CtrViewRef.current.checked = false
      setCtrView(false)

      ErViewRef.current.checked = false
      setErView(false)

      spentRef.current.checked = false
      setSpent(false)

      spentCurrencyRef.current.checked = false
      setSpentCurrency(false)

      hsDealRef.current.checked = false
      setHsDeal(false)

      over_underRef.current.checked = false
      setOver_Under(false)

      overSpentUsdRef.current.checked = false
      setOverSpentUsd(false)

      overCostUsdRef.current.checked = false
      setOverCostUsd(false)

      totalSpentUsdRef.current.checked = false
      setTotalSpentUsd(false)

      inversionBudgetUsdRef.current.checked = false
      setInversionBudgetUsd(false)

      dateRef.current.checked = false
      setStartEndDate(false)

      goalsRef.current.checked = false
      setGolas(false)
    }
  }

  useEffect(() => {
    let dimensionOptions;

    if (dimension.includes("LineItem")) {
      dimensionOptions = [
        { name: "Advertiser", value: "advertiser" },
        { name: "Campaign", value: "campaign" },
        { name: "Format", value: "format" },
        { name: "Creative", value: "creative" },
        { name: "Date", value: "date" },
        { name: "Line Item", value: "LineItem" },
        { name: "Offer Type", value: "OfferType" },
        { name: "Inventory Type", value: "inventory_type" }
      ];
      setDimension_Options(dimensionOptions)
    } else {
      dimensionOptions = [
        { name: "Advertiser", value: "advertiser" },
        { name: "Campaign", value: "campaign" },
        { name: "Format", value: "format" },
        { name: "Creative", value: "creative" },
        { name: "Date", value: "date" },
        { name: "Line Item", value: "LineItem" }
      ];
      setDimension_Options(dimensionOptions)
    }
  }, [dimension])

  const handleApply = (event, picker) => {
    picker.element.val(
      picker.startDate.format("DD/MM/YYYY") +
      " - " +
      picker.endDate.format("DD/MM/YYYY")
    );
    setStartend({
      start: picker.startDate.format("YYYY-MM-DD"),
      end: picker.endDate.format("YYYY-MM-DD"),
    });
  };
  const handleCancel = (event, picker) => {
    picker.element.val("");
  };

  function refreshPage() {
    window.location.reload();
  }

  let searchquery = `creation_date=${daterange}&startDate=${startend.start
    }&endDate=${startend.end
    }&campaign=${campaign}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
    }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""}&engagements=${engagements ? engagements : ""
    }&cpcv=${cpcv ? cpcv : ""}&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""
    }`;

  const handleSelectChange = (event) => {
    let value = event.target.value;
    setDataPeriod(value)
    if (value === "custom") {
      setCustomDate(true);
    } else {
      setCustomDate(false);
    }
    value == "custom" ? setDateDisable(false) : setDateDisable(true);
    if (value != "custom") {
      searchquery = removeEmptyParams(
        `creation_date=&startDate=&endDate=&campaign=${campaign}&format=${format}&period=${value}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
        }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
        }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
        }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
      );
      // fetchReports();
      setPeriod(value);
      setStartend({ ...startend, start: "", end: "" });
    } else {
      setPeriod("");
    }
  };
  const datechange = (events) => {
    let dimnesionValue = events.target.value;
    dimnesionValue == "date" ? setShowResults(true) : setShowResults(false);
    if (dimnesionValue == "date") {
      setFilterDate("");
    }
  };
  const findpercetage = (array, element) => {
    let sum = array
      .map((item) => item.impressions)
      .reduce((prev, curr) => prev + curr, 0);
    let percentage =
      sum == 0
        ? 0
        : (
          (array
            .map((item) => item[element])
            .reduce((prev, curr) => prev + curr, 0) /
            sum) *
          100
        ).toFixed(2);
    return percentage;
  };
  const usedimension = (e) => {
    const selecteddimensions = e.map((val) => val.value).filter(Boolean);
    setDimension(selecteddimensions);
  };
  const handle_multiremove_dimension = (e) => {
    let removeDimension = e.map((el) => el.name);
    setDimension(removeDimension);
  };

  const usedatefilter = (newdatefilter) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaign}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${newdatefilter}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setFilterDate(newdatefilter);
  };
  const handleCampainChange = (campaignName) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaignName}&format=${format}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setCampaign(campaignName);
  };

  const handleFormatChange = (formatName) => {
    searchquery = removeEmptyParams(
      `creation_date=${daterange}&startDate=${startend.start}&endDate=${startend.end
      }&campaign=${campaign}&format=${formatName}&period=${period}&advertiser=${advertiser}&dimension=${dimension}&filterDate=${filterDate}&impressions=${impressions ? impressions : ""
      }&views=${views ? views : ""}&clicks=${clicks ? clicks : ""
      }&engagements=${engagements ? engagements : ""}&cpcv=${cpcv ? cpcv : ""
      }&ctr=${ctr ? ctr : ""}&egRate=${egRate ? egRate : ""}`
    );

    setFormat(formatName);
  };

  const handleAdvertiserChange = (advertiserName) => {
    let arr1 = [];
    arr1.push(advertiserName);

    setAdvertiser(arr1);
  };

  const exportCsv = () => {
    const csvData = [
      [
        hsDeal ? "ID HS DEal" : undefined,
        resultData?.data?.advertiser ? 'Advertiser' : undefined,
        resultData?.data?.date ? 'Date' : undefined,
        resultData?.data?.campaign ? 'Campaign' : undefined,
        resultData?.data?.campaign ? 'CampaignID' : undefined,
        resultData?.data?.format ? 'Format' : undefined,
        resultData?.data?.country ? 'Country' : undefined,
        resultData?.data?.creative ? 'Creatives' : undefined,
        resultData?.data?.OfferType ? 'Offer Type' : undefined,
        resultData?.data?.inventory_type ? 'Inventory Type' : undefined,
        resultData?.data?.LineItem ? 'Line Item' : undefined,
        resultData?.data?.LineItem ? 'Line ItemID' : undefined,
        startEndDate ? 'start Date' : undefined,
        startEndDate ? 'End Date' : undefined,
        goals && resultData?.data && !resultData?.data?.country && !resultData?.data?.creative && !resultData?.data?.date && (resultData?.data?.advertiser || resultData?.data?.campaign || resultData?.data?.LineItem) ? 'Goal' : undefined,
        impressionView ? 'Impressions' : undefined,
        viewsView ? 'Views' : undefined,
        clicksView ? 'Clicks' : undefined,
        EngagmentView ? 'Engagements' : undefined,
        VtrView ? 'VTR' : undefined,
        CtrView ? 'CTR' : undefined,
        ErView ? 'ER' : undefined,
        spent ? 'Spent (USD)' : undefined,
        spentCurrency ? 'Currency Code' : undefined, // ver {spentCurrency && <th>Spent ({resultData?.data?.overview[0]?.currencyCode})</th>}
        spentCurrency ? 'Spent Currency Deal' : undefined,
        overSpentUsd ? 'Over Spent Usd' : undefined,
        overCostUsd ? 'Over Cost Usd' : undefined,
        totalSpentUsd ? 'Total Spent Usd' : undefined,
        inversionBudgetUsd ? 'Inversion Budget Usd' : undefined,
        over_under ? 'Over Under' : undefined
      ].filter((item) => item !== undefined),
      ...resultData?.data?.overview?.map((item) => ([
        hsDeal ? item.dealid : undefined,
        resultData?.data?.advertiser ? item.advertiser : undefined,
        resultData?.data?.date ? item.date : undefined,
        resultData?.data?.campaign ? item.campaign : undefined,
        resultData?.data?.campaign ? item.campaignID : undefined,
        resultData?.data?.format ? item.format : undefined,
        resultData?.data?.country ? item.country : undefined,
        resultData?.data?.creative ? item.creative : undefined,
        resultData?.data?.OfferType ? item.offer : undefined,
        resultData?.data?.inventory_type ? item.inventioryType : undefined,
        resultData?.data?.LineItem ? item.lineitem : undefined,
        resultData?.data?.LineItem ? item.lineitemID : undefined,
        startEndDate ? item.startDate : undefined,
        startEndDate ? item.endDate : undefined,
        goals && resultData?.data && !resultData?.data?.country && !resultData?.data?.creative && !resultData?.data?.date && (resultData?.data?.advertiser || resultData?.data?.campaign || resultData?.data?.LineItem) ? (item?.goal ? item?.goal.toFixed(0) : null) : undefined,
        impressionView ? String(item.impressions).replaceAll(',', '') : undefined,
        viewsView ? String(item.views).replaceAll(',', '') : undefined,
        clicksView ? String(item.clicks).replaceAll(',', '') : undefined,
        EngagmentView ? String(item.engagements).replaceAll(',', '') : undefined,
        VtrView ? (item.views != 0 ? item?.vtr.toFixed(2) : '-') + '%' : undefined,
        CtrView ? (item?.ctr ? item?.ctr.toFixed(2) + "%" : (item?.clicks / item?.impressions) * 100 + '%') : undefined,
        ErView ? item.er.toFixed(2) + '%' : undefined,
        spent ? (item.spentUsd ? item.spentUsd.toFixed(2) : 0.00) : undefined,
        spentCurrency ? item.currencyCode : undefined,
        spentCurrency ? item.spent.toFixed(2) : undefined,
        overSpentUsd ? item?.overSpentUsd?.toFixed(2) : undefined,
        overCostUsd ? item?.overCostUsd?.toFixed(2) : undefined,
        totalSpentUsd ? item?.totalSpentUsd?.toFixed(2) : undefined,
        inversionBudgetUsd ? item?.inversionBudgetUsd?.toFixed(2) : undefined,
        over_under ? item?.overUnder?.toFixed(2) + '%' || 0 : undefined
      ].filter((item) => item !== undefined)),
      )];
    let date = new Date();
    downloadCsv('report-' + date.getDate() + "-" + (date.getMonth() + 1) + "-" + date.getFullYear() + '.csv', csvData);
  }

  useEffect(() => {
    getCampaignData();
    getAdvertiseData();
    getFormat();
  }, []);
  const getCampaignData = async () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiserid": advertiserId ? advertiserId : null
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/get_campaign_data`, requestOptions)
    const result = await response.json();
    setCampaignData(result?.data);
  };

  useEffect(() => {
    if (advertiserId?.length !== 0) {
      getAdvertiseCampaigns()
    }
  }, [advertiserId])


  const getAdvertiseCampaigns = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "advertiserId": advertiserId ? advertiserId : null
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_reportcampaign`, requestOptions)
      .then(response => response.json())
      .then(result => { setCampaignData(result?.data?.Campaign) })
      .catch(error => toast.error(error));
  }

  const getAdvertiseData = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_advertiser`,
      {
        method: "GET",
        headers: {
          AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const result = await response.json();
    if (result) {
      setAdvertiseData(result?.data);
    }
  };

  useEffect(() => {
    if (setSelectedCampaignsId?.length !== 0) {
      getCampaignBasedFormat()
    }
  }, [selectedCampaignsId])

  const getCampaignBasedFormat = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

    var raw = JSON.stringify({
      "campaign_Id": selectedCampaignsId
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw,
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_reportformat`, requestOptions)
      .then(response => response.json())
      .then(result => { setFormatData(result?.data?.Formats) })
      .catch(error => toast.error(error));
  }

  const getFormat = async () => {
    const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/formats`,
      {
        method: "GET",
        headers: {
          AUTHORIZATION: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    const result = await response.json();
    if (result) {
      setFormatData(result?.data);
    }
  };

  const handle_multiselect_campaign = (e) => {
    //

    if (campaignData) {
      let campaign_selected = [];

      e.map((data) => {
        let campaign_index = campaignData.findIndex(
          (el) => el?.name === data?.name
        );
        if (
          campaign_index !== -1 &&
          campaignData[campaign_index]?.id &&
          campaignData[campaign_index]?.name
        ) {
          campaign_selected.push({
            id: campaignData[campaign_index].id,
            name: campaignData[campaign_index].name,
          });
        }
      });
      setModifyingCampaign(campaign_selected);
      handleCampaign(campaign_selected);
    }
  };

  const handleCampaign = (e) => {
    const selectedCampaigns = e.map((val) => val?.name);
    setSelectedCampaigns(selectedCampaigns);
    handleFormatValues(e)
  };

  const handleFormatValues = (e) => {
    const selectedCampaignsID = e.map((val) => val?.id);
    setSelectedCampaignsId(selectedCampaignsID);
  }

  const handle_multiselect_format = (e) => {
    let format_array = [];
    e.map((data) => {
      let format_index = formatData.findIndex((el) => el.id == data.id);
      if (
        format_index !== -1 &&
        formatData[format_index]?.id &&
        formatData[format_index]?.name
      ) {
        format_array.push({
          id: formatData[format_index]?.id,
          name: formatData[format_index]?.name,
        });
      }
    });
    setModifyingFormat(format_array);
    handleFormat(format_array);
  };

  const handleFormat = (e) => {
    const selectedFormats = e.map((val) => val?.name).filter(Boolean);
    setSelectedFormats(selectedFormats);
  };

  const handle_multiselect_advertiser = (e) => {
    var allSelectedAdvertiser = e.map((val) => val.name).filter(Boolean);
    setAdvertiser(allSelectedAdvertiser);

    var allSelectedAdvertiserId = e.map((val) => val.id).filter(Boolean);
    setAdvertiserId(allSelectedAdvertiserId);
  };

  const handleSearch = async (field) => {
    if (dataPeriod !== "custom" && !period) {
      toast.error("Please select period");
    } else if (startend.start === "" && startend.end === "" && dataPeriod === "custom") {
      toast.error("Please select dates");
    } else if (dimension?.length === 0) {
      toast.error("Please select Dimensions");
    } else {
      setloadershown(true);
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var raw = JSON.stringify({
        advertiser: advertiser,
        campaign: selectedCampaigns,
        format: selectedFormats,
        startDate: startend.start ? startend.start : null,
        endDate: startend.end ? startend.end : null,
        dimension: dimension,
        period: period ? period : null,
        impressions: true,
        views: true,
        clicks: true,
        engagements: true,
        ctr: true,
        egRate: true,
        spent_currency_deal: true,
        is_billable: billable_data ? billable_data : [],
        FieldName: field,
        Order: asc ? "asc" : "desc"
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}report/get_reportoverviewfinance`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          setResultData(result);
          setOverviewData(result?.data?.overview);
          setloadershown(false);
        })
        .catch((error) => toast.error(error));
    }
  };

  const handle_multiremove_Campaign = (e) => {
    let removeCampaign = e.map((el) => el.name);
    setSelectedCampaigns(removeCampaign);
  };

  const handle_multiremove_format = (e) => {
    let removeFormat = e.map((el) => el.name);
    setSelectedFormats(removeFormat);
  };



  const handle_multiremove_advertiser = (e) => {
    let removeAdvertiser = e.map((el) => el.name);
    setAdvertiser(removeAdvertiser);
  };

  const handle_set_billable = (e) => {
    if (e.target.value === "0") {
      setBillable_data([0])
    } else if (e.target.value === "1") {
      setBillable_data([1])
    } else if (e.target.value === "2") {
      setBillable_data([2])
    } else {
      setBillable_data([0, 1, 2])
    }
  }

  const handleSort = (field) => {
    if (!asc) {
      overviewData.sort((a, b) => {
        if (typeof a[field] === 'string') {
          return a[field].localeCompare(b[field]);
        } else {
          return a[field] - b[field];
        }
      });
    } else {
      overviewData.sort((a, b) => {
        if (typeof a[field] === 'string') {
          return b[field].localeCompare(a[field]);
        } else {
          return b[field] - a[field];
        }
      });
    }
    let new_data = overviewData.slice();
    setOverviewData(new_data);
  }

  return (
    <>
      <div className="content_outer">
        <Loader showLoader={loadershown} />
        <div className="content pb-0">
          <div className="App">
            <div className="reports__wrapper">
              <Form>
                <Row>
                  <Col lg={4} className="mb-3">
                    <div
                      className={
                        periodError
                          ? "input_wrap select_country_container mt-1  input_wrap_border"
                          : "input_wrap select_country_container mt-1 "
                      }
                    >
                      <Form.Group controlId="formBasicEmail">
                        <Form.Label className="center_label lbl_position form-label">Date Range</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handleSelectChange(e);
                          }}
                        >
                          <option disabled={true} selected={true}>
                            Select
                          </option>
                          <option value="yesterday">Yesterday</option>
                          <option value="month">So far this month</option>
                          <option value="seven_days">Last seven days</option>
                          <option value="last_month">Last month</option>
                          <option value="custom">Custom date</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                  {customDate && (
                    <Col md={3} lg={4} className="mb-3" >
                      <div className="input_wrap select_country_container mt-1">
                        <Form.Group controlId="formBasicEmail">
                          <Form.Label>Custom Date</Form.Label>
                          <DateRangePicker
                            initialSettings={{
                              autoUpdateInput: false,
                              showDropdowns: true,
                            }}
                            onApply={handleApply}
                            onCancel={handleCancel}
                            disabled={true}
                          >
                            <Form.Control
                              type="text"
                              placeholder="Custom Date"
                              className="form-control"
                              disabled={dateDisable}
                            />
                          </DateRangePicker>
                        </Form.Group>
                      </div>
                    </Col>
                  )}
                  <Col lg={4} md={4}>
                    <div className="input_wrap select_country_container mt-1">
                      <label className="center_label lbl_position form-label" htmlFor="Campaign">
                        Advertiser
                      </label>
                      <Multiselect
                        options={advertiseData.map((i) => {
                          const name = i?.name ?? "Unknown";
                          return { ...i, name };
                        })}
                        // selectedValues={adv}
                        onSelect={(e) => { handle_multiselect_advertiser(e); }}
                        onRemove={(e) => handle_multiremove_advertiser(e)}
                        displayValue="name"
                      />
                    </div>
                  </Col>

                  <Col lg={4} className=" mb-3">
                    <div className="input_wrap input_wrap select_country_container mt-1">
                      <div className="form-group">
                        <label className="center_label lbl_position form-label" htmlFor="Campaign">
                          Campaign
                        </label>
                        <Multiselect
                          options={campaignData?.map((i) => ({
                            ...i,
                            name: i?.name,
                          }))}
                          // selectedValues={selectedCampaigns}
                          onSelect={(e) => handle_multiselect_campaign(e)}
                          onRemove={(e) => handle_multiremove_Campaign(e)}
                          displayValue="name"
                          className="reportOverflow"
                        />
                      </div>
                    </div>
                  </Col>

                  {/* <Col lg={4}>
                    <div className="input_wrap">
                      <div className="form-group mb-3">
                        <label className="mb-2" htmlFor="country">
                          Format
                        </label>
                        <Multiselect
                          options={formatData}
                          // selectedValues={selectedFormats}
                          onSelect={(e) => handle_multiselect_format(e)}
                          onRemove={(e) => handle_multiremove_format(e)}
                          displayValue="name"
                        />
                      </div>
                    </div>
                  </Col> */}

                  <Col lg={4} className=" mb-3">
                    <div className="input_wrap input_wrap select_country_container mt-1">
                      <div className="form-group">
                        <label className="center_label lbl_position form-label" htmlFor="country">
                          Dimensions
                        </label>
                        <Multiselect
                          options={dimension_Options}
                          // selectedValues={dimension}
                          onSelect={(e) => usedimension(e)}
                          onRemove={(e) => handle_multiremove_dimension(e)}
                          displayValue="name"
                        />
                      </div>
                    </div>
                  </Col>
                  <Col lg={4}>
                    <div
                      className={
                        periodError
                          ? "input_wrap select_country_container mt-1 input_wrap_border"
                          : "input_wrap select_country_container mt-1"
                      }
                    >
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="center_label lbl_position form-label"> Billable</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          onChange={(e) => {
                            handle_set_billable(e);
                          }}
                        >
                          <option disabled={true} selected={true}>
                            Select
                          </option>
                          <option value="0">Not to bill</option>
                          <option value="1">Ready to bill</option>
                          <option value="2">Billed</option>
                          <option value="3">All</option>
                        </Form.Select>
                      </Form.Group>
                    </div>
                  </Col>
                  <Col sm={12}>
                    <div className="metrics mt-5">
                      <div className="metricsFlex">
                        <h2>Metrics</h2>
                        {!selectAll ? <Button
                          onClick={() => { setSelectAll(!selectAll); handleSelectAll() }}
                          className="btnMetrics"
                        >
                          Select All

                        </Button>
                          :
                          <Button
                            onClick={() => { setSelectAll(!selectAll); handleSelectAll() }}
                            className="btnMetrics"
                          >
                            Deselect All

                          </Button>}
                      </div>
                      <ul className="mt-3">
                        <li>
                          <Form.Check
                            label="Impressions"
                            name="impressions"
                            type="checkbox"
                            ref={impressionViewRef}
                            onChange={(e) => setImpressionView(!impressionView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="Views"
                            name="views"
                            type="checkbox"
                            ref={viewsViewRef}
                            onChange={(e) => setViewsView(!viewsView)}
                          />
                        </li>
                        {showGoal && dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Goal"
                            name="Goal"
                            type="checkbox"
                            ref={goalsRef}
                            onChange={(e) => setGolas(!goals)}
                          />
                        </li> : null}
                        <li>
                          <Form.Check
                            label="Clicks"
                            name="clicks"
                            type="checkbox"
                            ref={clicksViewRef}
                            onChange={(e) => setClicksView(!clicksView)}
                          />
                        </li>
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Start/End Dates"
                            name="start/end Dates"
                            type="checkbox"
                            ref={dateRef}
                            onChange={(e) => setStartEndDate(!startEndDate)}
                          />
                        </li> : null}
                        <li>
                          <Form.Check
                            label="Engagements"
                            name="engagements"
                            type="checkbox"
                            ref={EngagmentViewRef}
                            onChange={(e) => setEngagmentView(!EngagmentView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="VTR"
                            name="cpcv"
                            type="checkbox"
                            ref={VtrViewRef}
                            onChange={(e) => setVtrView(!VtrView)}
                          />
                        </li>
                        <li>
                          <Form.Check
                            label="CTR"
                            name="ctr"
                            type="checkbox"
                            ref={CtrViewRef}
                            onChange={(e) => setCtrView(!CtrView)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="ER"
                            name="egRate"
                            type="checkbox"
                            ref={ErViewRef}
                            onChange={(e) => setErView(!ErView)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="Spent (USD)"
                            name="spent"
                            type="checkbox"
                            ref={spentRef}
                            onChange={(e) => setSpent(!spent)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="Spent Currency Deal"
                            name="spent"
                            type="checkbox"
                            ref={spentCurrencyRef}
                            onChange={(e) => setSpentCurrency(!spentCurrency)}
                          />
                        </li>
                        <li className="me-3">
                          <Form.Check
                            label="ID HS Deal"
                            name="ID HS Deal"
                            type="checkbox"
                            ref={hsDealRef}
                            onChange={(e) => setHsDeal(!hsDeal)}
                          />
                        </li>
                        {dimension?.includes("LineItem") ? <li className="me-3">
                          <Form.Check
                            label="Over/Under"
                            name="Over/Under"
                            type="checkbox"
                            ref={over_underRef}
                            onChange={(e) => setOver_Under(!over_under)}
                          />
                        </li>
                          : null}
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Total Spent Usd"
                            name="Total Spent Usd"
                            type="checkbox"
                            ref={totalSpentUsdRef}
                            onChange={(e) => setTotalSpentUsd(!totalSpentUsd)}
                          />
                        </li>
                          : null}
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Inversion Budget Usd"
                            name="Inversion Budget Usd"
                            type="checkbox"
                            ref={inversionBudgetUsdRef}
                            onChange={(e) => setInversionBudgetUsd(!inversionBudgetUsd)}
                          />
                        </li>
                          : null}
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Over Spent Usd"
                            name="Over Spent Usd"
                            type="checkbox"
                            ref={overSpentUsdRef}
                            onChange={(e) => setOverSpentUsd(!overSpentUsd)}
                          />
                        </li>
                          : null}
                        {dimension?.includes("LineItem") ? <li>
                          <Form.Check
                            label="Over Cost Usd"
                            name="Over Cost Usd"
                            type="checkbox"
                            ref={overCostUsdRef}
                            onChange={(e) => setOverCostUsd(!overCostUsd)}
                          />
                        </li>
                          : null}
                      </ul>
                    </div>

                    {overviewData?.length !== 0 && (
                      <div className="goals_card">
                        <ul>
                          <li>
                            <p> Impressions</p>
                            <h4>
                              {resultData?.data?.Impression?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>
                          <li>
                            <p>Completed Views</p>
                            <h4>{resultData?.data?.Views?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</h4>
                          </li>
                          <li>
                            <p>Goal</p>
                            <h4>
                              {resultData?.data?.SumGoal?.toLocaleString('en-US', { maximumFractionDigits: 0 })}
                            </h4>
                          </li>
                          <li>
                            <p>VTR</p>
                            <h4>
                              {parseFloat((parseInt(resultData?.data?.Views) / parseInt(resultData?.data?.Impression)) * 100).toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%"}
                            </h4>
                          </li>
                          <li>
                            <p>CTR</p>
                            <h4>
                              {parseFloat(resultData?.data?.Sumctr?.toFixed(1)).toLocaleString('en-US', { maximumFractionDigits: 2 }) + "%"}
                            </h4>
                          </li>
                          <li>
                            <p>Spent USD</p>
                            <h4>
                              {resultData?.data?.SumSpentUsd?.toLocaleString('en-US', { maximumFractionDigits: 2 })}
                            </h4>
                          </li>
                        </ul>
                      </div>
                    )}
                    <div></div>
                  </Col>
                  <Col sm={12}>
                    <div className="resetBtns report_actions">
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label style={{ opacity: "0" }}>Reset</Form.Label>{" "}
                        <br />
                        <Button
                          variant="outline-danger me-4"
                          onClick={refreshPage}
                        >
                          Reset
                        </Button>
                        <Button
                          variant="outline-success"
                          onClick={() => handleSearch()}
                        >
                          Search
                        </Button>
                      </Form.Group>
                    </div>
                  </Col>
                </Row>

                <div className="">
                  <Row></Row>
                </div>
              </Form>
            </div>
          </div>
          {resultData?.data?.overview?.length === 0 && (
            <div className="d-flex w-100 justify-content-center card no_data_found">
              <img className="no_data_found" src="/assets/images/notfound.svg" />
            </div>
          )}
        </div>

        {(impressions == true ||
          views == true ||
          clicks == true ||
          engagements == true ||
          cpcv == true ||
          ctr == true ||
          egRate == true) && (overviewData?.length > 0) && (
            <div className="content">
              <div className="card report__table">
                {resultData?.data?.overview && <div className="text-end pt-2 pb-2 pe-2">
                  <button className="button expo_button" onClick={exportCsv}>
                    Export
                  </button>
                </div>}

                {overviewData?.length !== 0 && (

                  <Table bordered responsive>

                    <thead>
                      <tr>
                        {hsDeal && <th onClick={() => { handleSort("dealid"); setAsc(!asc) }}>ID HS Deal</th>}
                        {resultData?.data?.advertiser && <th onClick={() => { handleSort("advertiser"); setAsc(!asc) }}>
                          <div className="header_center"> Advertiser <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.date && <th onClick={() => { handleSort("date"); setAsc(!asc) }}>
                          <div className="header_center"> Date <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.campaign && <th onClick={() => { handleSort("campaignID"); setAsc(!asc) }}>Campaign ID</th>}
                        {resultData?.data?.campaign && <th onClick={() => { handleSort("campaign"); setAsc(!asc) }}>
                          <div className="header_center"> Campaign <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.format && <th onClick={() => { handleSort("format"); setAsc(!asc) }}>
                          <div className="header_center"> Format <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {resultData?.data?.country && <th onClick={() => { handleSort("country"); setAsc(!asc) }}>Country<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}

                        {resultData?.data?.LineItem && <th onClick={() => { handleSort("lineitemID"); setAsc(!asc) }}>Line Item ID<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.LineItem && <th onClick={() => { handleSort("lineitem"); setAsc(!asc) }}>
                          <div className="header_center"> Line Item <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>  </th>}

                        {resultData?.data?.creative && <th onClick={() => { handleSort("creative"); setAsc(!asc) }}>
                          <div className="header_center"> Creatives <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {startEndDate && <th onClick={() => { handleSort("startDate"); setAsc(!asc) }}>Start Date<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {startEndDate && <th onClick={() => { handleSort("endDate"); setAsc(!asc) }}>End Date<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.OfferType && dimension?.includes("offer") && <th onClick={() => { handleSort("offer_type"); setAsc(!asc) }}>Offer Type<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {resultData?.data?.inventory_type && dimension?.includes("inventioryType") && <th onClick={() => { handleSort("inventory_type"); setAsc(!asc) }}>Inventory Type<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {/* {goals && (resultData?.data?.advertiser || resultData?.data?.campaign || resultData?.data?.LineItem) ? (<th>Goal</th>) : null} */}
                        {goals && resultData?.data && !resultData?.data?.country && !resultData?.data?.creative && !resultData?.data?.date && (resultData?.data?.advertiser || resultData?.data?.campaign || resultData?.data?.LineItem) ? (<th onClick={() => { handleSort("goal"); setAsc(!asc) }}>
                          <div className="header_center">  Goal <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>) : null}


                        {impressionView && <th onClick={() => { handleSort("impressions"); setAsc(!asc) }}>
                          <div className="header_center">  Impressions <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>   </th>}

                        {viewsView && <th onClick={() => { handleSort("views"); setAsc(!asc) }}>
                          <div className="header_center"> Views <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>  </th>}

                        {clicksView && <th onClick={() => { handleSort("clicks"); setAsc(!asc) }}>
                          <div className="header_center">  Clicks <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>   </th>}

                        {EngagmentView && <th onClick={() => { handleSort("engagements"); setAsc(!asc) }}>
                          <div className="header_center">  Engagements <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}

                        {VtrView && <th onClick={() => { handleSort("vtr"); setAsc(!asc) }}>
                          <div className="header_center"> VTR <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div>  </th>}

                        {CtrView && <th onClick={() => { handleSort("ctr"); setAsc(!asc) }}>
                          <div className="header_center"> CTR <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}

                        {ErView && <th onClick={() => { handleSort("er"); setAsc(!asc) }}>
                          <div className="header_center"> ER <svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg>
                          </div> </th>}
                        {spent && <th onClick={() => { handleSort("spentUsd"); setAsc(!asc) }}>Spent (USD)<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {spentCurrency && <th onClick={() => { handleSort("currencyCode"); setAsc(!asc) }}>Currency Code<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {spentCurrency && <th>Spent Currency Deal</th>}
                        {totalSpentUsd && <th onClick={() => { handleSort("totalSpentUsd"); setAsc(!asc) }}>Total Spent Usd<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {inversionBudgetUsd && <th onClick={() => { handleSort("inversionBudgetUsd"); setAsc(!asc) }}>Inversion Budget Usd<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {overSpentUsd && <th onClick={() => { handleSort("overSpentUsd"); setAsc(!asc) }}>Over Spent Usd<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {overCostUsd && <th onClick={() => { handleSort("overCostUsd"); setAsc(!asc) }}>Over Cost Usd<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                        {over_under && <th onClick={() => { handleSort("overUnder"); setAsc(!asc) }}>Over Under<svg className="orderSvg" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M3 18v-1.5h6V18H3zm0-5.25v-1.5h12v1.5H3zM3 7.5V6h18v1.5H3z" fill="#242833"></path></svg></th>}
                      </tr>
                    </thead>
                    <tbody>
                      {overviewData?.map((data, index) => {
                        return (
                          <tr key={index}>
                            {hsDeal && <td>{parseFloat(data?.dealid) || 0}</td>}
                            {resultData?.data?.advertiser && <td>{data?.advertiser}</td>}
                            {resultData?.data?.date && <td><Moment format="DD/MM/YYYY">{data?.date}</Moment></td>}
                            {resultData?.data?.campaign && (
                              <td>{data?.campaignID}</td>
                            )}
                            {resultData?.data?.campaign && (
                              <td>{data?.campaign}</td>
                            )}
                            {resultData?.data?.format && <td>{data?.format}</td>}
                            {resultData?.data?.country && (
                              <td>{data?.country}</td>
                            )}
                            {resultData?.data?.creative && (
                              <td>
                                {data?.creative
                                  ? data?.creative
                                  : "Not Available"}
                              </td>
                            )}
                            {resultData?.data?.LineItem && (
                              <td>
                                {data?.lineitem
                                  ? data?.lineitemID
                                  : "Not Available"}
                              </td>
                            )}
                            {resultData?.data?.LineItem && (
                              <td>
                                {data?.lineitem
                                  ? data?.lineitem
                                  : "Not Available"}
                              </td>
                            )}
                            {startEndDate && <td>{data?.startDate}</td>}
                            {startEndDate && <td>{data?.endDate}</td>}

                            {data?.offer && dimension?.includes("OfferType") && (
                              <td>{data?.offer}</td>
                            )}
                            {data?.inventioryType && dimension?.includes("inventory_type") && (
                              <td>{data?.inventioryType}</td>
                            )}

                            {goals && resultData?.data && !resultData?.data?.country && !resultData?.data?.creative && !resultData?.data?.date && (resultData?.data?.advertiser || resultData?.data?.campaign || resultData?.data?.LineItem) ? <td>{data?.goal?.toLocaleString(undefined, { maximumFractionDigits: 0 })}</td> : null}


                            {impressionView && (
                              <td>{data?.impressions?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                            )}
                            {viewsView && (
                              <td>{data?.views?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                            )}
                            {clicksView && (
                              <td>{data?.clicks?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                            )}
                            {EngagmentView && (
                              <td>{data?.engagements?.toLocaleString('en-US', { maximumFractionDigits: 2 })}</td>
                            )}
                            {VtrView && <td>{data?.views != 0 ? (data?.vtr)?.toFixed(2) : "-"}%</td>}

                            {CtrView && <td>{data?.ctr ? data?.ctr.toFixed(2) : (data?.clicks / data?.impressions) * 100}%</td>}
                            {ErView && <td>{data?.er?.toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0.00}%</td>}
                            {spent && <td>{parseFloat(data?.spentUsd?.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0}</td>}
                            {spentCurrency && <td>{data?.currencyCode}</td>}
                            {spentCurrency && <td>{parseFloat(data?.spent?.toFixed(2)).toLocaleString('en-US', { maximumFractionDigits: 2 }) || 0}</td>}
                            {totalSpentUsd && <td>{data?.totalSpentUsd?.toFixed(2) || 0}</td>}
                            {inversionBudgetUsd && <td>{data?.inversionBudgetUsd?.toFixed(2) || 0}</td>}
                            {overSpentUsd && <td>{data?.overSpentUsd?.toFixed(2) || 0}</td>}
                            {overCostUsd && <td>{data?.overCostUsd?.toFixed(2) || 0}</td>}
                            {over_under && <td>{data?.overUnder?.toFixed(2) + '%' || 0}</td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
              </div>
            </div>
          )}
      </div>
    </>
  );
};
export default ReportRCFinance;
