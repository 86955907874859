import { configureStore } from "@reduxjs/toolkit";
import { authSlice } from "./features/Auth/authSlice";
import { DashboardSlice } from "./features/Dashboard/DashboardSlice";
import { indexSlice } from "./features/Report/reportSlice";
import { AbstractSlice } from "./features/Abstract/AbstractSlice";
import { CampaignManagerSlice } from "./features/CampaignManager/CampaignManagerSlice";
import { DeligateSlice } from "./features/CampaignManager/DelegateSlice";
import { MediaPlanManagerSlice } from "./features/CampaignManager/MediaPlanManager";
import { LineItemsSlice } from "./features/LineItemManager/LineItemManagerSlice";
import { CreativesSlice } from "./features/Creative/CreativeSlice";
import { CommonSlice } from "./features/Common/CommonSlice";
import { UserSlice } from "./features/Users/UserSlice";
import reset_password from "./features/Auth/resetPasswordSlice";
import change_user_password from "./features/Auth/changeUserPasswordSlice";
import changeUserPassword from "./features/Auth/changePasswordSlice";
import validate_token from "./features/Auth/validateTokenSlice";
import change_new_user_password from "./features/Auth/chnageNewUserPasswordSlice";
import optimizeTabGeneral from "./features/optimizeTab/optimizeTabGeneralSlice";
import optimizeDataSaas from "./features/optimizeTabSaas/optimizeTabSaasSlise";
import getPacing from "./features/update_optimize_tab/get_pacing";
import getPacingRate from "./features/update_optimize_tab/get_pacing_rate_slice";
import updatePacing from "./features/update_optimize_tab/update_pacing_slice";
import updatePacingRate from "./features/update_optimize_tab/update_pacing_rate_slice";
import optimizeTabReportData from "./features/optimizeTab/optimize_tab_report_data_slice";
import updateOptimizeTabGeneral from "./features/optimizeTab/update_optimize_tab_slice";
import updateOptimizeTabSaas from "./features/optimizeTabSaas/update_optimize_tab_slice";
import optimizeTabSaasReportData from "./features/optimizeTabSaas/optimize_saas_report_data_slice";
import lineitemLogHistory from "./features/lineitemHistory/lineitemHistorySlice";
import getUserList from "./features/User_subgroups/get_user_group";
import getUserPermissions from "./features/User_subgroups/get_user_permissions_slice";
import saveUserSubgroup from "./features/User_subgroups/save_subgroup_slice";
import getUserSubGroupPermissions from "./features/User_subgroups/get_sub_group_slice";
import getUserSubGroup from "./features/User_subgroups/get_user_group_slice";
import updateSubgroup from "./features/User_subgroups/update_subgroup_slice";
import kite_states from "./features/kiteStates/kite_states_slice";
import getUserAdvertiseClients from "./features/Users/get_advertise_clients_slice";
import saveAdvertiserClients from "./features/Users/save_advertiser_clients_slice";
import getSelectedAdvertiserClients from "./features/Users/get_selected_advertiser_clients_slice";
import saveServiceFee from "./features/Admin_saas/save_service_fee_slice";
import getAllSelectedDealClient from "./features/Admin_saas/get_all_selected_deal_client_slice";
import adminSaasServiceFeeHistory from "./features/Admin_saas/service_fee_history_slice";
import changeUserPasswordPrevious from "./features/Users/reser_userpassword_slice";
import get_user_profile_info from "./features/profile/getProfileInfoSlice";
import update_user_profile from "./features/profile/updateUserProfileSlice";
import get_backhround_color from "./features/get_background_color/backhround-color-slice";

export default configureStore({
    reducer: {
        auth: authSlice.reducer,
        dashboard: DashboardSlice.reducer,
        index: indexSlice.reducer,
        abstract: AbstractSlice.reducer,
        campaignManager: CampaignManagerSlice.reducer,
        deligate: DeligateSlice.reducer,
        MediaPlanManager: MediaPlanManagerSlice.reducer,
        LineItems: LineItemsSlice.reducer,
        Creatives: CreativesSlice.reducer,
        Common: CommonSlice.reducer,
        user: UserSlice.reducer,
        reset_user_password: reset_password,
        change_user_password: changeUserPassword,
        change_new_user_password: change_user_password,
        validate_user_token: validate_token,
        new_user_password_change: change_new_user_password,
        optimize_general: optimizeTabGeneral,
        optimize_saas: optimizeDataSaas,
        all_pacing : getPacing,
        all_pacing_rate : getPacingRate,
        update_pacing_value : updatePacing,
        update_pacing_rate_value : updatePacingRate,
        optimizeTab_report_data : optimizeTabReportData,
        optimizeTab_saas_report_data : optimizeTabSaasReportData,
        update_optimize_general : updateOptimizeTabGeneral,
        update_optimize_saas : updateOptimizeTabSaas,
        log_history : lineitemLogHistory,
        kitestates : kite_states,
        validate_user_token : validate_token,
        new_user_password_change : change_new_user_password,
        log_history : lineitemLogHistory,
        user_group_list : getUserList,
        user_permissions : getUserPermissions,
        save_subgroup : saveUserSubgroup,
        saved_user_permission : getUserSubGroupPermissions,
        saved_subGroups : getUserSubGroup,
        updated_subgroup_data : updateSubgroup,
        all_user_clients : getUserAdvertiseClients,
        save_advertiser_client_list : saveAdvertiserClients,
        all_selected_advertiser_clients : getSelectedAdvertiserClients,
        save_all_service_fee: saveServiceFee,
        get_selected_deal_clients_admin_saas: getAllSelectedDealClient,
        admin_saas_service_fee_history: adminSaasServiceFeeHistory,
        change_password : changeUserPasswordPrevious,
        get_user_profile : get_user_profile_info,
        update_profile : update_user_profile,
        background_color : get_backhround_color
    }
});