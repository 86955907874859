import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const getInventories = createAsyncThunk(
  'lineitem/getInventories',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/inventories`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getLineitemFormats = createAsyncThunk(
  'lineitem/getLineitemFormats',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/formats`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getOfferTypes = createAsyncThunk(
  'lineitem/getOfferTypes',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/offer-type`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getCountries = createAsyncThunk(
  'lineitem/getCountries',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}countries/getCountries`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getLineItemStatus = createAsyncThunk(
  'lineitem/getLineItemStatus',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineitem-status`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const linefilter = createAsyncThunk(
  'lineitem/linefilter',
  async ({ token, campaignId, filter, page, pageSize }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/lineItems/${campaignId}/${page}/${pageSize},${filter}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getCampaign = createAsyncThunk(
  'lineitem/getCampaign',
  async ({ token, campaignId }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/campaign/${campaignId}`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const getGender = createAsyncThunk(
  'lineitem/getGender',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/gender`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getAgeRange = createAsyncThunk(
  'lineitem/getAgeRange',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/age-range`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getDevices = createAsyncThunk(
  'lineitem/getDevices',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/devices`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const getPassionPoint = createAsyncThunk(
  'lineitem/getPassionPoint',
  async ({ token }, thunkAPI) => {
    try {
      return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/passion-points`, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + token
        }
      }).then((response) => {
        return response.data
      }).catch((e) => {
        let error = "";
        if (e.response) {
          error = e.response.data.message;
        } else if (e.request) {
          error = e.request;
        } else {
          error = e.message;
        }
        return thunkAPI.rejectWithValue(error)

      });
    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const saveLineItem = createAsyncThunk(
  'lineitem/saveLineItem',
  async ({ token, values }, thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-lineitem`, values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)

        });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)
export const changeLineItemStatus = createAsyncThunk(
  'lineitem/changeLineItemStatus',
  async ({ token, lineitem_id, status_id }, thunkAPI) => {
    try {
      return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-lineItem-status`, {
        id : lineitem_id,
        status_id: status_id
      },
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)

        });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const updateLineItem = createAsyncThunk(
  'lineitem/updateLineItem',
  async ({values, id} , thunkAPI) => {
    try {
      return await axios.put(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-lineitem/${id}`, values,
        {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + values?.token
          }
        }).then((response) => {
          return response.data
        }).catch((e) => {
          let error = "";
          if (e.response) {
            error = e.response.data.message;
          } else if (e.request) {
            error = e.request;
          } else {
            error = e.message;
          }
          return thunkAPI.rejectWithValue(error)

        });

    } catch (error) {
      return thunkAPI.rejectWithValue(error.message)
    }
  }
)

export const LineItemsSlice = createSlice({
  name: 'LineItems',
  initialState: {
    inventories: [],
    line_item_formats: [],
    offer_types: [],
    countries: [],
    line_item_status: [],
    filtered_items: [],
    genders: [],
    age_range: [],
    devices: [],
    passion_points: [],
    campaign_detail: {},
    inserted_lineitem_id: '',
    isFetchingInventories: false,
    isSuccessInventories: false,
    isErrorInventories: false,
    isFetchingFormats: false,
    isSuccessFormats: false,
    isErrorFormats: false,
    isFetchingOfferTypes: false,
    isSuccessOfferTypes: false,
    isErrorOfferTypes: false,
    isFetchingCountries: false,
    isSuccessCountries: false,
    isErrorCountries: false,
    isFetchingStatus: false,
    isSuccessStatus: false,
    isErrorStatus: false,
    isFetchingFilter: false,
    isSuccessFilter: false,
    isErrorFilter: false,
    isFetchingCampaign: false,
    isSuccessCampaign: false,
    isErrorCampaign: false,
    isFetchingGender: false,
    isSuccessGender: false,
    isErrorGender: false,
    isFetchingAgeRange: false,
    isSuccessAgeRange: false,
    isErrorAgeRange: false,
    isFetchingDevices: false,
    isSuccessDevices: false,
    isErrorDevices: false,
    isFetchingPassionPoint: false,
    isSuccessPassionPoint: false,
    isErrorPassionPoint: false,
    isFetchingsaveLineItem: false,
    isSuccesssaveLineItem: false,
    isErrorsaveLineItem: false,
    isFetchingchangeLineItemStatus: false,
    isSuccesschangeLineItemStatus: false,
    isErrorchangeLineItemStatus: false,
    isFetchingupdateLineItem: false,
    isSuccessupdateLineItem: false,
    isErrorupdateLineItem: false,
    successMessasge: '',
    error: ''
  },
  reducers: {
    clearState: (state) => {
      state.isFetchingInventories = false;
      state.isSuccessInventories = false;
      state.isErrorInventories = false;
      state.isFetchingFormats = false;
      state.isSuccessFormats = false;
      state.isErrorFormats = false;
      state.isFetchingOfferTypes = false;
      state.isSuccessOfferTypes = false;
      state.isErrorOfferTypes = false;
      state.isFetchingCountries = false;
      state.isSuccessCountries = false;
      state.isErrorCountries = false;
      state.isFetchingStatus = false;
      state.isSuccessStatus = false;
      state.isErrorStatus = false;
      state.isFetchingFilter = false;
      state.isSuccessFilter = false;
      state.isErrorFilter = false;
      state.isFetchingCampaign = false;
      state.isSuccessCampaign = false;
      state.isErrorCampaign = false;
      state.isFetchingchangeLineItemStatus = false;
      state.isSuccesschangeLineItemStatus = false;
      state.isErrorchangeLineItemStatus = false;
      state.isFetchingupdateLineItem = false;
      state.isSuccessupdateLineItem = false;
      state.isErrorupdateLineItem = false;
      state.error = "";
      state.inventories = [];
      state.line_item_formats = [];
      state.offer_types = [];
      state.countries = [];
      state.line_item_status = [];
      return state;
    },
    clearInventories: (state) => {
      state.isFetchingInventories = false;
      state.isSuccessInventories = false;
      state.isErrorInventories = false;
      return state;
    },
    clearFormats: (state) => {
      state.isFetchingFormats = false;
      state.isSuccessFormats = false;
      state.isErrorFormats = false;
      return state;
    },
    clearOffers: (state) => {
      state.isFetchingOfferTypes = false;
      state.isSuccessOfferTypes = false;
      state.isErrorOfferTypes = false;
      return state;
    },
    clearCountries: (state) => {
      state.isFetchingCountries = false;
      state.isSuccessCountries = false;
      state.isErrorCountries = false;
      return state;
    },
    clearStatus: (state) => {
      state.isFetchingStatus = false;
      state.isSuccessStatus = false;
      state.isErrorStatus = false;
      return state;
    },
    clearfilterStatus: (state) => {
      state.isFetchingFilter = false;
      state.isSuccessFilter = false;
      state.isErrorFilter = false;
      return state;
    },
    clearcampaignStatus: (state) => {
      state.isFetchingCampaign = false;
      state.isSuccessCampaign = false;
      state.isErrorCampaign = false;
      return state;
    },
    clearsaveLineItem: (state) => {
      state.isFetchingsaveLineItem = false;
      state.isSuccesssaveLineItem = false;
      state.isErrorsaveLineItem = false;
      state.error = '';
      return state;
    },
    clearupdateLineItem: (state) => {
      state.isFetchingupdateLineItem = false;
      state.isSuccessupdateLineItem = false;
      state.isErrorupdateLineItem = false;
      state.error = '';
      return state;
    }

  },
  extraReducers: {
    [getInventories.fulfilled]: (state, { payload }) => {
      state.inventories = payload.data;
      state.isFetchingInventories = false;
      state.isSuccessInventories = true;
      state.isErrorInventories = false;
    },
    [getInventories.rejected]: (state, { payload }) => {
      state.isFetchingInventories = false;
      state.isErrorInventories = true;
      state.error = payload
    },
    [getInventories.pending]: (state) => {
      state.isFetchingInventories = true;
    },

    [getLineitemFormats.fulfilled]: (state, { payload }) => {
      state.line_item_formats = payload.data;
      state.isFetchingFormats = false;
      state.isSuccessFormats = true;
      state.isErrorFormats = false;
    },
    [getLineitemFormats.rejected]: (state, { payload }) => {
      state.isFetchingFormats = false;
      state.isErrorFormats = true;
      state.error = payload
    },
    [getLineitemFormats.pending]: (state) => {
      state.isFetchingFormats = true;
    },

    [getOfferTypes.fulfilled]: (state, { payload }) => {
      state.offer_types = payload.data;
      state.isFetchingOfferTypes = false;
      state.isSuccessOfferTypes = true;
      state.isErrorOfferTypes = false;
    },
    [getOfferTypes.rejected]: (state, { payload }) => {
      state.isFetchingOfferTypes = false;
      state.isErrorOfferTypes = true;
      state.error = payload
    },
    [getOfferTypes.pending]: (state) => {
      state.isFetchingOfferTypes = true;
    },

    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload.data;
      state.isFetchingCountries = false;
      state.isSuccessCountries = true;
      state.isErrorCountries = false;
    },
    [getCountries.rejected]: (state, { payload }) => {
      state.isFetchingCountries = false;
      state.isErrorCountries = true;
      state.error = payload
    },
    [getCountries.pending]: (state) => {
      state.isFetchingCountries = true;
    },

    [getLineItemStatus.fulfilled]: (state, { payload }) => {
      state.line_item_status = payload.data;
      state.isFetchingStatus = false;
      state.isSuccessStatus = true;
      state.isErrorStatus = false;
    },
    [getLineItemStatus.rejected]: (state, { payload }) => {
      state.isFetchingStatus = false;
      state.isErrorStatus = true;
      state.error = payload
    },
    [getLineItemStatus.pending]: (state) => {
      state.isFetchingStatus = true;
    },
    [linefilter.fulfilled]: (state, { payload }) => {
      state.filtered_items = payload.data;
      state.isFetchingFilter = false;
      state.isSuccessFilter = true;
      state.isErrorFilter = false;
    },
    [linefilter.rejected]: (state, { payload }) => {
      state.isFetchingFilter = false;
      state.isErrorFilter = true;
      state.error = payload
    },
    [linefilter.pending]: (state) => {
      state.isFetchingFilter = true;
    },
    [getCampaign.fulfilled]: (state, { payload }) => {
      state.campaign_detail = payload.data;
      state.isFetchingCampaign = false;
      state.isSuccessCampaign = true;
      state.isErrorCampaign = false;
    },
    [getCampaign.rejected]: (state, { payload }) => {
      state.isFetchingCampaign = false;
      state.isErrorCampaign = true;
      state.error = payload
    },
    [getCampaign.pending]: (state) => {
      state.isFetchingCampaign = true;
    },
    [getGender.fulfilled]: (state, { payload }) => {
      state.genders = payload.data;
      state.isFetchingGender = false;
      state.isSuccessGender = true;
      state.isErrorGender = false;
    },
    [getGender.rejected]: (state, { payload }) => {
      state.isFetchingGender = false;
      state.isErrorGender = true;
      state.error = payload
    },
    [getGender.pending]: (state) => {
      state.isFetchingGender = true;
    },
    [getAgeRange.fulfilled]: (state, { payload }) => {
      state.age_range = payload.data;
      state.isFetchingAgeRange = false;
      state.isSuccessAgeRange = true;
      state.isErrorAgeRange = false;
    },
    [getAgeRange.rejected]: (state, { payload }) => {
      state.isFetchingAgeRange = false;
      state.isErrorAgeRange = true;
      state.error = payload
    },
    [getAgeRange.pending]: (state) => {
      state.isFetchingAgeRange = true;
    },
    [getDevices.fulfilled]: (state, { payload }) => {
      state.devices = payload.data;
      state.isFetchingDevices = false;
      state.isSuccessDevices = true;
      state.isErrorDevices = false;
    },
    [getDevices.rejected]: (state, { payload }) => {
      state.isFetchingDevices = false;
      state.isErrorDevices = true;
      state.error = payload
    },
    [getDevices.pending]: (state) => {
      state.isFetchingDevices = true;
    },
    [getPassionPoint.fulfilled]: (state, { payload }) => {
      state.passion_points = payload.data;
      state.isFetchingPassionPoint = false;
      state.isSuccessPassionPoint = true;
      state.isErrorPassionPoint = false;
    },
    [getPassionPoint.rejected]: (state, { payload }) => {
      state.isFetchingPassionPoint = false;
      state.isErrorPassionPoint = true;
      state.error = payload
    },
    [getPassionPoint.pending]: (state) => {
      state.isFetchingPassionPoint = true;
    },
    [saveLineItem.fulfilled]: (state, { payload }) => {
      state.inserted_lineitem_id = payload.data;
      state.isFetchingsaveLineItem = false;
      state.isSuccesssaveLineItem = true;
      state.isErrorsaveLineItem = false;
    },
    [saveLineItem.rejected]: (state, { payload }) => {
      state.isFetchingsaveLineItem = false;
      state.isErrorsaveLineItem = true;
      state.error = payload
    },
    [saveLineItem.pending]: (state) => {
      state.isFetchingsaveLineItem = true;
    },
    [changeLineItemStatus.fulfilled]: (state, { payload }) => {
      state.isFetchingchangeLineItemStatus = false;
      state.isSuccesschangeLineItemStatus = true;
      state.isErrorchangeLineItemStatus = false;
    },
    [changeLineItemStatus.rejected]: (state, { payload }) => {
      state.isFetchingchangeLineItemStatus = false;
      state.isErrorchangeLineItemStatus = true;
      state.error = payload
    },
    [changeLineItemStatus.pending]: (state) => {
      state.isFetchingchangeLineItemStatus = true;
    },
    [updateLineItem.fulfilled]: (state, { payload }) => {
      state.isFetchingupdateLineItem = false;
      state.isSuccessupdateLineItem = true;
      state.isErrorupdateLineItem = false;
    },
    [updateLineItem.rejected]: (state, { payload }) => {
      state.isFetchingupdateLineItem = false;
      state.isErrorupdateLineItem = true;
      state.error = payload
    },
    [updateLineItem.pending]: (state) => {
      state.isFetchingupdateLineItem = true;
    },
  }
})
export const {
  clearState, clearInventories,
  clearFormats, clearOffers,
  clearCountries, clearStatus,
  clearfilterStatus, clearsaveLineItem,
  clearupdateLineItem
} = LineItemsSlice.actions;
export const LineItemsSelector = (state) => state.LineItems;
