import { TiArrowSortedUp, TiArrowSortedDown } from 'react-icons/ti';
// import { IoMdInformationCircleOutline } from "react-icons/io";
import { AiOutlineInfoCircle, AiOutlineExclamationCircle } from "react-icons/ai";
import i18n from '../../../i18n';

const SortableHeader = ({
    innerRef,
    draggableProps,
    dragHandleProps,
    field,
    label,
    sortConfig,
    onSort,
    headerLarge,
    infoIcon,
    infoIconMessage,
    exclamationIcon
  }) => {
  const icon = sortConfig.direction === 'asc' ?
      <>
        {' '}
        <TiArrowSortedUp />
      </>
    : <>
        {' '}
        <TiArrowSortedDown />
      </>;

  return (
    <th
      ref={innerRef}
      {...draggableProps}
      {...dragHandleProps}
      className={`thPerformanceReport thPerformanceReportHeader ${headerLarge ? 'thPerformanceReportLarge' : ''}`}
      onClick={() => onSort(field)}
    >
      <div className="divThPerformanceReport">
        {label}
        {icon && <span className="sort-icon">{icon}</span>}
        {infoIcon && <div className="divInfoIcon"><AiOutlineInfoCircle title={infoIconMessage} size={15} /></div>}
        {exclamationIcon && <div className="divExclamationIcon"><AiOutlineExclamationCircle title={i18n.advanceReportsViewsExclamationMessage} size={15} /></div>}
      </div>   
    </th>
  );
};

export default SortableHeader;