import { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import PerformanceReport from "./performanceReport/performanceReport";
import MyReports from "./performanceReport/myReports";
import i18n from "../../i18n";

const AdvanceReports = () => {
  const [audienceReportTab, setAudienceReportTab] = useState(false);
	const [performanceReportTab, setPerformanceReportTab] = useState(true);
  const [savedReportsTab, setSavedReportsTab] = useState(false);
  const [storedDataReportId, setStoredDataReportId] = useState(null);
  const [storedDataReportName, setStoredDataReportName] = useState(null);
  const [storedDataSelectedColumns, setStoredDataSelectedColumns] = useState(null);
  const [storedDataSelectedRows, setStoredDataSelectedRows] = useState(null);
  const [storedDataSelectedAdvertisers, setStoredDataSelectedAdvertisers] = useState(null);
  const [storedDataSelectedAdvertiserSaas, setStoredDataSelectedAdvertiserSaas] = useState(null);
  const [storedDataSelectedCampagins, setStoredDataSelectedCampaigns] = useState(null);
  const [storedDataSelectedCampaginSaas, setStoredDataSelectedCampaignSaas] = useState(null);
  const [storedDataSelectedBilling, setStoredDataSelectedBilling] = useState(null);
  const [storedDataSelectedInventoryType, setStoredDataSelectedInventoryType] = useState(null);
  const [storedDataSelectedLineitems, setStoredDataSelectedLineitems] = useState(null);
  const [storedDataSelectedLineitemSaas, setStoredDataSelectedLineitemSaas] = useState(null);
  const [storedDataSelectedMarket, setStoredDataSelectedMarkets] = useState(null);
  const [storedDataSelectedDateRange, setStoredDataSelectedDateRange] = useState(null);
  const [storedDataSelectedStartEndDate, setStoredDataSelectedStartEndDate] = useState(null);
  const [storedDataSelectedClients, setStoredDataSelectedClients] = useState(null);
  const [fromMyReports, setFromMyReports] = useState(false);

  useEffect(() => {
    setFromMyReports(false);
  }, [window.location.href]);

  const handleAudienceReport = () => {
		setAudienceReportTab(true);
		setPerformanceReportTab(false);
    setSavedReportsTab(false);
	}

  const handlePerformanceReport = () => {
    clearStoredData();
		setAudienceReportTab(false);
		setPerformanceReportTab(true);
    setSavedReportsTab(false);
	}

  const handleSavedReports = () => {
		setAudienceReportTab(false);
		setPerformanceReportTab(false);
    setSavedReportsTab(true);
	}

  const clearStoredData = () => {
    setStoredDataReportId(null);
    setStoredDataReportName(null);
    setStoredDataSelectedColumns(null);
    setStoredDataSelectedRows(null);
    setStoredDataSelectedAdvertisers(null);
    setStoredDataSelectedAdvertiserSaas(null);
    setStoredDataSelectedCampaigns(null);
    setStoredDataSelectedCampaignSaas(null);
    setStoredDataSelectedBilling(null);
    setStoredDataSelectedInventoryType(null);
    setStoredDataSelectedLineitems(null);
    setStoredDataSelectedLineitemSaas(null);
    setStoredDataSelectedClients(null);
    setStoredDataSelectedMarkets(null);
    setStoredDataSelectedDateRange(null);
  }

  const handleStoredReport = (item) => {
    item.id && setStoredDataReportId(item.id);
    item.name && setStoredDataReportName(item.name);
    const itemJson = JSON.parse(item.parameters);
    itemJson.selectedColumns && setStoredDataSelectedColumns(itemJson.selectedColumns);
    itemJson.selectedRows && setStoredDataSelectedRows(itemJson.selectedRows);
    itemJson.selectedAdvertisers && setStoredDataSelectedAdvertisers(itemJson.selectedAdvertisers);
    itemJson.selectedAdvertiserSaas && setStoredDataSelectedAdvertiserSaas(itemJson.selectedAdvertiserSaas);
    itemJson.selectedCampaigns && setStoredDataSelectedCampaigns(itemJson.selectedCampaigns);
    itemJson.selectedCampaignSaas && setStoredDataSelectedCampaignSaas(itemJson.selectedCampaignSaas);
    itemJson.selectedBilling && setStoredDataSelectedBilling(itemJson.selectedBilling);
    itemJson.selectedInventoryType && setStoredDataSelectedInventoryType(itemJson.selectedInventoryType);
    itemJson.selectedLineitems && setStoredDataSelectedLineitems(itemJson.selectedLineitems);
    itemJson.selectedLineitemSaas && setStoredDataSelectedLineitemSaas(itemJson.selectedLineitemSaas);
    itemJson.selectedMarkets && setStoredDataSelectedMarkets(itemJson.selectedMarkets);
    itemJson.selectedDateRange && setStoredDataSelectedDateRange(itemJson.selectedDateRange);
    itemJson.selectedStartEndDate && setStoredDataSelectedStartEndDate(itemJson.selectedStartEndDate);
    itemJson.selectedClients && setStoredDataSelectedClients(itemJson.selectedClients);
    setFromMyReports(true);

    setAudienceReportTab(false);
		setPerformanceReportTab(true);
    setSavedReportsTab(false);
  }

  return (
    <div className="content_outer">
      <div className="content pb-0">
        <div className="divSuperiorAdvanceReports">
            <div className="divContentAdvanceReports">
              <Row className="divAdvanceReportsTitles text-center">
                {/*<Col xs={4} onClick={handleAudienceReport}>
                  <div className={`display-none divAdvanceReportsTitlesBottom ${audienceReportTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>{i18n.audienceReport}</h4>
                  </div>
                </Col>*/}
                <Col xs={6} onClick={handlePerformanceReport}>
                  <div className={`divAdvanceReportsTitlesBottom ${performanceReportTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>{i18n.performanceReport}</h4>
                  </div>
                </Col>
                <Col xs={6} onClick={handleSavedReports}>
                  <div className={`divAdvanceReportsTitlesBottom ${savedReportsTab ? 'colReportBorderBottom' : ''}`}>
                    <h4>{i18n.myReports}</h4>
                  </div>
                </Col>
              </Row>
            </div>
            {performanceReportTab ? (
              (window.location.href.toLocaleLowerCase().includes('saas')) ?
              <PerformanceReport
                isSaas={true}
                fromMyReports={fromMyReports}
                storedDataReportId={storedDataReportId}
                storedDataReportName={storedDataReportName}
                storedDataSelectedColumns={storedDataSelectedColumns}
                storedDataSelectedRows={storedDataSelectedRows}
                storedDataSelectedAdvertisersSaas={storedDataSelectedAdvertiserSaas}
                storedDataSelectedCampaignsSaas={storedDataSelectedCampaginSaas}
                storedDataSelectedInventoryType={storedDataSelectedInventoryType}
                storedDataSelectedLineitemsSaas={storedDataSelectedLineitemSaas}
                storedDataSelectedClients={storedDataSelectedClients}
                storedDataSelectedDateRange={storedDataSelectedDateRange}
                storedDataSelectedStartEndDate={storedDataSelectedStartEndDate}
              /> :
              <PerformanceReport
                isSaas={false}
                fromMyReports={fromMyReports}
                storedDataReportId={storedDataReportId}
                storedDataReportName={storedDataReportName}
                storedDataSelectedColumns={storedDataSelectedColumns}
                storedDataSelectedRows={storedDataSelectedRows}
                storedDataSelectedAdvertisers={storedDataSelectedAdvertisers}
                storedDataSelectedCampaigns={storedDataSelectedCampagins}
                storedDataSelectedBilling={storedDataSelectedBilling}
                storedDataSelectedInventoryType={storedDataSelectedInventoryType}
                storedDataSelectedLineitems={storedDataSelectedLineitems}
                storedDataSelectedMarkets={storedDataSelectedMarket}
                storedDataSelectedDateRange={storedDataSelectedDateRange}
                storedDataSelectedStartEndDate={storedDataSelectedStartEndDate}
              />
            ) : savedReportsTab ? (
              <MyReports
                isSaas={window.location.href.toLocaleLowerCase().includes('saas')}
                handleStoredReport={handleStoredReport}
                handlePerformanceReport={handlePerformanceReport}
              />
            ) : (null)
          }
        </div>
      </div>
    </div>
  );
}

export default AdvanceReports;