import {createSlice , createAsyncThunk } from '@reduxjs/toolkit'
import axios from "axios";

export const index = createAsyncThunk(
    'reports/index',
    async({},thunkAPI) => {
        try{
            return await axios.get(`${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/index` ,{headers: { 
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + localStorage.getItem('token')
              }}).then( (response) => {
                return response.data;
              }).catch( (e) => {
                let error="";
                if (e.response) {
                    error=e.response.data;
                  } else if (e.request) {
                    error=e.request;
                  } else {
                    error=e.message;
                  }

                return thunkAPI.rejectWithValue(error)
                
              });
        }catch(error){
            return thunkAPI.rejectWithValue(error)
        }
    }
)

export const getmedia_Planner = createAsyncThunk(
  'reports/getmedia_Planner',
  async(data,thunkAPI) => {
    try{
        return await axios.post(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/media-planner` ,data,{headers: { 
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + localStorage.getItem('token')
          }}).then( (response) => {
            return response.data;
          }).catch( (e) => {
            let error="";
            if (e.response) {
                error=e.response.data;
              } else if (e.request) {
                error=e.request;
              } else {
                error=e.message;
              }

            return thunkAPI.rejectWithValue(error)
            
          });
    }catch(error){
        return thunkAPI.rejectWithValue(error)
    }
}
)

export const indexSlice = createSlice({
    name : 'index',
    initialState: {
        products : [],
        countries : [],
        ageRanges : [],
        mediaPlanner: [],
        error: '',
        isFetching : false,
        isError : false,
        isSuccess : false,
        isFetchinggetmediaPlanner : false,
        isErrorgetmediaPlanner : false,
        isSuccessgetmediaPlanner : false
        
    },
    reducers : {
        clearState: (state) =>{
            state.isLoggedIn= false;
            state.isFetching= false;
            state.isSuccess= false;
            state.isError = false;
            return state;
        },
        clearMeiaPlanner: (state)=>{
          state.isFetchinggetmediaPlanner= false;
          state.isErrorgetmediaPlanner= false;
          state.isSuccessgetmediaPlanner = false;
        }

    },
    extraReducers : {
      
        [index.fulfilled]: (state, { payload }) => ({
            ...state,
            products:payload.data.products,
            countries:payload.data.countries,
            ageRanges:payload.data.ageRanges,
            isFetching : false,
            isSuccess : true,
            showgraph: true,
            isLoggedIn : true,
            isError:false
          }),
          [index.rejected]: (state, { payload }) => ({
            ...state,
            isFetching:false,
            isError:true,
            error:payload
          }),
          [index.pending]: (state) => ({
            ...state,
            isFetching:true
          }),
          [getmedia_Planner.fulfilled]: (state, { payload }) => {
            state.mediaPlanner = payload.data;
            state.isFetchinggetmediaPlanner = false;
            state.isSuccessgetmediaPlanner = true;
            state.isErrorgetmediaPlanner = false;
          },
          [getmedia_Planner.rejected]: (state, { payload }) => {
            state.isFetchinggetmediaPlanner = false;
            state.isErrorgetmediaPlanner = true;
            state.error = payload
          },
          [getmedia_Planner.pending]: (state) => {
            state.isFetchinggetmediaPlanner = true;
          },
    }
});

export const {clearState, clearMeiaPlanner} = indexSlice.actions;
export const reportSelector = (state) => state.index;