import React, { useState, useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Table } from 'react-bootstrap'
import { MdOutlineChevronLeft } from "react-icons/md";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { admin_saas_service_fee_history } from '../../app/features/Admin_saas/service_fee_history_slice';
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import { clear_service_fee_history_state } from '../../app/features/Admin_saas/service_fee_history_slice';

const ClientServiceHistory = () => {
    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()

    const { adveriser_id, dealName, clientName } = location?.state ? location?.state : location;
    const [loadershown, setloadershown] = useState(true);

    const service_fee_history_data = useSelector((state) => { return state.admin_saas_service_fee_history })

    useEffect(() => {
        return () => {
            // Your cleanup code or function goes here
            dispatch(clear_service_fee_history_state())
        };

    }, [])

    useEffect(() => {
        dispatch(admin_saas_service_fee_history({ adveriser_id }))
    }, [adveriser_id])

    useEffect(() => {
        if (service_fee_history_data?.isSuccess) {
            setloadershown(false)
        }
        if (service_fee_history_data?.isError) {
            toast.error(service_fee_history_data?.error?.message)
            setloadershown(false)
        }

    }, [service_fee_history_data])

		const formatDate = (dateString) => {
			const date = new Date(dateString + 'T00:00:00'); // Asigna medianoche de la fecha
			const year = date.getUTCFullYear();
			const month = date.getUTCMonth() + 1; // getUTCMonth() retorna 0-11
			const day = date.getUTCDate();
			return `${day}/${month}/${year}`;
		};

    return (
        <div className="content_outer adminSaas">
            <Loader showLoader={loadershown} />
            <div className="cmn_header_top campaign__header mb-3">
                <Row className="m-0 w-100">
                    <Col lg={9} className="m-auto">
                        <h3> <MdOutlineChevronLeft size={22} onClick={() => navigate(-1)} />{dealName}</h3>
                    </Col>
                </Row>
            </div>
            <Row className="m-0 w-100">
                <Col lg={9} className="m-auto">
                    <h3 className="filter_title mt-4">{clientName} - History Service Fee</h3>
                    <div className='advert_tabel campign_index_table'>
                        <Table responsive className={(service_fee_history_data?.data?.data?.length === 0 || service_fee_history_data?.error != null) ? "no_data" : "fixed_tale"}>
                            <thead>
                                <tr>
                                    <th className='text-start'>Date</th>
                                    <th className='text-start'>Created By</th>
                                    <th className='text-start'>Service Fee</th>
                                </tr>
                            </thead>
                            <tbody>
                                {(service_fee_history_data?.data?.data?.length === 0 || service_fee_history_data?.error != null) && <img className="nodata" src="/assets/images/notfound.svg" />}
                                {service_fee_history_data?.data?.data?.length != 0 ?
                                    service_fee_history_data?.data?.data?.map((data, index) => {
                                        return (
                                            <tr key={index}>
                                                <td>{data?.date && formatDate(data?.date)}</td>
                                                <td>{data?.createdBy}</td>
                                                <td>{data?.serviceFee}</td>
                                            </tr>
                                        )
                                    })
                                    : ""
                                }

                            </tbody>
                        </Table>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default ClientServiceHistory