import React, { useState, useEffect, useRef } from 'react'
import { Row, Col } from 'react-bootstrap'
import Multiselect from "multiselect-react-dropdown"
import { useSelector, useDispatch } from "react-redux";
import DateRangePicker from "react-bootstrap-daterangepicker";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from "../Loader";
import { toast } from "react-hot-toast";
import { kite_states } from '../../app/features/kiteStates/kite_states_slice';
import {
	LineItemsSelector,
	getInventories,
	getOfferTypes,
	getLineitemFormats,
	getCountries,
	getGender,
	getAgeRange,
	getDevices,
	getPassionPoint,
	saveLineItem,
	clearsaveLineItem,
	updateLineItem,
	clearupdateLineItem,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import { clear_kite_states_state } from '../../app/features/kiteStates/kite_states_slice';
import { AST_Null } from 'terser';
const AddLineItems = (props) => {
	const dispatch = useDispatch();
	const multiselectRef = useRef()
	const multiselectRef_states = useRef()
	const [lineitem_inventory_name, setLineitem_inventory_name] = useState("")
	const [lineitem_inventory_type_id, setLineitem_inventory_type_id] = useState(null)
	const [lineitem_format, setLineitem_format] = useState(null)
	const [lineitem_offer_type, setLineitem_offer_type] = useState(null)
	const [lineitem_start_date, setLineitem_start_date] = useState("")
	const [lineitem_end_date, setLineitem_end_date] = useState("")
	const [min_lineitem_date, setMin_lineitem_date] = useState("")
	const [max_lineitem_date, setMax_lineitem_date] = useState("")
	const [lineitem_budget, setLineitem_budget] = useState(0)
	const [lineitem_sell_rate, setLineitem_sell_rate] = useState(0)
	const [lineitem_margin, setLineitem_margin] = useState(0)
	const [lineitem_net_rate, setLineitem_net_rate] = useState(0)
	const [lineitem_budget_usd, setLineitem_budget_usd] = useState(0)
	const [lineitem_sell_rate_usd, setLineitem_sell_rate_usd] = useState(0)
	const [lineitem_net_rate_usd, setLineitem_net_rate_usd] = useState(0)
	const [lineitem_goal, setLineitem_goal] = useState(0)
	const [lineitem_selected_country_id, setLineitem_selected_country_id] = useState([])
	const [lineitem_selected_devices_id, setLineitem_selected_devices_id] = useState([])
	const [lineitem_gender, setLineitem_gender] = useState(null)
	const [lineitem_selected_age_id, setLineitem_selected_age_id] = useState([])
	const [lineitem_selected_passion_point_id, setLineitem_selected_passion_point_id] = useState([])
	const [lineitem_is_bonified, setLineitem_is_bonified] = useState(false)
	const [lineitem_is_reach, setLineitem_is_reach] = useState(false)
	const [loadershown, setloadershown] = useState(false);
	const [age_group, setAge_group] = useState([])
	const [valueInventory, setValueInventory] = useState(false)
	const [lineitem_pacing, setLineitem_pacing] = useState([])
	const [lineitem_pacing_rate, setLineitem_pacing_rate] = useState([])
	const [selected_pacing, setSelected_pacing] = useState(null)
	const [selected_pacing_rate, setSelected_pacing_rate] = useState(null)
	const [whiteListFile, setWhiteListFile] = useState('');
	const [blackListFile, setBlackListFile] = useState('');
	const [states, setStates] = useState([])
	const [selected_lineitem_name, setSelected_lineitem_name] = useState(null)
	const [selectedStates, setSelectedStates] = useState([]);


	const permission = JSON.parse(localStorage.getItem('userdata'));
	//    this below function is used to get all the inventory types and all other countries and devices and passion points nitin 12/09/2023
	const {
		inventories,
		line_item_formats,
		offer_types,
		countries,
		genders,
		age_range,
		devices,
		passion_points,
		error,
		isSuccesssaveLineItem,
		isErrorsaveLineItem,
		inserted_lineitem_id,
		isSuccessupdateLineItem,
		isErrorupdateLineItem,
	} = useSelector(LineItemsSelector);

	const all_states = useSelector((state) => { return state.kitestates })

	useEffect(() => {
		if (all_states?.isSuccess) {
			setStates(all_states?.data?.data[0])
		}
	}, [all_states])

	useEffect(() => {
		if (inventories.length == 0) {
			dispatch(getInventories({ token: localStorage.getItem("token") }));
		}
		if (line_item_formats.length == 0) {
			dispatch(getLineitemFormats({ token: localStorage.getItem("token") }));
		}
		if (offer_types.length == 0) {
			dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
		}

		if (countries.length == 0) {
			dispatch(getCountries({ token: localStorage.getItem("token") }));
		}
		if (genders.length == 0) {
			dispatch(getGender({ token: localStorage.getItem("token") }));
		}
		dispatch(getDevices({ token: localStorage.getItem("token") }));
		dispatch(getPassionPoint({ token: localStorage.getItem("token") }));
		dispatch(getAgeRange({ token: localStorage.getItem("token") }))

		setMin_lineitem_date(new Date(props?.start_date) < new Date() ? new Date() : new Date(props?.start_date));
		setMax_lineitem_date(new Date(props?.end_date));
	}, [])

	// this below function is used to get the details of net rate and goal etc. nitin 12/09/2023

	const getFormaulaData = async () => {
		var myHeaders = new Headers();
		myHeaders.append("Content-Type", "application/json");
		myHeaders.append(
			"Authorization",
			"Bearer " + localStorage.getItem("token")
		);

		var raw = JSON.stringify({
			deal_id: props?.dealid,
			inversion_sell_rate: lineitem_sell_rate ? lineitem_sell_rate : "",
			inversion_budget: lineitem_budget ? lineitem_budget : "",
			inversion_offer_type_id: lineitem_offer_type ? lineitem_offer_type : "",
		});

		var requestOptions = {
			method: "POST",
			headers: myHeaders,
			body: raw,
			redirect: "follow",
		};

		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/get_Lineitem_formulas`,
			requestOptions
		);
		const result = await response.json();
		// setFormulaData(result);
		setLineitem_budget_usd(result?.data?.inversion_budget_usd);
		setLineitem_net_rate(result?.data?.inversion_netrate);
		setLineitem_net_rate_usd(result?.data?.inversion_netRate_usd);
		setLineitem_sell_rate_usd(result?.data?.inversion_sellRate_usd);
		setLineitem_goal(result?.data?.inversion_goal);
	};

	//this below funcion is used to set the multiple countries this is trigger from the multiselect country field nitin 12/09/2023
	const handle_multiselect_countries = (e) => {
		var allSelectedCountries = e.map((val) => val.id).filter(Boolean);
		setLineitem_selected_country_id(allSelectedCountries);
	}
	const handle_multiselect_states = (e) => {
		var allSelectedStatesComplete = e.map((val) => ({ id: val.id, name: val.name, countryId: val.countryId })).filter(Boolean);
		setSelectedStates(allSelectedStatesComplete);
	}

	//this below function is used to set the multiple ages this is trigger from the multiselect age range field nitin 12/09/2023
	const handle_multiselect_age_range = (e) => {
		var allSelectedAges = e.map((val) => val.id).filter(Boolean);
		setLineitem_selected_age_id(allSelectedAges);
	}

	//this below function is used to set the multiple devices this is trigger from the devices field nitin 12/09/2023
	const handle_multiselect_devices = (e) => {
		var allSelectedDevices = e.map((val) => val.id).filter(Boolean);
		setLineitem_selected_devices_id(allSelectedDevices);
	}

	//this function is used to set the multiple passion points this is trigger from the passion points fields nitin 12/09/2023
	const handle_multiselect_passion_points = (e) => {
		var allSelectedPassionPoints = e.map((val) => val.id).filter(Boolean);
		setLineitem_selected_passion_point_id(allSelectedPassionPoints);
	}


	//    this below function is uded to remove selected countries from thw main array nitin 18/09/2023
	const handle_multiremove_country = (e) => {
		var allSelectedCountries = e.map((val) => val.id);
		setLineitem_selected_country_id(allSelectedCountries);
		if (selectedStates.length > 0){
			var allSelectedStates = selectedStates.filter((val) => allSelectedCountries.includes(val.countryId));
			setSelectedStates(allSelectedStates);
		}
	}
	const handle_multiremove_states = (e) => {
		var allSelectedStatesComplete = e.map((val) => ({
      id: val.id,
      name: val.name,
      countryId: val.countryId
    })).filter(Boolean);
		setSelectedStates(allSelectedStatesComplete);
	}

	//    this below function is uded to remove selected devices from thw main array nitin 18/09/2023
	const handle_multiremove_device = (e) => {
		var allSelectedDevices = e.map((val) => val.id);
		setLineitem_selected_devices_id(allSelectedDevices);
	}

	//    this below function is uded to remove selected ages from thw main array nitin 18/09/2023
	const handle_multiremove_age = (e) => {
		var allSelectedAges = e.map((val) => val.id);
		setLineitem_selected_age_id(allSelectedAges);
	}

	//    this below function is uded to remove selected passion points from thw main array nitin 18/09/2023
	const handle_multiremove_passion_points = (e) => {
		var allSelectedPassionPoints = e.map((val) => val.id);
		setLineitem_selected_passion_point_id(allSelectedPassionPoints);
	}

	function fileToBase64(file) {
		return new Promise((resolve, _) => {
			const reader = new FileReader();
			reader.onloadend = () => resolve(reader.result);
			reader.readAsDataURL(file);
		});
	}

	useEffect(() => {
		if (lineitem_selected_country_id?.length > 0) {
			dispatch(kite_states({ CountryId: lineitem_selected_country_id }))
		}

	}, [lineitem_selected_country_id])

	//this below function is used to save new lineitem nitin 12/09/2023
	const handle_save_lineitem = async () => {
		if (lineitem_inventory_name === "") {
			toast.error("Lineitem name can't be empty")
		} else if (lineitem_inventory_type_id === null || lineitem_inventory_type_id == 0) {
			toast.error("Please select Inventory type")
		} else if (lineitem_format === null || lineitem_format == 0) {
			toast.error("Please select Format")
		} else if (lineitem_offer_type === null || lineitem_offer_type == 0) {
			toast.error("Please select Offer type")	
		} else if (lineitem_start_date === "") {
			toast.error("Please select Start date")
		} else if (lineitem_end_date === "") {
			toast.error("Please select End date")
		} else if (selected_pacing === 0 || selected_pacing === '' || selected_pacing === null) {
			toast.error("Please select Pacing")
		} else if (selected_pacing_rate === 0 || selected_pacing_rate === '' || selected_pacing_rate === null) {
			toast.error("Please select Pacing rate")
		} else if (lineitem_budget <= 0 || lineitem_budget === null || lineitem_budget === "") {
			toast.error("Budget field must be greater than 0")
		} else if (!lineitem_is_bonified && lineitem_budget > props?.budget_validate) {
			toast.error(`Budget field can't be greater than ${props?.budget_validate}`)
		} else if (lineitem_sell_rate_usd <= 0) {
			toast.error('The Sell Rate USD must be higher than 0 usd')
		} else if (lineitem_sell_rate === null) {
			toast.error("Sell rate can't be empty")
		} else if (lineitem_margin <= 0) {
			toast.error("Please select Margin")
		} else if (lineitem_selected_country_id.length === 0) {
			toast.error("Please select at least one Country")
		} else if (lineitem_selected_devices_id.length === 0) {
			toast.error("Please select at least one Device")
		} else if (lineitem_gender === null || lineitem_gender == 0) {
			toast.error("Please select Gender")
		} else if (lineitem_selected_age_id.length === 0) {
			toast.error("Please select at least one Age group")
		} else {

		if(selectedStates.length > 0 &&
			!(lineitem_selected_country_id.includes(4) || 
			lineitem_selected_country_id.includes(12) ||
			lineitem_selected_country_id.includes(21))) {
			setSelectedStates([]);
		}
			setloadershown(true);
			var myHeaders = new Headers();
			myHeaders.append("Content-Type", "application/json");
			myHeaders.append(
				"Authorization",
				"Bearer " + localStorage.getItem("token")
			);

			let dateFrom = new Date(lineitem_start_date);
			dateFrom.setHours(0,0,0,0);

			let dateTo = new Date(lineitem_end_date);
			dateTo.setHours(0,0,0,0);

			var raw = JSON.stringify({
				name: lineitem_inventory_name,
				deal_id: props?.dealid,
				campaign_id: props?.campaignId,
				inventory_type_id: lineitem_inventory_type_id,
				format_id: lineitem_format,
				date_from: dateFrom,
				date_to: dateTo,
				inversion_budget: typeof lineitem_budget === 'number' ? lineitem_budget.toFixed(2) : lineitem_budget,
				inversion_sell_rate: typeof lineitem_sell_rate === 'number' ? lineitem_sell_rate.toFixed(4) : lineitem_sell_rate,
				inversion_offer_type_id: lineitem_offer_type * 1,
				inversion_goal: typeof lineitem_goal === 'number' ? lineitem_goal.toFixed(0) : lineitem_goal,
				target_country_id: lineitem_selected_country_id,
				target_state_id: (selectedStates.length > 0 && !(lineitem_selected_country_id.includes(4) || lineitem_selected_country_id.includes(12) || lineitem_selected_country_id.includes(21))) ? [] : selectedStates.map(state => state.id),
				inversion_netMargin: Object.values(permission.Permissions).includes('demoData') ? 0.50 : (typeof lineitem_margin === 'number' ? lineitem_margin.toFixed(0) : lineitem_margin),
				target_gender_id: lineitem_gender,
				inversion_budget_usd: typeof lineitem_budget_usd === 'number' ? lineitem_budget_usd.toFixed(2) : lineitem_budget_usd,
				inversion_netRate_usd: typeof lineitem_net_rate_usd === 'number' ? lineitem_net_rate_usd.toFixed(4) : lineitem_net_rate_usd,
				inversion_sellRate_usd: typeof lineitem_sell_rate_usd === 'number' ? lineitem_sell_rate_usd.toFixed(4) : lineitem_sell_rate_usd,
				inversion_netRate: typeof lineitem_net_rate === 'number' ? lineitem_net_rate.toFixed(4) : lineitem_net_rate,
				lineitem_devices: lineitem_selected_devices_id,
				lineitem_passion_points: lineitem_selected_passion_point_id,
				audiences: lineitem_selected_age_id,
				is_bonificated: lineitem_is_bonified,
				pacing_id: selected_pacing ? selected_pacing * 1 : null,
				pacing_rate: selected_pacing_rate ? selected_pacing_rate * 1 : null,
				is_reach: lineitem_is_reach ? 1 : 0,
				whiteListFile: whiteListFile ? await fileToBase64(whiteListFile) : null,
				blackListFile: blackListFile ? await fileToBase64(blackListFile) : null,
			});


			var requestOptions = {
				method: "POST",
				headers: myHeaders,
				body: raw,
				redirect: "follow",
			};

			const response = await fetch(
				`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/store-lineitem`,
				requestOptions
			);
			const result = await response.json()
			if (result) {
				props.setEditLineItemModal(false);
				props.onRefresh();
				props.getcampain();
				props?.getData()
				setloadershown(false);
				// saveCsv(result?.data, values?.white_list,)
			} else {
				toast.error("Some internal error occured");
				setloadershown(false);
			}
		}
	}

	// this below function is used to select the dates nitin 12/09/2023
	const handleCallback = (start, end) => {
		setLineitem_start_date(start.format("YYYY-MM-DD"))
		setLineitem_end_date(end.format("YYYY-MM-DD"))
	}

	//this below function is used to remove id 1 from the age group nitin 12/09/2023
	useEffect(() => {
		if (age_range) {
			let ages = []
			age_range?.map((data) => {
				if (data?.id !== 1) {
					ages.push({
						id: data?.id,
						age: data?.age
					})
				}
				setAge_group(ages)

			})
		}
	}, [age_range])

	// this below function is used to set the country fiels for single country and multiple nitin 13/09/2023
	useEffect(() => {
		if (lineitem_inventory_type_id == 4) {
			setValueInventory(true)
		} else {
			setValueInventory(false)
		}
	}, [lineitem_inventory_type_id])


	useEffect(() => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacing`, requestOptions)
			.then(response => response.json())
			.then(result => { setLineitem_pacing(result?.data) })
			.catch(error => toast.error(error));
	}, [])

	useEffect(() => {
		var myHeaders = new Headers();
		myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

		var requestOptions = {
			method: 'GET',
			headers: myHeaders,
			redirect: 'follow'
		};

		fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/getpacingrate`, requestOptions)
			.then(response => response.json())
			.then(result => { setLineitem_pacing_rate(result?.data) })
			.catch(error => toast.error(error));
	}, [])

	const handle_save_single_country = (e) => {
		if (lineitem_selected_country_id?.length > 0) {
			setLineitem_selected_country_id([])
		}
		let val = []
		val.push(e * 1)
		setLineitem_selected_country_id(val)
	}

	const clear_selected_states = () => {
		multiselectRef.current.resetSelectedValues()
		multiselectRef_states.current.resetSelectedValues()
		setSelectedStates([]);
		setLineitem_selected_country_id([]);
	}


	return (
		<div className='content pt-0'>
			<Loader showLoader={loadershown} />
			<label className="lable_title">Details</label>
			<div className="mb-3 row">
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="lignitemname">Line item Name*</label>
						<input type="text" className="form-control"
							name="name" placeholder="LineItem name"
							value={lineitem_inventory_name}
							onChange={(e) => setLineitem_inventory_name(e.target.value)}
						/>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="inventorytype">Inventory Type</label>
						<select name="inventory_type_id" className="form-select get_inventory_name"
							onChange={(e) => { setLineitem_inventory_type_id(e.target.value); clear_selected_states() }}
							value={lineitem_inventory_type_id}
						>
							<option value={0}>--Select--</option>
							{
								inventories?.map((all_inventory, index) => {
									if (all_inventory?.id === 2 || all_inventory?.id === 6) { return null }
									return (
										<option value={all_inventory?.id} key={index}>{all_inventory?.description}</option>
									)
								})
							}
						</select>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="format">Format</label>
						<select name="format_id" className="form-select"
							value={lineitem_format}
							onChange={(e) => setLineitem_format(e.target.value)}
						>
							<option value={0}>--Select--</option>
							{line_item_formats
								.filter((format) => {
									// Filter formats based on the selected inventory type (lineitem_inventory)
									return (
										lineitem_inventory_type_id === '' || // Show all formats when nothing is selected
										(lineitem_inventory_type_id == '1' && format.id <= 9) || // Show certain formats for inventory type 1
										(lineitem_inventory_type_id == '2' && format.id === 11) || // Show certain formats for inventory type 2
										(lineitem_inventory_type_id == '3' && format.id === 11) || // Show certain formats for inventory type 3
										(lineitem_inventory_type_id == '4' && (format.id === 12 || format.id === 13 || format.id === 18 || (permission.role==1 && format.id === 20))) || // Show certain formats for inventory type 4
										(lineitem_inventory_type_id == '5' && (format.id === 14 || format.id === 15 || format.id === 16 || format.id === 17 || format.id === 19)) // Show certain formats for inventory type 5
										// (lineitem_inventory_type_id === 6 &&  '') // Show certain formats for inventory type 6
									);
								})
								.map((selected_format) => {
									return (
										<option value={selected_format.id} key={selected_format.id}>
											{selected_format.name}
										</option>
									);
								})}

						</select>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="ofertype">Offer Type</label>
						<select name="inversion_offer_type_id" className="form-control"
							value={lineitem_offer_type}
							onChange={(e) => setLineitem_offer_type(e.target.value)}
						>
							<option value={0}>--Select--</option>
							{lineitem_format == 12
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 5) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 13
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 6 || offr.id == 5) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 3 ||
								lineitem_format == 4 ||
								lineitem_format == 5 ||
								lineitem_format == 6 ||
								lineitem_format == 7 ||
								lineitem_format == 8 ||
								lineitem_format == 9 ||
								lineitem_format == 20
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 5) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 2 ||
								lineitem_format == 1 ||
								lineitem_format == 10 ||
								lineitem_format == 11
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 5 || offr.id == 7) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 14 ||
								lineitem_format == 15
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 5) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 16
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 8) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 18
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 5) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 17
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 9) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
							{lineitem_format == 19
								? offer_types?.map((offr, offr_index) => {
									if (offr.id == 10) {
										return (
											<option value={offr?.id} key={offr_index}>
												{offr?.descripcion}
											</option>
										);
									}
								})
								: null}
						</select>
					</div>
				</div>
			</div>
			{/*  */}
			<label className="lable_title">Pricing and budget
			</label>
			<div className="mb-3 row">
				<div className="col-lg-3">
					<div className="form-group mb-3 select_country_container cmn_modal_field"><label className="lbl_position" for="startdate">Start Date</label>
						<DatePicker
						 	portalId="root-portal"
							className="form-control"
							dateFormat="dd/MM/yyyy"
							minDate={min_lineitem_date}
							maxDate={max_lineitem_date}
							value={lineitem_start_date}
							onChange={date =>
								lineitem_end_date == '' || date < lineitem_end_date ?
									setLineitem_start_date(date) :
									(toast.error("Start date must be before end date"))
							}
							selected={lineitem_start_date}
						/>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group mb-3 select_country_container cmn_modal_field"><label className="lbl_position" for="startdate">End Date</label>
						<DatePicker
						 	portalId="root-portal"
							className="form-control"
							dateFormat="dd/MM/yyyy"
							minDate={min_lineitem_date}
							maxDate={max_lineitem_date}
							value={lineitem_end_date}
							onChange={date =>
								date > lineitem_start_date ?
									(setLineitem_end_date(date)) :
									(toast.error("End date must be after start date"))
							}
							selected={lineitem_end_date}
						/>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="inventorytype">Pacing</label>
						<select name="format_id" className="form-select"
							value={selected_pacing}
							onChange={(e) => setSelected_pacing(e.target.value)}
						>
							<option value="">--Select--</option>
							{lineitem_pacing?.map((data, index) => {
								return (
									<option key={index} value={data?.id}>{data?.description}</option>
								)
							})}
						</select>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="inventorytype">Pacing Rate</label>
						<select name="format_id" className="form-select"
							value={selected_pacing_rate}
							onChange={(e) => setSelected_pacing_rate(e.target.value)}
						>
							<option value="">--Select--</option>
							{lineitem_pacing_rate?.map((data, index) => {
								return (
									<option key={index} value={data?.id}>{data?.description}</option>
								)
							})}
						</select>
					</div>
				</div>
			</div>
			<div className="mb-3 row">
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="enddate">
							Budget ({props?.dealCurrency}) (
							{!lineitem_is_bonified && <p className="inline">Must be between   0 and {props?.budget_validate}</p>}
							)
						</label>
						<input type="number" name="inversion_budget"
							className="form-control" placeholder="Budget"
							value={lineitem_budget}
							onChange={(e) => setLineitem_budget(e.target.value)}
							min={0}
							max={!lineitem_is_bonified ? props?.budget_validate : null}
						/>
					</div>
				</div>
				<div className="col-lg-3">
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="sellrate">Sell Rate ({props?.dealCurrency})</label>
						<input type="number" name="inversion_sell_rate"
							className="form-control" placeholder="Sell Rate"
							value={lineitem_sell_rate}
							onChange={(e) => setLineitem_sell_rate(e.target.value)}
							onBlur={getFormaulaData}
						/>

					</div>
				</div>
				<div className="col-lg-3">
					{!Object.values(permission.Permissions).includes('demoData') && <div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="netmargin">Net Margin </label>
						<select name="inversion_netMargin" className="form-control"
							value={Object.values(permission.Permissions).includes('demoData') ? "50" : lineitem_margin}
							onChange={(e) => setLineitem_margin(e.target.value)}
							disabled={Object.values(permission.Permissions).includes('demoData')}
						>
							<option value="">--Select--</option>
							<option value={5}>5%</option>
							<option value={10}>10%</option>
							<option value={15}>15%</option>
							<option value={20}>20%</option>
							<option value={25}>25%</option>
							<option value={30}>30%</option>
							<option value={35}>35%</option>
							<option value={40}>40%</option>
							<option value={45}>45%</option>
							<option value={50}>50%</option>
							<option value={55}>55%</option>
							<option value={60}>60%</option>
							<option value={65}>65%</option>
							<option value={70}>70%</option>
							<option value={75}>75%</option>
							<option value={80}>80%</option>
							<option value={85}>85%</option>
							<option value={90}>90%</option>
						</select>
					</div>}
				</div>
			</div>
			{/*  */}
			<div className="col-lg-12">
				<div className="rates_list mt-3 mb-1">
					{!Object.values(permission.Permissions).includes('demoData') && <div className="form-group mb-2">
						<div className='d-flex'><label className="mb-2" for="netrate">Net Rate ({props?.dealCurrency}): </label><span>{lineitem_net_rate?.toLocaleString(undefined, {
							minimumFractionDigits: 2,
						})}</span></div>
					</div>}
					<div className="form-group mb-2"><label className=" " for="netrate">Budget USD: </label><span>{lineitem_budget_usd?.toLocaleString(undefined, {
						minimumFractionDigits: 2,
					})}</span></div>
					<div className="form-group mb-2"><label className=" " for="netrate">Sell Rate USD: </label><span>{lineitem_sell_rate_usd?.toLocaleString(undefined, {
						minimumFractionDigits: 4,
					})}</span></div>
					{!Object.values(permission.Permissions).includes('demoData') && <div className="form-group  mb-2"><label className=" " for="netrate">Net Rate USD: </label><span>{lineitem_net_rate_usd?.toLocaleString(undefined, {
						minimumFractionDigits: 4,
					})}</span></div>}
					<div className="form-group  mb-2"><label className=" " for="goal">Goal: </label><span>{lineitem_goal?.toLocaleString()}</span></div>
				</div>
			</div>
			<label className="lable_title">Targeting</label>
			<Row className="mb-3">
				{valueInventory ? <Col lg={4}>
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " htmlFor="country">
							Country
						</label>
						<select name="inventory_type_id" className="form-select get_inventory_name"
							onChange={(e) => { handle_save_single_country(e.target.value); setSelected_lineitem_name(e.target.value) }}
							value={selected_lineitem_name}
						>
							<option value="">--Select--</option>
							{
								countries?.map((all_inventory, index) => {
									return (
										<option value={all_inventory?.id} key={index}>{all_inventory?.labelKey}</option>
									)
								})
							}
						</select>
					</div>
				</Col> :
					<Col lg={4}>
						<div className="form-group select_country_container cmn_modal_field mb-3">
							<label className="lbl_position " htmlFor="country">
								Country
							</label>
							<Multiselect
								options={countries.map((i) => {
									const name = i?.labelKey ?? "Unknown";
									return { ...i, name };
								})}
								onSelect={(e) => { handle_multiselect_countries(e) }}
								onRemove={(e) => handle_multiremove_country(e)}
								className="react-datepicker-popper"
								displayValue="name"
								ref={multiselectRef}
							/>
						</div>
					</Col>
				}
				{lineitem_selected_country_id?.length > 0 && (lineitem_selected_country_id?.includes(4) || lineitem_selected_country_id?.includes(12) || lineitem_selected_country_id?.includes(21)) &&
				<Col lg={4}>
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " htmlFor="country">
							States
						</label>
						<Multiselect
							options={states?.map((i) => {
								const name = i?.value ?? "Unknown";
								return { id: i.id, name, countryId: i.countryId };
							})}
							selectedValues={selectedStates}
							onSelect={(e) => handle_multiselect_states(e)}
							onRemove={(e) => handle_multiremove_states(e)}
							className="react-datepicker-popper"
							displayValue="name"
							ref={multiselectRef_states}
						/>
					</div>
				</Col>}
				<Col lg={4}>
					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " htmlFor="country">
							Devices
						</label>

						<Multiselect
							options={devices.map((i) => {
								const name = i?.name ?? "Unknown";
								return { ...i, name };
							})}
							// selectedValues={selectedValue}
							onSelect={(e) => handle_multiselect_devices(e)}
							onRemove={(e) => handle_multiremove_device(e)}
							displayValue="name"
						/>
					</div>
				</Col>
			</Row>
			<label className="lable_title">Audience
			</label>
			<Row>

				<Col lg={4}>

					<div className="form-group select_country_container cmn_modal_field mb-3">
						<label className="lbl_position " for="netmargin">Gender </label>
						<select name="inversion_netMargin" className="form-control"
							value={lineitem_gender}
							onChange={(e) => setLineitem_gender(e.target.value)}
						>
							<option value={0}>--Select--</option>
							{
								genders?.map((all_gender, index) => {
									return (
										<option value={all_gender?.id} key={index}>{all_gender?.description}</option>
									)
								})
							}
						</select>
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
						<label className="lbl_position " htmlFor="country">
							Age
						</label>

						<Multiselect
							options={age_group.map((i) => {
								const name = i?.age ?? "Unknown";
								return { ...i, name };
							})}
							// selectedValues={selectedValue}
							onSelect={(e) => { handle_multiselect_age_range(e) }}
							onRemove={(e) => handle_multiremove_age(e)}

							displayValue="name"
						/>
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-group select_country_container cmn_modal_field mb-3 multiselect_bottom">
						<label className="lbl_position " htmlFor="country">
							Passion Point
						</label>

						<Multiselect
							options={passion_points.map((i) => {
								const name = i?.name ?? "Unknown";
								return { ...i, name };
							})}
							// selectedValues={selectedValue}
							onSelect={(e) => handle_multiselect_passion_points(e)}
							onRemove={(e) => handle_multiremove_passion_points(e)}
							displayValue="name"
						/>
					</div>
				</Col>
			</Row>
			<Row className="mb-3 whitelistTop">
				<Col lg={4}>
					<div className="form-check mt-2">
						<input className="form-check-input" type="checkbox" id="flexCheckDefault" checked={lineitem_is_bonified} onChange={() => setLineitem_is_bonified(!lineitem_is_bonified)} />
						<label className="form-check-label" for="flexCheckDefault">Is bonified</label>
					</div>
				</Col>
				<Col lg={4}>
					<div className="form-check mt-2">
						<input className="form-check-input" type="checkbox" id="isReach" checked={lineitem_is_reach} onChange={() => setLineitem_is_reach(!lineitem_is_reach)} />
						<label className="form-check-label" for="flexCheckDefault">Reach</label>
					</div>
				</Col>
			</Row>
			<Row className="mb-3 whitelistTop">
				<Col lg={6}>
					<label className="lable_title">Whitelist (xls, xlsx)</label>
					<div className="boxLineItemFile">
						<label className="lable_title labelFileTitle">
							{whiteListFile ? whiteListFile.name : 'File'}
						</label>
						<input
							id="fileWhiteList"
							type="file"
							name="file"
							style={{ display: 'none' }}
							onChange={(e) => {
								const fileExtension = e.currentTarget.files[0].name.split('.').pop();
								if (fileExtension === 'xls' || fileExtension === 'xlsx') {
									setWhiteListFile(e.currentTarget.files[0])
								} else {
									setWhiteListFile(null);
									toast.error("File extension must be xls or xlsx");
								}
							}}
						/>
						<label className="labelFile" htmlFor="fileWhiteList">Choose File</label>
					</div>
				</Col>
				<Col lg={6}>
					<label className="lable_title">Blacklist (xls, xlsx)</label>
					<div className="boxLineItemFile">
						<label className="lable_title labelFileTitle">
							{blackListFile ? blackListFile.name : 'File'}
						</label>
						<input
							id="fileBlackList"
							type="file"
							name="file"
							style={{ display: 'none' }}
							onChange={(e) => {
								const fileExtension = e.currentTarget.files[0].name.split('.').pop();
								if (fileExtension === 'xls' || fileExtension === 'xlsx') {
									(setBlackListFile(e.currentTarget.files[0]));
								} else {
									setBlackListFile(null);
									toast.error("File extension must be xls or xlsx");
								}
							}}
						/>
						<label className="labelFile" htmlFor="fileBlackList">Choose File</label>
					</div>
				</Col>
			</Row>
			<div className="d-flex justify-content-end mt-4">
				<button type="button" className="me-4 ms-auto cancel_cm_btn" onClick={() => props.setEditLineItemModal(false)}>Cancel</button>
				<button className="cmn_btn ms-2 " type="submit" onClick={() => handle_save_lineitem()}>Save</button>
			</div>
		</div >
	)
}

export default AddLineItems