import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const change_user_password = createAsyncThunk("change_user_password", async ({token,new_password }, thunkAPI) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
        "token": token,
        "new_password":new_password
    });

    var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
    };
    try {
        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}Auth/passwordrecovery/changepassword`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }

})

export const changeUserPassword = createSlice({
    name: "changeUserPassword",
    initialState: {
        data: [],
        isSuccess: false,
        isError: false,
        loading: false,
        error: null
    },
    reducers: {
        clear_login_state: (state) => {
            state.data = [];
            state.loading = false;
            state.isSuccess = false;
            state.isError = false;
            state.error = null;
            return state;
        }

    },
    extraReducers: (builder) => {
        builder.addCase(change_user_password.pending, (state) => {
            state.loading = true
        })
            .addCase(change_user_password.fulfilled, (state, action) => {
                state.data = action.payload
                state.isSuccess = true
                state.isError = false
                state.loading = false
            })
            .addCase(change_user_password.rejected, (state, action) => {
                state.isSuccess = false
                state.isError = true
                state.loading = false
                state.error = action.payload
            })

    }


})
export const { clear_login_state } = changeUserPassword.actions;
export default changeUserPassword.reducer