import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import {
  kite_products,
  campaignManagerSelector,
  storeProduct,
  clearState,
  clearcampaignState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import { toast } from "react-hot-toast";
import {
  ExchangeRates,
  deligateSelector,
} from "../../app/features/CampaignManager/DelegateSlice";
import Loader from "../Loader";
import Moment from "react-moment";
import {
  LineItemsSelector,
  getCampaign
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import { format } from 'date-fns';

const NewCompaign = (props) => {
  const formikRef = React.useRef();
  const dispatch = useDispatch();
  const [budget_usd1, SetUSDBudget] = useState(0);
  const {
    kiteProducts,
    isCampaignSaved,
    isCampaignupdated,
    isFetching,
    isSuccess,
    isError,
    error,
  } = useSelector(campaignManagerSelector);
  const { dealCurrencies } = useSelector(deligateSelector);
  const [show_create_product, setShowCreateProduct] = useState(false);
  const [product_name, setProductName] = useState("");
  const [show_message, setShow_message] = useState(false);
  const navigate = useNavigate();
  const [check, setCheck] = useState(false);
  const [budget, setBudget] = useState();
  const [total_sum_budget, setTotal_sum_budget] = useState("");
  const [err, SetError] = useState(false);
  const [reload, setReload] = useState(false);
  const [reload_check, setReload_check] = useState(false);
  const [loadershown, setloadershown] = useState(false);
  const {
    filtered_items,
    campaign_detail,
    inserted_lineitem_id,
    isSuccesschangeLineItemStatus
  } = useSelector(LineItemsSelector);

  const initialValues = {
    name: "",
    kite_deal_id: props.deal_id,
    date_from: "",
    date_to: "",
    budge: "",
    product_id: "",
    plateform_campaign_id: "",
    status_id: 2,
    dateRange: "",
    budget_usd: "",
    token: localStorage.getItem("token"),
  };

  useEffect(() => {
    dispatch(
      kite_products({
        token: localStorage.getItem("token"),
        clientId: props.clientId,
      })
    );
  }, [campaign_detail?.id]);

  useEffect(() => {
    dispatch(
      getCampaign({
        token: localStorage.getItem("token"),
        campaignId: props?.editCampaignId,
      })
    );
  }, [])

  useEffect(() => {
    if (reload) {
      setTimeout(() => {
        props.setModalShow(false);
        props.getData();
        props.searchFilter();
        toast.success("Campaign Saved Successfully");
      }, 2000);
    }
    dispatch(clearState());
  }, [reload]);

  useEffect(() => {
    if (reload_check) {
      setTimeout(() => {
        props.getData();
        props.searchFilter();
        toast.success("Campaign Updated Successfully");
        props.setModalShow(false);
      }, 2000);
    }
    dispatch(clearState());
  }, [reload_check]);

  useEffect(() => {
    if (props.getcampain) {
      props.getcampain();
    }
    dispatch(clearcampaignState());
  }, [isSuccess]);

  useEffect(() => {
    if (props.dealCurrency != "") {
      dispatch(ExchangeRates({ dealCurrency: props.dealCurrency }));
    }
  }, [props.dealCurrency]);

  function dateFormatConverter(dateToFormat) {
    const formatDate = new Date(dateToFormat);
    const year = formatDate.getFullYear();
    const month = (formatDate.getMonth() + 1).toString().padStart(2, '0');
    const day = formatDate.getDate().toString().padStart(2, '0');
    return year + "-" + month + "-" + day;
  }

  useEffect(() => {
    if (campaign_detail) {
      setTotal_sum_budget(campaign_detail?.budget_usd)
      formikRef.current?.setFieldValue(
        "budget_usd",
        campaign_detail?.budget_usd,
        true
      );
      formikRef.current?.setFieldValue(
        "name",
        campaign_detail?.name,
        true
      );
      formikRef.current?.setFieldValue(
        "status_id",
        campaign_detail?.statusId,
        true
      );
      formikRef.current?.setFieldValue(
        "plateform_campaign_id",
        campaign_detail?.platformCampaignId,
        true
      );
      formikRef.current?.setFieldValue(
        "product_id",
        campaign_detail?.productNavigation?.productId,
        true
      );
      formikRef.current?.setFieldValue(
        "budge",
        campaign_detail?.budge,
        true
      );
      formikRef.current?.setFieldValue(
        "date_from",
        new Date(props?.campaign_start_date),
      );
      formikRef.current?.setFieldValue(
        "date_to",
        new Date(props?.campaign_end_date),
      );

      SetError(false);
      setBudget(parseFloat(campaign_detail?.budge));
    }
  }, [campaign_detail]);


  const handleFormSubmit = async (values, { setSubmitting, resetForm }) => {
    setloadershown(true);
    if (!campaign_detail) {
    } else {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      const raw = JSON.stringify({
        "name": values?.name,
        "kite_deal_id": values?.kite_deal_id,
        "date_from": values?.date_from instanceof Date ? format(values.date_from, "yyyy-MM-dd'T'HH:mm:ssXXX") : null,
        "date_to": values?.date_to instanceof Date ? format(values.date_to, "yyyy-MM-dd'T'HH:mm:ssXXX") : null,
        "budge": values?.budge,
        "product_id": values?.product_id,
        "plateform_campaign_id": values?.plateform_campaign_id,
        "budget_usd": values?.budget_usd,
      });

      const requestOptions = {
        method: "PUT",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/update-campaign/${campaign_detail.id}`, requestOptions)
        .then((response) => {
          props.getData();
          props.searchFilter();
          props.getCampaignInfo();
          toast.success("Campaign Updated Successfully");
          props.setModalShow(false);
        })
        .then((result) => console.log(result))
        .catch((error) => console.error(error));

    }
  };
  useEffect(() => {
    check_budget();
  });

  const check_budget = () => {
    if (campaign_detail) {
      setBudget(props.total_Budget - props.sum_Budget);
    } else {
      let pending_budget = props?.totalBudget - props?.sumBudget;
      setBudget(pending_budget);
    }
  };

  const calculate_budget = (e) => {
    if (budget + campaign_detail?.budge < e.target.value) {
      SetError(true);
    }
  };

  const calculatebudget = (e) => {
    if (e.target.value) {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

      var raw = JSON.stringify({
        "deal_id": props?.dealid,
        "campaign_id": campaign_detail?.id,
        "inversion_budget": e.target.value,
      });

      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow'
      };

      fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}CampaignManagerSaas/calculatebudgetusd`, requestOptions)
        .then(response => response.json())
        .then(result => { setTotal_sum_budget(result?.data?.inversion_goal_usd) })
        .catch(error => toast.error(error));
    }
  }

  const compute = (outputCurrency, budget) => {
    if (Object.keys(dealCurrencies).length > 0) {
      const newRate = dealCurrencies[outputCurrency];
      const outputAmount = (budget * newRate).toFixed(2);
      SetUSDBudget(outputAmount);
      formikRef.current?.setFieldValue("budget_usd", outputAmount, false);
      return outputAmount;
    }
  }

  const togglecreateproduct = (event) => {
    setShowCreateProduct(event.target.checked);
  };
  const saveProduct = () => {
    let productName = product_name.trim();
    if (productName != "") {
      dispatch(
        storeProduct({
          token: localStorage.getItem("token"),
          clientId: props.clientId,
          productName: productName,
        })
      ).then((result) => {
        dispatch(
          kite_products({
            token: localStorage.getItem("token"),
            clientId: props.clientId,
          })
        );
        setShowCreateProduct(false);
      });
    }
  };
  const handleProductName = (e) => {
    let Product_Name = e.target.value;
    if (Product_Name.length === 0) {
      setShow_message(false);
    } else {
      let Check_Product_Name = kiteProducts.find(
        (item) => item.name.toLowerCase() === Product_Name.toLowerCase()
      );
      if (Check_Product_Name) {
        setShow_message(true);
      } else {
        setProductName(Product_Name);
      }
    }
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure to delete the item?")) {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Bearer " + localStorage.getItem("token")
      );

      var requestOptions = {
        method: "DELETE",
        headers: myHeaders,
        redirect: "follow",
      };

      fetch(
        `${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/delete-campaign/${id}`,
        requestOptions
      )
        .then((response) => response.text())
        .then((result) => {
          props.searchFilter();
          props.getData()
          props.searchFilter();
          props.getCampaignInfo();
          props?.getReloadData()
        })
        .then(toast.success("Deleted Successfully"))
        .then(props.setModalShow(false))

        .catch((error) => toast.error(error));
    }
  };

  const permission = JSON.parse(localStorage.getItem('userdata'));

  return (
    // <div className="content_outer">
    <div className="content pt-0">
      <Loader showLoader={loadershown} />

      <Formik
        initialValues={initialValues}
        validationSchema={Yup.object({
          name: Yup.string().required("Name is required"),
          date_from: Yup.string().required('Select date from'),
          date_to: Yup.string().required('Select date to'),
          budge: Yup.string().required("Select Budget"),
          product_id: Yup.string().required("Please Select Product"),
        })}
        onSubmit={handleFormSubmit}
        innerRef={formikRef}
      >
        {({ values, errors, touched, handleChange, setFieldValue }) => {
          return (
            <Form>
              <Row>
                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="campaignName">
                      Campaign Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      onChange={handleChange}
                      value={values.name}
                      placeholder="Campaign Name"
                    />
                    {errors.name && touched.name ? (
                      <span className="text-danger">{errors.name}</span>
                    ) : null}
                  </div>
                </Col>

                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position " htmlFor="product">
                      Product
                    </label>
                    <select
                      name="product_id"
                      className="form-select"
                      value={values.product_id}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                    >
                      <option value="">--Select--</option>
                      {kiteProducts?.map((product, index) => {
                        return (
                          <option value={product?.productId} key={index}>
                            {product?.name}
                          </option>
                        );
                      })}
                    </select>
                    {errors.product_id && touched.product_id ? (
                      <span className="text-danger">{errors.product_id}</span>
                    ) : null}
                  </div>
                </Col>
                {props?.campaignStat === "ACTIVE" &&
                  <Col lg={6}>
                    <div className="form-group select_country_container cmn_modal_field mb-3">
                      <label className="lbl_position" htmlFor="startdate">
                        End Date
                      </label>
                      <DatePicker
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        minDate={new Date()}
                        maxDate={new Date(props?.dealEndDate)}
                        name="date_to"
                        id="date_to"
                        onChange={date => setFieldValue('date_to', date)}
                        selected={values.date_to}
                      />
                    </div>
                  </Col>
                }
                {campaign_detail && props?.campaignStat !== "ACTIVE" && (
                  <>
                    <Col lg={3}>
                      <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" htmlFor="startdate">
                          Start Date
                        </label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          name="date_from"
                          id="date_from"
                          onChange={date => {
                            date < values.date_to ?
                            setFieldValue('date_from', date) :
                            (toast.error("Start date must be before end date"))
                          }}
                          selected={values.date_from}
                          disabled={new Date() > values.date_from}
                        />
                        {errors.date_from && touched.date_from ? (
                          <span className="text-danger">{errors.date_from}</span>
                        ) : null}

                      </div>
                      <div className="d-flex">
                        <p className="me-4">
                          Actual:
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_from}
                          </Moment>
                        </p>
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" htmlFor="startdate">
                          End Date
                        </label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date(props?.dealEndDate)}
                          name="date_to"
                          id="date_to"
                          onChange={date =>
                            date > values.date_from ?
                              (setFieldValue('date_to', date)) :
                              (toast.error("End date must be after start date"))
                          }
                          selected={values.date_to}
                        />
                      </div>
                      <div className="d-flex">
                        <p>
                          Actual:
                          <Moment format="DD/MM/YYYY">
                            {campaign_detail?.date_to}
                          </Moment>
                        </p>
                      </div>
                    </Col>
                  </>
                )}
                {props?.deal_detail && (
                  <>
                    <Col lg={3}>
                      <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" htmlFor="startdate">
                          Start Date
                        </label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          name="date_from"
                          id="date_from"
                          onChange={date => {
                            date < values.date_to ?
                            setFieldValue('date_from', date) :
                            (toast.error("Start date must be before end date"))
                          }}
                          selected={values.date_from}
                          disabled={new Date() > values.date_from}
                        />
                        {errors.date_from && touched.date_from ? (
                          <span className="text-danger">{errors.date_from}</span>
                        ) : null}
                      </div>
                    </Col>
                    <Col lg={3}>
                      <div className="form-group select_country_container cmn_modal_field mb-3">
                        <label className="lbl_position" htmlFor="startdate">
                          End Date
                        </label>
                        <DatePicker
                          className="form-control"
                          dateFormat="dd/MM/yyyy"
                          minDate={new Date()}
                          maxDate={new Date(props?.dealEndDate)}
                          name="date_to"
                          id="date_to"
                          onChange={date => setFieldValue('date_to', date)}
                          selected={values.date_to}
                        />
                      </div>
                    </Col>
                  </>
                )}

                <Col lg={6}>
                  <div className="form-group select_country_container cmn_modal_field mb-3">
                    <label className="lbl_position" htmlFor="budget">
                      Budget ({(budget && !err) ? <p className='mt-2'>Budget must be between {campaign_detail?.lineitemCount !== 0 ? campaign_detail?.budge ? parseInt(campaign_detail?.lineItemTotalBudget)?.toLocaleString() : parseInt(campaign_detail?.lineItemTotalBudget)?.toLocaleString() : 1} {campaign_detail?.budge ? "and" : null} {campaign_detail?.budge + budget ? parseInt(campaign_detail?.budge + budget)?.toLocaleString() : null}</p> : <p style={{ color: "red" }}>You cannot add more then {(campaign_detail ? budget + campaign_detail?.budge : budget)}</p>}
                      {errors.budge && touched.budge ? (
                        <span className='text-danger'>{errors.budge}</span>
                      ) : null})
                    </label>
                    <input
                      type="number"
                      step="any"
                      style={err ? { border: "1px solid red" } : null}
                      min={campaign_detail?.lineitemCount !== 0 ?
                        campaign_detail
                          ? campaign_detail?.lineItemTotalBudget
                          : 1 : 1
                      }
                      max={
                        campaign_detail
                          ? campaign_detail?.budge +
                          props?.remainingBudget * 1
                          : props?.totalBudget - props?.sumBudget
                      }
                      className="form-control"
                      name="budge"
                      onChange={(e) => {
                        handleChange(e);
                        // handleSetBudget(e);
                        calculate_budget(e);
                      }}
                      value={!values.budge ? null : values.budge}
                      onBlur={(e) => calculatebudget(e)}
                    />


                    {/* {(budget && !err ) ? <p className='mt-2'>Budget must be between {!campaign_detail?.budge ? parseInt(budget) : parseInt(props?.budget_used)} {campaign_detail?.budge ? "and" : null} {campaign_detail?.budge + budget ? parseInt(campaign_detail?.budge + budget) : null}</p> : <p style={{color:"red"}}>You cannot add more then {( campaign_detail ? budget + campaign_detail?.budge : budget)}</p> }
                                                    {errors.budge && touched.budge ? (
                                                        <span className='text-danger'>{errors.budge}</span>
                                                    ) : null} */}
                  </div>
                  {/* {props?.totalBudget && (
                      <p className="mt-2">
                        Budget must be between 0 and{" "}
                        {props?.totalBudget - props?.sumBudget}
                      </p>
                    )}
                    {props?.total_Budget ? (
                      <p className="mt-2">
                        Budget must be between {props?.budget_used} and{" "}
                        {campaign_detail?.budge +
                          props?.remainingBudget * 1}
                      </p>
                    ) : null} */}

                  <p>Budget USD :  ${total_sum_budget ? total_sum_budget : 0}</p>

                </Col>
                {/* <Col lg={6}>
                  <div className="form-group mb-3">
                    <label className="mb-2" htmlFor="budgetUsd">
                      Budget USD
                    </label>
                    <span className="d-block">$ {values.budget_usd}</span>
                  </div>
                </Col> */}
                <Col lg={12}>
                  <div className="form-group mb-3 d-none">
                    <input
                      type="checkbox"
                      onChange={togglecreateproduct}
                      checked={show_create_product}
                    />
                    <span className="create_product">Create Product</span>

                    <div style={show_create_product ? {} : { display: "none" }}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="save_product">
                            <input
                              type="text"
                              autoComplete="off"
                              className="form-control"
                              name="product_name"
                              onChange={(e) => {
                                handleProductName(e);
                              }}
                            />
                            {show_message ? (
                              <small style={{ color: "red" }}>
                                *This product is already exist*
                              </small>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="save_product">
                            <button type="button" onClick={saveProduct}>
                              Save Product
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
                <Col lg={12} className="text-end mt-3">
                  <div className="d-flex justify-content-end">
                    {props?.campaignStat == "DRAFT" &&
                      campaign_detail && Object.values(permission.Permissions).includes('editcampaign') ? (
                      <button
                        type="button"
                        className="cmn_btn  delete_cmn_btn"
                        onClick={() => {
                          handleDelete(campaign_detail?.id);
                        }}
                      >
                        Delete
                      </button>
                    ) : null}
                    <button
                      type="button"
                      className="ms-auto me-4 cancel_cm_btn"
                      onClick={() => props.setModalShow(false)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="cmn_btn ms-2"
                      disabled={err}
                    >
                      {!campaign_detail ? "Save" : "Update"}{" "}
                    </button>
                  </div>
                </Col>
              </Row>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default NewCompaign;
