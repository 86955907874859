import React from 'react'
import { Navigate } from "react-router-dom";
import { authSelector } from '../app/features/Auth/authSlice';
import { useSelector } from 'react-redux';

export const VerifyType = ({ children }) => {
    const permission = JSON.parse(localStorage.getItem('userdata'));
    const { type } = useSelector(
        authSelector
    );
    if (Object.values(permission.Permissions).includes('Overview')) {
        return children
    } else {
        return children
    }
}