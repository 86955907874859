import styled from "styled-components";

export const ContainerSubtitleSC = styled.div`
	background: #fff;
	height: 60px;
	flex-grow: 0;
	padding: 20px;
	border: solid 1.5px #f6f7f9;
	background-color: #fff;
`;

export const TextSC = styled.p`
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: 0.3px;
	text-align: left;
	color: #242833;
`;
