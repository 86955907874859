import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Form } from "react-bootstrap";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { MultiSelect } from "react-multi-select-component";
import { useSelector, useDispatch } from "react-redux";
import Accordion from 'react-bootstrap/Accordion';
import "./style.css";
import style from "./style.css";
import { index, reportSelector } from "../../app/features/Report/reportSlice";
import ProgressBar from "react-bootstrap/ProgressBar";
import { ArgentinaIcon, BoliviaIcon, BrasilIcon, ChileIcon, ColombiaIcon, CostaRicaIcon, EcuadorIcon, ElSalvadorIcon, GuatemalaIcon, HondurasIcon, MexicoIcon, NicaraguaIcon, PanamaIcon, ParaguayIcon, PeruIcon, PuertoRicoIcon, RepublicaDominicanaIcon, UruguayIcon, VenezuelaIcon, UnitedStatesIcon } from './svg';
import {
  fetchabstract,
  abstractSelector,
  reachPlanner,
  cleareachPlanner,
} from "../../app/features/Abstract/AbstractSlice";
import { BiPlus } from "react-icons/bi";
import { AiFillDelete } from "react-icons/ai";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Loader from "../Loader";
import {
  LineItemsSelector,
  getInventories,
  getOfferTypes,
} from "../../app/features/LineItemManager/LineItemManagerSlice";
import {
  campaignManagerSelector,
  dealdetail,
  deal_currency,
  getowners,
  pipelinefilter,
  clearState,
} from "../../app/features/CampaignManager/CampaignManagerSlice";
import Button from "../button/button";
import { buttonColors, textColors } from "../../helpers/const/paletteColors";
import { ArIcon } from "../../icons/svg";
import Subtitle from "../Subtitle/subtitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-hot-toast";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import 'jspdf-autotable';

const Abstract = () => {
  const dispatch = useDispatch();
  const [key, setKey] = useState("");
  const genders = [
    { label: "Girls ", value: "F" },
    { label: "Boys ", value: "M" },
  ];
  const { deal_detail, dealCurrency, isFetching, isSuccess, isError, error } =
    useSelector(campaignManagerSelector);
  const targetRef = useRef(null)

  const [selected, setSelected] = useState([]);
  const [selectedGender, setSelectedGender] = useState([]);
  const [selectedAgeRange, setSelectedAgeRange] = useState([]);
  const [allAgeRange, setAllAgeRange] = useState([]);
  const [populatedData, setPopolatedData] = useState({});
  const [loadershown, setloadershown] = useState(false);
  const [filteredCountries, setcountries] = useState([]);
  const [gender, setGender] = useState([]);
  const [campaignoffertype, setCampaignOfferType] = useState(0);
  const [useasAgeRange, setuseasAgeRange] = useState([]);
  const [populationProjection, setpopulationProjection] = useState({});
  const { products, ageRanges } = useSelector(reportSelector);
  const [country, setCountry] = useState("");
  const [product, setProduct] = useState(0);
  const [budget, setBudget] = useState(0);
  const [noofdays, setNoOfDays] = useState(30);
  const [countries, setCountries] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [date, setDate] = useState("");
  const [show_less, setShow_less] = useState(false);
  const [show_more, setShow_more] = useState(true);
  const [uploading, setUploading] = useState(false)
  const [allFields, setAllFields] = useState([
    {
      country: "",
      selected_country: "",
      gender: [],
      age: [],
      selectGender: [],
      selectAge: [],
      selectedAges: [],
      inventories: [{ product: "", offerType: "5", days: "30", budget: "", products: [{ productCode: 1, description: 'Apps Kidscorp' }, { productCode: 2, description: 'Roblox' }, { productCode: 3, description: 'YouTube' }] }],
    },
  ]);

  const [indexValue, setIndexValue] = useState(null);
  const [resultData, setResultData] = useState([])

  const { offer_types } = useSelector(LineItemsSelector);

  const {
    incidencia,
    poblacion_proyectada,
    population_projection_by_age,
    use_as_per_age,
    dataloaded,
    rachpalnnerdata,
    reachplannerdataLoaded,
    connected_Population,
  } = useSelector(abstractSelector);

  useEffect(() => {
    if (offer_types.length == 0) {
      dispatch(getOfferTypes({ token: localStorage.getItem("token") }));
    }
  }, [offer_types]);
  useEffect(() => {
    dispatch(index({}));
    dispatch(getInventories({ token: localStorage.getItem("token") }));
  }, []);

  const handlecountrychange = (data) => {
    let selectedcountry = data.map((i) => {
      return i.value.toUpperCase();
    });
    setSelected(data);
    setcountries(selectedcountry);
  };
  const handleGanderchange = (data) => {
    if (!Array.isArray(data)) {
      return;
    }

    const selectedGender = data.map((i) => i.value);
    const selected_gender_value = data.map((i) => i.label)
    setSelectedGender(data);
    setGender(selectedGender);

    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      const currentField = updatedFields[indexValue];

      if (currentField && Array.isArray(currentField.gender)) {
        const uniqueGender = [
          ...new Set([...currentField.gender, ...selectedGender]),
        ];
        updatedFields[indexValue] = {
          ...currentField,
          gender: uniqueGender,
        };
      }

      return updatedFields;
    });

    allFields[indexValue].gender = allFields[indexValue].gender.filter(item => selectedGender.includes(item))

    setAllFields((prevFields) => {
      const updatedFieldsGrnder = [...prevFields];
      const currentField = updatedFieldsGrnder[indexValue];

      if (currentField && Array.isArray(currentField.selectGender)) {
        const currentSelectedGenders = currentField.selectGender;
        const uniqueSelectedGenders = [
          ...new Set([...currentSelectedGenders, ...data]),
        ];
        updatedFieldsGrnder[indexValue] = {
          ...currentField,
          selectGender: uniqueSelectedGenders,
        };
      }

      return updatedFieldsGrnder;
    });

    allFields[indexValue].selectGender = allFields[indexValue].selectGender.filter(obj => {
      return selectedGender.includes(obj.value);
    })

  };

  const handleCampaignAgeRangeChange = (data) => {
    let selectAgeRange = data.map((i) => {
      return i.value.toUpperCase();
    });

    let selectedAge = data.map((i) => {
      return i.label.toUpperCase();
    });
    setSelectedAgeRange(data);
    setAllAgeRange(selectAgeRange);


    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      const uniqueAge = [
        ...new Set([...updatedFields[indexValue].age, ...selectAgeRange]),
      ];
      updatedFields[indexValue] = {
        ...updatedFields[indexValue],
        age: uniqueAge,
      };
      return updatedFields;
    });

    allFields[indexValue].age = allFields[indexValue].age.filter(item => selectAgeRange.includes(item))

    setAllFields((prevFields) => {
      const updatedFieldsAge = [...prevFields];
      const uniqueAgeRange = [
        ...new Set([...updatedFieldsAge[indexValue].selectAge, ...data]),
      ];
      updatedFieldsAge[indexValue] = {
        ...updatedFieldsAge[indexValue],
        selectAge: uniqueAgeRange,
      };
      return updatedFieldsAge;
    });

    allFields[indexValue].selectAge = allFields[indexValue].selectAge.filter(obj => {
      return selectAgeRange.includes(obj.value);
    })

    setAllFields((prevFields) => {
      const updatedFieldsSelected = [...prevFields];
      const uniqueAgeRangeSelected = [
        ...new Set([
          ...updatedFieldsSelected[indexValue].selectedAges,
          ...selectedAge,
        ]),
      ];
      updatedFieldsSelected[indexValue] = {
        ...updatedFieldsSelected[indexValue],
        selectedAges: uniqueAgeRangeSelected,
      };
      return updatedFieldsSelected;
    });

    allFields[indexValue].selectedAges = allFields[indexValue].selectedAges.filter(item => selectAgeRange.includes(item))

    setDate("test" + Date.now());
  };

  const submitCampaignReach = () => {
    let isError = false; // Flag variable to track errors

    allFields?.map((data, i) => {
      if (data?.country === "") {
        toast.error("Please select Country");
        isError = true;
      } else if (data?.gender?.length === 0) {
        toast.error("Please select Gender");
        isError = true;
      } else if (data?.ageRange?.length === 0) {
        toast.error("Please select Age Range");
        isError = true;
      } else if (data?.inventories?.length !== 0) {
        data?.inventories?.map((value, index) => {
          if (value?.inventoryType === "") {
            toast.error("Please select Inventory type");
            isError = true;
          } else if (value?.offerType === "") {
            toast.error("Please select Offer type");
            isError = true;
          } else if (value?.budget === "") {
            toast.error("Please add Budget");
            isError = true;
          }
        });
      }
    });

    if (!isError) {
      fieldData();
    }
  };

  const fieldData = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );

    var raw = JSON.stringify({
      data: allFields,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/reach`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setResultData(result?.data);
        setShow_more(true);
        setloadershown(false);
      })
      .catch((error) => {
        toast.error("Something went wrong");
        setloadershown(false);
      });
  };

  const resetCampaignReachPlanner = () => {
    setAllFields([
      {
        country: "",
        gender: [],
        age: [],
        selectGender: [],
        selectAge: [],
        inventories: [{
          product: "", offerType: "", days: "", budget: "", products: [
            { productCode: 1, description: 'Apps Kidscorp' },
            { productCode: 2, description: 'Roblox' },
            { productCode: 3, description: 'YouTube' }
          ]
        }],
      },
    ]);
    setResultData([]);
  };

  useEffect(() => {
    getdata();
  }, []);

  const getdata = async () => {
    const response = await fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}countries/getCountries`,
      {
        method: "GET",
      }
    );
    const result = await response.json();
    setCountries(result.data);
  };

  const getCountryData = () => {
    setloadershown(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      age: [],
      country: filteredCountries,
      gender: gender,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      `${process.env.REACT_APP_DOT_NET_BASE_URL}calculator/abstract`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setPopolatedData(result);
        setloadershown(false);
      })
      .catch((error) => toast.error(error));
  };

  useEffect(() => {
    processdata();
  }, [populatedData]);

  const processdata = async () => {
    let ageusages = [];
    populatedData?.data?.map((curelem) => {
      let curelearray = {};
      Object.keys(curelem.value.use_as_per_age).forEach((age_value) => {
        let keydata = JSON.parse(curelem.value.use_as_per_age[age_value]);
        keydata.forEach((pop_val, index) => {
          let ageindex = ageRanges.findIndex(
            (x) => x.ageRangeCode == pop_val.ageRangeCode
          );
          if (ageindex != -1) {
            keydata[index] = {
              ...keydata[index],
              description: ageRanges[ageindex]?.description,
            };
          }
        });
        curelearray = { ...curelearray, [age_value]: keydata };
      });
      let newelement = {
        ...curelem.value,
        parsedvalues: curelearray,
        key: curelem.key,
      };

      ageusages.push(newelement);
    });
    await setuseasAgeRange(ageusages);
  };

  const duplicateInventory = (i) => {
    let allProducts = [
      { productCode: 1, description: 'Apps Kidscorp' },
      { productCode: 2, description: 'Roblox' },
      { productCode: 3, description: 'YouTube' }
    ];

    if (allFields[i]?.inventories?.length >= 3) {
      toast.error("You cannot add more fields");
      return;
    }

    if (allFields[i]?.inventories?.some((data) => data.product === "")) {
      toast.error("Please Select Inventory Type First");
      return;
    }

    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[i].inventories.push({
        product: "",
        offerType: "5",
        days: "30",
        budget: "",
        products: allProducts
      });
      return updatedFields;
    });
  };


  const duplicateCountry = () => {
    if (allFields?.length === 5) {
      toast.error("You cannot add more fields");
    } else {
      setAllFields((prevFields) => [
        ...prevFields,
        {
          country: "",
          gender: [],
          age: [],
          selectGender: [],
          selectAge: [],
          selectedAges: [],
          inventories: [
            { product: "", offerType: "5", days: "30", budget: "", products: [{ productCode: 1, description: 'Apps Kidscorp' }, { productCode: 2, description: 'Roblox' }, { productCode: 3, description: 'YouTube' }] },
          ],
        },
      ]);
    }
  };

  const handleSetCountries = (e, index) => {
    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        country: e.target.value,
      };
      return updatedFields;
    });
  };

  const handle_select_countries = (e, index) => {
    let country_value = "";
    const val = countries.find((el) => el.ab === e.target.value);
    country_value = val.labelKey;
    setAllFields((prevFields) => {
      const updatedFields = [...prevFields];
      updatedFields[index] = {
        ...updatedFields[index],
        selected_country: country_value,
      };
      return updatedFields;
    });


  }

  const handleSetInventory = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].product = e.target.value;
      return newState;
    });
  };

  const handleSetOffer = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].offerType = e.target.value;
      return newState;
    });
  };

  const handleSetBudget = (e, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState];
      newState[indexValue].inventories[i].budget = e.target.value;
      return newState;
    });
  };

  const handleDeleteIndex = (i) => {
    const updatedArray = [...allFields];
    updatedArray.splice(i, 1);
    setAllFields(updatedArray);
  };

  const handleDeleteChildIndex = (index, i) => {
    setAllFields((prevState) => {
      const newState = [...prevState]; // Create a copy of the state array
      newState[index].inventories.splice(i, 1); // Remove the inventory at the specified index
      return newState;
    });
  };

  const exportData = async () => {
    setUploading(true);
    setloadershown(true);
    const targetElement = targetRef.current;
    let total_height;
    const height = targetElement.clientHeight / 5;

    if (!targetElement) {
      return;
    }

    // Set default dimensions for elements with 0 width or height
    const setDefaultDimensions = (element) => {
      if (element.offsetWidth === 0) {
        element.style.width = "100px"; // Set a default width (adjust as needed)
      }
      if (element.offsetHeight === 0) {
        element.style.height = "100px"; // Set a default height (adjust as needed)
      }
    };

    // Recursively set default dimensions for all child elements
    const setDefaultDimensionsRecursively = (element) => {
      setDefaultDimensions(element);
      const children = element.children;
      for (let i = 0; i < children.length; i++) {
        setDefaultDimensionsRecursively(children[i]);
      }
    };

    // Set default dimensions for targetElement and its children
    setDefaultDimensionsRecursively(targetElement);

    try {
      // Ensure all images inside targetElement are loaded and have non-zero dimensions
      const images = Array.from(targetElement.querySelectorAll('img')).filter(img => img.offsetWidth > 0 && img.offsetHeight > 0);
      await Promise.all(images.map(img => img.decode()));

      // Calculate the total height of the content inside targetElement
      const totalContentHeight = targetElement.scrollHeight;

      // Create a canvas element with dynamic height based on the content
      const canvas = document.createElement('canvas');
      canvas.width = 1450;
      canvas.height = totalContentHeight + 1000; // Adding some padding

      // Draw the HTML content onto the canvas with scaling
      await html2canvas(targetElement, {
        canvas: canvas,
        logging: true, // Enable logging for detailed information (optional)
        scale: 1, // Scale factor to fit content within the canvas
        allowTaint: true,
        windowWidth: canvas.width,
        windowHeight: canvas.height,
        imageTimeout: 15000,
        proxy: null,
        height: 1800,
        autoPaging: true,
        scrollY: -window.scrollY
      });

      // Create a PDF document
      const pdf = new jsPDF({
        orientation: 'p',
        format: [208, canvas.height / 8],
        precision: 2,
      });
      // Convert canvas to an image and add it to the PDF
      const imgData = canvas.toDataURL('image/png');
      const imgWidth = 210; // A4 width in mm
      const imgHeight = (canvas.height * imgWidth) / canvas.width;
      pdf.addImage(imgData, 'PNG', 10, 10, imgWidth, imgHeight);

      // Save the PDF file
      pdf.save('Reports.pdf');
      setUploading(false);
      setloadershown(false);
    } catch (error) {
      setUploading(false);
      setloadershown(false);
    }
  };

  let current = new Date();
  let cDate = current.getDate() + '/' + (current.getMonth() + 1) + '/' + current.getFullYear();

  let hours = current.getHours();
  let minutes = current.getMinutes();
  let ampm = hours >= 12 ? 'PM' : 'AM';

  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12' in PM

  let cTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + ampm;

  let dateTime = cDate + ' ' + cTime;

  const hiddenIds = [22, 33, 27, 26, 25, 24, 35, 32, 23, 30, 34, 28, 31, 29];


  return (
    <div className="content_outer abstract_filter">
      <Subtitle subtitle="Reach calculator" />
      <Loader showLoader={loadershown} />
      <div className="content pt-3 mt-5">
        <div className="">
          <div className="reach_calculater_tabs">
            <Tabs
              defaultActiveKey="kite_universe"
              id="controlled-tab-example"
              className="tabs_outer_wrapper bg-white tab-lg campion_bg"
            >
              <Tab
                eventKey="kite_universe"
                className="mt-5"
                title="Kite Universe"
              >
                <div className="kite_uni ">
                  <Row className="align-items-center">
                    <Col lg={5}>
                      <div className="select_country_container input_wrap mt-0">
                        <label htmlFor="" className="center_label lbl_position form-label">Country</label>
                        <MultiSelect
                          options={countries
                            .filter((i) => !hiddenIds.includes(i.id)) // Exclude country with id 1
                            .map((i) => ({
                              ...i,
                              label: i.labelKey,
                              value: i.ab,
                            }))}
                          value={selected}
                          onChange={handlecountrychange}
                          disableSearch={true}
                          hasSelectAll={false}
                          labelledBy="Select"
                          overrideStrings={{
                            selectSomeItems: "Countries",
                          }}
                        />

                      </div>
                    </Col>
                    <Col lg={5}>
                      <div role="group" className="select_country_container input_wrap mt-0" aria-labelledby="checkbox-group ">
                        <label htmlFor="" className="center_label lbl_position form-label">Gender</label>
                        <MultiSelect
                          options={genders.map((gender) => ({
                            ...gender,
                            label: gender.label,
                            value: gender.value,
                          }))}
                          value={selectedGender}
                          onChange={handleGanderchange}
                          disableSearch={true}
                          hasSelectAll={false}
                          labelledBy="Select"
                          overrideStrings={{
                            selectSomeItems: "Gender",
                          }}
                        />
                      </div>
                    </Col>
                    <Col lg={2}>
                      <Button
                        disabled={selected != "" ? false : disabled}
                        bg={
                          selected != ""
                            ? buttonColors.search
                            : buttonColors.disabled
                        }
                        color={textColors.search}
                        text="Search"
                        onClick={getCountryData}
                      />
                    </Col>
                  </Row>
                </div>
                {useasAgeRange?.map((data, index) => {
                  return data.incidencia.AppsKidscorp === 0 ? null : (
                    <div className="py-4">
                      <div className="filter_search_universe mb-3">
                        <Row className="align-items-center m-0">
                          <Col lg={12} className="result-search">
                            <div className="pe-3">
                              <div className="bg_white select_country_globe">
                                <div className="social_image mr-2">
                                  {data.key === "AR" && <ArIcon size="35px" />}
                                  {data.key === "BO" && (
                                    <img
                                      src="assets/images/bo.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "BR" && (
                                    <img
                                      src="assets/images/br.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CL" && (
                                    <img
                                      src="assets/images/cl.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CO" && (
                                    <img
                                      src="assets/images/co.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "CR" && (
                                    <img
                                      src="assets/images/cr.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "EC" && (
                                    <img
                                      src="assets/images/ec.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "SV" && (
                                    <img
                                      src="assets/images/sv.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "GT" && (
                                    <img
                                      src="assets/images/gt.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "HN" && (
                                    <img
                                      src="assets/images/hn.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "MX" && (
                                    <img
                                      src="assets/images/mx.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "NI" && (
                                    <img
                                      src="assets/images/ni.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PA" && (
                                    <img
                                      src="assets/images/pa.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PY" && (
                                    <img
                                      src="assets/images/py.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PE" && (
                                    <img
                                      src="assets/images/pe.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "PR" && (
                                    <img
                                      src="assets/images/pr.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "DO" && (
                                    <img
                                      src="assets/images/do.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "UY" && (
                                    <img
                                      src="assets/images/uy.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "VE" && (
                                    <img
                                      src="assets/images/ve.png"
                                      alt="country"
                                    />
                                  )}
                                  {data.key === "US" && (
                                    <img
                                      src="assets/images/us.png"
                                      alt="country"
                                    />
                                  )}
                                  <span className="name-country">
                                    {
                                      countries.find(
                                        ({ ab }) =>
                                          ab === data?.key.toLowerCase()
                                      )?.labelKey
                                    }
                                  </span>
                                </div>

                                <div className="text-world">
                                  <div className="world-space">
                                  </div>
                                  <h4 className="text-total text-center">
                                    Total U18 connected population:
                                    <span className="space-population">
                                      {data?.connected_population?.toLocaleString(
                                        undefined,
                                        { minimumFractionDigits: 0 }
                                      )}
                                    </span>
                                  </h4>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg={12}>
                            <Accordion>
                              <Row className="m-0">
                                <Accordion.Item eventKey={index + 1}>
                                  <div
                                    className="bg_white youtube accordian_bg">
                                    <Accordion.Header>
                                      <div className="d-flex justify-content-between align-items-center w-100">
                                        <p class="abs_badge mb-0">YouTube</p>
                                        <div className="text-container">
                                          <h5>Total Selected Audience</h5>
                                          <p className="result-social">
                                            {parseInt(
                                              data?.poblacion_proyectada?.YouTube
                                            )?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div className="text-container">
                                          <h5>Incidence on Selected Audience</h5>
                                          <p className="result-social">
                                            {data?.incidencia?.YouTube?.toLocaleString(
                                              undefined,
                                              { minimumFractionDigits: 2 }
                                            )}
                                            %
                                          </p>
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div id="youtube" className="">
                                        <Row className="result-graf">
                                          {/* youtube percentage chart */}

                                          <Col lg={6}>
                                            <div>
                                              <div className="graph card-header">
                                                <h2 className="graph_title text-center">
                                                  YouTube projected population
                                                  by age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0, // Minimum number of fraction digits
                                                                maximumFractionDigits: 2, // Maximum number of fraction digits
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },

                                                    series: [
                                                      {
                                                        name: "",
                                                        data: data?.parsedvalues?.YouTube?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                        showInLegend: false,
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>

                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },

                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        data:
                                                          data?.parsedvalues?.YouTube.length > 0 &&
                                                          data?.parsedvalues?.YouTube?.map(
                                                            (el, index) => ({
                                                              name: el?.description,
                                                              y: el?.percentage,
                                                              color: "#f978c8",
                                                            })
                                                          ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Accordion.Body>
                                  </div>
                                </Accordion.Item>
                              </Row>

                              <Row
                                className="m-0"
                              >
                                <Accordion.Item eventKey={index + 2}>
                                  <div
                                    className="bg_white youtube accordian_bg">
                                    <Accordion.Header>
                                      <div className="d-flex justify-content-between align-items-center w-100">
                                        <p class="abs_badge mb-0">Roblox</p>

                                        <div className="text-container">
                                          <h5 className="">
                                            Total Selected Audience
                                          </h5>
                                          <p className="result-social">
                                            {parseInt(
                                              data?.poblacion_proyectada?.Roblox
                                            )?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div className="text-container">
                                          <h5>Incidence on Selected Audience</h5>
                                          <p className="result-social">
                                            {data?.incidencia?.Roblox?.toFixed(2)}
                                            %
                                          </p>
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div id="roblox" className="">
                                        <Row className="result-grafRo">
                                          {/* roblox percentage chart */}
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  Roblox projected population by
                                                  age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },
                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0, // Minimum number of fraction digits
                                                                maximumFractionDigits: 2, // Maximum number of fraction digits
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },
                                                    series: [
                                                      {
                                                        name: "",
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.Roblox?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },
                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },
                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.Roblox?.map(
                                                          (el, index) => ({
                                                            name: el?.description,
                                                            y: el?.percentage,
                                                            color: "#f978c8",
                                                          })
                                                        ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Accordion.Body>
                                  </div>
                                </Accordion.Item>
                              </Row>

                              <Row
                                className="m-0"
                              >
                                <Accordion.Item eventKey={index + 3}>
                                  <div className="bg_white youtube accordian_bg mb-0">
                                    <Accordion.Header>
                                      <div className="d-flex justify-content-between align-items-center w-100">
                                        <p class="abs_badge mb-0">Apps</p>
                                        <div className="text-container">
                                          <h5>Total Selected Audience</h5>
                                          <p className="result-social">
                                            {parseInt(
                                              data?.poblacion_proyectada
                                                ?.AppsKidscorp
                                            )?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div className="text-container">
                                          <h5>Incidence on Selected Audience</h5>
                                          <p className="result-social">
                                            {data?.incidencia?.AppsKidscorp?.toFixed(
                                              2
                                            )}
                                            %
                                          </p>
                                        </div>
                                        <FontAwesomeIcon
                                          icon={faAngleUp}
                                          className="vector"
                                        />
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body>
                                      <div id="kid" className="">
                                        <Row className="result-grafKid">
                                          {/* kidscorp percentage chart */}
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  Kidscorp projected population
                                                  by age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },
                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      categories: ageRanges.map(
                                                        (i) => {
                                                          return i?.description;
                                                        }
                                                      ),
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      line: {
                                                        dataLabels: {
                                                          enabled: true,
                                                        },
                                                        enableMouseTracking: false,
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },
                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormatter:
                                                        function () {
                                                          return (
                                                            '<span style="color:' +
                                                            this.color +
                                                            '">' +
                                                            this.category +
                                                            "</span>: <b>" +
                                                            this.y.toLocaleString(
                                                              undefined,
                                                              {
                                                                minimumFractionDigits: 0,
                                                                maximumFractionDigits: 2,
                                                                useGrouping: true, // Add thousand separators
                                                              }
                                                            ) +
                                                            "</b> of total<br/>"
                                                          );
                                                        },
                                                    },
                                                    series: [
                                                      {
                                                        name: "",
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.AppsKidscorp?.map(
                                                          (i, index) => {
                                                            return parseInt(
                                                              i?.proprojectedPopulation
                                                            );
                                                          }
                                                        ),
                                                        color: "#00eacb",
                                                      },
                                                    ],
                                                    responsive: {
                                                      rules: [
                                                        {
                                                          condition: {
                                                            maxWidth: 500,
                                                          },
                                                          chartOptions: {
                                                          },
                                                        },
                                                      ],
                                                    },
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="">
                                              <div className="graph">
                                                <h2 className="graph_title text-center">
                                                  % Incidence by Age
                                                </h2>
                                                <HighchartsReact
                                                  containerProps={{
                                                    style: { height: "230px" },
                                                  }}
                                                  highcharts={Highcharts}
                                                  options={{
                                                    chart: {
                                                      type: "column",
                                                    },
                                                    title: {
                                                      align: "center",
                                                      text: "",
                                                    },

                                                    accessibility: {
                                                      announceNewData: {
                                                        enabled: true,
                                                      },
                                                    },
                                                    xAxis: {
                                                      type: "category",
                                                    },
                                                    yAxis: {
                                                      title: {
                                                        text: false,
                                                      },
                                                    },
                                                    plotOptions: {
                                                      column: {
                                                        borderRadius: 10,
                                                      },
                                                      series: {
                                                        borderWidth: 0,
                                                        dataLabels: {
                                                          enabled: true,
                                                          format:
                                                            "{point.y:.1f}%",
                                                        },
                                                      },
                                                    },
                                                    credits: {
                                                      enabled: false,
                                                    },

                                                    tooltip: {
                                                      headerFormat:
                                                        '<span style="font-size:11px">{series.name}</span><br>',
                                                      pointFormat:
                                                        '<span style="color:{point.color}">{point.name}</span>: <b>{point.y:.2f}%</b> of total<br/>',
                                                    },
                                                    series: [
                                                      {
                                                        colorByPoint: true,
                                                        showInLegend: false,
                                                        data: data?.parsedvalues?.AppsKidscorp?.map(
                                                          (el, index) => ({
                                                            name: el?.description,
                                                            y: el?.percentage,
                                                            color: "#f978c8",
                                                          })
                                                        ),
                                                      },
                                                    ],
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </Row>
                                      </div>
                                    </Accordion.Body>
                                  </div>
                                </Accordion.Item>
                              </Row>
                            </Accordion>
                          </Col>
                        </Row>
                      </div>
                      {/* graphs starts here */}
                    </div>
                  );
                  // }
                })}
              </Tab>

              <Tab
                eventKey="campaignreach"
                title="Campaign Reach Planner"
                className="mt-5"
              >
                <div className="reach_filter_wrapper">
                  <div className="d-flex align-items-center">
                    {" "}
                    <h4
                      className={
                        !show_more
                          ? "filter_headings mb-0 flex-grow-1"
                          : "filter_headings flex-grow-1"
                      }
                    >
                      Filter
                    </h4>{" "}
                    {show_more && resultData?.length !== 0 && (
                      <div
                        className="show_btns"
                        onClick={() => {
                          setShow_more(!show_more);
                          setShow_less(!show_less);
                        }}
                      >
                        Show Less
                        <svg
                          className="ms-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="m11.441 14.748-4.464-4.463a.75.75 0 1 1 1.061-1.061l3.96 3.961 3.962-3.96a.749.749 0 1 1 1.06 1.06l-4.492 4.491a.744.744 0 0 1-.53.22.744.744 0 0 1-.557-.248z"
                            fill="#04190D"
                          />
                        </svg>
                      </div>
                    )}
                    {!show_more && resultData?.length !== 0 && show_less && (
                      <div
                        className="show_btns"
                        onClick={() => {
                          setShow_more(!show_more);
                          setShow_less(!show_less);
                        }}
                      >
                        Show More
                        <svg
                          className="ms-1"
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                        >
                          <path
                            fillRule="evenodd"
                            clip-rule="evenodd"
                            d="m11.441 14.748-4.464-4.463a.75.75 0 1 1 1.061-1.061l3.96 3.961 3.962-3.96a.749.749 0 1 1 1.06 1.06l-4.492 4.491a.744.744 0 0 1-.53.22.744.744 0 0 1-.557-.248z"
                            fill="#04190D"
                          />
                        </svg>
                      </div>
                    )}
                  </div>
                  {show_more && !show_less && (
                    <div className="reach_inner_wrapper">
                      {allFields?.map((fields, index) => {
                        return (
                          <>
                            <div className="kite_uni">
                              <b className="select_country_heading bold_item">
                                Select country and...
                              </b>
                              <Row className="w-100 m-0">
                                <Col lg={4}>
                                  <div className="select_country_container">
                                    <Form.Group className="mb-3 form-group">
                                      <label
                                        htmlFor=""
                                        className="center_label lbl_position"
                                      >
                                        Select Country
                                      </label>
                                      <Form.Select
                                        onChange={(e) => {
                                          handleSetCountries(e, index);
                                          setCountry(e.target.value);
                                          handle_select_countries(e, index)
                                        }}
                                        value={allFields?.selected_country}
                                      >
                                        <option>{allFields[index]?.selected_country ? allFields[index]?.selected_country : "Select"}</option>
                                        {countries
                                          .filter((data) => !hiddenIds.includes(data.id))
                                          .map((data, i) => (
                                            <option value={data.ab} key={i}>
                                              {" "}
                                              {data.labelKey}
                                            </option>
                                          ))}
                                      </Form.Select>
                                    </Form.Group>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="select_country_container ">
                                    <div
                                      className="form-group justify-content-around"
                                      onClick={() => setIndexValue(index)}
                                    >
                                      <label
                                        htmlFor=""
                                        className="lbl_position lblndex"
                                      >
                                        Select Gender
                                      </label>

                                      <MultiSelect
                                        options={genders.map((gender) => ({
                                          ...gender,
                                          label: gender.label,
                                          value: gender.value,
                                        }))}
                                        value={allFields[index]?.selectGender}
                                        onChange={handleGanderchange}
                                        disableSearch={false}
                                        hasSelectAll={false}
                                        labelledBy="Select Gender"
                                      />
                                    </div>
                                  </div>
                                </Col>
                                <Col lg={4}>
                                  <div className="budget_container">
                                    <div className="select_country_container">
                                      <div
                                        className="form-group "
                                        onClick={() => setIndexValue(index)}
                                      >
                                        <label
                                          htmlFor=""
                                          className="lbl_position lblndex"
                                        >
                                          Select Age Range
                                        </label>

                                        <MultiSelect
                                          options={ageRanges.map((i) => ({
                                            ...i,
                                            label: i.description,
                                            value: i.ageRangeCode.toString(),
                                          }))}
                                          value={allFields[index]?.selectAge}
                                          onChange={
                                            handleCampaignAgeRangeChange
                                          }
                                          disableSearch={true}
                                          hasSelectAll={false}
                                          labelledBy="Select Country"
                                        />
                                      </div>
                                    </div>
                                    <div className="delete_icon">
                                      {allFields?.length > 1 && (
                                        <AiFillDelete
                                          onClick={() =>
                                            handleDeleteIndex(index)
                                          }
                                        />
                                      )}
                                    </div>
                                  </div>
                                </Col>
                              </Row>
                              <hr className="dot_hr_line"></hr>
                              <Row className="mt-3 m-0">
                                <Col lg={10}>
                                  <Row className="mr-0">
                                    {fields?.inventories?.map((inFields, i) => {
                                      return (
                                        <>
                                          <Col
                                            lg={6}
                                            onClick={() => setIndexValue(index)}
                                          >
                                            <div className="select_country_container">
                                              <Form.Group className="mb-3 form-group">
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select Inventory Type
                                                </label>
                                                <Form.Select
                                                  onChange={(e) => {
                                                    handleSetInventory(e, i);
                                                    setProduct(e.target.value);
                                                  }}
                                                  value={inFields?.product}
                                                >
                                                  <option>select</option>
                                                  {inFields?.products?.map((data, index) => (
                                                    <option value={data.productCode} key={index}>
                                                      {data.description}
                                                    </option>
                                                  ))}

                                                </Form.Select>
                                              </Form.Group>
                                            </div>
                                          </Col>
                                          <Col lg={6}>
                                            <div className="budget_container">
                                              <div className="select_country_container">
                                                <div
                                                  className="form-group"
                                                  onClick={() =>
                                                    setIndexValue(index)
                                                  }
                                                >
                                                  <label
                                                    htmlFor=""
                                                    className="center_label lbl_position"
                                                  >
                                                    Select Budget USD
                                                  </label>
                                                  <input
                                                    type="number"
                                                    name="inversion_budget"
                                                    onChange={(e) => {
                                                      if (e.target.value >= 0) {
                                                        setBudget(e.target.value);
                                                        handleSetBudget(e, i);
                                                      } else {
                                                        toast.error("Please enter a non-negative number");
                                                      }
                                                    }}                                                    
                                                    value={inFields?.budget}
                                                    className="form-control"
                                                  />
                                                </div>
                                              </div>
                                              <div className="delete_icon">
                                                {fields.inventories?.length >
                                                  1 && (
                                                    <AiFillDelete
                                                      onClick={() =>
                                                        handleDeleteChildIndex(
                                                          index,
                                                          i
                                                        )
                                                      }
                                                    />
                                                  )}
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={3} className=" d-none">
                                            <div className="select_country_container">
                                              <div
                                                className="form-group"
                                                onClick={() =>
                                                  setIndexValue(index)
                                                }
                                              >
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select Offer Type
                                                </label>
                                                <select
                                                  name="inversion_offer_type_id"
                                                  className="form-control"
                                                  value={inFields?.offerType}
                                                  onChange={(e) => {
                                                    setCampaignOfferType(
                                                      e.target.value
                                                    );
                                                    handleSetOffer(e, i);
                                                  }}
                                                >
                                                  <option>select</option>
                                                  {inFields?.product == 1 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  {inFields?.product == 2 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                  {inFields?.product == 3 &&
                                                    offer_types?.map(
                                                      (offr, offr_index) => {
                                                        if (offr.id == 5) {
                                                          return (
                                                            <option
                                                              value={offr?.id}
                                                              key={offr_index}
                                                            >
                                                              {
                                                                offr?.descripcion
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      }
                                                    )}
                                                </select>
                                              </div>
                                            </div>
                                          </Col>
                                          <Col lg={3} className=" d-none">
                                            <div className="select_country_container d-none">
                                              <div
                                                className="form-group"
                                                onClick={() =>
                                                  setIndexValue(index)
                                                }
                                              >
                                                <label
                                                  htmlFor=""
                                                  className="center_label lbl_position"
                                                >
                                                  Select # of Days
                                                </label>
                                                <input
                                                  type="number"
                                                  name="numberdays"
                                                  value={noofdays}
                                                  className="form-control"
                                                />
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      );
                                    })}
                                  </Row>
                                </Col>
                                <Col lg={2}>
                                  <Row>
                                    <Col
                                      lg={12}
                                      className="add_inventory_btn_outer"
                                    >
                                      <div className="form-group  mb-3">
                                        <button
                                          className="add_inventorty_btn"
                                          onClick={() => {
                                            duplicateInventory(index)
                                          }
                                          }
                                        >
                                          {" "}
                                          <BiPlus />
                                          Add Inventory Type
                                        </button>
                                      </div>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                              <hr className="horizontal_line mt-4 "></hr>
                            </div>
                          </>
                        );
                      })}
                      <div className="add_country_wrapper">
                        <div className="add_inventory_btn_outer">
                          {allFields?.length !== 5 ? (
                            <div className="form-group mb-3">
                              <button
                                className="add_country_btn add_inventorty_btn"
                                onClick={duplicateCountry}
                              >
                                {" "}
                                <BiPlus />
                                Add Country
                              </button>
                            </div>
                          ) : null}
                        </div>
                        <Col lg={4}>
                          <div className="d-flex setbuttons">
                            <button
                              className="cmn_btn reset_button_color me-2"
                              onClick={resetCampaignReachPlanner}
                            >
                              Reset
                            </button>

                            <button
                              className="cmn_btn search_btn "
                              onClick={() => {
                                submitCampaignReach();
                              }}
                            >
                              Search
                            </button>
                          </div>
                        </Col>
                      </div>
                    </div>
                  )}
                </div>
                <div className="position-relative">

                  <div className="pdf_wrapper" ref={targetRef}>
                    {uploading && <div className=" p-3 pdf_head">
                      <div className="d-flex justify-content-between align-items-center">
                        <img src="/assets/images/kids_header.svg" width="180" />
                        <img src="/assets/images/mariano.png" width="200" />
                      </div>
                      <div className="d-flex justify-content-between align-items-center mt-3">
                        <h2 style={{ fontSize: "32px", fontWeight: "bold" }} className="mb-0 header_title">Reach Calculator Export</h2>
                        <h2 style={{ fontSize: "32px" }} className="mb-0 header_title">{dateTime}</h2>
                      </div>
                    </div>}
                    {resultData?.length !== 0 && (
                      <div className="summary">
                        <ul>
                          <li>
                            <h5>Total Summary</h5>
                          </li>
                          <li>
                            <p>
                              Country:{" "}
                              <strong>
                                {resultData[
                                  resultData.length - 1
                                ].Report?.countryName?.map((data) => {
                                  switch (data) {
                                    case "ar":
                                      return "Argentina,";
                                    case "bo":
                                      return "Bolivia,";
                                    case "br":
                                      return "Brasil,";
                                    case "cl":
                                      return "Chile,";
                                    case "co":
                                      return "Colombia,";
                                    case "cr":
                                      return "Costa Rica,";
                                    case "ec":
                                      return "Ecuador,";
                                    case "sv":
                                      return "El Salvador,";
                                    case "gt":
                                      return "Guatemala,";
                                    case "hn":
                                      return "Honduras,";
                                    case "mx":
                                      return "Mexico,";
                                    case "ni":
                                      return "Nicaragua,";
                                    case "pa":
                                      return "Panama,";
                                    case "py":
                                      return "Paraguay,";
                                    case "pe":
                                      return "Peru,";
                                    case "pr":
                                      return "Puerto Rico,";
                                    case "do":
                                      return "Republica Dominicana,";
                                    case "uy":
                                      return "Uruguay,";
                                    case "ve":
                                      return "Venezuela,";
                                    case "us":
                                      return "United States,";
                                    default:
                                      return data;
                                  }
                                })}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Total budget:{" USD "}
                              <strong>
                                {
                                  resultData[resultData?.length - 1].Report
                                    ?.allBudgetSum?.toLocaleString(undefined, { minimumFractionDigits: 0 })
                                }
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Apps reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.appReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.appReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Youtube reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.youtubeReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.youtubeReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                          <li>
                            <p>
                              Roblox reach:{" "}
                              <strong>
                                {resultData[resultData?.length - 1].Report
                                  ?.robloxReachSum
                                  ? resultData[resultData?.length - 1].Report
                                    ?.robloxReachSum?.toLocaleString(undefined, {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 0
                                    })
                                  : "-"}
                              </strong>
                            </p>
                          </li>
                        </ul>
                      </div>
                    )}


                    {resultData?.map((values, indexVal) => {
                      if (
                        Array.isArray(values?.inventory) &&
                        values?.inventory.length > 0 &&
                        values?.inventory.every((item) => item !== null) &&
                        Object.keys(values?.insight).length > 0
                      ) {
                        return (

                          <div key={indexVal} className="audience_outer_wrapper mt-3">
                            <div className="flag_outer">
                              <h2 className="heading_argentina">
                                {values?.country === "ar" && (
                                  <ArgentinaIcon size={35} />
                                )}
                                {values?.country === "bo" && (
                                  <BoliviaIcon size={35} />
                                )}
                                {values?.country === "br" && (
                                  <BrasilIcon size={35} />
                                )}
                                {values?.country === "cl" && (
                                  <ChileIcon size={35} />
                                )}
                                {values?.country === "co" && (
                                  <ColombiaIcon size={35} />
                                )}
                                {values?.country === "cr" && (
                                  <CostaRicaIcon size={35} />
                                )}
                                {values?.country === "ec" && (
                                  <EcuadorIcon size={35} />
                                )}
                                {values?.country === "sv" && (
                                  <ElSalvadorIcon size={35} />
                                )}
                                {values?.country === "gt" && (
                                  <GuatemalaIcon size={35} />

                                )}
                                {values?.country === "hn" && (
                                  <HondurasIcon size={35} />
                                )}
                                {values?.country === "mx" && (
                                  <MexicoIcon size={35} />
                                )}
                                {values?.country === "ni" && (
                                  <NicaraguaIcon size={35} />
                                )}
                                {values?.country === "pa" && (
                                  <PanamaIcon size={35} />
                                )}
                                {values?.country === "py" && (
                                  <ParaguayIcon size={35} />
                                )}
                                {values?.country === "pe" && (
                                  <PeruIcon size={35} />
                                )}
                                {values?.country === "pr" && (
                                  <PuertoRicoIcon size={35} />
                                )}
                                {values?.country === "do" && (
                                  <RepublicaDominicanaIcon size={35} />
                                )}
                                {values?.country === "uy" && (
                                  <UruguayIcon size={35} />
                                )}
                                {values?.country === "ve" && (
                                  <VenezuelaIcon size={35} />
                                )}
                                {values?.country === "us" && (
                                  <UnitedStatesIcon size={35} />
                                )}
                              </h2>
                              <h3 className="ms-2 flex-grow-1">
                                {values?.country === "ar" && "Argentina"}
                                {values?.country === "bo" && "Bolivia"}
                                {values?.country === "br" && "Brasil"}
                                {values?.country === "cl" && "Chile"}
                                {values?.country === "co" && "Colombia"}
                                {values?.country === "cr" && "Costa Rica"}
                                {values?.country === "ec" && "Ecuador"}
                                {values?.country === "sv" && "El Salvador"}
                                {values?.country === "gt" && "Guatemala"}
                                {values?.country === "hn" && "Honduras"}
                                {values?.country === "mx" && "Mexico"}
                                {values?.country === "ni" && "Nicaragua"}
                                {values?.country === "pa" && "Panama"}
                                {values?.country === "py" && "Paraguay"}
                                {values?.country === "pe" && "Peru"}
                                {values?.country === "pr" && "Puerto Rico"}
                                {values?.country === "do" &&
                                  "Republica Dominicana"}
                                {values?.country === "uy" && "Uruguay"}
                                {values?.country === "ve" && "Venezuela"}
                                {values?.country === "us" && "United States"}
                              </h3>
                              <h3 className="ranges_list age_range">
                                Age:&nbsp;
                                {allFields && allFields.length > 0 && (
                                  <div>
                                    {allFields[indexVal]?.selectedAges?.map(
                                      (childVal, i) => (
                                        <h3 key={i}>
                                          {childVal}
                                          {i != allFields.length - 1 ? "," : ""}
                                          &nbsp;
                                        </h3>
                                      )
                                    )}
                                  </div>
                                )}
                              </h3>
                              <h3 className="ranges_list age_range">
                                {"| "}Gender:&nbsp;
                                <strong>
                                  {values?.countryreturn?.Gender?.map(
                                    (gender, index) => {
                                      return (
                                        <React.Fragment key={index}>
                                          {gender?.length > 1 && (
                                            <span> Both</span>
                                          )}
                                          {gender?.length == 1 &&
                                            gender[0] === "F" && (
                                              <span>Girls</span>
                                            )}
                                          {gender?.length == 1 &&
                                            gender[0] === "M" && (
                                              <span>Boys</span>
                                            )}
                                        </React.Fragment>
                                      );
                                    }
                                  )}
                                </strong>
                              </h3>
                            </div>
                            {values?.inventory?.map((data, index) => {
                              return (
                                <>
                                  <div className="selcted_audience_container m-0 row mb-3 mt-3">
                                    <div className="col-lg-12 p-0">
                                      <div className="projection_wrapper">
                                        <div className="projection_content">
                                          <p className="abs_badge mb-0">
                                            {data?.inventor === "Apps Kidscorp" ? "Apps" : null}
                                            {data?.inventor === "Roblox" ? "Roblox" : null}
                                            {data?.inventor === "YouTube" ? "YouTube" : null}
                                          </p>
                                          <h2 className="projectionTitle mb-0 ms-2">
                                            Reach Projection
                                          </h2>
                                          <svg
                                            stroke="currentColor"
                                            fill="none"
                                            stroke-width="2"
                                            viewBox="0 0 24 24"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            height="20"
                                            width="20"
                                            xmlns="http://www.w3.org/2000/svg"
                                          >
                                            <circle cx="12" cy="12" r="10"></circle>
                                            <line x1="12" y1="8" x2="12" y2="12"></line>
                                            <line x1="12" y1="16" x2="12.01" y2="16"></line>
                                            <title>The reach projection is the result for a 30 days campaign according to your budget.</title>
                                          </svg>
                                        </div>


                                        <div className="apps_total_calc">
                                          <ProgressBar
                                            className="bar_color"
                                            now={data?.percentage}
                                          />
                                          <h4 className="m-0">
                                            {data?.percentage?.toFixed(1) + "%"}
                                          </h4>
                                        </div>
                                        <div className="total_selected_calc">
                                          <p>Total u18 selected audience</p>
                                          <p>
                                            <strong>
                                              {data?.boldProjectpopulation?.toLocaleString(undefined, {
                                                minimumFractionDigits: 0,
                                                maximumFractionDigits: 0
                                              })}
                                            </strong>
                                            /
                                            {data?.projectedPopulation?.toLocaleString()}
                                          </p>
                                        </div>
                                        <div className="line_divide"></div>

                                        <div className="budget_calc">
                                          <p className="mb-0">
                                            Budget <strong>USD {data?.budget?.toLocaleString()}</strong>
                                          </p>
                                        </div>
                                        <div className="line_divide"></div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              );
                            })}

                            {values?.insight && (
                              <div className="selcted_audience_container audience_data m-0 row">
                                <div className="col-lg-8">
                                  <div className="audience_stat">
                                    <div className="activity_wrapper">
                                      <div className="activity_timing">
                                        <div className="activity_card">
                                          <div className="actvity_card_image">
                                            <img
                                              width={68}
                                              height={68}
                                              src="/assets/images/iphone_image.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="atcivityTiming">
                                            <h3>
                                              {values?.insight
                                                ?.averagePlayInAppsMin
                                                ? values?.insight?.averagePlayInAppsMin?.toFixed(
                                                  0
                                                )
                                                : "NA"}{" "}
                                              {values?.insight
                                                ?.averagePlayInAppsMin
                                                ? "min"
                                                : null}
                                            </h3>
                                            <p>playing in apps daily</p>
                                          </div>
                                        </div>
                                        <div className="activity_card">
                                          <div className="actvity_card_image">
                                            <img
                                              width={68}
                                              height={68}
                                              src="/assets/images/youtub_image.png"
                                              alt=""
                                            />
                                          </div>
                                          <div className="atcivityTiming">
                                            <h3>
                                              {values?.insight
                                                ?.averageWatchYtDailyMin
                                                ? values?.insight?.averageWatchYtDailyMin?.toFixed(
                                                  0
                                                )
                                                : "NA"}{" "}
                                              {values?.insight
                                                ?.averageWatchYtDailyMin
                                                ? "min"
                                                : null}
                                            </h3>
                                            <p>watching Youtube daily</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="long_divider"></div>
                                      <div className="top_activitesWrappers">
                                        <h5>Top 3 activities</h5>
                                        <div className="top_activites">
                                          <div className="online offline">
                                            <h3>Online</h3>
                                            <ul>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline1Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline1Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline1Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline1Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline1Pct
                                                    ? values?.insight?.MaxActivityOnline1Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline2Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline2Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline2Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline2Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline2Pct
                                                    ? values?.insight?.MaxActivityOnline2Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOnline3Text
                                                    ? values?.insight
                                                      ?.MaxActivityOnline3Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOnline3Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOnline3Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOnline3Pct
                                                    ? values?.insight?.MaxActivityOnline3Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                            </ul>
                                          </div>
                                          <div className="offline">
                                            <h3>Offline</h3>
                                            <ul>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline1Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline1Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline1Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline1Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline1Pct
                                                    ? values?.insight?.MaxActivityOffline1Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline2Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline2Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline2Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline2Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline2Pct
                                                    ? values?.insight?.MaxActivityOffline2Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                              <li>
                                                <p>
                                                  {values?.insight
                                                    ?.MaxActivityOffline3Text
                                                    ? values?.insight
                                                      ?.MaxActivityOffline3Text
                                                    : "NA"}
                                                </p>
                                                <ProgressBar
                                                  className="bar_color"
                                                  now={
                                                    values?.insight
                                                      ?.MaxActivityOffline3Pct
                                                      ? values?.insight
                                                        ?.MaxActivityOffline3Pct
                                                      : 0
                                                  }
                                                />
                                                <strong>
                                                  {values?.insight
                                                    ?.MaxActivityOffline3Pct
                                                    ? values?.insight?.MaxActivityOffline3Pct?.toFixed(
                                                      1
                                                    )
                                                    : 0}
                                                  %
                                                </strong>
                                              </li>
                                            </ul>
                                          </div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="passion_point">
                                      <ul>
                                        <li>
                                          <h2>Top Passion Points</h2>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint1[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint1[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint1
                                            )
                                              ? values.insight.PassionPoint1[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint2[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TRAVEl" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint2[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint2
                                            )
                                              ? values.insight.PassionPoint2[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint3[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint3[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint3
                                            )
                                              ? values.insight.PassionPoint3[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint4[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "Music" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint4[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint4
                                            )
                                              ? values.insight.PassionPoint4[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                        <li>
                                          {values.insight.PassionPoint5[0] ==
                                            "ARTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/arts.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "INGENUITY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/ingenuity.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "CONTENT CREATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/content-creation.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "FASHION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/fashion.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "ENTERTAINMENT" && (
                                              <img width={25}
                                                height={25} src="/assets/images/entertainment.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "SUPERHEROS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/superhero.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "PETS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/pets.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MAGIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/magic.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "SPORTS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/sports.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TRAVEL" && (
                                              <img width={25}
                                                height={25} src="/assets/images/travel.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MUSIC" && (
                                              <img width={25}
                                                height={25} src="/assets/images/music.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "GAMING" && (
                                              <img width={25}
                                                height={25} src="/assets/images/gaming.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "EDUCATION" && (
                                              <img width={25}
                                                height={25} src="/assets/images/education.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "FOOD" && (
                                              <img width={25}
                                                height={25} src="/assets/images/food.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "MOVIE" && (
                                              <img width={25}
                                                height={25} src="/assets/images/movie.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "BEAUTY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/beauty.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TOYS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/toys.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TECHNOLOGY" && (
                                              <img width={25}
                                                height={25} src="/assets/images/technology.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "TV" && (
                                              <img width={25}
                                                height={25} src="/assets/images/tv.png" />
                                            )}
                                          {values.insight.PassionPoint5[0] ==
                                            "PRINCESS" && (
                                              <img width={25}
                                                height={25} src="/assets/images/princess.png" />
                                            )}
                                          <p>
                                            {Array.isArray(
                                              values?.insight?.PassionPoint5
                                            )
                                              ? values.insight.PassionPoint5[0]
                                              : "NA"}
                                          </p>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                </div>
                                {/* if there is any data inside the graph only when below code execute */}
                                {Object.keys(values?.insight).length !== 0 && (
                                  <div className="col-lg-4">
                                    <div className="com_used">
                                      <h3>Commonly used devices</h3>
                                      <HighchartsReact
                                        containerProps={{
                                          style: { height: "200px" },
                                        }}
                                        highcharts={Highcharts}
                                        options={{
                                          chart: {
                                            type: "column",
                                          },

                                          xAxis: [
                                            {
                                              categories: [
                                                "Smart Tv",
                                                "Smartphone",
                                                "Tablet,ipad",
                                                "Desktop",
                                                "Console",
                                              ],
                                              crosshair: true,
                                            },
                                          ],
                                          yAxis: [
                                            {
                                            },
                                            {
                                              opposite: true,
                                            },
                                          ],
                                          tooltip: {
                                            shared: true,
                                          },
                                          legend: {
                                            align: "left",
                                            x: 80,
                                            verticalAlign: "top",
                                            y: 60,
                                            floating: true,
                                          },

                                          series: [
                                            {
                                              name: "",
                                              type: "column",
                                              showInLegend: false,
                                              yAxis: 1,
                                              data: [
                                                values?.insight
                                                  ?.averageDeviceSmarttv,
                                                values?.insight
                                                  ?.averageDeviceSmartphone,
                                                values?.insight
                                                  ?.averageDeviceTablet,
                                                values?.insight
                                                  ?.averageDeviceDesktop,
                                                values?.insight
                                                  ?.averageDeviceConsole,
                                              ],
                                              color: "#00eacb",
                                            },
                                          ],
                                          plotOptions: {
                                            column: {
                                            },
                                            series: {
                                              borderRadiusTopLeft: 5,
                                              borderRadiusTopRight: 5,
                                            },
                                          },
                                        }}
                                      />
                                    </div>
                                  </div>
                                )}
                              </div>
                            )}
                            {values?.inventioryCount > 1 && (
                              <div className="reachFooter">
                                <ul>
                                  <li>
                                    <h4>Country Summary</h4>
                                  </li>
                                  <li>
                                    <p className="d-flex">
                                      Age:{" "}
                                      <strong>
                                        {allFields && allFields.length > 0 && (
                                          <div className="d-flex">
                                            {allFields[indexVal]?.selectedAges?.map(
                                              (childVal, i) => (
                                                <strong key={i}>
                                                  {childVal}
                                                  {i != allFields.length - 1 ? "" : ","}
                                                  &nbsp;
                                                </strong>
                                              )
                                            )}
                                          </div>
                                        )}
                                      </strong>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Gender:{" "}
                                      <strong>
                                        {values?.countryreturn?.Gender?.map(
                                          (gender, index) => {
                                            return (
                                              <React.Fragment key={index}>
                                                {gender?.length > 1 && (
                                                  <span> Both</span>
                                                )}
                                                {gender?.length == 1 &&
                                                  gender[0] === "F" && (
                                                    <span>Girls</span>
                                                  )}
                                                {gender?.length == 1 &&
                                                  gender[0] === "M" && (
                                                    <span>Boys</span>
                                                  )}
                                              </React.Fragment>
                                            );
                                          }
                                        )}
                                      </strong>
                                    </p>
                                  </li>
                                  <li>
                                    <p>
                                      Total Budget:{" USD "}
                                      <strong>
                                        {values?.countryreturn?.Budget?.toLocaleString(undefined, { minimumFractionDigits: 0 })}
                                      </strong>
                                    </p>
                                  </li>
                                  {values?.countryreturn?.AppReachsum != 0 && <li>
                                    <p>
                                      Apps reach: <strong>{values?.countryreturn?.AppReachsum ? values?.countryreturn?.AppReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                  {values?.countryreturn?.RobloxReachsum != 0 && <li>
                                    <p>
                                      Roblox reach: <strong>{values?.countryreturn?.RobloxReachsum ? values?.countryreturn?.RobloxReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                  {values?.countryreturn?.YoutubeReachsum != 0 && <li>
                                    <p>
                                      Youtube reach: <strong>{values?.countryreturn?.YoutubeReachsum ? values?.countryreturn?.YoutubeReachsum?.toLocaleString(undefined, {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 0
                                      }) : "-"}</strong>
                                    </p>
                                  </li>}
                                </ul>
                              </div>
                            )}
                          </div>

                        );
                      }
                    })}
                  </div>
                  {resultData?.length !== 0 && <div className="mt-3">
                    <button onClick={exportData} className="pdf_export">
                      {!uploading ? "PDF" : "Exporting"}
                      <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 24 24" height="14px" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="M19 9h-4V3H9v6H5l7 8zM4 19h16v2H4z"></path></svg>
                    </button>
                  </div>}
                </div>
              </Tab>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abstract;