import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { AiOutlineFileZip } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Loader from "../Loader";
import "./campaign.css";
import { AiOutlineLeft } from "react-icons/ai";
import {
	clearState,
	CreativeSelector,
	getLineItem,
	listCreative,
	CreativeOnOff,
} from "../../app/features/Creative/CreativeSlice";
import { useSelector, useDispatch } from "react-redux";
import Moment from "react-moment";

const CreativeView = () => {
	const params = useParams()
	const navigate = useNavigate();
	const [creative_data, setCreative_data] = useState([]);
	const [link, setLink] = useState("");
	const [loadershown, setloadershown] = useState(false);
	const {
		list_creative,
		line_Item,
		isSuccesslistCreative,
		isErrorlistCreative,
	} = useSelector(CreativeSelector);

	useEffect(() => {
		getCreative();
	}, []);
	const getCreative = async () => {
		setloadershown(true);
		const response = await fetch(
			`${process.env.REACT_APP_DOT_NET_BASE_URL}campaignManager/getlineitemid/${params.id}`,
			{
				method: "GET",
				headers: {
					Authorization: "Bearer " + localStorage.getItem("token"),
				},
			}
		);
		const result = await response.json();
		setCreative_data(result?.data);
		setLink(result?.data?.mediaLink);
		setloadershown(false);
	};

	const handleZip = (zipfile) => {
		// window.open(`/viewzip/${zipFile}`,'',"width=1000,height=500");
		navigate("/viewzip", { state: { zipfile } });
	};

	const handleTags = (tags) => {
		navigate("/viewtag", { state: { tags } });
	};
	return (
		<>
			<div className="content_outer">
				<Loader showLoader={loadershown} />
				<div className="content">
					<div className="App">
						<div className="header_nav">
							<button onClick={() => navigate(-1)}>
								<AiOutlineLeft />
							</button>
							<div className="bredcrum">
								<p>Lineitem Creatives</p>
							</div>
						</div>
						<div className="mediaplan_details mb-3">
							<div className="advert_tabel">
								<ul>
									<li>
										<strong>Inventory Type</strong>:
										<p> {creative_data?.inventoryNavigation?.description}</p>
									</li>
									<li>
										<strong>Offer type</strong>:
										<p> {creative_data?.offerTypeNavigation?.descripcion}</p>
									</li>
									<li>
										<strong>Country</strong>:
										<p>
											{" "}
											{creative_data?.countryList?.map((data) => {
												return `${data.country.labelKey},`;
											})}
										</p>
									</li>
									<li>
										<strong>Start Date</strong>:
										<p>
											{" "}
											<Moment format="DD/MM/YYYY">
												{creative_data?.date_from}
											</Moment>
										</p>
									</li>
									<li>
										<strong>End Date</strong>:
										<p>
											{" "}
											<Moment format="DD/MM/YYYY">
												{creative_data?.date_to}
											</Moment>
										</p>
									</li>
									<li>
										<strong>Format</strong>:
										<p> {creative_data?.formatNavigation?.name}</p>
									</li>
									<li>
										<strong>Goal</strong>:
										<p> {creative_data?.inversion_goal}</p>
									</li>
									<li>
										<strong>Audience </strong>:
										<p>
											{creative_data?.audienceList?.map((data) => {
												return `${data.audience.audience},`;
											})}
										</p>
									</li>
									<li>
										<strong>Gender</strong>:
										<p> {creative_data?.genderNavigation?.description}</p>
									</li>
									<li>
										<strong>Status</strong>:
										<p>
											{" "}
											{creative_data?.lineItemStatusNavigation?.description}
										</p>
									</li>
								</ul>
							</div>
						</div>

						<div className="row">
							{/* this loop is for video */}
							{!creative_data?.creatives ? (
								<div className="notfounwrapper">
									<img
										className="not_found"
										src="/assets/images/notfound.svg"
									/>
								</div>
							) : (
								creative_data?.creatives?.map((data) => {
									if (data?.creativeType?.id === 1) {
										return (
											<>
												<div className="col-md-2 mb-3">
													<div className="creativesimg pdf">
														{/* <img className="img-fluid" src="https://upload.wikimedia.org/wikipedia/en/f/f8/Dummy_Title_Card.jpeg" alt="" /> */}
														{/* <video className="w-100" controls>
															<source
																src={link + "/" + data.object} 
																type="video/mp4"
															/>
															
														</video> */}

{/* before we are using video tag here but i changed it do iframe because video tag is not working as we want but this is only work for a valid url if the url is not valid so it is not present the video and same thing happen on video tag
nitin - 24-08-2023
*/}
														<iframe
														src={
															link + "/" + data.object
														}
														className="w-100"
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
														allowfullscreen
													></iframe>

														{/* <AiOutlineFileZip/> */}
													</div>
													<div className="creative_text">
														<p>
															<strong>Creative ID:</strong>
															{data?.creativeType?.id}
														</p>
														<p>
															<strong>Line Item ID:</strong>
															{data?.lineitemId}
														</p>
														<p>
															<strong>Format:</strong>
															{data?.formatId}
														</p>
														<p>
															<strong>Click Url:</strong>
															<a
																href={link + "/" + data?.object}
																target="blank"
															>
																Link
															</a>
														</p>
														<p>
															<strong>Status:</strong>44545
														</p>
													</div>
												</div>
											</>
										);
									}
								})
							)}

							{/* this loop is for images */}
							{creative_data?.data?.creatives?.map((data) => {
								if (data?.creativeType?.id === 4) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src={link + "/" + data?.object}
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p>
														<strong>Click Url:</strong>
														<a href={link + "/" + data?.object} target="blank">
															Link
														</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for tags */}
							{creative_data?.data?.creatives?.map((data) => {
								if (data?.creativeType?.id === 5) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src="https://ahrefs.com/blog/wp-content/uploads/2020/02/blog-meta-tags-400x200.png"
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p
														onClick={() => {
															handleTags(data?.object);
														}}
													>
														<strong>Click Url:</strong>View
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for youtube link */}
							{creative_data?.data?.creatives?.map((data) => {
								if (data?.creativeType?.id === 3) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													{/* <video className='w-100' controls autoplay >
                                        <source src={data?.object} type="video/mp4" />
                                            </video> */}

													<iframe
														src={
															data?.object.includes("watch")
																? data?.object.replace("watch?v=", "embed/")
																: data?.object
														}
														className="w-100"
														frameborder="0"
														allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
														allowfullscreen
													></iframe>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p>
														<strong>Click Url:</strong>
														<a href={data?.object} target="blank">
															Link
														</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}

							{/* this loop is for zip file */}
							{creative_data?.data?.creatives?.map((data) => {
								if (data?.creativeType?.id === 2) {
									return (
										<>
											<div className="col-md-2 mb-3">
												<div className="creativesimg pdf">
													<img
														className="img-fluid"
														src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ0crO16mI5mGJwYUh1Al9Faxf765RbmQZahg&usqp=CAU"
														alt=""
													/>
												</div>
												<div className="creative_text">
													<p>
														<strong>Creative ID:</strong>
														{data?.creativeType?.id}
													</p>
													<p>
														<strong>Line Item ID:</strong>
														{data?.lineitemId}
													</p>
													<p>
														<strong>Format:</strong>
														{data?.formatId}
													</p>
													<p
														onClick={() => {
															handleZip(link + "/" + data?.object);
														}}
													>
														<strong>Click Url:</strong>
														<a>Link</a>
													</p>
													<p>
														<strong>Status:</strong>44545
													</p>
												</div>
											</div>
										</>
									);
								}
							})}
						</div>
						<div></div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreativeView;
