import React, { useState, useEffect } from 'react'
import { Row, Col } from "react-bootstrap";
import { AiOutlinePlus } from "react-icons/ai";
import { MdOutlineModeEdit } from "react-icons/md";
import Dropdown from 'react-bootstrap/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { get_user_groups } from '../../app/features/User_subgroups/get_user_group';
import { get_user_permissions } from '../../app/features/User_subgroups/get_user_permissions_slice';
import Multiselect from "multiselect-react-dropdown";
import { toast } from "react-hot-toast";
import { save_user_subgroup } from '../../app/features/User_subgroups/save_subgroup_slice';
import { clear_save_dub_group_state } from '../../app/features/User_subgroups/save_subgroup_slice';
import { get_user_group_permissions } from '../../app/features/User_subgroups/get_sub_group_slice';
import { get_user_subgroup } from '../../app/features/User_subgroups/get_user_group_slice';
import { update_subgroup } from '../../app/features/User_subgroups/update_subgroup_slice';
import { clear_update_subgroup_state } from '../../app/features/User_subgroups/update_subgroup_slice';
import { clear_permission_state } from '../../app/features/User_subgroups/get_sub_group_slice';
import { StepFunctions } from 'aws-sdk';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useNavigate } from 'react-router-dom';
import { faSleigh } from '@fortawesome/free-solid-svg-icons';

const EditSubgroup = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [groupId, setGroupId] = useState()
    const [groupName, setGroupName] = useState()
    const [permissions, set_permissions] = useState([])
    const [permission_name, setPermission_name] = useState("")
    const [edit, setEdit] = useState(false)
    const [selected_group_id, setSelected_group_id] = useState(null)
    const [selected_group_name, setSelected_group_name] = useState()
    const [selected_permissions, setSelected_permissions] = useState([])
    const [updated_permissions, setUpdated_permissions] = useState([])
    const [buttonBlue, setButtonBlue] = useState(false)
    const [updatedButtonBlue,setUpdatedButtonBlue] = useState(faSleigh)
    // this below const is haveb all the user groups
    const user_group_data = useSelector((state) => { return state.user_group_list })
    const user_permission_data = useSelector((state) => { return state.user_permissions })
    const save_user_permission = useSelector((state) => { return state.save_subgroup })
    const selected_user_groups = useSelector((state) => { return state.saved_subGroups })
    const selected_user_permissions = useSelector((state) => { return state.saved_user_permission })
    const updated_subgroup = useSelector((state) => { return state.updated_subgroup_data })

    useEffect(() => {
        // this useefferct is used to get all the user groups and permissions
        dispatch(get_user_groups())
        dispatch(get_user_permissions())
        dispatch(get_user_subgroup())
    }, [])

    // this below function is used to store multiple permissions for a group
    const handle_multiselect_permissions_updated = (e) => {
        var allSelectedPermissions = e.map((val) => val.id).filter(Boolean);
        setUpdated_permissions(allSelectedPermissions);
    }

    const handle_remove_multiselect_permissions_updated = (e) => {
        var allSelectedPermissions = e.map((val) => val.id).filter(Boolean);
        setUpdated_permissions(allSelectedPermissions);
    }
    const handle_multiselect_permissions = (e) => {
        var allSelectedPermissions = e.map((val) => val.id).filter(Boolean);
        set_permissions(allSelectedPermissions);
    }

    const handle_remove_multiselect_permissions = (e) => {
        var allSelectedPermissions = e.map((val) => val.id).filter(Boolean);
        set_permissions(allSelectedPermissions);
    }

    const handle_save = () => {
        if (permission_name === "") {
            toast.error("Please Enter Sub-Group Name")
        }
        else if (permissions?.length === 0) {
            toast.error("PLease Select Permissions")
        }
        else if (!groupId) {
            toast.error("Please Assign Group")
        }
        else {
            dispatch(save_user_subgroup({ permission_name, groupId, permissions }))
        }
    }

    useEffect(() => {
        if (save_user_permission?.message?.message === "OK") {
            toast.success("SubGroup Created Successfully !!!")
            setPermission_name("")
            setGroupId(null)
            setGroupName("")
            set_permissions([])
            dispatch(clear_save_dub_group_state())
            dispatch(get_user_subgroup())
            navigate('/userlist', {
                state: {
                    refresh: true,
                    show_new_user: true,
                    show_permission : true
                }
            });
            
        }

        if (save_user_permission?.error) {
            toast.error(save_user_permission?.error?.message)
            setPermission_name("")
            setGroupId(null)
            setGroupName("")
            set_permissions([])
            dispatch(clear_save_dub_group_state())

        }

    }, [save_user_permission])

    useEffect(() => {
        if (edit) {
            dispatch(get_user_group_permissions({ selected_group_id }))
        }
    }, [selected_group_id])

    useEffect(() => {
        if (selected_user_permissions?.isSuccess) {
            let user_permissions = []
            let permissions = []
            selected_user_permissions?.data?.data?.map((data) => {
                user_permissions?.push({
                    id: data?.id,
                    data: data?.description
                })
            })
            selected_user_permissions?.data?.data?.map((data) => {
                permissions?.push(data?.id)
            })
            setSelected_permissions(user_permissions)
            setUpdated_permissions(permissions)
        }

    }, [selected_user_permissions])

    const handle_edit = () => {
        if (!selected_group_id) {
            toast.error("Please Select Group")
        }
        else if (updated_permissions?.length === 0) {
            toast.error("Please Select Permissions")
        }
        else {
            dispatch(update_subgroup({ selected_group_id, selected_group_name, updated_permissions }))
        }

    }

    useEffect(() => {
        if (updated_subgroup?.isSuccess) {
            toast.success("Sub Group Updated Successfully !!")
            setEdit(false)
            dispatch(clear_update_subgroup_state())
            dispatch(clear_permission_state())
            navigate('/userlist', {
                state: {
                    refresh: true,
                    show_new_user: true,
                    show_permission : true
                }
            });
        }
        if (updated_subgroup?.error) {
            toast.error(updated_subgroup?.error?.message)
            dispatch(clear_update_subgroup_state())
            dispatch(clear_permission_state())
        }

    }, [updated_subgroup])

    const handleChangeTabs = (eventKey) => {
        if (eventKey === "profile") {
            setEdit(true)
        }
        else {
            setEdit(false)
        }
    };

    useEffect(() => {
        if (permission_name !== "" && groupId !== undefined && permissions?.lenght !== 0) {
            setButtonBlue(true)
        }
        else {
            setButtonBlue(false)
        }

        if(selected_group_id !== null && selected_permissions?.length !== 0){
            setUpdatedButtonBlue(true)
        }
        else{
            setUpdatedButtonBlue(false)
        }

    }, [permission_name, groupId, permissions,selected_permissions,selected_group_id])

    return (
        <div className="content_outer">
            <div className="cmn_header_top mb-3">
                <Row className='m-0 w-100'>
                    <Col lg={11} className='m-auto'>
                        <h3>
                            Edit Sub-Groups
                        </h3>
                    </Col>
                </Row>
            </div>
            <Row className='m-0 w-100'>
                <Col lg={11} className='m-auto'>
                    <div className='subgroup_tabs'>
                        <Tabs
                            defaultActiveKey="home"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                            onSelect={(handleChangeTabs)}
                        >
                            <Tab eventKey="home" title={<> <AiOutlinePlus className="me-2" size={20} />New Sub-Group</>}>
                                <div className='subgroup_filter cmn__filters campiagn_filter filter_menu align-items-start'>
                                    <div>

                                        <div className="mb-3 select_country_container mt-3 input_wrap w-100">
                                            <label className="center_label lbl_position form-label" >Sub-Group Name</label>
                                            <input type="text" className='form-control' placeholder='Name...' value={permission_name} onChange={(e) => setPermission_name(e.target.value)} />
                                        </div>
                                        <div className="mb-3 select_country_container mt-3 input_wrap mt-0 w-100">
                                            <label className="center_label lbl_position form-label" >Assign Group</label>
                                            <Dropdown className='owner_filter w-100'>
                                                <Dropdown.Toggle id="dropdown-basic">
                                                    {groupName ? groupName : "Select"} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                                        <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='w-100'>
                                                    {
                                                        user_group_data?.data?.data?.length > 0 && user_group_data?.data?.data?.map((data, i) => {
                                                            return (
                                                                <li className='d-flex gap-2'>
                                                                    <input
                                                                        type="radio"
                                                                        checked={data?.Id === groupId}
                                                                        onChange={() => { setGroupId(data?.Id); setGroupName(data?.Name) }}
                                                                    />
                                                                    {data?.Name}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {/* <div className='border-top mt-3 pt-3 text-end w-100'>
                                            <button className='transparent'>Clean</button>   <button className='clear__btn1'>Apply</button>
                                        </div> */}
                                                </Dropdown.Menu>
                                            </Dropdown>

                                        </div>
                                    </div>



                                    <div className='filter_menu '>
                                        <div className="mb-3 select_country_container mt-3 input_wrap w-100 ">
                                            <label className="center_label lbl_position form-label" >Permissions</label>
                                            <Multiselect
                                                className='w-100'
                                                options={user_permission_data?.data?.data?.map((i) => {
                                                    const name = i?.description ?? "Unknown";
                                                    return { ...i, name };
                                                })}
                                                onSelect={(e) => handle_multiselect_permissions(e)}
                                                onRemove={(e) => handle_remove_multiselect_permissions(e)}
                                                showCheckbox={true}
                                                displayValue="name"
                                            />
                                        </div>
                                    </div>



                                    <div className='permission_btn mt-3 d-block'>
                                        {/* <button className='transparent'>Cancel</button> */}
                                        {!edit ? (
                                            <button className='grey_btn btn btn-primary' disabled={!buttonBlue} style={buttonBlue ? { backgroundColor: "blue" } : {backgroundColor: "#989fb3"}} onClick={() => handle_save()}>Save</button>
                                        ) : (
                                            <button className='grey_btn btn btn-primary' onClick={() => handle_edit()}>Update</button>
                                        )}
                                    </div>

                                </div>
                            </Tab>
                            <Tab eventKey="profile" title={<> <MdOutlineModeEdit className="me-2" size={20} />Edit existing Sub-Group</>}>
                                <div className='subgroup_filter cmn__filters campiagn_filter filter_menu align-items-start'>

                                    <div>
                                        <div className="mb-3 select_country_container mt-3 input_wrap w-100">
                                            <label className="center_label lbl_position form-label" >Sub-Group Name</label>
                                            {/* <select className='form-control' onChange={(e) => setSelected_group_id(e.target.value)}>
                                    <option>Select</option>
                                    {
                                        selected_user_groups?.data?.data?.map((val,i) => {
                                            return(
                                                <option value={val?.id}>{val?.description}</option>
                                            )
                                        })
                                    }
                                </select> */}

                                            <Dropdown className='owner_filter w-100'>
                                                <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                                    {selected_group_name ? selected_group_name : "Select"} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                                        <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                                    </svg>
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className='w-100'>
                                                    {
                                                        selected_user_groups?.data?.data?.length > 0 && selected_user_groups?.data?.data?.map((data, i) => {
                                                            return (
                                                                <li className='d-flex gap-2'>
                                                                    <input
                                                                        type="radio"
                                                                        checked={data?.id === selected_group_id}
                                                                        onChange={() => { setSelected_group_id(data?.id); setSelected_group_name(data?.description) }}
                                                                    />
                                                                    {data?.description}
                                                                </li>
                                                            )
                                                        })
                                                    }
                                                    {/* <div className='border-top mt-3 pt-3 text-end w-100'>
                                            <button className='transparent'>Clean</button>   <button className='clear__btn1'>Apply</button>
                                        </div> */}
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </div>

                                    </div>

                                    <div className='filter_menu '>
                                        <div className="mb-3 select_country_container mt-3 input_wrap w-100 ">
                                            <label className="center_label lbl_position form-label" >Permissions</label>
                                            <Multiselect
                                                className='w-100'
                                                options={user_permission_data?.data?.data?.map((i) => {
                                                    const data = i?.description ?? "Unknown";
                                                    return { ...i, data };
                                                })}
                                                selectedValues={selected_permissions}
                                                onSelect={(e) => handle_multiselect_permissions_updated(e)}
                                                onRemove={(e) => handle_remove_multiselect_permissions_updated(e)}
                                                showCheckbox={true}
                                                displayValue="data"
                                            />
                                        </div>
                                    </div>


                                    <div className='permission_btn mt-3 d-block'>
                                        {/* <button className='transparent'>Cancel</button> */}
                                        <button className='grey_btn btn btn-primary' disabled={!updatedButtonBlue} style={updatedButtonBlue ? { backgroundColor: "blue" } : {backgroundColor: "#989fb3"}} onClick={() => handle_edit()}>Update</button>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    {/* <div className='d-flex gap-3 btns_bg mt-3'>
                        <button className={!edit ? "cmn_btn ms-0" : "cmn_btn grey_btn ms-0"} onClick={() => setEdit(false)}>
                            <AiOutlinePlus className="me-2" size={20} />
                            New Sub-Group
                        </button>
                        <button className={edit ? "cmn_btn ms-0" : "cmn_btn grey_btn ms-0"} onClick={() => setEdit(true)}>
                            <MdOutlineModeEdit className="me-2" size={20} />
                            Edit existing Sub-Group
                        </button>
                    </div> */}
                    <div className='subgroup_filter cmn__filters campiagn_filter filter_menu align-items-start d-none'>
                        {!edit ? <div>

                            <div className="mb-3 select_country_container mt-3 input_wrap w-100">
                                <label className="center_label lbl_position form-label" >Sub-Group Name</label>
                                <input type="text" className='form-control' placeholder='Name...' value={permission_name} onChange={(e) => setPermission_name(e.target.value)} />
                            </div>
                            <div className="mb-3 select_country_container mt-3 input_wrap mt-0 w-100">
                                <label className="center_label lbl_position form-label" >Assign Group</label>
                                <Dropdown className='owner_filter w-100'>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {groupName ? groupName : "Select"} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                            <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100'>
                                        {
                                            user_group_data?.data?.data?.length > 0 && user_group_data?.data?.data?.map((data, i) => {
                                                return (
                                                    <li className='d-flex gap-2'>
                                                        <input
                                                            type="radio"
                                                            checked={data?.Id === groupId}
                                                            onChange={() => { setGroupId(data?.Id); setGroupName(data?.Name) }}
                                                        />
                                                        {data?.Name}
                                                    </li>
                                                )
                                            })
                                        }
                                        {/* <div className='border-top mt-3 pt-3 text-end w-100'>
                                            <button className='transparent'>Clean</button>   <button className='clear__btn1'>Apply</button>
                                        </div> */}
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div> :
                            <div>
                                <div className="mb-3 select_country_container mt-3 input_wrap w-100">
                                    <label className="center_label lbl_position form-label" >Sub-Group Name</label>
                                    {/* <select className='form-control' onChange={(e) => setSelected_group_id(e.target.value)}>
                                    <option>Select</option>
                                    {
                                        selected_user_groups?.data?.data?.map((val,i) => {
                                            return(
                                                <option value={val?.id}>{val?.description}</option>
                                            )
                                        })
                                    }
                                </select> */}

                                    <Dropdown className='owner_filter w-100'>
                                        <Dropdown.Toggle id="dropdown-basic" className='w-100'>
                                            {selected_group_name ? selected_group_name : "Select"} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                                <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                            </svg>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='w-100'>
                                            {
                                                selected_user_groups?.data?.data?.length > 0 && selected_user_groups?.data?.data?.map((data, i) => {
                                                    return (
                                                        <li className='d-flex gap-2'>
                                                            <input
                                                                type="radio"
                                                                checked={data?.id === selected_group_id}
                                                                onChange={() => { setSelected_group_id(data?.id); setSelected_group_name(data?.description) }}
                                                            />
                                                            {data?.description}
                                                        </li>
                                                    )
                                                })
                                            }
                                            {/* <div className='border-top mt-3 pt-3 text-end w-100'>
                                            <button className='transparent'>Clean</button>   <button className='clear__btn1'>Apply</button>
                                        </div> */}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>

                            </div>
                        }
                        {!edit ? (
                            <div className='filter_menu '>
                                <div className="mb-3 select_country_container mt-3 input_wrap w-100 ">
                                    <label className="center_label lbl_position form-label" >Permissions</label>
                                    <Multiselect
                                        options={user_permission_data?.data?.data?.map((i) => {
                                            const name = i?.description ?? "Unknown";
                                            return { ...i, name };
                                        })}
                                        onSelect={(e) => handle_multiselect_permissions(e)}
                                        onRemove={(e) => handle_remove_multiselect_permissions(e)}
                                        showCheckbox={true}
                                        displayValue="name"
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className='filter_menu '>
                                <div className="mb-3 select_country_container mt-3 input_wrap w-100 ">
                                    <label className="center_label lbl_position form-label" >Permissions</label>
                                    <Multiselect
                                        options={user_permission_data?.data?.data?.map((i) => {
                                            const data = i?.description ?? "Unknown";
                                            return { ...i, data };
                                        })}
                                        selectedValues={selected_permissions}
                                        onSelect={(e) => handle_multiselect_permissions_updated(e)}
                                        onRemove={(e) => handle_remove_multiselect_permissions_updated(e)}
                                        showCheckbox={true}
                                        displayValue="data"
                                    />
                                </div>
                            </div>
                        )}

                        <div className='permission_btn mt-3 d-block'>
                            {/* <button className='transparent'>Cancel</button> */}
                            <button className='grey_btn btn btn-primary' onClick={() => handle_edit()}>Update</button>
                        </div>
                    </div>
                    {/* {!edit && <div className='subgroup_filter justify-content-start'>

                        <div className='filter_menu'>

                            <div className="mb-3 select_country_container mt-3 input_wrap mt-0">
                                <label className="center_label lbl_position form-label" >Assign Group</label>
                                <Dropdown className='owner_filter w-100'>
                                    <Dropdown.Toggle id="dropdown-basic">
                                        {groupName ? groupName : "Select"} <svg width="16" height="12" xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'>
                                            <path fill='none' stroke='#343a40' strokeLinecap='round' strokeLinejoin='round' strokeWidth='2' d='m2 5 6 6 6-6' />
                                        </svg>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='w-100'>
                                        {
                                            user_group_data?.data?.data?.length > 0 && user_group_data?.data?.data?.map((data, i) => {
                                                return (
                                                    <li className='d-flex gap-2'>
                                                        <input
                                                            type="radio"
                                                            checked={data?.Id === groupId}
                                                            onChange={() => { setGroupId(data?.Id); setGroupName(data?.Name) }}
                                                        />
                                                        {data?.Name}
                                                    </li>
                                                )
                                            })
                                        }
                                        <div className='border-top mt-3 pt-3 text-end w-100'>
                                            <button className='transparent'>Clean</button>   <button className='clear__btn1'>Apply</button>
                                        </div>
                                    </Dropdown.Menu>
                                </Dropdown>

                            </div>
                        </div>
                    </div>} */}
                </Col>
            </Row>
        </div>
    )
}

export default EditSubgroup