import React, { useState, useEffect, useCallback } from 'react';
import { BsChevronBarLeft, BsChevronLeft, BsChevronRight, BsChevronBarRight } from 'react-icons/bs';

const Pagination = ({ totalItems, itemsPerPage: initialItemsPerPage, onPageChange }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(initialItemsPerPage);
  const [totalPages, setTotalPages] = useState(1);

  const calculatePagination = useCallback(() => {
    const totalPgs = Math.max(1, Math.ceil(totalItems / pageSize));
    setTotalPages(totalPgs);
    
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = Math.min(startIndex + pageSize, totalItems);
    
    return {
      currentPage,
      pageSize,
      totalPages: totalPgs,
      startIndex,
      endIndex
    };
  }, [totalItems, pageSize, currentPage]);

  useEffect(() => {
    const paginationInfo = calculatePagination();
    onPageChange(paginationInfo);
  }, [calculatePagination, onPageChange]);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handlePageSizeChange = (newPageSize) => {
    setPageSize(newPageSize);
    setCurrentPage(1);
  };

  return (
    <div className="tablePagination">
      <ul className="ulPagination">
        <span className="spanPagination">Rows per page</span>
        <li>
          <select 
            value={pageSize} 
            className="selectPaginationValues" 
            onChange={(e) => handlePageSizeChange(Number(e.target.value))}
          >
            <option value={5}>5</option>
            <option value={10}>10</option>
            <option value={15}>15</option>
            <option value={20}>20</option>
          </select>
        </li>
        <li>
          <span>{currentPage} of {totalPages}</span>
        </li>
        {totalPages > 1 && (
          <>
            <li>
              <BsChevronBarLeft cursor={'pointer'} onClick={() => handlePageChange(1)} />
            </li>
            <li>
              <BsChevronLeft cursor={'pointer'} onClick={() => handlePageChange(Math.max(1, currentPage - 1))} />
            </li>
            <li>
              <BsChevronRight cursor={'pointer'} onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))} />
            </li>
            <li>
              <BsChevronBarRight cursor={'pointer'} onClick={() => handlePageChange(totalPages)} />
            </li>
          </>
        )}
      </ul>
    </div>
  );
};

export default Pagination;