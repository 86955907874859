import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const initialState = {
    data: {},
    loading: false,
    isSuccess: false,
    isError: false,
    error: null
}

export const getUserProfileInfo = createAsyncThunk("getUserProfileInfo", async ({ userId }, thunkAPI) => {
    try {
        const myHeaders = new Headers();
        myHeaders.append("Authorization", "Bearer " + localStorage.getItem('token'));

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        const response = await fetch(`${process.env.REACT_APP_DOT_NET_BASE_URL}User/user_profile/${userId}`, requestOptions)
        if (!response.ok) {
            const errorMessage = await response.json();
            const statusCode = response.status;
            if (errorMessage) {
                throw new Error(errorMessage.message);
            }
        }
        const result = await response.json();
        return result;
    } catch (error) {
        return thunkAPI.rejectWithValue({
            message: error.message,
        });
    }
})

export const get_user_profile_info = createSlice({
    name: "get_user_profile_info",
    initialState,
    reducers: {
        clear_profile_info_state: (state) => {
            Object.assign(state, initialState);
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserProfileInfo.pending, (state) => {
                state.loading = true
            })
            .addCase(getUserProfileInfo.fulfilled, (state, action) => {
                state.data = action.payload?.data
                state.isError = false
                state.isSuccess = true
                state.loading = false
            })
            .addCase(getUserProfileInfo.rejected, (state, action) => {
                state.error = action.payload
                state.isError = true
                state.isSuccess = false
                state.loading = false
            })
    }
})
export const { clear_profile_info_state } = get_user_profile_info.actions
export default get_user_profile_info.reducer