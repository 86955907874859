import { useEffect, useState } from "react";
import { Modal, Row, Col } from "react-bootstrap";
import SearcherInput from "../General/SearcherInput";
import {  getAdvertisers,
          getCampaigns,
          getMarkets,
          getInventories,
          getLineitemBySearch,
          getLineitemByFilter,
          getAdvertiserSaas,
          getClient,
          getCampaignSaas,
          getLineitemBySearchSaas,
          getLineitemByFilterSaas,
} from '../../services/reportsService';
import billableOptions from "./constants/billingOptionsList";
import Spinner from 'react-bootstrap/Spinner';
import Button from "../General/Button";
import i18n from "../../i18n";

const ModalFiltersReports = (props) => {
  const [data, setData] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredData, setFilteredData] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  useEffect(() => {
    data !== null &&
    setFilteredData(
        data.filter(item =>
        (item.name ?? '').toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.id.toString().includes(searchTerm)
      )
    );
  }, [searchTerm, data]);

  const handleSearchFilter = (e) => {
    if ((props.title == i18n.advanceReportsLineItem || (props.title == i18n.advanceReportsLineItemSaas && props.role == 1)) && !(props.selectedAdvertisers.length > 0 ||
      props.selectedAdvertiserSaas.length > 0 ||
      props.selectedClients.length > 0 ||
      props.selectedCampaigns.length > 0 ||
      props.selectedCampaignSaas.length > 0)) {
        e.length == 0 && setData(props.selectedItems);

        if (props.title == i18n.advanceReportsLineItem && e.length > 2) {
          fetchData(getLineitemBySearch(e));
        }

        if (props.title == i18n.advanceReportsLineItemSaas && e.length > 2) {
          fetchData(getLineitemBySearchSaas(props.userCredentialsAdvertiserId, e, props.userCredentialsAdvertiserClientsId));
        }

    } else if ((props.title == i18n.advanceReportsLineItemSaas && props.role != 1) && !(props.selectedClients.length > 0
      || props.selectedCampaigns.length > 0 || props.selectedCampaignSaas.length > 0)) {
        e.length == 0 && setData(props.selectedItems);        
        e.length > 2 && fetchData(getLineitemBySearchSaas(props.userCredentialsAdvertiserId, e, props.userCredentialsAdvertiserClientsId));
      } else {
        setSearchTerm(e);
      }
  };

  const fetchData = async (dataFunction) => {
    try {
      setShowSpinner(true);
      const result = await dataFunction;
      if (props.title == i18n.advanceReportsLineItem) {
        let combinedData = [...props.selectedItems, ...result];
        const uniqueData = combinedData.filter(
          (item, index, self) => index === self.findIndex((t) => t.id === item.id)
        );
        setData(uniqueData);
      } else {
        setData(result);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
    setShowSpinner(false);
  };

  useEffect(() => {
  if (props.show === true) {
    switch (props.title) {
      case i18n.advanceReportsAdvertiser:
        fetchData(getAdvertisers());
        break;
      case i18n.advanceReportsCampaign:
        fetchData(getCampaigns(props.selectedAdvertisers));
        break;
      case i18n.advanceReportsBillingStatus:
        setData(billableOptions);
        break;
      case i18n.advanceReportsMarket:
        fetchData(getMarkets());
        break;
      case i18n.advanceReportsInventoryType:
        fetchData(getInventories());
        break;
      case i18n.advanceReportsLineItem:
        (props.selectedAdvertisers.length > 0 ||
          props.selectedAdvertiserSaas.length > 0 ||
          props.selectedClients.length > 0 ||
          props.selectedCampaigns.length > 0 ||
          props.selectedCampaignSaas.length > 0) ? (            
          fetchData(getLineitemByFilter(
            props.selectedAdvertisers.concat(props.selectedAdvertiserSaas).map(item => item.name),
            props.selectedClients,
            props.selectedCampaigns.concat(props.selectedCampaignSaas).map(item => item.name)
          ))) : setData(props.selectedItems);
        break;

        // Saas
      case i18n.advanceReportsAdvertiserSaas:
        fetchData(getAdvertiserSaas());
        break;
      case i18n.advanceReportsClient:
        fetchData(getClient(props.selectedAdvertiserSaas));
        break;
      case i18n.advanceReportsCampaignSaas:
        fetchData(getCampaignSaas(props.selectedAdvertiserSaas, props.selectedClients));
        break;
      case i18n.advanceReportsLineItemSaas:
        props.role == 1 ? (
          (props.selectedAdvertiserSaas.length > 0 ||
          props.selectedClients.length > 0 ||
          props.selectedCampaignSaas.length > 0) ? (            
          fetchData(getLineitemByFilterSaas(
            props.selectedAdvertiserSaas?.map(item => item.name) || [],
            props.selectedClients.map(client => client.id),
            props.selectedCampaignSaas?.map(item => item.name) || [],
          ))) : setData(props.selectedItems)
        ) : (
          (props.selectedClients.length > 0 ||
            props.selectedCampaignSaas.length > 0) ? (
              fetchData(getLineitemByFilterSaas(
                props.selectedAdvertiserSaas?.map(item => item.id) || [],
                (props.userCredentialsAdvertiserClientsId.length > 0 && props.selectedClients.length == 0) ? props.userCredentialsAdvertiserClientsId : props.selectedClients.map(client => client.id),
                props.selectedCampaignSaas?.map(item => item.name) || []
              ))) : setData(props.selectedItems)
        )
        break;
      
      default:
        break;
    }
  }
  }, [props.show]);

  const handleSelectDeselectAll = () => {
    if (props.selectedItems.length === filteredData.length) {
      props.setSelectedItems([]);
    } else {
      props.setSelectedItems([...filteredData]);
    }
  }

  const handleCheckboxChange = (item) => {
    if (props.selectedItems.some(selectedItem => selectedItem.id === item.id)) {
      props.setSelectedItems(props.selectedItems.filter(selectedItem => selectedItem.id !== item.id));
    } else {
      props.setSelectedItems([...props.selectedItems, item]);
    }
  };

  const handleApply = () => {
    props.setApplyButton(true);
    setSearchTerm('');
    props.handlePerformanceReport();
    setData(null);
    setFilteredData(null);
    props.handleClose();
  };

  return (
    <Modal
      show={props.show}
      onHide={() => {
        setSearchTerm("");
        setData(null);
        setFilteredData(null);
        props.handleClose();
      }}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className="modalTitle" id="contained-modal-title-vcenter">
          {props.title}
        </Modal.Title>
        <div className="divModalFilterReportsTitle">
          <div className="divModalFilterReportsTitleSearcher">
            <SearcherInput
              iconSize={18}
              onChange={handleSearchFilter}
              placeholder="Search..."
              divStyle="searchDivGrey"
              inputStyle="searchInputGrey"
            />
          </div>
        </div>
      </Modal.Header>
      <Modal.Body>
        <div className="modalFilterSelectAll">
          <button
            className="buttonFilters buttonFiltersVariablesSmaller"
            onClick={handleSelectDeselectAll}>{filteredData==null || props.selectedItems.length !== filteredData.length ? 'Select all' : 'Deselect all'}
          </button>
        </div>
        <Row className="rowModalFilter">
          <Col xs={6} className="colModalFilter">
          {showSpinner ? (
            <div className="divSpinnerFlex">
              <Spinner className="loaderSpinner" animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
            </div>
          ) : (
            (filteredData != null && filteredData.length > 0) ? (
              filteredData.map((item) => (
                <div key={item.id} className="divListAttribute lettersPerformanceReport">
                  <input
                    key={item.id}
                    type="checkbox"
                    value={item.id}
                    checked={props.selectedItems.some(selectedItem => selectedItem.id === item.id)}
                    onChange={() => handleCheckboxChange(item)}
                  />
                  {props.title == i18n.advanceReportsAdvertiserSaas || props.title == i18n.advanceReportsAdvertiser
                  || props.title == i18n.advanceReportsClient || props.title == i18n.advanceReportsCampaignSaas ||
                  props.title == i18n.advanceReportsLineItemSaas || props.title == i18n.advanceReportsLineItem ||
                  props.title == i18n.advanceReportsCampaign ? item.id + ' - ' + item.name : item.name}
                  {item.country && ' - ' + item.country}
                </div>
              ))
            ) : (
              <h3 className="h3NoMatches">{i18n.advanceReportsNoDataFound}</h3>
            )
          )}
          </Col>
          <Col xs={6} className="colModalFilter">
            <h3 className="lettersPerformanceReport fontBold modalFiltersselectedItems">Selected</h3>
            {
              props.selectedItems &&
                props.selectedItems.map((item) => (
                  <div className="divListAttribute lettersPerformanceReport">
                    {item.name}
                    {item.country && ' - ' + item.country}
                  </div>
                ))
            }
          </Col>
        </Row>
        <div className="divDateRangeButton">
          <h3 onClick={props.handleClose} className="h3Cancel">{i18n.cancel}</h3>
          <Button text="Apply" className="largeSizeButton" onClick={handleApply} />
        </div>
      </Modal.Body>
    </Modal>
  )
};

export default ModalFiltersReports; 