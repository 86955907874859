import React, { useState, useMemo, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import SortableHeader from "./sortableHeader";
import i18n from "../../../i18n";
import { incompatibleFormatEngagements, incompatibleFormatViews } from "../constants/incompatibleFormats";
import { fieldsColumnsMap } from "../constants/fieldsColumnsMap";
import { initialOrderPerformanceReport } from "../constants/columnsInitialOrderPerformanceReport";
import { infoMessageOptions } from "../constants/infoMessageOptions";

const TablePerformanceReport = (props) => {
  const [sortConfig, setSortConfig] = useState({ key: 'impressions', direction: 'desc' });
  const [tableData, setTableData] = useState(null);
  const [columns, setColumns] = useState([]);

  useEffect(() => {
    setTableData(props.data);
    let initialColumns = [
      ...props.selectedRows.map(row => ({ id: row, type: 'row' })),
      ...props.selectedColumns.map(col => ({ id: col, type: 'column' }))
    ];

    if (props.selectedRows.includes(i18n.advanceReportsCountryApp) && props.selectedRows.includes(i18n.advanceReportsCountryYoutube)) {
      initialColumns = initialColumns.filter(column => column.id !== i18n.advanceReportsCountryApp && column.id !== i18n.advanceReportsCountryYoutube)
      initialColumns.push({ id: i18n.advanceReportsCountry, type: 'row' });
    }

    if (props.selectedColumns.includes(i18n.advanceReportsQuartiles)) {
      initialColumns = initialColumns.filter(column => column.id !== i18n.advanceReportsQuartiles);
      initialColumns.push(
        { id: i18n.advanceReportsFirstQuartile, type: 'column' },
        { id: i18n.advanceReportsMidQuartile, type: 'column' },
        { id: i18n.advanceReportsThirdQuartile, type: 'column' },
        { id: i18n.advanceReportsCompletedViews, type: 'column' }
      );
    }

    if (!props.isCurrencyReport) {
      initialColumns = initialColumns.filter(column => column.id !== i18n.advanceReportsCurrency &&
        column.id !== i18n.advanceReportsInversionBudgetCurrency &&
        column.id !== i18n.advanceReportsSpentCurrencyDeal &&
        column.id !== i18n.advanceReportsCpmCurrency &&
        column.id !== i18n.advanceReportsCpvCurrency
      );
    }

    initialColumns.sort((a, b) => {
      const indexA = initialOrderPerformanceReport.indexOf(a.id);
      const indexB = initialOrderPerformanceReport.indexOf(b.id);
      
      if (indexA === -1 && indexB === -1) return 0;
      if (indexA === -1) return 1;
      if (indexB === -1) return -1;
      
      return indexA - indexB;
    });
    
    setColumns(initialColumns);
    props.handleColumnOrder(initialColumns);
  }, [props.data, props.selectedRows, props.selectedColumns]);

  const sortedData = useMemo(() => {
    if (sortConfig.key && tableData !== null) {
      return [...tableData].sort((a, b) => {
        if (a[sortConfig.key] < b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? -1 : 1;
        }
        if (a[sortConfig.key] > b[sortConfig.key]) {
          return sortConfig.direction === 'asc' ? 1 : -1;
        }
        return 0;
      });
    }
    return tableData;
  }, [tableData, sortConfig]);

  const handleSort = (field) => {
    const direction = sortConfig.key === field && sortConfig.direction === 'asc' ? 'desc' : 'asc';
    setSortConfig({ key: field, direction });
  };
  
  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const newColumns = Array.from(columns);
    const [reorderedItem] = newColumns.splice(result.source.index, 1);
    newColumns.splice(result.destination.index, 0, reorderedItem);

    setColumns(newColumns);
    props.handleColumnOrder(newColumns);
  };

  const renderCell = (item, column) => {
    switch (column.id) {
      case i18n.advanceReportsIDHSDeal:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.dealId}</td>;
      case i18n.advanceReportsAdvertiser:
        return <td className="tdPerformanceReport tdPerformanceReportLarge">{item.advertiser}{item.advertiserCountry ? ' - ' + item.advertiserCountry : ''}</td>;
      case i18n.advanceReportsClient:
        return <td className="tdPerformanceReport tdPerformanceReportLarge">{item.client}</td>;
      case i18n.advanceReportsCampaignId:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.campaignId != null ? item.campaignId : '-'}</td>;
      case i18n.advanceReportsCampaign:
        return <td className="tdPerformanceReport tdPerformanceReportLarge">{item.campaign}</td>;
      case i18n.advanceReportsLineItemId:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.lineitemId != null ? item.lineitemId : '-'}</td>;
      case i18n.advanceReportsLineItem:
        return <td className="tdPerformanceReport tdPerformanceReportLarge">{item.lineItem}</td>;
      case i18n.advanceReportsCreativeId:
        return <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.creativeId ? item.creativeId : '-'}</td>;
      case i18n.advanceReportsCreative:
        return <td className="tdPerformanceReport tdPerformanceReportLarge">{item.creative != null ? item.creative : '-'}</td>;
      case i18n.advanceReportsInventoryType:
        return <td className="tdPerformanceReport">{item.inventoryType != null ? item.inventoryType : '-'}</td>;
      case i18n.advanceReportsFormat:
        return <td className="tdPerformanceReport tdPerformanceReportMediumLarge">{item.format != null ? item.format : '-'}</td>;
      case i18n.advanceReportsOfferType:
        return <td className="tdPerformanceReport">{item.offerType}</td>;
      case i18n.advanceReportsCountry:
      case i18n.advanceReportsCountryApp:
      case i18n.advanceReportsCountryYoutube:
        return <td className="thPerformanceReport">{item.country ? item.country : '-'}</td>;
      case i18n.advanceReportsAppName:
      case i18n.advanceReportsYoutubeChannel:
        return <td className="thPerformanceReport">{item.placement != null ? item.placement : '-'}</td>;
      case i18n.advanceReportsDevices:
        return <td className="thPerformanceReport tdPerformanceReportMid">{item.device != null ? item.device : '-'}</td>;
      case i18n.advanceReportsDate:
        return <td className="tdPerformanceReport">{item.date && item.date.split('T')[0]}</td>;
      case i18n.advanceReportsMonth:
        return <td className="thPerformanceReport">{item.monthName != null ? item.monthName : '-'}</td>;
      case i18n.advanceReportsStartDate:
        return <td className="tdPerformanceReport">{item.startDate.split('T')[0]}</td>;
      case i18n.advanceReportsEndDate:
        return <td className="tdPerformanceReport">{item.endDate.split('T')[0]}</td>;
      case i18n.advanceReportsGoal:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.goal != null ? item.goal.toLocaleString('en-US') : '-'}</td>;
      case i18n.advanceReportsImpressions:
        return <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.impressions != null ? item.impressions.toLocaleString('en-US') : '-'}</td>;
      case i18n.advanceReportsViews:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.completeViewsVideo == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat))  ? '-' : item.completeViewsVideo.toLocaleString('en-US')}</td>;
      case i18n.advanceReportsFirstQuartile:
        return <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.firstQuartile == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat)) ? "-" : item.firstQuartile.toLocaleString('en-US')}</td>;
      case i18n.advanceReportsMidQuartile:
        return <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.midQuartile == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat)) ? "-" : item.midQuartile.toLocaleString('en-US')}</td>;
      case i18n.advanceReportsThirdQuartile:
        return <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.thirdQuartile == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat)) ? "-" : item.thirdQuartile.toLocaleString('en-US')}</td>;
      case i18n.advanceReportsCompletedViews:
        return <td className="thPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.completeViewsVideoQuartile == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat)) ? "-" : item.completeViewsVideoQuartile.toLocaleString('en-US')}</td>;        
      case i18n.advanceReportsClicks:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.clicks != null ? item.clicks.toLocaleString('en-US') : '-'}</td>;
      case i18n.advanceReportsEngagements:
        return <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.engagements == null || incompatibleFormatEngagements.includes(item.format) ? '-' : item.engagements.toFixed(2)}</td>;
      case i18n.advanceReportsVTR:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.vtr == null || (incompatibleFormatViews.includes(item.format) && props.selectedRows.includes(i18n.advanceReportsFormat)) ? "-" : item.vtr.toFixed(2) + "%"}</td>;
      case i18n.advanceReportsCTR:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.ctr != null ? item.ctr.toFixed(2) + "%" : '-'}</td>;
      case i18n.advanceReportsER:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.er == null || incompatibleFormatEngagements.includes(item.format) ? '-' : item.er.toFixed(2) + "%"}</td>;
      case i18n.advanceReportsCurrency:
        return props.isCurrencyReport ? <td className="tdPerformanceReport">{item.currencyCode}</td> : null;
      case i18n.advanceReportsInversionBudgetCurrency:
        return props.isCurrencyReport ? <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudget != null ? (parseFloat(item?.inversionBudget?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td> : null;
      case i18n.advanceReportsInversionBudgetUSD:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item?.inversionBudgetUsd != null ? (parseFloat(item?.inversionBudgetUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>;
      case i18n.advanceReportsSpentCurrencyDeal:
        return props.isCurrencyReport ? <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item?.spent != null ? (parseFloat(item?.spent?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td> : null;
      case i18n.advanceReportsSpentUSD:
        return <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item?.spentUsd != null ? (parseFloat(item?.spentUsd?.toFixed(2)).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })) : '-'}</td>;
      case i18n.advanceReportsCpmCurrency:
        return props.isCurrencyReport ? <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.cpmCurrency != null ? item.cpmCurrency.toFixed(2).toLocaleString('en-US') : '-'}</td> : null;
      case i18n.advanceReportsCpm:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.cpm != null ? item.cpm.toFixed(2).toLocaleString('en-US') : '-'}</td>;
      case i18n.advanceReportsCpvCurrency:
        return props.isCurrencyReport ? <td className="tdPerformanceReport tdPerformanceReportMid tdPerformanceReportRight">{item.cpv != null ? item.cpvCurrency.toLocaleString('en-US') : '-'}</td> : null;
      case i18n.advanceReportsCpv:
        return <td className="tdPerformanceReport tdPerformanceReportRight">{item.cpv != null ? item.cpv.toLocaleString('en-US') : '-'}</td>;
      default:
        return <td>-</td>;
    }
  };

  return (
    tableData && (
      <DragDropContext onDragEnd={onDragEnd}>
        <table className="tablePerformanceReport">
          <Droppable droppableId="columns" direction="horizontal">
            {(provided, snapshot) => (
              <thead {...provided.droppableProps} ref={provided.innerRef}>
                <tr className="trPerformanceReport"
                style={{
                  backgroundColor: snapshot.isDraggingOver ? '#f3ecff' : 'inherit',
                }}
                >
                  {columns.map((column, index) => (
                    <Draggable key={column.id} draggableId={column.id} index={index}>
                      {(provided) => (
                        <SortableHeader
                          innerRef={provided.innerRef}
                          draggableProps={provided.draggableProps}
                          dragHandleProps={provided.dragHandleProps}
                          field={fieldsColumnsMap.find((item) => item.name === column.id)?.field}
                          label={column.id}
                          sortConfig={sortConfig}
                          onSort={handleSort}
                          headerLarge={column.id === i18n.advanceReportsIDHSDeal ||
                            column.id === i18n.advanceReportsCampaignId ||
                            column.id === i18n.advanceReportsLineItemId ||
                            column.id === i18n.advanceReportsInventoryType ||
                            column.id === i18n.advanceReportsOfferType ||
                            column.id === i18n.advanceReportsCountryApp ||
                            column.id === i18n.advanceReportsAppName ||
                            column.id === i18n.advanceReportsYoutubeChannel ||
                            column.id === i18n.advanceReportsInversionBudgetCurrency ||
                            column.id === i18n.advanceReportsInversionBudgetUSD ||
                            column.id === i18n.advanceReportsSpentCurrencyDeal ||
                            column.id === i18n.advanceReportsCountryYoutube
                          }
                          infoIcon={column.id === i18n.advanceReportsViews || column.id === i18n.advanceReportsVTR ||
                            column.id === i18n.advanceReportsAppName || column.id === i18n.advanceReportsYoutubeChannel}
                            infoIconMessage={infoMessageOptions.find(opt => opt.id === column.id)?.description}
                          exclamationIcon={columns.find((item) => item.id == i18n.advanceReportsCountryYoutube || item.id == i18n.advanceReportsCountry) && column.id === i18n.advanceReportsViews}
                        />
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </tr>
              </thead>
            )}
          </Droppable>
          <tbody>
            {sortedData.map((item, rowIndex) => (
              <tr key={rowIndex} className="trPerformanceReport">
                {columns.map((column, columnIndex) => (
                  <React.Fragment key={columnIndex}>
                    {renderCell(item, column)}
                  </React.Fragment>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </DragDropContext>
    )
  );
};

export default TablePerformanceReport;