import { createSlice } from "@reduxjs/toolkit";

export const get_backhround_color = createSlice({
    name : "get_backhround_color",
    initialState : {
        color : ""
    },
    reducers : {
        change_background_color : (state) => {
            const generateRandomColor = () => {
                const letters = '0123456789ABCDEF';
                let color = '#';
                for (let i = 0; i < 6; i++) {
                  color += letters[Math.floor(Math.random() * 16)];
                }
                return color;
              };
              state.color = generateRandomColor()
        }
    }
})

export const {change_background_color} = get_backhround_color.actions
export default get_backhround_color.reducer